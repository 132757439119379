import React, { useState, Dispatch, SetStateAction } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import GradeIcon from '@mui/icons-material/Grade';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import './style.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';
import cssUtils from '../../../Utils/cssUtils';
import InternalRoute from '../../../Utils/internalRoutes';
import ApplicationString from '../../../Constants/applicationString';
import {
  DeactivatedSvg,
  DefaultIcon,
  OwnerComponentDeleteAccountSvg,
  PersonFilledIcon,
  PlaceholderIcon,
  SizeIconFilled,
} from '../../../Assets/Svgs';
import { svgIcons } from '../../../Utils/commonIconsobject';
import DeleteVehiclePopUpComponent from './DeleteVehiclePopUp';
import { localStorageEnums } from '../../../Utils/enums';
import { removeLocalStorageItems } from '../../../Utils/utils';

interface OwnerVehicleDetailsComponentProps {
  vehicleDetails: IVehiclesData;
  handleDeactivateVehicle: (id: number) => void;
  showActions: boolean;
  expandActions: boolean;
  setExpandActions: Dispatch<SetStateAction<boolean>>;
  setShowActions: Dispatch<SetStateAction<boolean>>;
  handleAcivateVehicle: (id: number) => void;
  handleDeleteVehicle: (id: number) => void;
  isLoading: boolean;
}

const OwnerVehicleDetailsComponent: React.FC<
  OwnerVehicleDetailsComponentProps
> = ({
  vehicleDetails,
  isLoading,
  setExpandActions,
  expandActions,
  showActions,
  setShowActions,
  handleDeactivateVehicle,
  handleAcivateVehicle,
  handleDeleteVehicle,
}) => {
  const navigate = useNavigate();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const [showAllRestrictions, setShowAllRestrictions] = useState(false);

  const handleViewAllAmenities = () => {
    setShowAllAmenities(!showAllAmenities);
  };
  const handleViewAllRestrictions = () => {
    setShowAllRestrictions(!showAllRestrictions);
  };
  const handleActionClickOpen = () => {
    setExpandActions(true);
    setShowActions(true);
  };
  const handleActionClickClose = () => {
    setExpandActions(false);
    setShowActions(false);
  };
  const displayedAmenities = showAllAmenities
    ? vehicleDetails.metaData?.amenities
    : vehicleDetails.metaData?.amenities.slice(0, 4);

  const displayedRestrictions = showAllRestrictions
    ? vehicleDetails.metaData?.restricted
    : vehicleDetails.metaData?.restricted.slice(0, 4);

  const formattedRating = vehicleDetails.rating
    ? vehicleDetails.rating.toFixed(1)
    : '';
  return (
    <div className="w-full bg-page_bg dark:bg-primary_text">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress disableShrink />
        </div>
      ) : (
        <>
          {openDeletePopup && (
            <DeleteVehiclePopUpComponent
              open={openDeletePopup}
              setOpen={setOpenDeletePopup}
              handleDeleteVehicle={handleDeleteVehicle}
              vehicleId={vehicleDetails.id}
            />
          )}
          <div className="md:hidden relative">
            <Slide
              duration={1000}
              arrows={false}
              autoplay
              transitionDuration={1000}
              indicators
            >
              {vehicleDetails.images?.map((image, index) => {
                const isCoverImage = image?.isCover === true;

                // Render the cover image first
                if (isCoverImage) {
                  return (
                    <div key={image.id}>
                      <img
                        className="w-full h-70 sm:h-100 2xl:h-70 object-cover"
                        src={image.path || ''}
                        alt={`Slide ${index + 1}`}
                        loading="lazy"
                      />
                    </div>
                  );
                }
                return (
                  <div key={image.id}>
                    <img
                      className="w-full h-70 sm:h-100 2xl:h-70 object-cover"
                      src={image.path}
                      alt={`Slide ${index + 1}`}
                      loading="lazy"
                    />
                  </div>
                );
              })}
            </Slide>
          </div>
          <div className={cssUtils.layout.sectionLayout}>
            <div className="hidden md:block">
              <div className="p-1 md:p-2 hover:bg-transparent cursor-pointer">
                {vehicleDetails.images?.length === 1 && (
                  <img
                    src={
                      vehicleDetails.images &&
                      vehicleDetails.images.find((img) => img.isCover === true)
                        ?.path
                    }
                    alt={`vehicle ${vehicleDetails.title}`}
                    className="object-cover w-full h-115 rounded-xl shadow-md"
                  />
                )}
                {vehicleDetails.images?.length > 1 && (
                  <div className="grid grid-cols-3 grid-rows-2 h-96 gap-2">
                    <div className="col-span-2 row-span-2">
                      <img
                        src={
                          vehicleDetails.images &&
                          vehicleDetails.images.find(
                            (img) => img.isCover === true
                          )?.path
                        }
                        key={vehicleDetails.id}
                        alt={`vehicle ${vehicleDetails.title}`}
                        className="object-cover w-full h-full rounded-xl shadow-md"
                      />
                    </div>
                    <div className="grid grid-cols-2 grid-rows-2 gap-2 row-span-2 col-span-1">
                      {vehicleDetails.images
                        .filter((image) => !image.isCover)
                        .slice(0, 3) // Remove the cover image from the array
                        .map((image) => (
                          <img
                            src={image.path}
                            key={image.id}
                            alt={`vehicle ${vehicleDetails.title}`}
                            className="object-cover w-full h-full rounded-xl shadow-md"
                          />
                        ))}
                      <div className="relative h-full">
                        {vehicleDetails.images && vehicleDetails.images[4] && (
                          <>
                            <img
                              src={
                                vehicleDetails.images &&
                                vehicleDetails.images[4]?.path
                              }
                              alt={`vehicle ${vehicleDetails.title}`}
                              className="object-cover w-full h-full rounded-xl shadow-md"
                            />

                            {vehicleDetails.images.length > 5 && (
                              <Button
                                data-testid="Owner-view-photo-gallery-button"
                                onClick={() => {
                                  const path =
                                    InternalRoute.Vehicle_photo_gallery.replace(
                                      ':VehicleId',
                                      vehicleDetails.id.toString()
                                    );
                                  navigate(path);
                                }}
                                className="text-white text-center absolute inset-0 font-semibold text-xl capitalize cursor-pointer flex items-center justify-center bg-black bg-opacity-50"
                              >
                                + {vehicleDetails.images.length - 5} more
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {vehicleDetails.images?.length < 1 && (
                  <div className="object-cover bg-borderStroke w-full h-115 rounded-xl shadow-md">
                    <PlaceholderIcon />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col md:mt-10 lg:gap-12">
              <div className="flex flex-col">
                <div className="flex flex-col md:flex-row items-start justify-between gap-y-2 md:gap-y-0">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-4 items-center">
                      <Typography
                        fontSize={18}
                        fontWeight={600}
                        className="md:text-3xl text-2xl sm:text-2xl lg:text-4xl 2xl:text-5xl md:font-bold text-title_text dark:text-dart_web_accent text-nowrap"
                      >
                        {vehicleDetails.title && vehicleDetails.title}
                      </Typography>
                      <div className="hidden md:flex">
                        {vehicleDetails.rating && (
                          <>
                            <Divider
                              orientation="vertical"
                              flexItem
                              variant="fullWidth"
                              className="font-bold"
                            />
                            <Typography
                              fontSize={20}
                              fontWeight={400}
                              className="text-[#292929] ml-2 dark:text-white"
                            >
                              <GradeIcon
                                fontSize="inherit"
                                className="text-amber-300 mb-1 mr-1"
                              />
                              {formattedRating}
                              {` (${vehicleDetails?.reviewCount})`}
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:flex-row items-center mt-2 hidden gap-3">
                      {vehicleDetails?.capacity && (
                        <Typography
                          fontSize={28}
                          fontWeight={400}
                          className=" text-secondary_text text-xl sm:text-2xl 2xl:text-[28px] dark:text-dark_secondary_text gap-2 capitalize"
                        >
                          {`${
                            vehicleDetails.capacity !== null &&
                            vehicleDetails.capacity
                          }
                          ${vehicleDetails?.capacity > 1 ? ApplicationString.vehicleDetails.typoGraphyText.guests : ApplicationString.vehicleDetails.typoGraphyText.guest}
                       `}
                        </Typography>
                      )}
                      {vehicleDetails?.size && (
                        <>
                          <div className="size-1.5 bg-borderStroke rounded-full" />
                          <Typography
                            fontSize={28}
                            fontWeight={500}
                            className="text-secondary_text text-xl sm:text-2xl 2xl:text-[28px] dark:text-dark_secondary_text gap-2"
                          >
                            {` ${
                              vehicleDetails.size !== null &&
                              vehicleDetails.size
                            } ${ApplicationString.vehicleDetails.typoGraphyText.size} ${ApplicationString.vehicleDetails.typoGraphyText.lengthCaps}`}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2 relative">
                    <div className="text-nowrap md:mt-2 w-44 right-0 ml-auto bg-dark_formFieldBg  dark:bg-dark_bg_primary dark:border  dark:border-dark_border_color rounded-full flex flex-row items-center gap-1 pl-4 pr-2 md:pl-6 md:pr-4 py-0.5 md:py-1">
                      {expandActions ? (
                        <IconButton onClick={handleActionClickClose}>
                          <Typography className="capitalize text-base font-bold text-white">
                            {
                              ApplicationString.OwnervehicleDetailsComponent
                                .buttons.moreActions
                            }
                          </Typography>
                          <ExpandLessIcon className="text-white" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={handleActionClickOpen}>
                          <Typography className="capitalize text-base font-bold text-white">
                            {
                              ApplicationString.OwnervehicleDetailsComponent
                                .buttons.moreActions
                            }
                          </Typography>
                          <ExpandMoreIcon className="text-white" />
                        </IconButton>
                      )}
                    </div>
                    {showActions && (
                      <div className="flex flex-col w-55 items-start gap-y-2 px-4 py-3 md:right-0 ml-auto bg-white rounded-lg absolute top-full mt-2 z-10 dark:bg-dark_bg_secondary">
                        <Link
                          to={`${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleDetails?.id.toString())}`}
                          className="flex flex-row gap-x-2 items-center capitalize text-black dark:text-white"
                          data-testid="Owner-vehicle-details-edit-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeLocalStorageItems([
                              localStorageEnums.skippedSteps,
                              localStorageEnums.step,
                              localStorageEnums.stepsData,
                              localStorageEnums.isSlotError,
                              localStorageEnums.isStepperHasError,
                            ]);
                          }}
                        >
                          <DriveFileRenameOutlineOutlinedIcon color="primary" />
                          <Typography>
                            {
                              ApplicationString.OwnervehicleDetailsComponent
                                .editVehicle
                            }
                          </Typography>
                        </Link>
                        <Divider className="w-full border-t border-gray-300" />
                        {vehicleDetails?.isEnabled ? (
                          <Button
                            onClick={() =>
                              handleDeactivateVehicle(vehicleDetails?.id)
                            }
                            data-testid="Owner-vehicle-details-deactivate-button"
                            className="flex flex-row gap-x-2 items-center capitalize text-black dark:text-white"
                          >
                            <DeactivatedSvg />
                            <Typography>
                              {
                                ApplicationString.OwnervehicleDetailsComponent
                                  .deactivate
                              }
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              handleAcivateVehicle(vehicleDetails?.id)
                            }
                            className="flex flex-row gap-x-2 items-center capitalize text-black dark:text-white"
                          >
                            <DeactivatedSvg />
                            <Typography>
                              {
                                ApplicationString.OwnervehicleDetailsComponent
                                  .activate
                              }
                            </Typography>
                          </Button>
                        )}

                        <Divider className="w-full border-t border-gray-300" />
                        <Button
                          onClick={() => {
                            setOpenDeletePopup(true);
                          }}
                          className="flex flex-row gap-x-2 items-center"
                          data-testid="Owner-vehicle-details-delete-button"
                        >
                          <OwnerComponentDeleteAccountSvg />
                          <Typography className="text-red-500 capitalize">
                            {
                              ApplicationString.OwnervehicleDetailsComponent
                                .deleteVehicle
                            }
                          </Typography>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row mt-4 md:mt-2 items-center justify-start md:hidden">
                  <div className=" flex flex-col items-start bg-white w-21 h-21 rounded-lg mr-2 px-2 py-2 shadow-md">
                    <SizeIconFilled />
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      className="dark:text-dark_secondary_text"
                    >
                      {` ${vehicleDetails.size !== null ? vehicleDetails.size : 0}
                      ${ApplicationString.vehicleDetails.typoGraphyText.size}`}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={300}
                      className="text-light_text dark:text-dark_secondary_text"
                    >
                      {
                        ApplicationString.vehicleDetails.typoGraphyText
                          .lengthCaps
                      }
                    </Typography>
                  </div>
                  <div className="flex flex-col items-start bg-white w-21 h-21 rounded-lg px-2 py-2 shadow-md">
                    <PersonFilledIcon />
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      className="dark:text-dark_secondary_text"
                    >
                      {`${ApplicationString.vehicleDetails.typoGraphyText.upto}
                      ${
                        vehicleDetails.capacity !== null
                          ? vehicleDetails.capacity
                          : 0
                      }`}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={300}
                      className="text-light_text dark:text-dark_secondary_text"
                    >
                      {ApplicationString.vehicleDetails.typoGraphyText.people}
                    </Typography>
                  </div>
                </div>
                <Divider className="mt-4 md:hidden" />
                <Typography
                  className="mt-2 md:hidden dark:text-white"
                  fontSize={16}
                  fontWeight={600}
                >
                  {ApplicationString.vehicleDetails.typoGraphyText.About}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={300}
                  className="mt-2 md:mt-4 text-light_text dark:text-white md:font-semibold md:text-primary_text"
                >
                  {vehicleDetails.description}
                </Typography>
                <div className="bg-white border border-borderStroke dark:border-dark_border_color rounded-lg dark:bg-outlineButton backdrop:rounded-xl py-4 px-2 flex flex-col mt-2 md:mt-5">
                  <div className="flex flex-row items-center justify-start">
                    <img
                      src={vehicleDetails.ownerDetails?.avatarPath}
                      alt="baby with headphones"
                      className="w-14 h-14 rounded-full object-cover"
                    />
                    <div className="ml-2 flex flex-col">
                      <Typography
                        className="mt-1 text-title_text dark:text-white"
                        fontSize={18}
                        fontWeight={600}
                      >
                        {`${
                          ApplicationString.vehicleDetails.typoGraphyText
                            .ownedBy
                        } ${vehicleDetails.ownerDetails?.name}`}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        className="dark:text-secondary_text text-light_text"
                      >
                        {` ${
                          ApplicationString.vehicleDetails.typoGraphyText
                            .JoinedIn
                        }
                        ${vehicleDetails.ownerDetails?.joinedMonth}
                        ${vehicleDetails.ownerDetails?.joinedYear}`}
                      </Typography>
                    </div>
                  </div>
                  {vehicleDetails.rating && (
                    <div className="ml-17 flex flex-col md:hidden">
                      <Typography
                        className="mt-2 dark:text-white"
                        fontSize={18}
                        fontWeight={600}
                      >
                        {ApplicationString.vehicleDetails.typoGraphyText.rating}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        className="dark:text-secondary_text text-light_text underline"
                      >
                        {formattedRating}
                        {` (${vehicleDetails?.reviewCount})`}
                        <GradeIcon
                          fontSize="inherit"
                          className="text-amber-300 mb-1 ml-1"
                        />
                      </Typography>
                    </div>
                  )}
                </div>
                <Divider className=" mt-10 md:block md:mt-8" />
                <div className="md:mt-6">
                  <Typography
                    className="mt-3 md:text-2xl md:mt-5 dark:text-white"
                    fontSize={16}
                    fontWeight={600}
                  >
                    {ApplicationString.vehicleDetails.typoGraphyText.amenities}
                  </Typography>
                  <div className="mt-4 md:mt-6 grid grid-cols-1">
                    <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 md:gap-y-7 ">
                      {vehicleDetails.metaData?.amenities?.map((item) => {
                        let foundMatch = false;
                        const renderedAmenities = svgIcons.map((amenity) => {
                          if (
                            item.toLowerCase() === amenity.label.toLowerCase()
                          ) {
                            foundMatch = true;
                            return (
                              <Typography
                                key={item}
                                fontSize={14}
                                fontWeight={500}
                                className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                              >
                                {amenity.icon} {item}
                              </Typography>
                            );
                          }
                          return null;
                        });

                        if (!foundMatch) {
                          renderedAmenities.push(
                            <Typography
                              key={item}
                              fontSize={14}
                              fontWeight={500}
                              className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                            >
                              <DefaultIcon /> {item}
                            </Typography>
                          );
                        }
                        return renderedAmenities;
                      })}
                    </div>
                    <div className="md:hidden">
                      {showAllAmenities ? (
                        <div className="grid grid-cols-2 gap-4">
                          {vehicleDetails.metaData?.amenities?.map((item) => {
                            let foundMatch = false;
                            const renderedAmenities = svgIcons.map(
                              (amenity) => {
                                if (
                                  item.toLowerCase() ===
                                  amenity.label.toLowerCase()
                                ) {
                                  foundMatch = true;
                                  return (
                                    <Typography
                                      key={item}
                                      fontSize={14}
                                      fontWeight={500}
                                      className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                    >
                                      {amenity.icon} {item}
                                    </Typography>
                                  );
                                }
                                return null;
                              }
                            );
                            if (!foundMatch) {
                              renderedAmenities.push(
                                <Typography
                                  key={item}
                                  fontSize={14}
                                  fontWeight={500}
                                  className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                >
                                  <DefaultIcon /> {item}
                                </Typography>
                              );
                            }
                            return renderedAmenities;
                          })}
                        </div>
                      ) : (
                        <div className="grid grid-cols-2 gap-4 ">
                          {displayedAmenities?.map((item) => {
                            let foundMatch = false;
                            const renderedAmenities = svgIcons.map(
                              (amenity) => {
                                if (
                                  item.toLowerCase() ===
                                  amenity.label.toLowerCase()
                                ) {
                                  foundMatch = true;
                                  return (
                                    <Typography
                                      key={item}
                                      fontSize={14}
                                      fontWeight={500}
                                      className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                    >
                                      {amenity.icon} {item}
                                    </Typography>
                                  );
                                }
                                return null;
                              }
                            );

                            if (!foundMatch) {
                              renderedAmenities.push(
                                <Typography
                                  key={item}
                                  fontSize={14}
                                  fontWeight={500}
                                  className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                >
                                  <DefaultIcon /> {item}
                                </Typography>
                              );
                            }
                            return renderedAmenities;
                          })}
                        </div>
                      )}
                    </div>
                    {vehicleDetails.metaData?.amenities?.length > 4 && (
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        data-testid="owner-vehicle-details-view-all-amenities"
                        className="mt-3 text-primary cursor-pointer md:hidden"
                        onClick={handleViewAllAmenities}
                      >
                        {showAllAmenities
                          ? `${ApplicationString.vehicleDetails.ButtonText.showLess}`
                          : `${ApplicationString.vehicleDetails.ButtonText.viewAll}`}
                      </Typography>
                    )}
                  </div>
                </div>
                <Divider className="mt-4 md:mt-12" />
                <div className="md:mt-6">
                  <Typography
                    className="mt-3 md:text-2xl md:mt-5 dark:text-white"
                    fontSize={16}
                    fontWeight={600}
                  >
                    {ApplicationString.vehicleDetails.typoGraphyText.details}
                  </Typography>
                  <div className="grid grid-cols-2 gap-x-16 md:grid-cols-4 mt-4 md:mt-6 gap-y-4 md:gap-y-7">
                    {vehicleDetails?.maker && (
                      <>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          className=" text-light_text dark:text-white justify-end md:text-lg"
                        >
                          {
                            ApplicationString.vehicleDetails.typoGraphyText
                              .Manufacturer
                          }
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                        >
                          {vehicleDetails.maker}
                        </Typography>
                      </>
                    )}
                    {vehicleDetails?.manufacturingYear && (
                      <>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          className=" text-light_text dark:text-white justify-end md:text-lg"
                        >
                          {ApplicationString.vehicleDetails.typoGraphyText.year}
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                        >
                          {vehicleDetails.manufacturingYear}
                        </Typography>
                      </>
                    )}
                    {vehicleDetails.model && (
                      <>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          className=" text-light_text dark:text-white justify-end md:text-lg"
                        >
                          {
                            ApplicationString.vehicleDetails.typoGraphyText
                              .Model
                          }
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                        >
                          {vehicleDetails.model}
                        </Typography>
                      </>
                    )}
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      className=" text-light_text dark:text-white justify-end md:text-lg"
                    >
                      {
                        ApplicationString.vehicleDetails.typoGraphyText
                          .lengthCaps
                      }
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                    >
                      {` ${
                        vehicleDetails.size !== null ? vehicleDetails.size : 0
                      } ${ApplicationString.vehicleDetails.typoGraphyText.size}`}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      className=" text-light_text dark:text-white justify-end md:text-lg"
                    >
                      {ApplicationString.vehicleDetails.typoGraphyText.capacity}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                    >
                      {`${
                        vehicleDetails.capacity !== null
                          ? vehicleDetails.capacity
                          : 0
                      }
                      ${ApplicationString.vehicleDetails.typoGraphyText.person}`}
                    </Typography>
                    {vehicleDetails.metaData?.engineSize && (
                      <>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          className=" text-light_text dark:text-white justify-end md:text-lg"
                        >
                          {
                            ApplicationString.vehicleDetails.typoGraphyText
                              .engineSize
                          }
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                        >
                          {vehicleDetails.metaData?.engineSize || 'N/A'}
                        </Typography>
                      </>
                    )}
                    {vehicleDetails.metaData?.hitchSize && (
                      <>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          className=" text-light_text dark:text-white justify-end md:text-lg"
                        >
                          {
                            ApplicationString.vehicleDetails.typoGraphyText
                              .hitchSize
                          }
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                        >
                          {vehicleDetails.metaData?.hitchSize || 'N/A'}
                        </Typography>
                      </>
                    )}
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      className=" text-light_text dark:text-white justify-end md:text-lg"
                    >
                      {
                        ApplicationString.vehicleDetails.typoGraphyText
                          .isTrailerIncluded
                      }
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      className="md:text-lg text-bold dark:text-dark_secondary_text dark:font-normal"
                    >
                      {vehicleDetails.metaData?.isTrailerIncluded
                        ? 'Yes'
                        : 'No'}
                    </Typography>
                  </div>
                </div>
                <Divider className="mt-4 md:mt-12" />
                <div>
                  <Typography
                    className="mt-3 md:text-2xl md:mt-5 dark:text-white"
                    fontSize={16}
                    fontWeight={600}
                  >
                    {
                      ApplicationString.vehicleDetails.typoGraphyText
                        .restrictions
                    }
                  </Typography>
                  <div className="mt-4 md:mt-6 grid grid-cols-1">
                    <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 md:gap-y-7 ">
                      {vehicleDetails.metaData?.restricted?.map((item) => {
                        let foundMatch = false;
                        const renderedRestrictions = svgIcons.map(
                          (restriction) => {
                            if (
                              item.toLowerCase() ===
                              restriction.label.toLowerCase()
                            ) {
                              foundMatch = true;
                              return (
                                <Typography
                                  key={item}
                                  fontSize={14}
                                  fontWeight={500}
                                  className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                >
                                  {restriction.icon} {item}
                                </Typography>
                              );
                            }
                            return null;
                          }
                        );

                        if (!foundMatch) {
                          renderedRestrictions.push(
                            <Typography
                              key={item}
                              fontSize={14}
                              fontWeight={500}
                              className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                            >
                              <DefaultIcon /> {item}
                            </Typography>
                          );
                        }
                        return renderedRestrictions;
                      })}
                    </div>

                    <div className="md:hidden">
                      {showAllRestrictions ? (
                        <div className="grid grid-cols-2 gap-4">
                          {vehicleDetails.metaData?.restricted?.map((item) => {
                            let foundMatch = false;
                            const renderedRestrictions = svgIcons.map(
                              (restriction) => {
                                if (
                                  item.toLowerCase() ===
                                  restriction.label.toLowerCase()
                                ) {
                                  foundMatch = true;
                                  return (
                                    <Typography
                                      key={item}
                                      fontSize={14}
                                      fontWeight={500}
                                      className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                    >
                                      {restriction.icon} {item}
                                    </Typography>
                                  );
                                }
                                return null;
                              }
                            );

                            if (!foundMatch) {
                              renderedRestrictions.push(
                                <Typography
                                  key={item}
                                  fontSize={14}
                                  fontWeight={500}
                                  className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                >
                                  <DefaultIcon /> {item}
                                </Typography>
                              );
                            }
                            return renderedRestrictions;
                          })}
                        </div>
                      ) : (
                        <div className="grid grid-cols-2 gap-4 ">
                          {displayedRestrictions?.map((item) => {
                            let foundMatch = false;
                            const renderedRestrictions = svgIcons.map(
                              (restriction) => {
                                if (
                                  item.toLowerCase() ===
                                  restriction.label.toLowerCase()
                                ) {
                                  foundMatch = true;
                                  return (
                                    <Typography
                                      key={item}
                                      fontSize={14}
                                      fontWeight={500}
                                      className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                    >
                                      {restriction.icon} {item}
                                    </Typography>
                                  );
                                }
                                return null;
                              }
                            );

                            if (!foundMatch) {
                              renderedRestrictions.push(
                                <Typography
                                  key={item}
                                  fontSize={14}
                                  fontWeight={500}
                                  className="font-medium flex flex-row gap-2 md:text-lg dark:text-white text-primary_text items-center "
                                >
                                  <DefaultIcon /> {item}
                                </Typography>
                              );
                            }
                            return renderedRestrictions;
                          })}
                        </div>
                      )}
                    </div>
                    {vehicleDetails?.metaData?.restricted?.length > 4 && (
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        className="mt-3 text-primary cursor-pointer md:hidden"
                        data-testid="owner-vehicle-details-view-all-restrictions"
                        onClick={handleViewAllRestrictions}
                      >
                        {showAllRestrictions
                          ? `${ApplicationString.vehicleDetails.ButtonText.showLess}`
                          : `${ApplicationString.vehicleDetails.ButtonText.viewAll}`}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OwnerVehicleDetailsComponent;

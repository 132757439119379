import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Parallax, ParallaxProps } from 'react-parallax';
import cssUtils from '../../../Utils/cssUtils';
import exploreImg from '../../../Assets/explore.png';
import img1 from '../../../Assets/explore1.png';
import img2 from '../../../Assets/explore2.png';
import ApplicationString from '../../../Constants/applicationString';
import './style.css';
import ColorModeContext from '../../../Utils/ColorModeContext';
import exploreImgDark from '../../../Assets/exploreDark1.png';

interface CustomParallaxProps extends ParallaxProps {
  strength?: number;
  bgImage: string;
}

const ExploreSection1: React.FC = () => {
  const navigate = useNavigate();
  const { currentTheme } = useContext(ColorModeContext);
  const vehicle = ApplicationString.exploreSection.vehicleCategory;
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const customParallaxProps: CustomParallaxProps = {
    strength: 500,
    bgImage: currentTheme === 'dark' ? exploreImgDark : exploreImg,
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Parallax
      strength={customParallaxProps.strength}
      bgImage={customParallaxProps.bgImage}
    >
      <div className="w-full">
        <div className={cssUtils.layout.sectionLayout}>
          <div className="flex flex-col md:flex-row w-full gap-8 justify-between mt-0 sm:mt-40 2xl:mt-80 md:mt-60">
            <div
              role="button"
              tabIndex={0}
              className="w-full relative cursor-pointer"
              onMouseEnter={() => setIsHovered1(true)}
              onMouseLeave={() => setIsHovered1(false)}
              onClick={() =>
                handleNavigation(
                  `/explore?category=${ApplicationString.searchScreen.categories.watercraft}`
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleNavigation(
                    `/explore?category=${ApplicationString.searchScreen.categories.watercraft}`
                  );
                }
              }}
            >
              <img
                className={`rounded-3xl 2xl:rounded-4xl w-full h-80 md:h-80 object-cover md:object-none transition-opacity ${isHovered1 ? 'opacity-80' : 'opacity-100'}`}
                src={img1}
                alt="Boat on water"
              />
              <div className="absolute bottom-8 w-full text-center text-white text-xl 2xl:text-4xl  font-bold">
                {vehicle.waterCraft}
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              className="w-full relative cursor-pointer"
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleNavigation(
                    `/explore?category=${ApplicationString.searchScreen.categories.landVehicles}`
                  );
                }
              }}
              onClick={() =>
                handleNavigation(
                  `/explore?category=${ApplicationString.searchScreen.categories.landVehicles}`
                )
              }
            >
              <img
                className={`rounded-3xl  2xl:rounded-4xl w-full h-80 md:h-80 object-cover md:object-none transition-opacity ${isHovered2 ? 'opacity-80' : 'opacity-100'}`}
                src={img2}
                alt="Another boat on water"
              />
              <div className="absolute bottom-8 w-full text-center text-white text-xl 2xl:text-4xl font-bold">
                {vehicle.landVehicle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default ExploreSection1;

import React from 'react';
import StepperComponent from '../VehicleStepperComponents/StepperComponent';
import { ICreateVehicleData } from '../../Interfaces/Stepper';

interface IStepperProps {
  createVehicle: (
    data: ICreateVehicleData,
    setShowVerified: (arg: boolean) => void
  ) => Promise<void>;
  isLoading: boolean;
}

const VehicleStepperComponent: React.FC<IStepperProps> = ({
  createVehicle,
  isLoading,
}) => {
  return (
    <div className="w-full flex flex-col items-center  bg-[#F3F4F6] dark:bg-Light_bg_surface">
      <StepperComponent
        createVehicle={createVehicle}
        isEdit={false}
        isLoading={isLoading}
      />
    </div>
  );
};

export default VehicleStepperComponent;

import React from 'react';
import cssUtils from '../../Utils/cssUtils';
import ApplicationString from '../../Constants/applicationString';

interface PaymentConfirmProps {
  handleConfirmAndPay: () => void;
}

const PaymentConfirmModal: React.FC<PaymentConfirmProps> = ({
  handleConfirmAndPay,
}) => {
  return (
    <div>
      <div className="p-4">
        <div className="h-80">
          <p className="font-semibold text-xl dark:text-white">
            {ApplicationString.bookingDetailsData.confirmAndPayLabel}
          </p>
          {/* Stripe card will be place here */}
        </div>
        <button
          type="button"
          className={`w-full ${cssUtils.button.secondary}`}
          onClick={handleConfirmAndPay}
          data-testid="confirmPay-button"
        >
          {ApplicationString.bookingDetailsData.confirmAndPay}
        </button>
      </div>
    </div>
  );
};

export default PaymentConfirmModal;

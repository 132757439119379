import React from 'react';
import InstantBooking from '../OwnerDashboardComponents/InstantBooking';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { IOwnerStatistics } from '../../Interfaces/Owner/ownerDashboard.interface';
import OwnerStatics from '../OwnerDashboardComponents/OwnerStatics';
import OwnerDashboardBookings from '../OwnerDashboardComponents/OwnerDashboardBookings';

interface IOwnerDashboardComponentProps {
  ownerStatistics: IOwnerStatistics | undefined;
  isStatisticsLoading: boolean;
  instantBookings: IMyBookings[];
  allBookings: IMyBookings[];
  instantBookingActionsHandler: (
    bookingId: number,
    payload: string
  ) => Promise<void>;
  isLoading: boolean;
}

const OwnerDashboardComponent: React.FC<IOwnerDashboardComponentProps> = (
  props
): JSX.Element => {
  const {
    ownerStatistics,
    instantBookings,
    instantBookingActionsHandler,
    isStatisticsLoading,
    allBookings,
    isLoading,
  } = props;

  return (
    <div className="bg-page_bg dark:bg-dark_bg_surface">
      <OwnerStatics
        ownerStatistics={ownerStatistics}
        isStatisticsLoading={isStatisticsLoading}
      />
      {instantBookings.length > 0 && (
        <InstantBooking
          instantBookings={instantBookings}
          instantBookingActionsHandler={instantBookingActionsHandler}
        />
      )}
      <OwnerDashboardBookings allBookings={allBookings} isLoading={isLoading} />
    </div>
  );
};

export default OwnerDashboardComponent;

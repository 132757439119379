import React from 'react';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordChangedSuccessfullyComponent from '../../Components/ForgotPasswordComponent/ForgotPasswordChangedSuccessfullyComponent';
import { removeLocalStorageAccountInfoOnDeleteOrLogout } from '../../Utils/utils';
import { navigationKeys } from '../../Utils/enums';

const ForgotPasswordChangedSuccessfullyContainer: React.FC = () => {
  const navigate = useNavigate();
  const handleLogoutOnSuccess = () => {
    removeLocalStorageAccountInfoOnDeleteOrLogout(
      navigate,
      navigationKeys.logout
    );
  };
  return (
    <ForgotPasswordChangedSuccessfullyComponent
      handleLogoutOnSuccess={handleLogoutOnSuccess}
    />
  );
};

export default ForgotPasswordChangedSuccessfullyContainer;

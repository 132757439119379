import React from 'react';

interface InfoBlockProps {
  icon: string;
  label: string;
  value: string | number;
  // eslint-disable-next-line react/require-default-props
}

const InfoBlock: React.FC<InfoBlockProps> = ({ icon, label, value }) => (
  <div className="w-full flex flex-col dark:border-dark_borderStroke rounded-3xl p-4 bg-white dark:bg-dark_bg_secondary border border-gray-300">
    <div className="flex gap-2 items-center">
      <img src={icon} className="w-4 h-4" alt="icon" />
      <span className="text-secondary_text dark:text-dark_text_sub_primary">
        {label}
      </span>
    </div>
    <span className="mt-2 text-lg md:text-2xl dark:text-dart_web_accent text-title_text font-semibold">
      {value}
    </span>
  </div>
);

export default InfoBlock;

import React, { useEffect } from 'react';
import {
  type NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ProtectedRoute from './protectedRoutes';
import {
  getDetailsFromLocalStorage,
  removeLocalStorageAccountInfoOnDeleteOrLogout,
} from '../Utils/utils';

import InternalRoute from '../Utils/internalRoutes';
import RouteConfig, { RouteConfigType } from './appRouteConfig';
import { localStorageEnums, navigationKeys } from '../Utils/enums';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/LoginInterfaces';

const AppRouter: React.FC = () => {
  const userInfo = getDetailsFromLocalStorage<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  );
  const navigate: NavigateFunction = useNavigate();
  const match = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (match.pathname === InternalRoute.Logout) {
      removeLocalStorageAccountInfoOnDeleteOrLogout(
        navigate,
        navigationKeys.logout
      );
      navigate(InternalRoute.EmailLogin);
    }
  }, [match]);

  return (
    <Routes>
      {RouteConfig?.map((route: RouteConfigType) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <ProtectedRoute
              allowedLayout={route.allowedLayout}
              isProtectedRoute={route.isProtectedRoute}
              userToken={userInfo?.accessToken}
              element={route.element}
              allowedRoles={route.allowedRoles}
            />
          }
        />
      ))}
    </Routes>
  );
};

export default AppRouter;

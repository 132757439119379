/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import cssUtils from '../../../Utils/cssUtils';
import SelectionComponent from '../CustomSelect';
import Calendar from '../../../Assets/Calendar.png';
import './style.css';
import CommonDatePicker from '../CommonDatePicker';
import datePickerIcon from '../../../Assets/Large.png';
import ApplicationString from '../../../Constants/applicationString';
import ColorModeContext from '../../../Utils/ColorModeContext';
import {
  getLatLonByPlaceId,
  getLatLongFromAddress,
  getPlaceSuggestions,
} from '../../../Services/location.service';
import { debounce } from '../../../Utils/utils';

interface SearchProps {
  onSearch: (data: {
    category: string;
    latlong: string;
    locationName: string;
    startDate: string;
    endDate: string;
  }) => void;
  initialCategory: string;
  initialLocation: string;
  initialCoordinates: string;
}

type Category = {
  label: string;
  value: string;
};

const SearchComponent: React.FC<SearchProps> = ({
  onSearch,
  initialCategory,
  initialLocation,
  initialCoordinates,
}) => {
  const [category, setVehicleCategory] = React.useState(initialCategory || '');
  const [inputValue, setInputValue] = React.useState(initialLocation || '');
  const [coordinates, setCoordinates] = React.useState<string>('');
  const [suggestions, setSuggestions] = React.useState([]);
  const [dateRange, setDateRange] = React.useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const { currentTheme } = useContext(ColorModeContext);

  const handleSuggestionSelect = (
    event: React.SyntheticEvent,
    newValue: { place_id: string }
  ) => {
    if (newValue) {
      getLatLonByPlaceId(newValue.place_id, 'geometry').then((res) => {
        if (res) {
          if (typeof res === 'string') {
            setCoordinates(res);
          } else {
            setCoordinates(JSON.stringify(res));
          }
        }
      });
    } else {
      setCoordinates('');
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSuggestions = useCallback(
    debounce(async (value: string) => {
      if (value) {
        const suggestionValues = await getPlaceSuggestions(value);
        if (suggestionValues) {
          setSuggestions(suggestionValues);
        }
      }
    }, 300),
    []
  );
  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value === '') {
      setCoordinates('');
      setInputValue('');
    }
    if (value) {
      setInputValue(value);
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    setVehicleCategory(initialCategory || '');
    setInputValue(initialLocation || '');
    setCoordinates(initialCoordinates || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCategory, initialLocation, initialCoordinates]);

  const handleSearch = () => {
    onSearch({
      category,
      latlong: coordinates || '',
      locationName: inputValue || '',
      startDate: dateRange.startDate || '',
      endDate: dateRange.endDate || '',
    });
  };

  const handleDateChange = (newValue: {
    startDate: string | null;
    endDate: string | null;
  }) => {
    setDateRange(newValue);
  };

  const handleSelect = (Category: Category) => {
    setVehicleCategory(Category.value);
  };

  const searchInputTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (searchInputTimeoutRef.current) {
      clearTimeout(searchInputTimeoutRef.current);
    }
    searchInputTimeoutRef.current = setTimeout(() => {
      if (
        inputValue &&
        (suggestions.length === 0 ||
          !suggestions.some((suggestion: any) =>
            suggestion.description
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          ))
      ) {
        getLatLongFromAddress(inputValue, 'searchInput').then((details) => {
          if (typeof details === 'string') {
            setCoordinates(details);
          } else {
            setCoordinates(JSON.stringify(details));
          }
        });
      }
    }, 500);

    return () => {
      if (searchInputTimeoutRef.current) {
        clearTimeout(searchInputTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, suggestions]);

  return (
    <div className="w-full">
      <div className="bg-white dark:bg-dark_bg_secondary flex flex-col xl:flex-row items-center p-4 md:p-6 lg:px-8 rounded-2xl justify-between shadow-lg border border-slate-200 dark:border-dark_borderStroke space xl:space-y-0 xl:space-x-4">
        {/* Vehicle Category */}
        <div className="flex 2xl:min-w-65 w-full lg:w-full">
          <SelectionComponent
            categorySelected={initialCategory}
            options={ApplicationString.categoryOptions}
            label={ApplicationString.searchLabels.vehicleCategory}
            onSelect={handleSelect}
          />
        </div>

        {/* Location */}
        <div className="flex w-full lg:w-full items-center gap-4">
          <div className="bg-slate-200 w-0.5 h-10 hidden 2xl:block" />
          <div className="flex w-full md:w-auto flex-col items-start">
            <div className="flex text-slate-500 items-center space-x-2">
              <img className="" src={datePickerIcon} alt="" />
              <p className="text-sm font-medium dark:text-dark_text_sub_primary text-gray-700">
                {ApplicationString.searchLabels.location}
              </p>
            </div>
            <div className="w-full mt-2">
              <Autocomplete
                freeSolo
                sx={{
                  border: 'transparent',
                  padding: 0,
                  margin: 0,
                  height: '48px',
                }}
                className="md:w-95 w-full"
                options={suggestions}
                clearOnEscape
                inputValue={inputValue}
                getOptionLabel={(option: any) => option.description || ''}
                onInputChange={handleInputChange}
                onChange={handleSuggestionSelect}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    placeholder="Search location"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        border: 'none',
                        padding: '0 !important',
                        height: '48px',
                        classes: {
                          root: 'padding-0',
                        },
                        '& .MuiOutlinedInput-root': {
                          padding: 0,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiInputBase-input': {
                          color:
                            currentTheme === 'dark'
                              ? '#4968AC !important'
                              : '#283B64 !important',
                        },
                      },
                      style: {
                        backgroundColor:
                          currentTheme === 'dark' ? '#292929' : 'white',
                      },
                      className: 'w-full text-lg md:text-2xl font-semibold',
                    }}
                    InputLabelProps={{
                      sx: {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {/* Date */}
        <div className="flex w-full lg:w-full items-start lg:items-center gap-4">
          <div className=" bg-slate-200 w-0.5 h-10 hidden 2xl:block" />
          <div className="flex w-full 2xl:w-auto flex-col">
            <div className="flex text-slate-500 items-center space-x-2">
              <img className="" src={Calendar} alt="" />
              <p className="text-sm font-medium dark:text-dark_text_sub_primary text-gray-700">
                {ApplicationString.searchLabels.date}
              </p>
            </div>
            <div className="search-date-picker">
              <CommonDatePicker
                isDateSingle={false}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>

        {/* Search Button */}
        <button
          type="button"
          className={`h-fit ${cssUtils.button.secondary} mt-4 md:mt-0 md:w-auto w-full text-base`}
          onClick={handleSearch}
          data-testid="search-button"
          aria-label="search-button"
        >
          {ApplicationString.commonButton.search}
        </button>
      </div>
    </div>
  );
};

export default SearchComponent;

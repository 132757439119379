import { useState, useEffect } from 'react';
import { PAGE_SIZE, PAGE_SIZE_MID, PAGE_SIZE_SMALL } from '../Utils/utils';

const usePageSize = (): number => {
  const determinePageSize = (width: number): number => {
    if (width >= 300 && width < 640) {
      return PAGE_SIZE_SMALL;
    }
    if (width >= 640 && width < 1280) {
      return PAGE_SIZE_MID;
    }
    if (width >= 1280) {
      return PAGE_SIZE;
    }
    return PAGE_SIZE_SMALL;
  };

  const [pageSize, setPageSize] = useState<number>(
    determinePageSize(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setPageSize(determinePageSize(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return pageSize;
};
export default usePageSize;

import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import userIcon from '../../Assets/personIcon.png';
import cssUtils from '../../Utils/cssUtils';
import ApplicationString from '../../Constants/applicationString';

type PassengerDropdownProps = {
  passengerCount: number;
  passengerCapacity: number;
  setPassengerCount: (count: number) => void;
  // eslint-disable-next-line react/require-default-props
  borderRadious?: string;
};

const PassengerDropdown: React.FC<PassengerDropdownProps> = ({
  passengerCount,
  passengerCapacity,
  setPassengerCount,
  borderRadious = 'rounded-3xl',
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  const incrementPassengerCount = () => {
    if (passengerCount < passengerCapacity) {
      setPassengerCount(passengerCount + 1);
    }
  };

  const decrementPassengerCount = () => {
    if (passengerCount > 1) {
      setPassengerCount(passengerCount - 1);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="relative" ref={menuRef}>
      <button
        type="button"
        data-testid="passengerDropdown-button"
        aria-expanded={isDropdownOpen}
        className={`w-full p-4 dark:border-dark_borderStroke dark:bg-dark_bg_secondary bg-white border gap-1 border-gray-300 ${borderRadious || 'rounded-3xl'} flex flex-col justify-between`}
        onClick={toggleDropdown}
      >
        <div className="flex w-full justify-between">
          <div className="flex gap-2 items-center">
            <img src={userIcon} className="w-4 h-4" alt="info text" />
            <span className="text-secondary_text dark:text-dark_text_sub_primary">
              {ApplicationString.passengerDropdown.passengers}
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-gray-800 dark:text-dark_text_sub_primary text-sm"
            />
          </div>
          {/* <div className="text-lg font-semibold underline dark:text-white text-primary_text">
            {ApplicationString.passengerDropdown.edit}
          </div> */}
        </div>
        <span className="mt-2 text-lg md:text-2xl dark:text-dart_web_accent text-title_text font-semibold">
          {passengerCount} {ApplicationString.passengerDropdown.passengers}
          {passengerCount > 1 ? 's' : ''}
        </span>
      </button>

      {isDropdownOpen && (
        <div className="absolute w-full shadow-xl bg-white border dark:border-dark_borderStroke dark:shadow-2xl dark:shadow-slate-700 dark:bg-dark_bg_secondary border-gray-300 rounded-xl mt-1 p-4 z-10">
          <div className="flex justify-between items-center mb-4">
            <span className="text-lg md:text-xl dark:text-dart_web_accent text-title_text font-semibold">
              {ApplicationString.passengerDropdown.choosePassengers}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <img src={userIcon} className="w-4 h-4" alt="info text" />
              <span>{ApplicationString.passengerDropdown.noOfPassengers}</span>
            </div>
            <div className="flex items-center">
              <button
                aria-label="decrement"
                type="button"
                className="w-8 h-8 bg-gray-200 dark:border dark:border-dark_borderStroke dark:bg-dark_bg_surface text-gray-600 rounded-full flex items-center justify-center"
                onClick={decrementPassengerCount}
                data-testid="decrementPassengerCount-button"
              >
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M20 12H4"
                  />
                </svg>
              </button>
              <span className="mx-4 text-lg md:text-xl dark:text-dart_web_accent text-title_text font-semibold">
                {passengerCount}
              </span>
              <button
                type="button"
                aria-label="increment"
                className="w-8 h-8 bg-darkPrimary text-white dark:text-title_text rounded-full flex items-center justify-center"
                onClick={incrementPassengerCount}
                data-testid="incrementPassengerCount-button"
              >
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex w-full mt-4 justify-end">
            <button
              type="button"
              aria-expanded={isDropdownOpen}
              className={cssUtils.button.primary}
              onClick={toggleDropdown}
              data-testid="passengerDropdown-close-button"
            >
              {ApplicationString.passengerDropdown.close}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PassengerDropdown;

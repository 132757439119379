import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError, HttpStatusCode } from 'axios';
import OwnerBookingDetailsComponent from '../../Components/OwnerBookingDetails';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { myBookingsDefaultValues } from '../../Constants/commonConst';
import { getLocalStorageAccountInfo, showApiError } from '../../Utils/utils';
import {
  ITermsAndCondition,
  IUserSettingsData,
} from '../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums, myBookingsStatusEnums } from '../../Utils/enums';
import { useToast } from '../../Services/ToastService';
import ApplicationString from '../../Constants/applicationString';
import { IApiErrorResponse } from '../../Interfaces/interfaces';

const OwnerBookingDetailsContainer: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [ownerBookingDetails, setOwnerBookingDetails] =
    React.useState<IMyBookings>(myBookingsDefaultValues);
  const { id: bookingId } = useParams<{ id: string }>();
  const [TermsAndConditions, setTermsAndConditions] = React.useState<
    ITermsAndCondition[]
  >([]);
  const [requestModal, setRequestModal] = useState<boolean>(false);
  const handleCloseRequestModal = () => {
    setRequestModal(false);
  };

  const handleOpenRequestModal = () => {
    setRequestModal(true);
  };
  const toast = useToast();
  const fetchOwnerBookingDetailsById = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ApiRequest.get(
        URLS.MY_BOOKINGS_DETAILS.replace('#{id}', bookingId ?? '')
      );
      setOwnerBookingDetails(res.data);
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    fetchOwnerBookingDetailsById();
    const settingsData = getLocalStorageAccountInfo<IUserSettingsData>(
      localStorageEnums.settings
    )?.setting;
    if (settingsData) {
      const { securityDeposit, termsAndCondition } = settingsData;

      // Define titles for "Regular Bookings" and "Short Notice Bookings"
      const regularBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.RegularBookings;
      const shortNoticeBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.ShortNoticeBookings;

      // Extract details for "Regular Bookings"
      const regularBookingsDetails =
        termsAndCondition.find((term) => term.title === regularBookingsTitle)
          ?.details || [];

      // Extract details for "Short Notice Bookings"
      const shortNoticeBookingsDetails =
        termsAndCondition.find(
          (term) => term.title === shortNoticeBookingsTitle
        )?.details || [];

      // Ensure securityDeposit is an array
      const securityDepositDetails = Array.isArray(securityDeposit)
        ? securityDeposit
        : [securityDeposit];

      // Combine details, ensuring they are flat arrays
      const combinedDetails = [
        regularBookingsTitle,
        ...regularBookingsDetails,
        shortNoticeBookingsTitle,
        ...shortNoticeBookingsDetails,
      ];

      // Update terms and conditions
      const updatedTermsAndConditions: ITermsAndCondition[] =
        termsAndCondition.map((term) => {
          if (term.title === regularBookingsTitle) {
            return {
              ...term,
              details: securityDepositDetails,
            };
          }
          if (term.title === shortNoticeBookingsTitle) {
            return {
              ...term,
              details: combinedDetails.flat(), // Flatten the combined details array if it's nested
            };
          }
          return term;
        });

      setTermsAndConditions(updatedTermsAndConditions);
    } else {
      setTermsAndConditions([]);
    }
    scrollToTop();
  }, []);
  const acceptBookingHandler = async () => {
    try {
      setLoading(true);
      const res = await ApiRequest.put(
        URLS.MY_BOOKINGS_CANCEL.replace('#{id}', String(bookingId)),
        {
          type: myBookingsStatusEnums.active,
        }
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast.success(
          ApplicationString.OwnerBookingDetails.OwnerBookingRequestStatus
            .acceptBooking
        );
        handleCloseRequestModal();
        fetchOwnerBookingDetailsById();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setLoading(false);
    }
  };
  const rejectBookingHandler = async () => {
    try {
      setLoading(true);
      const res = await ApiRequest.put(
        URLS.MY_BOOKINGS_CANCEL.replace('#{id}', String(bookingId)),
        {
          type: myBookingsStatusEnums.rejected,
        }
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast.success(
          ApplicationString.OwnerBookingDetails.OwnerBookingRequestStatus
            .rejectBooking
        );
        handleCloseRequestModal();
        fetchOwnerBookingDetailsById();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OwnerBookingDetailsComponent
      loading={loading}
      ownerBookingDetails={ownerBookingDetails}
      TermsAndConditions={TermsAndConditions}
      requestModal={requestModal}
      handleCloseRequestModal={handleCloseRequestModal}
      handleOpenRequestModal={handleOpenRequestModal}
      onRejectHandler={rejectBookingHandler}
      onAcceptHandler={acceptBookingHandler}
    />
  );
};

export default OwnerBookingDetailsContainer;

import React from 'react';
import { Link } from 'react-router-dom';
import { commonConfig } from '../../../Constants/commonConfig';
import PlayStore from '../../../Assets/playStore.png';
import IosStore from '../../../Assets/iosStore.png';
import Instagram from '../../../Assets/Svgs/insta.png';
import Facebook from '../../../Assets/Svgs/fb.png';
import ApplicationString from '../../../Constants/applicationString';

const UserFooter: React.FC = () => {
  return (
    <>
      <footer className="relative z-10 w-full p-4 dark:bg-dark_bg_secondary bg-white shadow md:flex md:items-center ">
        <div className="mx-auto sm:flex max-w-7xl w-full justify-between px-2 py-4 2xl:px-0 lg:px-8">
          <div className="flex sm:mb-0 mb-6 flex-col gap-4 sm:gap-4">
            <div className="flex flex-col gap-2 sm:gap-2">
              <p className="text-black dark:text-white font-normal text-xl leading-7">
                {ApplicationString.footer.text.connectWithUs}
              </p>
              <div className="flex gap-2">
                {/* TODO: Add proper links here once available */}
                <Link
                  to="/"
                  className="text-blue-600 hover:underline"
                  data-testid="userFooter-link1-common"
                >
                  <img
                    className="h-8 w-auto"
                    src={Instagram}
                    alt=""
                    data-testid="userFooter-image1-common"
                  />
                </Link>
                <Link
                  to="/"
                  className="text-blue-600 hover:underline"
                  data-testid="userFooter-link2-common"
                >
                  <img
                    className="h-8 w-auto"
                    src={Facebook}
                    alt=""
                    data-testid="userFooter-image2-common"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 sm:gap-2 ">
            <p className="text-black dark:text-white font-normal sm:text-end text-xl leading-7">
              {ApplicationString.footer.text.downloadOnlifeApp}
            </p>
            <div className="flex items-center gap-2">
              <img
                className="3xsm:h-9 2xsm:h-9.5 xsm:h-11.5 w-fit cursor-pointer"
                src={IosStore}
                alt=""
                data-testid="userFooter-image3-common"
              />
              <img
                className="3xsm:h-9 2xsm:h-10 xsm:h-12 w-fit cursor-pointer"
                src={PlayStore}
                alt=""
              />
            </div>
          </div>
        </div>
      </footer>
      <footer className="relative z-10 w-full p-4 dark:bg-dark_bg_secondary bg-white border-t border-slate-300 shadow md:flex md:items-center md:justify-between  md:p-6  dark:border-dark_border_color">
        <div className="text-darkPrimary font-semibold mx-auto sm:flex px-2 sm:px-0 max-w-7xl w-full justify-between lg:px-8 2xl:px-0">
          <span className="text-sm  dark:text-darkPrimary">
            {commonConfig.CopyrightText}
          </span>
          {/* <ul className="flex sm:text-center justify-center flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
            <li>
              <Link to="" className="hover:underline me-4">
                {ApplicationString.footer.text.privacy}
              </Link>
            </li>
            <li>
              <Link to="" className="hover:underline me-4">
                {ApplicationString.footer.text.privacy}
              </Link>
            </li>
          </ul> */}
        </div>
      </footer>
    </>
  );
};

export default UserFooter;

import React, { ReactNode } from 'react';
import Footer from '../Footer';
import NewsLetterComponent from '../NewsLetter';
import UserHeader from '../UserHeader';

const UserDefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className=" dark:text-bodydark flex flex-col min-h-screen">
      <UserHeader />
      <main className="mt-20 lg:mt-24 z-20 relative flex-1 min-h-0">
        {children}
      </main>
      <NewsLetterComponent />
      <Footer />
    </div>
  );
};

export default UserDefaultLayout;

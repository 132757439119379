import React, { ReactNode } from 'react';
import OwnerHeader from '../OwnerHeader';
import './style.css';

const OwnerWithoutLoginLayout: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <div className=" before-logic-screen dark:text-bodydark flex flex-col min-h-screen">
      <OwnerHeader />
      <main className="mt-20 lg:mt-24 z-20 relative flex-1 min-h-0">
        {children}
      </main>
    </div>
  );
};

export default OwnerWithoutLoginLayout;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { VerifiedCardSvg } from '../../Assets/Svgs';
import ApplicationString from '../../Constants/applicationString';
import InternalRoute from '../../Utils/internalRoutes';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';

const AccountCreatedComponent: React.FC = () => {
  return (
    <BackgroundLayout>
      <div className="xsm:w-100  max-h-[100vh] border border-borderStroke bg-white dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-8 px-6 flex flex-col">
          <div className="flex justify-center">
            <VerifiedCardSvg />
          </div>
          <div className="flex flex-col gap-2 items-center mt-5">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.VerifiedCard.AccountCreated.title}
            </h1>
            <p className="text-secondary_text font-normal text-center text-base dark:text-dark_secondary_text">
              {ApplicationString.VerifiedCard.AccountCreated.subTitle}
            </p>
          </div>
          <div className="mt-10 mb-8 flex flex-col gap-3">
            <Link to={`${InternalRoute.Home}`}>
              <Button
                variant="contained"
                data-testid="after-signUp-continue-button"
                className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                color="primary"
              >
                {ApplicationString.VerifiedCard.buttonText}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default AccountCreatedComponent;

import { AxiosError, HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequest } from '../../Api/api';
import ForgotPasswordOtpComponent from '../../Components/ForgotPasswordComponent/ForgotPasswordOtpComponent';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { verificationTypeEnums } from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import { handleApiError } from '../../Utils/utils';

interface IerrorsData {
  otp: {
    error: boolean;
    validationMessage: string;
  };
}

interface IApiPayload {
  verificationOf: string;
  verificationType: string;
}

const ForgotPasswordOtpContainer: React.FC = () => {
  const [params, setParams] = useSearchParams({});
  const verificationId = atob(params.get('verificationId') || '');
  const email = atob(params.get('email') || '');
  const phone = atob(params.get('phone') || '');
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>('');
  const [canResendOtp, setCanResetOtp] = useState<boolean>(false);
  const [countDownTimeNumber, setCountDownTimeNumber] = useState<number>(20);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState<boolean>(false);
  const [errorsData, setErrorsData] = useState<IerrorsData>({
    otp: {
      error: false,
      validationMessage: '',
    },
  });

  const startCountingDownTime = (): void => {
    setCountDownTimeNumber(20);
    setErrorsData({
      otp: {
        error: false,
        validationMessage: '',
      },
    });
    setCanResetOtp(false);
    const timerRef = setInterval(() => {
      setCountDownTimeNumber((prevState) => {
        if (prevState <= 1) {
          clearTimeout(timerRef);
          setCanResetOtp(true);
          return 0;
        }
        return prevState - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startCountingDownTime();
  }, []);

  const getVerificationId = async (payload: IApiPayload) => {
    try {
      const res = await ApiRequest.post(
        `${URLS.USER_FORGOT_PASSWORD_VERIFICATION_ID}`,
        payload
      );
      const cryptedVerificationId = window.btoa(res.data.verificationId);
      const cryptedEmail = btoa(email);
      const cryptedPhone = btoa(phone);
      if (email) {
        setParams({
          verificationId: cryptedVerificationId,
          email: cryptedEmail,
        });
      } else {
        setParams({
          verificationId: cryptedVerificationId,
          phone: cryptedPhone,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
      setErrorsData({
        otp: {
          error: true,
          validationMessage:
            ApplicationString.otpScreen.validationMessages.invalidMsgs
              .wrongCode,
        },
      });
    }
  };

  const resendOtp = (): void => {
    getVerificationId({
      verificationOf: email || phone,
      verificationType: email
        ? verificationTypeEnums.email
        : verificationTypeEnums.phone,
    });
    startCountingDownTime();
  };

  // Submit Function to verify the OTP
  const verifyTheOtp = async (): Promise<void> => {
    if (!verificationId) {
      navigate(InternalRoute.EmailSignup);

      return;
    }
    if (otp === '') {
      setErrorsData({
        otp: {
          error: true,
          validationMessage:
            ApplicationString.otpScreen.validationMessages.requiredMsgs.code,
        },
      });
      return;
    }
    if (otp.length !== 6) {
      setErrorsData({
        otp: {
          error: true,
          validationMessage:
            ApplicationString.otpScreen.validationMessages.invalidMsgs
              .minOtpLength,
        },
      });
      return;
    }
    try {
      const payload = {
        token: otp,
      };
      setIsVerifyingOtp(true);
      const res = await ApiRequest.put(
        `${URLS.USER_VERIFY_OTP.replace('#{id}', verificationId)}`,
        payload
      );
      if (res?.status === HttpStatusCode.NoContent) {
        setErrorsData({
          otp: {
            error: false,
            validationMessage: '',
          },
        });
        navigate(
          `${InternalRoute.ForgotPasswordCreateNewPassword}?verificationId=${window.btoa(verificationId)}`
        );
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<IApiErrorResponse>;
        handleApiError(axiosError);
        setErrorsData({
          otp: {
            error: true,
            validationMessage:
              ApplicationString.otpScreen.validationMessages.invalidMsgs
                .wrongCode,
          },
        });
      }
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  React.useEffect(() => {
    setErrorsData({
      otp: {
        error: false,
        validationMessage: '',
      },
    });
  }, [otp]);
  return (
    <ForgotPasswordOtpComponent
      otp={otp}
      setOtp={setOtp}
      verifyTheOtp={verifyTheOtp}
      canResendOtp={canResendOtp}
      countDownTimeNumber={countDownTimeNumber}
      errorsData={errorsData}
      isVerifyingOtp={isVerifyingOtp}
      resendOtp={resendOtp}
    />
  );
};

export default ForgotPasswordOtpContainer;

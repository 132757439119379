import React from 'react';
import StepperComponent from '../VehicleStepperComponents/StepperComponent';

interface IStepperProps {
  updateVehicle(
    setActiveStep?: (arg0: (prevActiveStep: number) => number) => void
  ): Promise<void>;
  isLoading: boolean;
}

const VehicleEditStepperComponent: React.FC<IStepperProps> = ({
  updateVehicle,
  isLoading,
}) => {
  return (
    <div className="w-full flex flex-col items-center  bg-[#F3F4F6] dark:bg-Light_bg_surface2">
      <StepperComponent
        updateVehicle={updateVehicle}
        isEdit
        isLoading={isLoading}
      />
    </div>
  );
};

export default VehicleEditStepperComponent;

import React, { useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import VehicleStepperComponent from '../../Components/VehicleStepper';
import { ApiRequest } from '../../Api/api';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { removeLocalStorageItems, showApiError } from '../../Utils/utils';
import ApplicationString from '../../Constants/applicationString';
import { useToast } from '../../Services/ToastService';
import URLS from '../../Constants/urls';
import { ICreateVehicleData } from '../../Interfaces/Stepper';
import { localStorageEnums } from '../../Utils/enums';

const VehicleStepperContainer = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const createVehicle = async (
    data: ICreateVehicleData,
    setShowVerified: (arg: boolean) => void
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const res = await ApiRequest.post(URLS.VEHICLE_CREATE, data);
      if (res?.status === HttpStatusCode.Created) {
        setIsLoading(false);
        setShowVerified(true);
        removeLocalStorageItems([
          localStorageEnums.skippedSteps,
          localStorageEnums.step,
          localStorageEnums.stepsData,
          localStorageEnums.isSlotError,
          localStorageEnums.isStepperHasError,
        ]);
        toast.success(ApplicationString.vehicleStepper.vehicleSuccessMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setShowVerified(false);
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };
  return (
    <VehicleStepperComponent
      createVehicle={(
        data: ICreateVehicleData,
        setShowVerified: (arg: boolean) => void
      ) => createVehicle(data, setShowVerified)}
      isLoading={isLoading}
    />
  );
};

export default VehicleStepperContainer;

import WineBarOutlinedIcon from '@mui/icons-material/WineBarOutlined';
import {
  AirConditionSvg,
  FishingSvgIcon,
  KidsSvgIcon,
  SeaSideSvg,
  SmokingSvgIcon,
  WifiSvgIcon,
} from '../Assets/Svgs';

export const svgIcons = [
  {
    id: 1,
    label: 'A/C',
    icon: <AirConditionSvg />,
  },
  {
    id: 1,
    label: 'Sea Side',
    icon: <SeaSideSvg />,
  },
  {
    id: 1,
    label: 'Wifi',
    icon: <WifiSvgIcon />,
  },
  {
    id: 1,
    label: 'Fish Finder',
    icon: <FishingSvgIcon />,
  },
  {
    id: 1,
    label: 'Smoking',
    icon: <SmokingSvgIcon />,
  },
  {
    id: 1,
    label: 'Alcohol',
    icon: <WineBarOutlinedIcon color="primary" />,
  },
  {
    id: 1,
    label: 'Kids Under 12',
    icon: <KidsSvgIcon />,
  },
  {
    id: 1,
    label: 'Fishing',
    icon: <FishingSvgIcon />,
  },
];
export const restrictionIcons = [
  {
    id: 1,
    label: 'Smoking',
    icon: <SmokingSvgIcon />,
  },
  {
    id: 1,
    label: 'Alcohol',
    icon: <WineBarOutlinedIcon color="primary" />,
  },
  {
    id: 1,
    label: 'Kids Under 12',
    icon: <KidsSvgIcon />,
  },
  {
    id: 1,
    label: 'Fishing',
    icon: <FishingSvgIcon />,
  },
];

import { TextField, Button, FormControl, FormHelperText } from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import InternalRoute from '../../Utils/internalRoutes';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import ApplicationString from '../../Constants/applicationString';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';

interface ISignUpWithEmailComponentProps {
  formData: { email: string };
  errorsData: {
    email: {
      error: boolean;
      ValidationMessage: string;
    };
  };
  onFormSubmit: (e: React.FormEvent) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isVerifying: boolean;
}

const SignUpWithEmailComponent: React.FC<ISignUpWithEmailComponentProps> = ({
  formData,
  errorsData,
  onFormSubmit,
  handleEmailChange,
  isVerifying,
}: ISignUpWithEmailComponentProps) => {
  return (
    <BackgroundLayout>
      <div className="xsm:w-100  max-h-[100vh]  border bg-white border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.signUpWithEmail.title.mainTitle}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.signUpWithEmail.title.subTitle}
            </p>
          </div>
          <form onSubmit={onFormSubmit}>
            <div data-testid="form-fields-container " className=" w-full mt-8">
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
              >
                <FormControl>
                  <TextField
                    label={ApplicationString.signUpWithEmail.label.email}
                    className=" w-full rounded-4xl text-base font-medium "
                    InputProps={{ className: 'rounded-4xl px-2 ' }}
                    onChange={handleEmailChange}
                    error={errorsData.email.error}
                    value={formData.email}
                  />
                  {errorsData.email.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.email.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </FormControl>
            </div>
            <div className="mt-8 flex flex-col gap-3">
              {isVerifying ? (
                <LoadingButton
                  buttonText={
                    ApplicationString.signUpWithEmail.button.verifyingEmail
                  }
                />
              ) : (
                <Button
                  variant="contained"
                  data-testid="signUp-with-email-button"
                  className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                  color="primary"
                  type="submit"
                >
                  {ApplicationString.signUpWithEmail.button.SignUp}
                </Button>
              )}

              <span className="text-secondary_text text-base font-bold flex justify-center">
                {ApplicationString.signUpWithEmail.otherText.or}
              </span>
              {isVerifying ? (
                <Button
                  variant="outlined"
                  disabled
                  className="w-full normal-case cursor-not-allowed  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                >
                  <span className="flex gap-1 items-center font-bold text-base  ">
                    <PhoneIphoneIcon className="h-[16px] w-[18px]" />{' '}
                    {ApplicationString.signUpWithEmail.button.continueWithPhone}
                  </span>
                </Button>
              ) : (
                <Link to={InternalRoute.PhoneSignup}>
                  <Button
                    variant="outlined"
                    data-testid="signUp-continue-with-phone-button"
                    className="w-full normal-case  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                  >
                    <span className="flex gap-1 items-center font-bold text-base  ">
                      <PhoneIphoneIcon className="h-[16px] w-[18px]" />{' '}
                      {
                        ApplicationString.signUpWithEmail.button
                          .continueWithPhone
                      }
                    </span>
                  </Button>
                </Link>
              )}
            </div>
          </form>
        </div>
        <div className="mt-8 border-t border-borderStroke flex justify-center items-center  dark:border-dark_borderStroke">
          <span className="p-5 flex gap-1 text-sm sm:text-base">
            {ApplicationString.signUpWithEmail.otherText.haveAccount}
            <Link
              to={InternalRoute.PhoneLogin}
              data-testid="existing-user-login-link"
              className="text-primary font-bold"
            >
              {ApplicationString.signUpWithEmail.links.login}
            </Link>
          </span>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default SignUpWithEmailComponent;

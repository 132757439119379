import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ClearIcon } from '@mui/x-date-pickers/icons';
import ApplicationString from '../../../Constants/applicationString';
import { InformationSvgIcon } from '../../../Assets/Svgs';
import { IVehicleDetailsStepComponentProps } from '../VehicleDetailsStep/interfaces';
import useSelectOpenClose from '../../../Hooks/useSelectOpenClose';
import { RequiredSelectLabel, RequiredTextFieldLabel } from '../label';

const VehicleDetailsStepComponent: React.FC<
  IVehicleDetailsStepComponentProps
> = ({
  formData,
  setFormData,
  setErrorsData,
  vehicleCategory,
  vehicleSubCategory,
  maker,
  manufacturingYear,
  engineSize,
  hitchSize,
  handleNameChange,
  handleDescriptionChange,
  handleCategoryChange,
  handleSubCategoryChange,
  handleManufacturingYearChange,
  handleModelChange,
  handleMaxPersonChange,
  handleVehicleLengthChange,
  handleEngineSizeChange,
  handleIsTrailerChange,
  handleHitchSizeChange,
  handleOwnerShipTypeChange,
  clearMaker,
  errorsData,
}): JSX.Element => {
  const [customMaker, setCustomMaker] = useState<string[]>([]);
  const categorySelectProps = useSelectOpenClose(true);
  const vehicleTypeSelectProps = useSelectOpenClose(true);
  const makerSelectProps = useSelectOpenClose(true);
  const manufacturingYearSelectProps = useSelectOpenClose(true);
  const engineSizeSelectProps = useSelectOpenClose(true);
  const hitchSizeSelectProps = useSelectOpenClose(true);
  const [isFocusState, setIsFocusState] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleFocus = (field: string) => {
    setIsFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field: string) => {
    setIsFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  return (
    <div className="w-full">
      <div className=" w-7xl">
        <div className="flex flex-col lg:flex-row gap-[28px] ">
          <div className="lg:w-1/2">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2 text-secondary_text">
                <FormControl>
                  <div className="flex items-center border-borderStroke border-0 rounded-[50px] dark:border-none">
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#D1D5DB',
                          },
                        },
                      }}
                      data-testid="input-vehicle-title"
                      label={
                        <RequiredTextFieldLabel
                          text={
                            ApplicationString.vehicleStepper.step1Details.labels
                              .listing
                          }
                        />
                      }
                      className="w-full rounded-[50px] bg-[#F9FAFB] border-0 dark:bg-dark_bg_primary dark:border-none"
                      error={errorsData.step1.title.error}
                      InputProps={{
                        className:
                          'rounded-[50px]  pl-1  h-15 !border-slate-50',
                      }}
                      InputLabelProps={{
                        className: 'leading-normal text-secondary_text ',
                        classes: {
                          root: 'left-1',
                        },
                      }}
                      value={formData.step1.title}
                      onChange={handleNameChange}
                    />
                  </div>
                  {errorsData.step1.title.error && (
                    <FormHelperText className="text-error">
                      {errorsData.step1.title.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <div className="flex gap-2">
                  <InformationSvgIcon />

                  <p className="text-secondary_text text-sm leading-4 font-normal	">
                    {
                      ApplicationString.vehicleStepper.step1Details.information
                        .listing
                    }
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2 text-secondary_text">
                <div className="flex h-full items-center border-0 rounded-3xl dark:border-none">
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        height: '78% !important',
                        '&:hover': {
                          borderColor: errorsData.step1.description.error
                            ? '#f30c0c !important'
                            : '#D1D5DB',
                        },
                        '&:focus': {
                          borderColor: errorsData.step1.description.error
                            ? '#f30c0c !important'
                            : '#D1D5DB',
                        },
                      },
                      '& .MuiInputLabel-shrink': {
                        color: errorsData.step1.description.error
                          ? '#f30c0c !important'
                          : '#6B7280',
                      },
                    }}
                    data-testid="input-vehicle-description"
                    label={
                      ApplicationString.vehicleStepper.step1Details.labels
                        .description
                    }
                    className="w-full h-39 rounded-3xl bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                    InputProps={{
                      className: 'rounded-3xl h-39 pl-2 pt-0',
                      classes: {
                        root: 'left-0',
                      },
                    }}
                    multiline
                    rows={4}
                    error={errorsData.step1.description.error}
                    value={formData.step1.description}
                    onChange={handleDescriptionChange}
                    InputLabelProps={{
                      shrink:
                        Boolean(formData.step1.description) ||
                        isFocusState.description,
                      className: 'text-secondary_text ',
                    }}
                    onFocus={() => handleFocus('description')}
                    onBlur={() => handleBlur('description')}
                  />
                </div>
                {errorsData.step1.description.error && (
                  <FormHelperText className="text-error">
                    {errorsData.step1.description.ValidationMessage}
                  </FormHelperText>
                )}
                <div className="flex gap-2">
                  <div>
                    <InformationSvgIcon />
                  </div>

                  <p className="text-secondary_text text-sm leading-4 font-normal	">
                    {
                      ApplicationString.vehicleStepper.step1Details.information
                        .description
                    }
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-5 text-secondary_text mt-3">
                <FormControl
                  error={errorsData.step1.category.error}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB',
                      },
                      '& .MuiSvgIcon-root': {
                        right: '11px',
                        fontSize: '2rem',
                      },
                    },
                  }}
                >
                  <RequiredSelectLabel
                    className="ml-1 leading-normal text-secondary_text"
                    text={
                      ApplicationString.vehicleStepper.step1Details.labels
                        .category
                    }
                  />
                  <Select
                    value={
                      formData.step1.category
                        ? String(formData.step1.category)
                        : ''
                    }
                    open={categorySelectProps.open}
                    onOpen={categorySelectProps.onOpen}
                    onClose={categorySelectProps.onClose}
                    data-testid="select-category"
                    onChange={handleCategoryChange}
                    label={
                      ApplicationString.vehicleStepper.step1Details.category
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    className="rounded-[50px] pl-1 h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-gray-300"
                  >
                    {vehicleCategory?.map((category) => (
                      <MenuItem value={category.id} key={category.id}>
                        {category?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorsData.step1.category.error && (
                    <FormHelperText className="text-error">
                      {errorsData.step1.category.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  error={errorsData.step1.subCategory.error}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB !important',
                      },
                      '& .MuiSvgIcon-root': {
                        right: '11px',
                        fontSize: '2rem',
                      },
                    },
                  }}
                >
                  <RequiredSelectLabel
                    className="ml-1 leading-normal text-secondary_text"
                    text={
                      ApplicationString.vehicleStepper.step1Details.labels
                        .subCategory
                    }
                  />
                  <Select
                    value={
                      formData.step1.subCategory
                        ? String(formData.step1.subCategory)
                        : ''
                    }
                    data-testid="select-subCategory"
                    open={vehicleTypeSelectProps.open}
                    onOpen={vehicleTypeSelectProps.onOpen}
                    onClose={vehicleTypeSelectProps.onClose}
                    onChange={handleSubCategoryChange}
                    disabled={formData?.step1.category === null}
                    label={
                      ApplicationString.vehicleStepper.step1Details.subCategory
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    className="rounded-[50px] pl-1 h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-gray-300"
                  >
                    {formData?.step1.category !== null &&
                      vehicleSubCategory?.map((category) => (
                        <MenuItem value={category.id} key={category.id}>
                          {category?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errorsData.step1.subCategory.error && (
                    <FormHelperText className="text-error">
                      {errorsData.step1.subCategory.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB !important',
                      },
                      '& .MuiSvgIcon-root': {
                        right: '11px',
                        fontSize: '2rem',
                      },
                    },
                  }}
                >
                  <Autocomplete
                    data-testid="autocomplete-vehicleStep"
                    className="rounded-[50px] h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-gray-300"
                    value={formData.step1.maker}
                    clearIcon={null}
                    onChange={(event, newValue) => {
                      if (
                        newValue &&
                        !maker.includes(newValue) &&
                        !customMaker.includes(newValue)
                      ) {
                        setCustomMaker((prevState) => [...prevState, newValue]);
                      }
                      setErrorsData((prevState) => ({
                        ...prevState,
                        step1: {
                          ...prevState.step1,
                          maker: {
                            ValidationMessage: '',
                            error: false,
                          },
                        },
                      }));
                      setFormData((prevState) => ({
                        ...prevState,
                        step1: {
                          ...prevState.step1,
                          maker: newValue || '',
                        },
                      }));
                    }}
                    options={Array.from(new Set([...maker, ...customMaker]))}
                    freeSolo
                    sx={{
                      '& .MuiInputBase-root': {
                        borderRadius: '50px',
                        height: '60px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: errorsData.step1.maker.error
                          ? '1px solid red'
                          : '1px solid #D1D5DB',
                        '&:hover': {
                          border: errorsData.step1.maker.error
                            ? '1px solid red !important'
                            : '1px solid #D1D5DB',
                        },
                        '&:focus': {
                          border: errorsData.step1.maker.error
                            ? '1px solid red !important'
                            : '1px solid #D1D5DB',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: errorsData.step1.maker.error
                          ? 'red'
                          : 'rgb(107 114 128 / var(--tw-text-opacity))',
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '9px !important',
                        paddingLeft: '12px',
                      },
                    }}
                    selectOnFocus
                    open={makerSelectProps.open}
                    onOpen={makerSelectProps.onOpen}
                    onClose={makerSelectProps.onClose}
                    handleHomeEndKeys
                    clearOnBlur
                    id="maker-autocomplete"
                    key={formData.step1.maker}
                    renderInput={(params) => (
                      <TextField
                        error={errorsData.step1.maker.error}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label={
                          <RequiredTextFieldLabel
                            text={
                              ApplicationString.vehicleStepper.step1Details
                                .labels.maker
                            }
                          />
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {formData.step1.maker && (
                                <InputAdornment position="end">
                                  <ClearIcon
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '1.5rem',
                                      marginRight: '6px',
                                    }}
                                    onClick={clearMaker}
                                  />
                                </InputAdornment>
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            marginThreshold: 20,
                            style: {
                              maxHeight: 300,
                            },
                          },
                          endAdornment: <KeyboardArrowDown color="primary" />,
                        }}
                        className="rounded-[50px] h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-gray-300"
                      />
                    )}
                  />
                  {errorsData.step1.maker.error && (
                    <FormHelperText className="text-error">
                      {errorsData.step1.maker.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <div className="flex gap-2">
                  <InformationSvgIcon />

                  <p className="text-secondary_text text-sm leading-4 font-normal	">
                    {
                      ApplicationString.vehicleStepper.step1Details.information
                        .maker
                    }
                  </p>
                </div>
                <FormControl
                  error={errorsData.step1.manufacturingYear.error}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB',
                      },
                    },
                    '& .MuiSvgIcon-root': {
                      right: '11px',
                      fontSize: '2rem',
                    },
                  }}
                >
                  <RequiredSelectLabel
                    text={
                      ApplicationString.vehicleStepper.step1Details.labels
                        .manufacturingYear
                    }
                    id="manufacturingYear"
                    className=" ml-1 leading-normal text-secondary_text"
                  />

                  <Select
                    data-testid="manufacturingYear-select"
                    id="manufacturingYear"
                    labelId="manufacturingYear"
                    value={String(formData.step1.manufacturingYear)}
                    onChange={handleManufacturingYearChange}
                    label={
                      ApplicationString.vehicleStepper.step1Details.labels
                        .manufacturingYear
                    }
                    open={manufacturingYearSelectProps.open}
                    onOpen={manufacturingYearSelectProps.onOpen}
                    onClose={manufacturingYearSelectProps.onClose}
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    className="rounded-[50px] pl-1 h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-gray-300"
                  >
                    {manufacturingYear?.map((item) => (
                      <MenuItem value={item.value} key={item.key}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorsData.step1.manufacturingYear.error && (
                    <FormHelperText className="text-error">
                      {errorsData.step1.manufacturingYear.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="flex flex-col gap-3 mb-5">
              <FormControl>
                <div className="flex items-center border rounded-[50px] dark:border-none">
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    label={
                      <RequiredTextFieldLabel
                        text={
                          ApplicationString.vehicleStepper.step1Details.labels
                            .model
                        }
                      />
                    }
                    data-testid="input-vehicle-model"
                    error={errorsData.step1.model.error}
                    className="w-full h-15 rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                    InputProps={{
                      className: 'rounded-[50px] h-15',
                    }}
                    InputLabelProps={{
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    value={formData.step1.model}
                    onChange={handleModelChange}
                  />
                </div>
                {errorsData.step1.model.error && (
                  <FormHelperText className="text-error">
                    {errorsData.step1.model.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <div className="flex items-center border rounded-[50px] dark:border-none">
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB',
                      },
                    },
                  }}
                  data-testid="input-vehicle-capacity"
                  label={
                    ApplicationString.vehicleStepper.step1Details.labels
                      .capacity
                  }
                  className="w-full h-15 rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                  InputProps={{
                    className: 'rounded-[50px] h-15',
                  }}
                  InputLabelProps={{
                    shrink: !!formData.step1.capacity || isFocusState.capacity,
                    className: 'text-secondary_text ',
                    classes: {
                      root: 'left-1',
                    },
                  }}
                  onFocus={() => handleFocus('capacity')}
                  onBlur={() => handleBlur('capacity')}
                  value={formData.step1.capacity ? formData.step1.capacity : ''}
                  onChange={handleMaxPersonChange}
                />
              </div>
              <div className="flex items-center border rounded-[50px] dark:border-none">
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB',
                      },
                    },
                  }}
                  data-testid="input-vehicle-length"
                  label={
                    ApplicationString.vehicleStepper.step1Details.labels.length
                  }
                  className="w-full h-15 rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                  InputProps={{
                    className: 'rounded-[50px] h-15',
                  }}
                  InputLabelProps={{
                    shrink: !!formData.step1.size || isFocusState.size,
                    className: 'text-secondary_text ',
                    classes: {
                      root: 'left-1',
                    },
                  }}
                  onFocus={() => handleFocus('size')}
                  onBlur={() => handleBlur('size')}
                  value={formData.step1.size ? formData.step1.size : ''}
                  onChange={handleVehicleLengthChange}
                />
              </div>
              <div className="flex flex-col gap-2 text-secondary_text">
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB',
                      },
                      '& .MuiSvgIcon-root': {
                        right: '11px',
                        fontSize: '2rem',
                      },
                    },
                  }}
                  className="rounded-[50px] h-15 bg-white border border-gray-300 "
                >
                  <InputLabel className=" ml-1">
                    {
                      ApplicationString.vehicleStepper.step1Details.labels
                        .engine
                    }
                  </InputLabel>
                  <Select
                    value={formData.step1.engineSize}
                    onChange={handleEngineSizeChange}
                    label={
                      ApplicationString.vehicleStepper.step1Details.labels
                        .engine
                    }
                    data-testid="select-vehicleEngineSize"
                    open={engineSizeSelectProps.open}
                    onOpen={engineSizeSelectProps.onOpen}
                    onClose={engineSizeSelectProps.onClose}
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    className="rounded-[50px] pl-1 h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-gray-300"
                  >
                    {engineSize?.map((item) => (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="flex gap-2">
                  <InformationSvgIcon />

                  <p className="text-secondary_text text-sm leading-4 font-normal	">
                    {
                      ApplicationString.vehicleStepper.step1Details.information
                        .engine
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mb-5">
              <p className="font-[#1F1F1F] font-bold text-sm leading-4 dark:text-dark_primary_text">
                {
                  ApplicationString.vehicleStepper.step1Details.information
                    .trailer
                }
              </p>
              <RadioGroup
                value={
                  // eslint-disable-next-line no-nested-ternary
                  formData.step1.trailer !== null
                    ? formData.step1.trailer === true
                      ? 'yes'
                      : 'no'
                    : null
                }
                className="flex flex-row justify-between mb-1 mt-6 xsm:mt-0"
              >
                <label
                  className="flex items-center w-[49%] rounded-[50px] h-[60px] cursor-pointer bg-[#F9FAFB] border border-borderStroke dark:bg-dark_bg_primary dark:border-none"
                  htmlFor="yes"
                >
                  <Radio
                    data-testid="optionYes-vehicleStepper-radio"
                    className="ml-[4px] pointer-events-none cursor-default"
                    onChange={handleIsTrailerChange}
                    value="yes"
                    id="yes"
                  />

                  <span className=" text-secondary_text dark:text-gray-300">
                    {ApplicationString.vehicleStepper.step1Details.yes}
                  </span>
                </label>
                <label
                  className="flex items-center w-[49%] rounded-[50px] h-[60px] cursor-pointer bg-[#F9FAFB] border-borderStroke border dark:bg-dark_bg_primary dark:border-none"
                  htmlFor="no"
                >
                  <Radio
                    data-testid="optionNo-vehicleStepper-radio"
                    className="ml-[4px] pointer-events-none cursor-default"
                    onChange={handleIsTrailerChange}
                    value="no"
                    id="no"
                  />

                  <span className=" text-secondary_text dark:text-gray-300">
                    {ApplicationString.vehicleStepper.step1Details.no}
                  </span>
                </label>
              </RadioGroup>
            </div>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D1D5DB',
                  },
                  '& .MuiSvgIcon-root': {
                    right: '11px',
                    fontSize: '2rem',
                  },
                },
              }}
              className="rounded-[50px] h-15 bg-white border border-gray-300 mb-5 dark:border-none"
            >
              <InputLabel className=" ml-1 leading-normal text-secondary_text">
                {ApplicationString.vehicleStepper.step1Details.labels.hitchSize}
              </InputLabel>
              <Select
                value={formData.step1.hitchSize}
                onChange={handleHitchSizeChange}
                data-testid="select-hitchSize"
                label={
                  ApplicationString.vehicleStepper.step1Details.labels.hitchSize
                }
                open={hitchSizeSelectProps.open}
                onOpen={hitchSizeSelectProps.onOpen}
                onClose={hitchSizeSelectProps.onClose}
                MenuProps={{
                  disableScrollLock: true,
                  marginThreshold: 20,
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                className="rounded-[50px] pl-1 h-15 bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-secondary_text dark:text-gray-300"
              >
                {hitchSize?.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="flex flex-col gap-2 mb-5">
              <FormControl>
                <p className="font-[#1F1F1F] font-bold text-sm leading-4 dark:text-dark_primary_text mb-2">
                  {
                    ApplicationString.vehicleStepper.step1Details.information
                      .vehicleOwnerShip
                  }
                  <span className="text-[#FF0000]">*</span>
                </p>
                <RadioGroup
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    formData.step1.ownerShipType !== null
                      ? formData.step1.ownerShipType
                      : null
                  }
                  className="flex flex-col md:flex-row justify-between gap-2 mb-1 mt-6 xsm:mt-0"
                >
                  <label
                    className="flex items-center md:w-[49%] rounded-[50px] h-[60px] cursor-pointer bg-[#F9FAFB] border-borderStroke border dark:bg-dark_bg_primary dark:border-none"
                    htmlFor="commercial"
                  >
                    <Radio
                      className="ml-[4px] pointer-events-none cursor-default"
                      onChange={handleOwnerShipTypeChange}
                      value="commercial"
                      id="commercial"
                      data-testid="commercial-vehicleStepper-radio"
                    />

                    <span className="ml-2 text-secondary_text dark:text-gray-300">
                      {
                        ApplicationString.vehicleStepper.step1Details.labels
                          .commercial
                      }
                    </span>
                  </label>
                  <label
                    className="flex items-center md:w-[49%] rounded-[50px] h-[60px] cursor-pointer bg-[#F9FAFB] border-borderStroke border dark:bg-dark_bg_primary dark:border-none"
                    htmlFor="private"
                  >
                    <Radio
                      className="ml-[4px] pointer-events-none cursor-default"
                      onChange={handleOwnerShipTypeChange}
                      value="private"
                      id="private"
                      data-testid="private-vehicleStepper-radio"
                    />
                    <span className="ml-2 text-secondary_text dark:text-gray-300">
                      {
                        ApplicationString.vehicleStepper.step1Details.labels
                          .private
                      }
                    </span>
                  </label>
                </RadioGroup>
                {errorsData.step1.ownerShipType.error && (
                  <FormHelperText className="text-error">
                    {errorsData.step1.ownerShipType.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetailsStepComponent;

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {
  PickersDayProps,
  PickersDay,
} from '@mui/x-date-pickers/PickersDay/PickersDay';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './ownerCalendar.css';
import { HttpStatusCode } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { CancelBookingCalenderIcon } from '../../Assets/Svgs';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import ApplicationString from '../../Constants/applicationString';
import ColorModeContext from '../../Utils/ColorModeContext';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { getBlockedDatesForOwnerCalendar } from '../../Utils/blockBookings';
import { BookingsDetails, DateRange } from '../BookingDetails/bookingInterface';
import { getDateRangeFromDayjsDate, userRoleEnums } from '../../Utils/utils';
import ToastContext from '../../Services/ToastService';

interface BlockUnblockBody {
  dateRanges: DateRange[];
  isBlocked: boolean;
}
interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBookingData: React.Dispatch<React.SetStateAction<BookingsDetails[]>>;
  monthLimit: number;
}

const OwnerCalendarComponent: React.FC<IProps> = ({
  isLoading,
  setIsLoading,
  setBookingData,
  monthLimit,
}) => {
  const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);
  const [ownerBlockedDates, setOwnerBlockedDates] = useState<Dayjs[]>([]);
  const [ownerUnBlockDates, setOwnerUnBlockDates] = useState<Dayjs[]>([]);
  const [highlightedDays, setHighlightedDays] = useState<Dayjs[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs()); // Use dayjs() directly
  const [calendarValue, setCalendarValue] = useState<Dayjs>(dayjs());
  const [userBlockedDates, setUserBlockedDates] = useState<Dayjs[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params] = useSearchParams({});
  const vehicleId = params.get('vehicleId');

  const { currentTheme } = useContext(ColorModeContext);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const toast = useContext(ToastContext);
  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      // Check if the date is in userBlockedDates
      if (
        userBlockedDates.some((blockedDate) => blockedDate.isSame(date, 'day'))
      ) {
        return;
      }

      setCalendarValue(date);
      if (
        selectedDates.some((selectedDate) => selectedDate.isSame(date, 'day'))
      ) {
        setSelectedDates(
          selectedDates.filter(
            (selectedDate) => !selectedDate.isSame(date, 'day')
          )
        );
      } else if (
        ownerBlockedDates.some((blockedDate) => blockedDate.isSame(date, 'day'))
      ) {
        setOwnerUnBlockDates([...ownerUnBlockDates, date]);

        setSelectedDates([]);
      } else {
        setSelectedDates([...selectedDates, date]);
        setOwnerUnBlockDates([]);
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ServerDay(
    // eslint-disable-next-line react/require-default-props
    props: PickersDayProps<Dayjs> & { highlightedDays?: Dayjs[] }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const isSelected = highlightedDays?.some((highlightedDate) =>
      highlightedDate?.isSame(day, 'day')
    );
    // eslint-disable-next-line react/destructuring-assignment
    const isBookedByUser = userBlockedDates.some((date) =>
      day?.isSame(date, 'day')
    );
    const isBookedByOwner = ownerBlockedDates.some((date) =>
      day?.isSame(date, 'day')
    );
    const render = () => {
      if (isSelected) {
        return {
          '&.Mui-selected': {},
          '&.MuiPickersDay-dayWithMargin': {
            backgroundColor: '#1a56db',
            color: 'white',
            borderRadius: '10000px',
          },
          '&.MuiPickersDay-today': {
            '&:hover': {
              backgroundColor: '#1a56db',
            },
          },
        };
      }

      if (isBookedByUser) {
        return {
          '&.MuiPickersDay-dayWithMargin': {
            backgroundColor: '#E1EFFE',
            color: 'blue !important',
            borderRadius: '100%',
            cursor: 'not-allowed !important',
          },
          '&.Mui-selected': {},
          '&.MuiPickersDay-today': {
            '&:hover': {
              backgroundColor: '#E1EFFE',
            },
          },
        };
      }

      if (isBookedByOwner) {
        return {
          '&.MuiPickersDay-dayWithMargin': {
            color: '#d1d5db !important',
            backgroundColor: '#f3f4f6',
            borderRadius: '100%',
            cursor: 'pointer !important',
          },
          '&.Mui-selected': {
            opacity: '1 !important',
          },
          '&.MuiPickersDay-today': {
            '&:hover': {
              backgroundColor: '#f3f4f6',
            },
          },
        };
      }

      return {
        '&.MuiPickersDay-dayWithMargin': {},
        '&.Mui-selected': {},
        '&.MuiPickersDay-today': { '&:hover': {} },
      };
    };
    return (
      <div
        className={
          // eslint-disable-next-line no-nested-ternary
          isSelected
            ? 'selectedDates-calendar'
            : // eslint-disable-next-line no-nested-ternary
              isBookedByUser
              ? 'blocked-by-user'
              : isBookedByOwner
                ? 'blocked-by-owner'
                : 'not-selected'
        }
      >
        <PickersDay
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          sx={render()}
        />
      </div>
    );
  }

  const blockSelectedDate = async () => {
    try {
      setIsLoading(true);
      if (vehicleId) {
        const dateRanges = getDateRangeFromDayjsDate(selectedDates);
        const unblockDates = getDateRangeFromDayjsDate(ownerUnBlockDates);

        // Initialize a local blockUnblockBody variable
        let localBlockUnblockBody: BlockUnblockBody = {
          dateRanges: [],
          isBlocked: false,
        };

        // Check lengths and set localBlockUnblockBody accordingly
        if (dateRanges.length > 0) {
          localBlockUnblockBody = {
            dateRanges,
            isBlocked: true,
          };
        } else if (unblockDates.length > 0) {
          localBlockUnblockBody = {
            dateRanges: unblockDates,
            isBlocked: false,
          };
        } else {
          return;
        }

        // Use the localBlockUnblockBody in the API request
        const response = await ApiRequest.post(
          URLS.BLOCK_UNBLOCK_DATE.replace('#{vehicleId}', String(vehicleId)),
          localBlockUnblockBody
        );
        if (response?.status === HttpStatusCode.NoContent) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          localBlockUnblockBody?.isBlocked
            ? toast?.success(ApplicationString.OwnerCalendar.successMessage)
            : toast?.success(ApplicationString.OwnerCalendar.UnblockDatesTitle);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setSelectedDates([]);
      setOwnerUnBlockDates([]);
      setHighlightedDays([]);
    }
  };

  const handleBlockedDates = () => {
    blockSelectedDate();
    setSelectedDates([]);
    setHighlightedDays([]);
    setIsModalOpen(false);
  };

  const handlePreviousMonth = () => {
    const newMonth = currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setCalendarValue(newMonth);
  };

  const handleNextMonth = () => {
    const newMonth = currentMonth.add(1, 'month');
    setCurrentMonth(newMonth);
    setCalendarValue(newMonth);
  };
  const getBookingAndBlockedDate = async (
    startDate: string,
    endDate: string
  ) => {
    try {
      if (startDate && endDate && vehicleId) {
        const response = await ApiRequest.get(
          URLS.GET_ALL_BOOKING_BY_DATES.replace('#{startDate}', startDate)
            .replace('#{endDate}', endDate)
            .replace('#{userType}', userRoleEnums.OWNER)
            .replace('#{vehicleId}', String(vehicleId))
            .replace('#{pageSize}', '1000')
            .replace('#{type}', 'active')
        );
        if (response?.status === HttpStatusCode.Ok) {
          const newMonth = currentMonth;
          const bookingsFilterData = response?.data?.data?.filter(
            (data: BookingsDetails) => {
              const startDateMonth = new Date(data.startDate).getUTCMonth();
              const endDateMonth = new Date(data.endDate).getUTCMonth();
              const newMonthMonth = newMonth.month();
              return (
                (startDateMonth === newMonthMonth ||
                  endDateMonth === newMonthMonth) &&
                data?.type === 'active'
              );
            }
          );
          setBookingData(bookingsFilterData);
          const ownerCalendarBlockedDates = getBlockedDatesForOwnerCalendar(
            response?.data?.data
          );
          const ownerBookingType = response?.data?.data?.filter(
            (dates: BookingsDetails) => {
              return dates?.type !== 'cancelled';
            }
          );
          const blockedDates =
            getBlockedDatesForOwnerCalendar(ownerBookingType);
          setOwnerBlockedDates(blockedDates.ownerBlockedDate);
          setUserBlockedDates(ownerCalendarBlockedDates.userBlockedDate);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const startDate = currentMonth.startOf('month').toDate().toISOString();
    const endDate = currentMonth
      .endOf('month')
      .add(1, 'day')
      .toDate()
      .toISOString();
    getBookingAndBlockedDate(startDate, endDate);
  }, [vehicleId, isLoading, currentMonth]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomHeader() {
    const oneYearLater = dayjs().add(monthLimit, 'month');
    return (
      <div className="w-full flex justify-between p-1">
        <div className="w-5/12 xl:w-7/12 flex gap-5 xl:gap-0">
          <Button
            onClick={handlePreviousMonth}
            data-testid="owner-calendar-month-previous-button"
            className="hover:bg-white lg:pl-0 min-w-3"
          >
            <ArrowBackIosIcon className="cursor-pointer text-black text-lg" />
          </Button>
          <Typography className="flex sm:min-w-[200px] items-center text-center font-semibold md:font-bold text-sm md:text-xl w-3/6 justify-center">
            {currentMonth?.format('MMMM YYYY')}
          </Typography>
          {!currentMonth.isSame(oneYearLater, 'month') && (
            <Button
              data-testid="owner-calendar-month-next-button"
              onClick={handleNextMonth}
              className="hover:bg-white w-1/6  min-w-3"
            >
              <ArrowForwardIosIcon className="cursor-pointer text-black text-lg" />
            </Button>
          )}
        </div>
        {((!isLoading && vehicleId && selectedDates?.length > 0) ||
          ownerUnBlockDates?.length > 0) && (
          <div className="w-5/12 md:w-3/12 flex">
            <Button
              sx={{ textTransform: 'none' }}
              className="bg-[#1a56db] p-2 rounded-3xl text-xs md:text-lg w-full text-white hover:!bg-[#1a56db]"
              onClick={() => setIsModalOpen(true)}
              data-testid="owner-calendar-block-dates-modal"
            >
              {selectedDates?.length > 0
                ? ApplicationString.OwnerCalendar.BlockDatesTitle
                : ApplicationString.OwnerCalendar.UnblockDatesTitle}
            </Button>
          </div>
        )}
      </div>
    );
  }
  useEffect(() => {
    setCurrentMonth(dayjs());
    setCalendarValue(dayjs());
  }, [vehicleId]);

  useEffect(() => {
    const daysToHighlight: Dayjs[] = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedDates.length; index++) {
      const element = selectedDates[index];
      daysToHighlight.push(element);
      // eslint-disable-next-line no-unsafe-optional-chaining
      setHighlightedDays(daysToHighlight);
      // No need to update setCurrentMonth here
    }
  }, [selectedDates]);

  useEffect(() => {
    const daysToHighlight: Dayjs[] = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < ownerUnBlockDates.length; index++) {
      const element = ownerUnBlockDates[index];
      daysToHighlight.push(element);
      // eslint-disable-next-line no-unsafe-optional-chaining
      setHighlightedDays(daysToHighlight);
      // No need to update setCurrentMonth here
    }
  }, [ownerUnBlockDates]);

  const handleCloseRequestModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        component="div"
        aria-labelledby="Rating Modal"
        aria-describedby="Rating Modal"
        disableScrollLock
        open={isModalOpen}
        onClose={handleCloseRequestModal}
        closeAfterTransition
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: `${currentTheme === 'light' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.4)'}`,
          },
        }}
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={isModalOpen}>
          <Box sx={style}>
            <div className="w-[90vw] max-w-125 min-h-max bg-white dark:bg-dark_bg_secondary p-6 rounded-2xl flex flex-col gap-1">
              <div>
                <CancelBookingCalenderIcon />
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text ">
                  {selectedDates?.length > 0
                    ? ApplicationString.OwnerCalendar.BlockDatesTitle
                    : ApplicationString.OwnerCalendar.UnblockDatesTitle}
                </h1>
                <h3 className="text-base font-normal text-secondary_text dark:text-dark_secondary_text">
                  {selectedDates?.length > 0
                    ? ApplicationString.OwnerCalendar
                        .SelectedDatesBookingConfirmation
                    : ApplicationString.OwnerCalendar
                        .SelectedDatesBookingUnblockConfirmation}
                </h3>
              </div>
              <div className="flex flex-row justify-between gap-2 mt-6 w-full">
                {isLoading ? (
                  <LoadingButton
                    buttonText={
                      ApplicationString.OwnerBookingDetails
                        .OwnerBookingRequestStatus.onAcceptLoadingTitle
                    }
                    extraBtnClasses=" bg-transparent border border-black"
                    extraTxtClasses="text-primary_text w-full dark:text-dark_primary_text"
                  />
                ) : (
                  <Button
                    onClick={handleBlockedDates}
                    data-testid="owner-calendar-block-dates-button"
                    className={`bg-primary text-white
                    capitalize ${selectedDates?.length > 0 ? 'px-10' : 'px-0'} w-1/2 md:px-12 py-2 md:py-2.5 text-base font-bold rounded-full hover:bg-primary`}
                  >
                    {selectedDates?.length > 0
                      ? ApplicationString.OwnerCalendar.BlockDatesTitle
                      : ApplicationString.OwnerCalendar.UnblockDatesTitle}{' '}
                  </Button>
                )}

                <Button
                  onClick={handleCloseRequestModal}
                  data-testid="owner-calendar-cancel-dates-button"
                  className="capitalize w-1/2 px-10 md:px-15 py-2 md:py-2.5 text-base font-bold rounded-full border"
                  style={{
                    border: `${currentTheme === 'dark' ? '1px solid white' : '1px solid black'}`,
                    backgroundColor: 'transparent',
                    color: `${currentTheme === 'dark' ? 'white' : 'black'}`,
                  }}
                >
                  {ApplicationString.MyBookingsCancelComponent.cancelBtn}
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box className="w-full h-full mx-auto bg-white  rounded-3xl overflow-hidden dark:bg-dark_bg_surface  dark:bg-dark_bg_gray_primary">
          {/* <Box className="ownerCalendar-box"> */}
          <Box className="date-calendar-box w-full h-full">
            <DemoContainer
              sx={{ paddingTop: '0px' }}
              components={['DateCalendar']}
            >
              <DemoItem>
                <DateCalendar
                  dayOfWeekFormatter={(day) => day.format('ddd')}
                  value={calendarValue}
                  onChange={handleDateChange}
                  className="owner-calendar"
                  sx={{
                    '& [aria-selected="true"]': {
                      backgroundColor: '#007BFF',
                      color: '#fff',
                    },
                    '& .MuiTypography-caption': {
                      width: '100% !important',
                      shadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    },
                    '& .MuiDayCalendar-weekContainer > div': {
                      width: '100% !important',
                      textAlign: 'center',
                      alignItems: 'center',
                      height: '100% !important',
                      '@media (max-width: 325px)': {
                        width: '14.5% !important',
                      },
                    },
                    '& .MuiPickersSlideTransition-root': {
                      height: '100% !important',
                    },
                    '& .MuiDateCalendar-viewTransitionContainer': {
                      height: '100% !important',
                      width: '100% !important',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      justifyContent: 'center',
                    },
                    '& .MuiDateCalendar-viewTransitionContainer > div': {
                      height: '100% !important',
                    },
                    '& .MuiDayCalendar-root': {
                      height: '100% !important',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1.5rem',
                      '@media (max-width: 1024px)': {
                        gap: '0px',
                      },
                      '@media (min-width: 1024px)': {
                        border: '1px solid var(--Stroke, #D1D5DB)',
                        borderRadius: '10px',
                        padding: '.8rem 0rem',
                        boxShadow: '0px 10px 20px 10px #8F9AB02E',
                      },
                    },
                    '& .MuiDayCalendar-monthContainer': {
                      height: '100% !important',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                      '@media (max-width: 1024px)': {
                        gap: '0.5rem',
                      },
                    },
                    '& .MuiBox-root': {
                      height: '100% !important',
                    },
                  }}
                  disablePast
                  slots={{
                    day: ServerDay,
                    calendarHeader: CustomHeader,
                  }}
                  slotProps={{
                    day: {
                      highlightedDays,
                    } as unknown as PickersDayProps<Dayjs>,
                  }}
                  openTo="day"
                />
              </DemoItem>{' '}
            </DemoContainer>
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default OwnerCalendarComponent;

export const commonConfig = {
  ApiUrl: process.env.REACT_APP_API_URL,
  GooglePlaceApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  GooglePlaceApi: process.env.REACT_APP_GOOGLE_PLACE_API,
  CompanyName: 'CompanyName',
  CompanyNameSmall: 'CompanyNameSmall',
  Companyaddress: 'Companyaddress',
  Country: 'Country',
  CompanyContactNumber: 'CompanyContactNumber',
  CompanyEmail: 'CompanyEmail',
  CompanyWebsite: 'CompanyWebsite',
  CopyrightText: `© ${new Date().getFullYear()} OneLife. All rights reserved.`,
};

export const config = 'config';

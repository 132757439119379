import React from 'react';
import cssUtils from '../../Utils/cssUtils';
import BookingComponent from './bookingDetailsComponet';
import { ChildComponentProps } from './bookingInterface';

const BookingDetailsComponent: React.FC<ChildComponentProps> = ({
  vehicleDetails,
  disabledDateRange,
  onCreateBooking,
  disabledSlots,
  setChange,
  disabledMultipleDayStartSlots,
  disabledMultipleDayEndSlots,
  isSubmitting,
  monthLimit,
}) => {
  return (
    <div className="w-full bg-bg_light_primary dark:bg-dark_bg_surface">
      <div className={cssUtils.layout.sectionLayout}>
        <BookingComponent
          disabledDateRange={disabledDateRange}
          vehicleDetails={vehicleDetails}
          onCreateBooking={onCreateBooking}
          disabledSlots={disabledSlots}
          setChange={setChange}
          disabledMultipleDayStartSlots={disabledMultipleDayStartSlots}
          disabledMultipleDayEndSlots={disabledMultipleDayEndSlots}
          isSubmitting={isSubmitting}
          monthLimit={monthLimit}
        />
      </div>
    </div>
  );
};

export default BookingDetailsComponent;

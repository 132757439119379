import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  ErrorOutlined,
} from '@mui/icons-material';
import {
  Button,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  // InputLabel,
} from '@mui/material';
import '../../index.css';
// import { styled } from '@mui/system';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InternalRoute from '../../Utils/internalRoutes';
import ApplicationString from '../../Constants/applicationString';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import BackgroundLayout from './BackgroundLayout';
import '../ContactUsComponent/contactUs.css';
import { getBrowserName, onRefreshOverlapHandler } from '../../Utils/utils';

interface ILoginFormData {
  email: string;
  password: string;
}

interface IErrorsData {
  email: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface ILoginWithEmailComponentProps {
  formData: ILoginFormData;
  errorsData: IErrorsData;
  setEmailHasValue: Dispatch<SetStateAction<boolean>>;
  setPasswordHasValue: Dispatch<SetStateAction<boolean>>;
  emailHasValue: boolean;
  passwordHasValue: boolean;
  onEmailSubmit: (e: React.FormEvent) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loggingIn: boolean;
}

const LoginWithEmailComponent: React.FC<ILoginWithEmailComponentProps> = ({
  formData,
  errorsData,
  onEmailSubmit,
  handleEmailChange,
  setPasswordHasValue,
  passwordHasValue,
  setEmailHasValue,
  emailHasValue,
  handlePasswordChange,
  loggingIn,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [browser, setBrowser] = React.useState<string>('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const detectedBrowser = getBrowserName();
    setBrowser(detectedBrowser);
  }, []);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <BackgroundLayout>
      <div className="xsm:w-100 max-h-[100vh] bg-white  border border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.loginWithEmail.title.mainTitle}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.loginWithEmail.title.subTitle}
            </p>
          </div>
          <form onSubmit={onEmailSubmit}>
            <div data-testid="form-fields-container " className=" w-full mt-8">
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
              >
                <FormControl>
                  <TextField
                    variant="outlined"
                    label={ApplicationString.loginWithEmail.label.email}
                    className="rounded-4xl border-none"
                    InputLabelProps={{
                      shrink: formData?.email.length > 0 || emailHasValue,
                      classes: {
                        focused: `${browser === 'Safari' ? 'safari-login-focused' : 'chrome-login-focused'}`,
                      },
                    }}
                    InputProps={{
                      onAnimationStart:
                        onRefreshOverlapHandler(setEmailHasValue),
                      className:
                        'rounded-4xl border-none text-base dark:bg-dark_formFieldBg dark:text-white',
                    }}
                    onChange={handleEmailChange}
                    error={errorsData.email.error}
                    value={formData.email || ''}
                  />

                  {errorsData.email.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.email.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    placeholder="Enter your password"
                    label={ApplicationString.loginWithEmail.label.password}
                    type={showPassword ? 'text' : 'password'}
                    className="customInputBase rounded-4xl border-none text-base font-medium "
                    onChange={handlePasswordChange}
                    error={errorsData.password.error}
                    value={formData.password}
                    InputLabelProps={{
                      shrink: formData?.password.length > 0 || passwordHasValue,
                      classes: {
                        focused: `${browser === 'Safari' ? 'safari-login-focused' : 'chrome-login-focused'}`,
                      },
                    }}
                    InputProps={{
                      onAnimationStart:
                        onRefreshOverlapHandler(setPasswordHasValue),
                      className:
                        'rounded-4xl border-none text-base text-input_text dark:bg-dark_formFieldBg dark:text-white',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="pr-2 absolute right-4"
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            data-testid="login-show-password-icon"
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlined className=" text-primary " />
                            ) : (
                              <VisibilityOffOutlined className=" text-primary  " />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(errorsData.password.error ||
                    errorsData.password.onlyErrorMsg) && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.password.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <div className="flex justify-end mt-2">
                  <Link
                    data-testid="login-forgot-password-link"
                    to={InternalRoute.EmailForgotPassword}
                    className=" text-sm font-medium text-primary_text dark:text-dark_primary_text "
                  >
                    {ApplicationString.loginWithEmail.links.forgotPassword}
                  </Link>
                </div>
              </FormControl>
            </div>
            <div className="mt-8 flex flex-col gap-3">
              {loggingIn ? (
                <LoadingButton
                  buttonText={
                    ApplicationString.loginWithEmail.buttons.loggingIn
                  }
                />
              ) : (
                <Button
                  variant="contained"
                  className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                  color="primary"
                  data-testid="login--submit-button"
                  type="submit"
                >
                  {ApplicationString.loginWithEmail.buttons.login}
                </Button>
              )}

              <span className="text-secondary_text text-base font-bold flex justify-center">
                {ApplicationString.loginWithEmail.otherText.or}
              </span>
              {loggingIn ? (
                <Button
                  variant="outlined"
                  disabled
                  className="w-full normal-case cursor-not-allowed  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                >
                  <span className="flex gap-1 items-center font-bold text-base  ">
                    <PhoneIphoneIcon className="h-[16px] w-[18px]" />{' '}
                    {ApplicationString.loginWithEmail.buttons.continueWithPhone}
                  </span>
                </Button>
              ) : (
                <Link to={InternalRoute.PhoneLogin}>
                  <Button
                    variant="outlined"
                    data-testid="login-with-phone-button"
                    className="w-full normal-case  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                  >
                    <span className="flex gap-1 items-center font-bold text-base  ">
                      <PhoneIphoneIcon className="h-[16px] w-[18px]" />{' '}
                      {
                        ApplicationString.loginWithEmail.buttons
                          .continueWithPhone
                      }
                    </span>
                  </Button>
                </Link>
              )}
            </div>
          </form>
        </div>
        <div className="mt-8 border-t border-borderStroke flex justify-center items-center  dark:border-dark_borderStroke">
          <span className="p-5 flex gap-1 text-sm sm:text-base ">
            {ApplicationString.loginWithEmail.otherText.dontHaveAccount}
            <Link
              data-testid="login-with-email-signUp-link"
              to={InternalRoute.EmailSignup}
              className="text-primary font-bold"
            >
              {ApplicationString.loginWithEmail.links.signUp}
            </Link>
          </span>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default LoginWithEmailComponent;

import { InputLabel } from '@mui/material';
import React from 'react';

interface ISelectLabelProps {
  text: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
  // eslint-disable-next-line react/require-default-props
  id?: string;
}
export const RequiredSelectLabel: React.FC<ISelectLabelProps> = ({
  text,
  className,
  id,
}): JSX.Element => {
  return (
    <InputLabel
      className={className}
      id={id}
      htmlFor="model"
      data-testid={`label-${id}`}
    >
      {text}
      <span className="text-[#F30C0C]">*</span>
    </InputLabel>
  );
};
interface ITextFieldLabelProps {
  text: string;
  // eslint-disable-next-line react/require-default-props
  color?: string;
}
export const RequiredTextFieldLabel: React.FC<ITextFieldLabelProps> = ({
  text,
  color,
}): JSX.Element => {
  return (
    <span className={`p-1 ${color && `text-[${color}]`}`}>
      {text}
      <span className="text-[#F30C0C]">*</span>
    </span>
  );
};

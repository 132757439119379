import React from 'react';
import { Navigate } from 'react-router-dom';
import { IProtectedRoute } from '../Interfaces/interfaces';
import { getDetailsFromLocalStorage, userRoleEnums } from '../Utils/utils';
import InternalRoute from '../Utils/internalRoutes';
import UserDefaultLayout from '../Components/common/Layout/UserDefaultLayout';
import OwnerDefaultLayout from '../Components/common/Layout/OwnerDefaultLayout';
import { localStorageEnums } from '../Utils/enums';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/LoginInterfaces';
import OwnerWithoutLoginLayout from '../Components/common/Layout/OwnerWithoutLoginLayout';
import usePreviousRoute from '../Hooks/usePreviousRoute';

const protectedRoutes: React.FC<IProtectedRoute> = ({
  element,
  allowedLayout,
  isProtectedRoute,
  userToken,
  allowedRoles,
}: IProtectedRoute): React.ReactElement => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const previousRoute = usePreviousRoute();
  const checkLayoutType = (routeElement: IProtectedRoute['element']) => {
    switch (allowedLayout) {
      case userRoleEnums.USER:
        return <UserDefaultLayout>{routeElement}</UserDefaultLayout>;
      case userRoleEnums.OWNER:
        return <OwnerDefaultLayout>{routeElement}</OwnerDefaultLayout>;
      case userRoleEnums.OWNER_WITHOUT_LOGIN:
        return (
          <OwnerWithoutLoginLayout>{routeElement}</OwnerWithoutLoginLayout>
        );
      default:
        return routeElement;
    }
  };
  const handleRedirection = (): React.ReactElement => {
    if (previousRoute === InternalRoute.splashScreen) {
      return <Navigate to={InternalRoute.EmailLogin} />;
    }
    if (window.location.pathname === InternalRoute.vehicleCreate) {
      return <Navigate to={InternalRoute.splashScreen} />;
    }
    if (userToken) {
      return <Navigate to={InternalRoute.Forbidden} />;
    }

    return <Navigate to={InternalRoute.EmailLogin} />;
  };

  const checkAuthorization = (
    routeElement: IProtectedRoute['element']
  ): React.ReactElement => {
    const userInfo = getDetailsFromLocalStorage<LoggedInUserInfoDataType>(
      localStorageEnums.userInfo
    );
    const userRoles = userInfo?.roles;

    if (
      userToken &&
      userRoles &&
      userRoles.length > 0 &&
      userRoles.some((role) => allowedRoles.includes(role))
    ) {
      return checkLayoutType(routeElement);
    }

    return handleRedirection();
  };

  return isProtectedRoute ? (
    checkAuthorization(element)
  ) : (
    <>{checkLayoutType(element)}</>
  );
};

export default protectedRoutes;

const commonRegexs = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  name: /^[a-zA-Z\s]+$/,
  signupPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{6,}$/,
  hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
  hasCapitalLetter: /[A-Z]/,
  hasSmallLetter: /[a-z]/,
  hasDigit: /\d/,
  hasSpace: /\s/g,
  hasNoSpace: /\s+/g,
  matchCapitalLetters: /([A-Z])/g,
  floatValues: /^\d*\.?\d*$/,
};

export default commonRegexs;

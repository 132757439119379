import React, { useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { useParams } from 'react-router-dom';
import { ApiRequest } from '../../Api/api';
import { getDetailsFromLocalStorage, showApiError } from '../../Utils/utils';
import URLS from '../../Constants/urls';
import { IStepData } from '../../Interfaces/Stepper';
import { localStorageEnums, stepsNumberEnum } from '../../Utils/enums';
import { useToast } from '../../Services/ToastService';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import VehicleEditStepperComponent from '../../Components/VehicleEditStepper';

const VehicleStepperContainer = (): JSX.Element => {
  const { vehicleId } = useParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const getUpdatePayload = () => {
    const stepsData =
      getDetailsFromLocalStorage<IStepData[]>(localStorageEnums.stepsData) ||
      [];

    const step1Data = stepsData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step1
    )?.data;
    const step2Data = stepsData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step2
    )?.data;
    const step3Data = stepsData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step3
    )?.data;
    const step4Data = stepsData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step4
    )?.data;
    const step5Data = stepsData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step5
    )?.data;
    const step6Data = stepsData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step6
    )?.data;

    const vehicleData = {
      title: step1Data?.title,
      description: step1Data?.description || null,
      category: step1Data?.category,
      subCategory: step1Data?.subCategory,
      maker: step1Data?.maker,
      manufacturingYear: Number(step1Data?.manufacturingYear),
      model: step1Data?.model,
      capacity: step1Data?.capacity,
      size: step1Data?.size,
      isAddressSame: step2Data?.isSameAddress,
      country: step2Data?.country,
      state: step2Data?.pickUp?.state,
      city: step2Data?.pickUp?.city,
      zip: step2Data?.pickUp?.zipCode,
      address: step2Data?.pickUp?.address1,
      address2: step2Data?.pickUp?.address2,
      dropCountry: step2Data?.country,
      dropState: step2Data?.dropOff?.state,
      dropCity: step2Data?.dropOff?.city,
      dropZip: step2Data?.dropOff?.zipCode,
      dropAddress: step2Data?.dropOff?.address1,
      dropAddress2: step2Data?.dropOff?.address2,
      metaData: {
        engineSize: step1Data?.engineSize,
        isTrailerIncluded: step1Data?.trailer,
        hitchSize: step1Data?.hitchSize,
        ownershipType: step1Data?.ownerShipType,
        amenities: step4Data?.amenities,
        restricted: step4Data?.restrictions,
        termsAndCondition: step6Data?.termsAndCondition,
      },
      images: step3Data?.images,
      maxDaysFor24Hourly:
        step5Data && step5Data.maxDaysFor24Hourly
          ? Number(step5Data.maxDaysFor24Hourly)
          : null,
      bookingSlotType: step5Data?.bookingSlotType,
      bookingGap: step5Data?.bookingGap,
      shortNoticePeriod: step5Data?.shortNoticePeriod,
      isMultipleBookingSameDay: step5Data?.isMultipleBookingSameDay,
      bookingRateHourly: step5Data?.bookingRateHourly,
      bookingRate12Hourly: step5Data?.bookingRate12Hourly,
      timeFrameFor12Hourly: step5Data?.timeFrameFor12Hourly,
      bookingRate24Hourly: step5Data?.bookingRate24Hourly,
      monthLimit: step5Data?.monthLimit,
    };
    return vehicleData;
  };
  const updateVehicle = async (
    setActiveStep?: (arg0: (prevActiveStep: number) => number) => void
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const vehiclePayload = getUpdatePayload();
      const response = await ApiRequest.put(
        URLS.GET_VEHICLE_DETAILS_BY_ID.replace('#{id}', `${vehicleId}`),
        vehiclePayload
      );
      if (response?.status === HttpStatusCode.NoContent) {
        setIsLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setActiveStep &&
          setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } catch (error) {
      setIsLoading(false);
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  return (
    <VehicleEditStepperComponent
      updateVehicle={(
        setActiveStep?: (arg0: (prevActiveStep: number) => number) => void
      ) => updateVehicle(setActiveStep)}
      isLoading={isLoading}
    />
  );
};

export default VehicleStepperContainer;

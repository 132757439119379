import React from 'react';

interface SearchLayoutProps {
  children: React.ReactNode;
}

const SearchLayout: React.FC<SearchLayoutProps> = ({ children }) => {
  return (
    <div className="relative px-6 lg:px-8 2xl:px-0 3xsm:flex justify-center items-center h-full">
      <div className="xl:absolute md:max-w-7xl mx-auto w-full 3xsm:-mt-10 xl:px-8 2xl:px-0 xl:my-0 my-8 xl:mt-0 z-10">
        {children}
      </div>
    </div>
  );
};

export default SearchLayout;

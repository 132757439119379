import React from 'react';

interface IStaticCardProps {
  svg: string;
  StatisticsValue: number | string;
  cardTitle: string;
}

const StaticCard: React.FC<IStaticCardProps> = (props): JSX.Element => {
  const { svg, StatisticsValue, cardTitle } = props;
  return (
    <div className="dark:bg-[#292929] py-6 px-2 w-full bg-white shadow-[0px_4px_16px_0px_#1212120F] rounded-md flex sm:flex-col lg:flex-row items-center gap-4">
      <div className="w-fit p-3 rounded-full bg-[#E1EFFE]">
        <img src={svg} alt="" className="h-[25px] w-[25px]" />
      </div>
      <div className="flex items-start sm:items-center lg:items-start flex-col gap-1">
        <span className="font-semibold dark:text-white">{StatisticsValue}</span>
        <span className="text-secondary_text dark:text-[#B8B8B8] text-start sm:text-center lg:text-start max-[450px]:text-start">
          {cardTitle}
        </span>
      </div>
    </div>
  );
};

export default StaticCard;

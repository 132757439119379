import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { Modal, Typography } from '@mui/material';
import { DeleteAccountSvg } from '../../../Assets/Svgs';
import useDeleteAccountHook from '../../../Hooks/useDeleteAccountHook';
import ApplicationString from '../../../Constants/applicationString';
import ColorModeContext from '../../../Utils/ColorModeContext';

interface IDeleteAccount {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteAccountPopupComponent: React.FC<IDeleteAccount> = ({
  open,
  setOpen,
}) => {
  const deleteAccount = useDeleteAccountHook();
  const { currentTheme } = useContext(ColorModeContext);

  const handleDeleteClose = () => {
    deleteAccount.deleteAccount();
    setOpen(false);
  };
  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        data-testid="modal-modal-title"
        className="flex items-center justify-center min-h-screen p-4"
      >
        <div className="bg-white px-6 py-4 rounded-xl w-full max-w-xs md:max-w-md mx-auto relative dark:bg-dark_bg_secondary">
          <div className="flex flex-col justify-between items-start">
            <div className="flex flex-col">
              <DeleteAccountSvg />
              <Typography className=" mt-2 font-bold text-2xl text-black text-nowrap dark:text-white">
                {ApplicationString.DeletePopUp.deleteLabel}
              </Typography>
              <Typography
                data-testid="modal-modal-description"
                sx={{ mt: 2 }}
                className="md:text-nowrap dark:text-dark_text_sub_primary"
              >
                {ApplicationString.DeletePopUp.content}
              </Typography>
            </div>
          </div>

          <div className="mt-4 w-full flex flex-row gap-4">
            <Button
              autoFocus
              onClick={handleDeleteClose}
              className="capitalize px-10 md:px-20 py-2 md:py-2.5 text-base font-bold rounded-full border"
              style={{
                border: `${currentTheme === 'dark' ? '1px solid white' : '1px solid black'}`,
                backgroundColor: 'transparent',
                color: `${currentTheme === 'dark' ? 'white' : 'black'}`,
              }}
              data-testid="account-button-delete"
            >
              {ApplicationString.DeletePopUp.buttons.delete}
            </Button>
            <Button
              onClick={handleCancelClose}
              autoFocus
              className="bg-primary text-white
          capitalize px-10 md:px-20 py-2 md:py-2.5 text-base font-bold rounded-full hover:bg-primary"
              data-testid="account-button-cancel"
            >
              {ApplicationString.DeletePopUp.buttons.cancel}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAccountPopupComponent;

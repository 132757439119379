import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import SignUpVerifiedComponent from '../../Components/SignUpComponents/SignUpVerifiedComponent';
import ApplicationString from '../../Constants/applicationString';

const SignUpVerifiedContainer: React.FC = () => {
  const location = useLocation();
  const [params] = useSearchParams({});
  const verificationId = params.get('verificationId');

  const title = location.pathname.includes('email')
    ? ApplicationString.VerifiedCard.email.title
    : ApplicationString.VerifiedCard.phone.title;
  const subTitle = location.pathname.includes('email')
    ? ApplicationString.VerifiedCard.email.subTitle
    : ApplicationString.VerifiedCard.phone.subTitle;

  return (
    <div className="w-full h-full flex justify-center">
      <SignUpVerifiedComponent
        title={title}
        subTitle={subTitle}
        verificationId={verificationId}
      />
    </div>
  );
};

export default SignUpVerifiedContainer;

import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError, HttpStatusCode } from 'axios';
import React, { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { ApiRequest } from '../../Api/api';
import { getSettingsData } from '../../Components/common/UserHeader';
import LogInWithPhoneComponent from '../../Components/LoginComponents/LogInWithPhone';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { IUserSettingsData } from '../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums, loginSignupFormEnums } from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import {
  getDetailsFromLocalStorage,
  handleApiError,
  setLocalStorageInfo,
} from '../../Utils/utils';
import commmonRegexs from '../../Utils/commonRegex';
import SendBirdService from '../../Services/sendbird.service';

interface ILoginFormData {
  countryCode: string;
  phoneNumber: string;
  password: string;
}

interface IErrorsData {
  countryCode: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  phoneNumber: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

const LoginWithPhoneContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [phoneNumberHasValue, setPhoneNumberHasValue] = React.useState(false);
  const [passwordHasValue, setPasswordHasValue] = React.useState(false);
  const [countryCodeOptions, setCountryCodeOptions] = React.useState<string[]>(
    []
  );
  const hasValue = (value: string) => value !== '';
  const globalStore = useSendbirdStateContext();

  const [formData, setFormData] = React.useState<ILoginFormData>({
    countryCode: '',
    phoneNumber: '',
    password: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    countryCode: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    phoneNumber: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    password: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
  });

  // Show validation errors based on api response
  const showValidationErrors = (err: AxiosError<IApiErrorResponse>) => {
    if (
      (err?.response?.status === HttpStatusCode.BadRequest ||
        err?.response?.status === HttpStatusCode.NotFound) &&
      formData.password.trim() &&
      formData.phoneNumber.trim()
    ) {
      setErrorsData((prevState) => ({
        ...prevState,
        countryCode: {
          error: false,
          ValidationMessage: '',
        },
        phoneNumber: {
          error: false,
          ValidationMessage: '',
        },
        password: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage:
            ApplicationString.loginWithPhone.validationMessages.invalidMsgs
              .invalidPhoneOrPassword,
        },
      }));
    }
  };

  // On Form submit
  const onPhoneLoginSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (
      formData.phoneNumber.length > loginSignupFormEnums.maxPhoneNumberLength ||
      formData.password.length > loginSignupFormEnums.maxPasswordLength
    )
      return;
    setErrorsData((prevState) => ({
      ...prevState,
      countryCode: {
        error: !formData.countryCode.trim(),
        ValidationMessage: '',
      },
      phoneNumber: {
        error: !formData.phoneNumber.trim() || !formData.countryCode.trim(),
        ValidationMessage:
          !formData.phoneNumber.trim() || !formData.countryCode.trim()
            ? ApplicationString.loginWithPhone.validationMessages.requiredMsgs
                .phone
            : '',
      },
      password: {
        error: !formData.password.trim(),
        ValidationMessage: !formData.password.trim()
          ? ApplicationString.loginWithPhone.validationMessages.requiredMsgs
              .password
          : '',
      },
    }));
    if (
      !formData.countryCode.trim() ||
      !formData.phoneNumber.trim() ||
      !formData.password.trim()
    )
      return;

    const payload = {
      userName: `${formData.countryCode}-${formData.phoneNumber}`,
      password: formData.password,
    };
    try {
      setIsLoggingIn(true);
      const res = await ApiRequest.post(`${URLS.USER_LOGIN}`, payload);

      if (res?.status === HttpStatusCode.Ok) {
        setLocalStorageInfo(res.data, localStorageEnums.userInfo);
        const lastAccessUrl = getDetailsFromLocalStorage(
          localStorageEnums.lastAccessUrl
        );
        navigate(lastAccessUrl || InternalRoute.Home);

        const { accountId: currentUserId, userName, avatarPath } = res.data;

        await SendBirdService.connectUser(currentUserId, globalStore);

        await SendBirdService.handleUserCreationOrUpdate({
          currentUserId,
          userName,
          avatarPath,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showValidationErrors(axiosError);
      handleApiError(axiosError);
    } finally {
      setIsLoggingIn(false);
    }
  };

  // Form change handlers

  const countryCodeChangeHandler = (e: SelectChangeEvent<string>) => {
    setFormData({ ...formData, countryCode: e.target.value });
    setErrorsData((prevState) => ({
      ...prevState,
      countryCode: { error: false, ValidationMessage: '' },
    }));
  };

  const phoneNumberChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        phoneNumber: e.target.value.trim(),
      }));
      setPhoneNumberHasValue(hasValue(e.target.value.trim()));
      const validationMessages =
        e.target.value.trim().length > loginSignupFormEnums.maxPhoneNumberLength
          ? ApplicationString.loginWithPhone.validationMessages.invalidMsgs
              .maxPhoneNumberLength
          : '';

      setErrorsData({
        ...errorsData,
        phoneNumber: {
          error: validationMessages !== '',
          ValidationMessage: validationMessages,
        },
      });
    }
  };

  const passwordChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');

    setFormData((prevState) => ({
      ...prevState,
      password: trimmedPassword,
    }));
    setPasswordHasValue(hasValue(trimmedPassword));

    if (trimmedPassword.length > loginSignupFormEnums.maxPasswordLength) {
      setErrorsData((prevState) => ({
        ...prevState,
        password: {
          error: true,
          ValidationMessage:
            ApplicationString.loginWithPhone.validationMessages.invalidMsgs
              .maxPasswordLength,
        },
      }));
    } else {
      setErrorsData({
        ...errorsData,
        password: { error: false, ValidationMessage: '' },
      });
    }
  };

  // Get user settings data on header mount
  useEffect(() => {
    const userSettings = getDetailsFromLocalStorage<IUserSettingsData>(
      localStorageEnums.settings
    );
    if (userSettings) {
      setCountryCodeOptions(
        userSettings.setting.country.map((item) => item.countryCode)
      );
    } else {
      getSettingsData(setCountryCodeOptions);
    }
  }, []);

  return (
    <LogInWithPhoneComponent
      formData={formData}
      onPhoneLoginSubmit={onPhoneLoginSubmit}
      errorsData={errorsData}
      setPhoneNumberHasValue={setPhoneNumberHasValue}
      phoneNumberHasValue={phoneNumberHasValue}
      passwordHasValue={passwordHasValue}
      setPasswordHasValue={setPasswordHasValue}
      countryCodeOptions={countryCodeOptions}
      countryCodeChangeHandler={countryCodeChangeHandler}
      phoneNumberChangeHandler={phoneNumberChangeHandler}
      passwordChangeHandler={passwordChangeHandler}
      isLoggingIn={isLoggingIn}
    />
  );
};

export default LoginWithPhoneContainer;

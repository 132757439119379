import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Parallax, ParallaxProps } from 'react-parallax';
import exploreImg from '../../../Assets/explore4.png';
import ListingCard from '../../common/CreateListingCard';
import ApplicationString from '../../../Constants/applicationString';
import ColorModeContext from '../../../Utils/ColorModeContext';
import exploreDarkImg from '../../../Assets/exploreDark2.png';
import InternalRoute from '../../../Utils/internalRoutes';

interface CustomParallaxProps extends ParallaxProps {
  strength?: number;
  bgImage: string;
}

const ListingExploreSection: React.FC = () => {
  const { OwnerlistingCard } = ApplicationString;
  const { currentTheme } = useContext(ColorModeContext);
  const Navigate = useNavigate();
  const customParallaxProps: CustomParallaxProps = {
    strength: 500,
    bgImage: currentTheme === 'dark' ? exploreDarkImg : exploreImg,
  };

  return (
    <Parallax
      strength={customParallaxProps.strength}
      bgImage={customParallaxProps.bgImage}
    >
      <div className="w-full">
        <div className="mt-20 mb-20 sm:mt-40 2xl:mt-80 md:mt-60">
          <ListingCard
            dataTestId="OwnerListingButton"
            // backgroundImage={OwnerlistingCard.backgroundImage}
            title={OwnerlistingCard.title}
            description={OwnerlistingCard.description}
            buttonText={OwnerlistingCard.buttonText}
            buttonOnClick={() => {
              Navigate(InternalRoute.vehicleCreate);
            }}
          />
        </div>
      </div>
    </Parallax>
  );
};

export default ListingExploreSection;

import axios, { AxiosInstance, AxiosResponse, HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationString from '../Constants/applicationString';

import { commonConfig } from '../Constants/commonConfig';
import URLS from '../Constants/urls';

import { LoggedInUserInfoDataType } from '../Interfaces/Login/LoginInterfaces';
import {
  localStorageEnums,
  navigationKeys,
  refreshTokenCallTime,
} from '../Utils/enums';
import {
  getDetailsFromLocalStorage,
  removeLocalStorageAccountInfoOnDeleteOrLogout,
  setLocalStorageInfo,
} from '../Utils/utils';
import InternalRoute from '../Utils/internalRoutes';

export const ApiRequest: AxiosInstance = axios.create({
  baseURL: commonConfig.ApiUrl,
  headers: {
    Accept: '*/*',
  },
});

interface AxiosInterceptorProps {
  children: React.ReactNode;
}

const getRefreshToken = async () => {
  const refreshToken = getDetailsFromLocalStorage<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.refreshToken;
  const res = await ApiRequest.post(URLS.REFRESH_TOKEN, {
    refreshToken,
  });
  if (
    res?.status !== HttpStatusCode.Ok ||
    !res?.data ||
    JSON.stringify(res.data) === '{}'
  ) {
    throw new Error(ApplicationString.API.refreshToken.error);
  } else {
    return res.data;
  }
};

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

let isRefreshTokenFetching = false;

const AxiosInterceptor = ({
  children,
}: AxiosInterceptorProps): React.ReactElement | null => {
  const navigate = useNavigate();
  // Request interceptor
  ApiRequest.interceptors.request.use(
    async (originalConfig) => {
      const config = { ...originalConfig };
      const accessToken = getDetailsFromLocalStorage<LoggedInUserInfoDataType>(
        localStorageEnums.userInfo
      )?.accessToken;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        const jwtData = parseJwt(accessToken);
        const differenceInSeconds = dayjs(jwtData.exp * 1000).diff(
          dayjs(),
          'seconds'
        );
        if (differenceInSeconds <= refreshTokenCallTime) {
          if (!isRefreshTokenFetching) {
            isRefreshTokenFetching = true;
            try {
              const refreshedLoggedInUserData = await getRefreshToken();
              setLocalStorageInfo(
                refreshedLoggedInUserData,
                localStorageEnums.userInfo
              );
            } catch (error) {
              removeLocalStorageAccountInfoOnDeleteOrLogout(
                navigate,
                navigationKeys.logout
              );
            } finally {
              isRefreshTokenFetching = false;
            }
          }
        }
      }
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  // let isRefreshTokenFetching = false;
  // let failedApis: AxiosRequestConfig<unknown>[] = [];
  /**
 * Interceptor for handling responses from all requests made using the ApiRequest instance.
 * This interceptor simply returns the response object without any modifications, acting as a pass-through.
 
 * @param {AxiosResponse} response - The response object from the Axios request.
 * @returns {AxiosResponse} The unmodified response object.
 */

  // Response interceptor
  ApiRequest.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error) => {
      if (error.response?.status === HttpStatusCode.Unauthorized) {
        if (!window.location.href.includes('/login')) {
          setLocalStorageInfo(
            window.location.pathname + window.location.search,
            localStorageEnums.lastAccessUrl
          );
        }
        removeLocalStorageAccountInfoOnDeleteOrLogout(
          navigate,
          navigationKeys.logout
        );
      }
      if (error.response?.status === HttpStatusCode.Forbidden) {
        navigate(InternalRoute.Forbidden);
      }

      return Promise.reject(error);
    }
  );

  return React.isValidElement(children) ? children : null;
};
export default AxiosInterceptor;

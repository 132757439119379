import { Alert, Divider } from '@mui/material';
import { localStorageEnums } from '../../../Utils/enums';
import PreviewLocationDetails from '../PreviewStepComponents/LocationDetails';
import PreviewVehicleDetails from '../PreviewStepComponents/VehicleDetails';
import PreviewVehiclePhoto from '../PreviewStepComponents/VehiclePhoto';
import PreviewBookingDetails from '../PreviewStepComponents/BookingDetails';
import PreviewAdditionalDetails from '../PreviewStepComponents/AdditionalDetails';
import { getDetailsFromLocalStorage } from '../../../Utils/utils';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import ApplicationString from '../../../Constants/applicationString';

interface PreviewProps {
  slotError: boolean;
  isEdit: boolean;
}

const PreviewStep: React.FC<PreviewProps> = ({
  slotError,
  isEdit,
}): JSX.Element => {
  const settings = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  );
  const skippedSteps =
    getDetailsFromLocalStorage<number[]>(localStorageEnums.skippedSteps) || [];
  const isStepperHasError =
    getDetailsFromLocalStorage<boolean>(localStorageEnums.isStepperHasError) ||
    false;
  const isSlotError =
    getDetailsFromLocalStorage<boolean>(localStorageEnums.isSlotError) || false;
  return (
    <div className="w-full flex flex-col">
      <div className="flex h-full gap-0 md:gap-9 flex-col w-full xl:flex-row">
        <div className="w-full flex flex-col gap-0 md:gap-6">
          <PreviewVehiclePhoto isEdit={isEdit} />
          <Divider
            className="my-6 xl:hidden
         2xsm:flex"
          />
          <PreviewVehicleDetails settings={settings} isEdit={isEdit} />
          <Divider
            className="my-6 xl:hidden
          2xsm:flex"
          />
        </div>
        <div className="w-full">
          <PreviewLocationDetails isEdit={isEdit} />
          <Divider className="my-6" />
          <PreviewBookingDetails settings={settings} isEdit={isEdit} />
          <Divider className="my-6" />
          <PreviewAdditionalDetails isEdit={isEdit} />
        </div>
      </div>
      {skippedSteps?.length > 0 && isStepperHasError && (
        <>
          <Divider className="my-6" />
          <Alert
            variant="outlined"
            severity="error"
            className="w-full md:w-9/12 flex items-center text-secondary_text dark:text-dark_text_sub_primary dark:bg-dark_borderStroke dark:border-dark_borderStroke border-borderStroke bg-formFieldBg rounded-2xl text-base font-normal"
          >
            {slotError || isSlotError
              ? ApplicationString.vehicleStepper.previewStep
                  .alertMessageWithSlotError
              : ApplicationString.vehicleStepper.previewStep.alertMessage}
          </Alert>
        </>
      )}
    </div>
  );
};

export default PreviewStep;

/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControlLabel,
  Slider,
  Typography,
  MenuItem,
  styled,
  CircularProgress,
  PaginationItem,
  Pagination,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  SelectProps,
} from '@mui/material';
import Select from '@mui/material/Select';
import { FormControl } from '@mui/base';
import FilterListIcon from '@mui/icons-material/FilterList';
import Checkbox from '@mui/material/Checkbox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useContext, useEffect, useState } from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  checkIfArrayIsTruthyOrNot,
  getCoverPhoto,
  getLocalStorageAccountInfo,
} from '../../Utils/utils';
import {
  ISubCategory,
  IUserSettingsData,
  IVehicleCategory,
} from '../../Interfaces/common/settings/settings';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import {
  PaginationButtonEnums,
  categoryEnums,
  localStorageEnums,
} from '../../Utils/enums';
import SearchComponent from '../common/SearchComponent';
import ApplicationString from '../../Constants/applicationString';
import {
  LeftArrowSvg,
  LocationIcon,
  PersonIcon,
  RightArrowSvg,
  VehicleSizeIcon,
  FilterByIcon,
  CheckBoxIcon,
  CheckedIcon,
} from '../../Assets/Svgs';
import cssUtils from '../../Utils/cssUtils';
import img2 from '../../Assets/image-new.png';
import InternalRoute from '../../Utils/internalRoutes';
import ColorModeContext from '../../Utils/ColorModeContext';

interface FilteredSearchedData {
  data: IVehiclesData[];
  total: number;
}

interface QueryParams {
  category?: string;
  latlong?: string;
  startDate?: string;
  endDate?: string;
  subCategory?: number[];
  pageNumber: number;
  sortBy?: string;
  sortDirection?: string;
  radius: number;
}
interface SearchComponentProps {
  loading: boolean;
  getSearchResults: (queryParams: QueryParams) => void;
  filteredSearchedData: FilteredSearchedData;
}
const CustomSelect = styled((props: SelectProps) => (
  <Select
    {...props}
    IconComponent={(iconProps) => (
      <FilterListIcon
        {...iconProps}
        sx={{ fontSize: '18px' }} // Adjust the icon size here
      />
    )}
  />
))({});

const SearchScreenComponent: React.FC<SearchComponentProps> = ({
  loading,
  getSearchResults,
  filteredSearchedData,
}) => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [sorterDrawerOpen, setSorterDrawerOpen] = useState(false);

  const toggleFilterDrawer = (open: boolean) => () => {
    setFilterDrawerOpen(open);
  };
  const toggleSorterDrawer = (open: boolean) => () => {
    setSorterDrawerOpen(open);
  };
  const [sortBy, setSortBy] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedCategory = searchParams.get('category') || 'allVehicles';
  const selectedLocationName = searchParams.get('locationName') || '';
  const selectedLocation = searchParams.get('latlong') || '';
  const selectedStartDate = searchParams.get('startDate') || '';
  const selectedEndDate = searchParams.get('endDate') || '';
  const [filterData, setFilterData] = useState<ISubCategory[]>([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [radius, setRadius] = useState<number>(100);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [filteredItems, setFilteredItems] = useState<number[]>([]);
  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;
  const minValue = userSettingsData?.radius.min;
  const maxValue = userSettingsData?.radius.max;
  const { currentTheme } = useContext(ColorModeContext);
  const check = (category: string): string => {
    if (category === ApplicationString.searchScreen.categories.watercraft) {
      return String(categoryEnums.watercraft);
    }
    if (category === ApplicationString.searchScreen.categories.landVehicles) {
      return String(categoryEnums.landVehicles);
    }
    return '';
  };

  const categoryString = searchParams.get('category') || 'allVehicles';
  const [queryParamsforSearch, setQueryParamsforSearch] = useState<QueryParams>(
    {
      category: categoryString
        ? check(categoryString.toLowerCase()?.replace(/ /g, ''))
        : '',
      latlong: selectedLocation,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      subCategory: [],
      pageNumber: 1,
      sortBy: '',
      sortDirection: '',
      radius: 100,
    }
  );
  function getSubcategoryNameById(subcategories: ISubCategory[], id: number) {
    const subcategoryId = subcategories.find(
      (subCat: ISubCategory) => subCat.id === id
    );
    return subcategoryId ? subcategoryId.name : '';
  }
  const handleDelete = (id: number) => {
    setCheckedItems(checkedItems.filter((item) => item !== id));
    setFilteredItems(filteredItems.filter((item) => item !== id));
    setSelectedPage(1);
    setQueryParamsforSearch({
      ...queryParamsforSearch,
      subCategory: checkedItems.filter((item) => item !== id),
      pageNumber: 1,
    });
    const updatedSearchParams = new URLSearchParams(window.location.search);
    if (id) {
      const currentSubCategories = updatedSearchParams.getAll('subCategory');
      const filteredSubCategories = currentSubCategories.filter(
        (subCategory) => getSubcategoryNameById(filterData, id) !== subCategory
      );
      updatedSearchParams.delete('subCategory');
      filteredSubCategories.forEach((subCategory) =>
        updatedSearchParams.append('subCategory', subCategory)
      );
    }
    setSearchParams(updatedSearchParams);
    if (
      Object.keys(queryParamsforSearch.subCategory ?? []).length === 0 &&
      loading
    ) {
      getSearchResults(queryParamsforSearch);
    }
  };
  const handleRadiusChange = (value: number) => {
    setSelectedPage(1);
    setQueryParamsforSearch({
      ...queryParamsforSearch,
      radius: value,
      pageNumber: 1,
    });

    const updatedSearchParams = new URLSearchParams(window.location.search);
    if (value) {
      updatedSearchParams.set('radius', String(value));
      setSearchParams(updatedSearchParams);
    }
    setRadius(value);
  };

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { checked: isChecked } = event.target;
    setCheckedItems((prevItems) => {
      const updatedItems = isChecked
        ? [...prevItems, id]
        : prevItems.filter((item) => item !== id);
      setQueryParamsforSearch({
        ...queryParamsforSearch,
        subCategory: updatedItems,
        pageNumber: 1,
      });
      return updatedItems;
    });
    const updatedSearchParams = new URLSearchParams(window.location.search);
    if (isChecked) {
      updatedSearchParams.append(
        'subCategory',
        getSubcategoryNameById(filterData, id)
      );
    } else {
      const currentSubCategories = updatedSearchParams.getAll('subCategory');
      const filteredSubCategories = currentSubCategories.filter(
        (subCategory) => getSubcategoryNameById(filterData, id) !== subCategory
      );
      updatedSearchParams.delete('subCategory');
      filteredSubCategories.forEach((subCategory) =>
        updatedSearchParams.append('subCategory', subCategory)
      );
    }
    setSearchParams(updatedSearchParams);
    setSelectedPage(1);
    setFilteredItems((prevItems) => {
      const updatedItems = isChecked
        ? [...prevItems, id]
        : prevItems.filter((item) => item !== id);
      return updatedItems;
    });
  };

  const handleClearFilters = () => {
    setCheckedItems([]);
    setFilteredItems([]);
    setRadius(100);
    setSelectedPage(1);
    const updatedSearchParams = new URLSearchParams(window.location.search);
    updatedSearchParams.delete('subCategory');
    updatedSearchParams.delete('radius');
    setQueryParamsforSearch({
      ...queryParamsforSearch,
      subCategory: [],
      radius: 100,
      pageNumber: 1,
    });

    if (
      Object.keys(queryParamsforSearch.subCategory ?? []).length === 0 &&
      loading
    ) {
      getSearchResults(queryParamsforSearch);
    }
    setSearchParams(updatedSearchParams);
  };
  const handlePageChange = (page: number) => {
    setSelectedPage(page);
    const updatedSearchParams = new URLSearchParams(window.location.search);
    if (page) {
      updatedSearchParams.set('pageNumber', String(page));
      setSearchParams(updatedSearchParams);
    }
    setQueryParamsforSearch({ ...queryParamsforSearch, pageNumber: page });
  };
  const handleSorting = (value: string) => {
    setSortBy(value);
    const updatedUrlParams = new URLSearchParams(window.location.search);

    if (value) {
      updatedUrlParams.set('sortBy', String(value));
      setSearchParams(updatedUrlParams);
    }
    if (
      value ===
      ApplicationString.searchScreen.queryParams.sortDirection.ascending
    ) {
      setQueryParamsforSearch({
        ...queryParamsforSearch,
        sortBy: ApplicationString.searchScreen.queryParams.sortBy,
        sortDirection:
          ApplicationString.searchScreen.queryParams.sortDirection.ascending,
      });
    } else
      setQueryParamsforSearch({
        ...queryParamsforSearch,
        sortBy: ApplicationString.searchScreen.queryParams.sortBy,
        sortDirection:
          ApplicationString.searchScreen.queryParams.sortDirection.descending,
      });
  };
  const handleSearch = (data: {
    category: string;
    latlong: string;
    locationName?: string;
    startDate: string | null;
    endDate: string | null;
  }) => {
    const trimmedData = {
      ...data,
      locationName: data && data.locationName,
    };
    setQueryParamsforSearch({
      ...queryParamsforSearch,
      category: check(trimmedData.category.toLowerCase()?.replace(/ /g, '')),
      latlong: trimmedData.latlong,
      // city: trimmedData.city,
      startDate: trimmedData.startDate ?? undefined,
      endDate: trimmedData.endDate ?? undefined,
      pageNumber: 1,
    });
    setSearchParams({
      ...searchParams,
      category: trimmedData.category,
      latlong: trimmedData.latlong,
      locationName: trimmedData.locationName,
      startDate: trimmedData.startDate ?? undefined,
      endDate: trimmedData.endDate ?? undefined,
    });
    setSelectedPage(1);
  };
  const data = filteredSearchedData?.data || [];
  const categoriesData: IVehicleCategory[] = checkIfArrayIsTruthyOrNot<
    IVehicleCategory[]
  >(userSettingsData?.vehicleCategory);
  const subCategories = categoriesData.flatMap(
    (category) => category.subCategory
  );

  useEffect(() => {
    if (selectedCategory === 'allVehicles' || !selectedCategory) {
      const allSubCategories = categoriesData.flatMap(
        (category) => category.subCategory
      );
      setFilterData(allSubCategories);
    } else {
      const categorySelected = categoriesData.find(
        (category) =>
          category.name.toLowerCase() === selectedCategory.toLowerCase()
      );
      if (categorySelected) {
        setFilterData(categorySelected.subCategory);
      } else {
        setFilterData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const getSubcategoryIdByName = (
    allSubCategories: ISubCategory[],
    subcat: string[]
  ) => {
    return subcat
      .map((name) => {
        const subcategory = allSubCategories.find((sub) => sub.name === name);
        return subcategory ? subcategory.id : null;
      })
      .filter((id) => id !== null) as number[];
  };

  useEffect(() => {
    const searchParameters = new URLSearchParams(window.location.search);
    const subCategory = searchParameters.getAll('subCategory');
    const radiusValue = searchParameters.get('radius');
    const pageNumber = searchParameters.get('pageNumber');
    const sortValue = searchParameters.get('sortBy');

    let updatedQueryParams = { ...queryParamsforSearch };

    if (subCategory.length > 0) {
      const subCategoryIds = getSubcategoryIdByName(subCategories, subCategory);
      setCheckedItems(subCategoryIds);
      setFilteredItems(subCategoryIds);
      updatedQueryParams = {
        ...updatedQueryParams,
        subCategory: subCategoryIds,
      };
    }

    if (radiusValue) {
      setRadius(Number(radiusValue));
      updatedQueryParams = {
        ...updatedQueryParams,
        radius: Number(radiusValue),
      };
    }

    if (pageNumber) {
      setSelectedPage(Number(pageNumber));
      updatedQueryParams = {
        ...updatedQueryParams,
        pageNumber: Number(pageNumber),
      };
    }

    if (sortValue) {
      setSortBy(sortValue);
      updatedQueryParams = {
        ...updatedQueryParams,
        sortBy: ApplicationString.searchScreen.queryParams.sortBy,
        sortDirection: sortValue,
      };
    }
    setQueryParamsforSearch(updatedQueryParams);

    getSearchResults(updatedQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setQueryParamsforSearch((prevState) => ({
      ...prevState,
      category: check(selectedCategory.toLowerCase()?.replace(/ /g, '')),
      latlong: selectedLocation,
      startDate: selectedStartDate ?? undefined,
      endDate: selectedEndDate ?? undefined,
    }));
  }, [selectedCategory, selectedLocation, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    const hasSearchParams = Object.keys(queryParamsforSearch).length > 0;
    if (hasSearchParams) {
      getSearchResults(queryParamsforSearch);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParamsforSearch]);

  return (
    <div className="w-full bg-slate-100 dark:bg-dark_bg_surface">
      <div className={cssUtils.layout.sectionLayout}>
        <div className="relative">
          <SearchComponent
            onSearch={handleSearch}
            initialCategory={selectedCategory}
            initialLocation={selectedLocationName}
            initialCoordinates={selectedLocation}
          />
        </div>
        <div className="dark:bg-dark_bg_surface mt-10 flex flex-col gap-10 sm:flex-row md:flex-row lg:flex-row  relative">
          <div className="hidden lg:block">
            <div className="flex flex-col gap-3">
              <p className="text-2xl font-bold dark:text-dart_web_accent text-title_text">
                {ApplicationString.searchScreen.labels.filterby}
              </p>
              <div className="h-[70vh] min-w-64 bg-white dark:bg-dark_bg_secondary border border-slate-300 dark:border-dark_border_color rounded-20r flex flex-col items-start max-w-fit px-5 py-5 ">
                <p className=" text-lg font-bold dark:text-dart_web_accent text-title_text tracking-wide">
                  {ApplicationString.searchScreen.labels.type_of_vehicles}
                </p>
                <div className="mt-3 pl-1 w-full overflow-auto extend-popover-inner grid grid-cols-1 overflow-x-hidden text-wrap space-y-1">
                  {filterData?.map((subCategory) => (
                    <FormControlLabel
                      key={subCategory.id}
                      componentsProps={{
                        typography: checkedItems.includes(subCategory.id)
                          ? { fontWeight: 'bold' }
                          : {},
                      }}
                      className="text-base w-full capitalize"
                      control={
                        <Checkbox
                          checked={checkedItems.includes(subCategory.id)}
                          data-testid={`search-checkbox-${subCategory.name}`}
                          onChange={(e) =>
                            handleCheckboxChange(e, subCategory.id)
                          }
                          name={subCategory.name}
                          sx={{
                            '&:hover': { bgcolor: 'transparent' },
                          }}
                          disableRipple
                          color="default"
                          checkedIcon={<CheckedIcon />}
                          icon={<CheckBoxIcon />}
                          inputProps={{ 'aria-label': 'Checkbox demo' }}
                        />
                      }
                      label={subCategory.name}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-4 py-4 px-6 gap-4 bg-white dark:bg-dark_bg_secondary border dark:border-dark_border_color border-slate-300 rounded-20r justify-center justify-items-start  flex flex-col max-w-full">
              <div className="flex flex-col">
                <p className="text-xl font-bold  dark:text-dart_web_accent text-title_text">
                  {ApplicationString.searchScreen.labels.search_radius}
                </p>
                <p className="text-base font-normal dark:text-dart_web_accent text-title_text text-nowrap">
                  {`${minValue}mi - ${maxValue}mi`}
                </p>
              </div>
              <div className="mt-2">
                <Box sx={{ width: '100%' }} />
                {radius && (
                  <Slider
                    valueLabelDisplay="on"
                    onChangeCommitted={(e, value) => {
                      handleRadiusChange(Number(value));
                    }}
                    data-testid="search-radius-slider"
                    sx={{
                      '& .MuiSlider-root': {
                        padding: 0,
                      },
                      '& .MuiSlider-valueLabel': {
                        lineHeight: 1.2,
                        fontSize: 12,
                        background: 'unset',
                        color: `${currentTheme === 'dark' ? '#fff' : '#000'}`,
                        padding: 0,
                        backgroundColor: 'transparent',
                        transform:
                          'translate(50%, -100%) rotate(-45deg) scale(0)',
                        '&::before': { display: 'none' },
                        '&.MuiSlider-valueLabelOpen': {
                          transform:
                            'translate(50%, -100%) rotate(-45deg) scale(1)',
                        },
                        '& > *': {
                          transform: 'rotate(45deg)',
                        },
                      },
                    }}
                    min={1}
                    max={100}
                    value={radius}
                  />
                )}
              </div>
            </div>
            <div className="mt-4">
              <Button
                variant="outlined"
                data-testid="search-clear-filters-button"
                className=" bg-slate-100 w-full pl-24 pr-22 py-3 dark:bg-dark_bg_secondary border border-slate-950 rounded-full text-black text-base capitalize font-semibold dark:text-white  dark:border dark:border-dark_border_color"
                onClick={handleClearFilters}
              >
                {ApplicationString.searchScreen.labels.clear}
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row md:items-start justify-between items-start">
              <div className="flex flex-col items-start justify-center">
                <p className="text-2xl font-bold dark:text-dart_web_accent text-title_text text-nowrap">
                  {ApplicationString.searchScreen.labels.available_vehicles}
                </p>
                {/* <p className="mt-2 ml-2 text-sm font-medium dark:text-darkPrimary text-secondary_text">
                    {`(${filteredSearchedData?.total} Results)`}
                  </p> */}
                <div className="flex flex-wrap gap-2 flex-row mt-2">
                  {filteredItems.map((item) => {
                    return (
                      <Chip
                        key={item}
                        label={getSubcategoryNameById(filterData, item)}
                        data-testid={`search-clear-subcategory-${item}`}
                        onDelete={() => {
                          handleDelete(item);
                        }}
                        className="bg-white text-black dark:text-white dark:bg-black dark:border-white"
                      />
                    );
                  })}
                </div>
              </div>
              <div className="lg:hidden mt-2">
                <div className="flex flex-row gap-2">
                  <div>
                    <Button
                      variant="outlined"
                      data-testid="search-responsive-sort-button"
                      className="bg-white border border-borderStroke dark:border-dark_border_color rounded-full text-sm capitalize text-secondary_text px-4 py-3 gap-1.5"
                      onClick={toggleSorterDrawer(true)}
                    >
                      {ApplicationString.searchScreen.buttonLabels.sort}
                      <FilterListIcon fontSize="small" className="text-black" />
                    </Button>
                    <Drawer
                      anchor="right"
                      open={sorterDrawerOpen}
                      onClose={toggleSorterDrawer(false)}
                    >
                      <div className="w-80 sm:w-100 md:w-125 px-8 py-4">
                        <div className="flex flex-row justify-between items-center border-b-2 border-slate-300 px-2 py-3 gap-1.5">
                          <p className="text-2xl font-bold dark:text-dart_web_accent text-title_text ">
                            {ApplicationString.searchScreen.labels.sort_by}
                          </p>
                          <div>
                            <ClearIcon
                              fontSize="large"
                              data-testid="search-responsive-drawer-close"
                              onClick={() => {
                                setSorterDrawerOpen(false);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <List>
                            <ListItem disablePadding>
                              <ListItemButton
                                selected={
                                  sortBy ===
                                  ApplicationString.searchScreen.queryParams
                                    .sortDirection.ascending
                                }
                                data-testid="search-responsive-sort-ascending"
                                onClick={() =>
                                  handleSorting(
                                    ApplicationString.searchScreen.queryParams
                                      .sortDirection.ascending
                                  )
                                }
                              >
                                <ListItemText
                                  primary={
                                    ApplicationString.searchScreen.labels
                                      .menuItems.low_to_high
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                selected={
                                  sortBy ===
                                  ApplicationString.searchScreen.queryParams
                                    .sortDirection.descending
                                }
                                data-testid="search-responsive-sort-descending"
                                onClick={() =>
                                  handleSorting(
                                    ApplicationString.searchScreen.queryParams
                                      .sortDirection.descending
                                  )
                                }
                              >
                                <ListItemText
                                  primary={
                                    ApplicationString.searchScreen.labels
                                      .menuItems.high_to_low
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      data-testid="search-responsive-filter-button"
                      className="bg-white border border-borderStroke rounded-full text-sm capitalize text-secondary_text px-4 py-3 gap-2"
                      onClick={toggleFilterDrawer(true)}
                    >
                      {ApplicationString.searchScreen.buttonLabels.filter}
                      <FilterByIcon />
                    </Button>
                    <Drawer
                      anchor="right"
                      open={filterDrawerOpen}
                      onClose={toggleFilterDrawer(false)}
                    >
                      <div className="w-80 sm:w-100 md:w-125 px-8 py-4">
                        <div className="flex flex-row justify-between items-center  border-b-2 border-slate-300 px-2 py-3">
                          <p className="text-2xl font-bold dark:text-dart_web_accent text-title_text ">
                            {ApplicationString.searchScreen.labels.filterby}
                          </p>
                          <div>
                            <ClearIcon
                              fontSize="large"
                              data-testid="search-responsive-drawer-close"
                              onClick={() => {
                                setFilterDrawerOpen(false);
                              }}
                            />
                          </div>
                        </div>

                        <div className="mt-2 ">
                          <p className="text-xl mt-5 font-bold dark:text-dart_web_accent text-title_text">
                            {
                              ApplicationString.searchScreen.labels
                                .type_of_vehicles
                            }
                          </p>
                          <div className="mt-3 overflow-auto extend-popover-inner grid grid-cols-1 overflow-x-hidden text-wrap space-y-1">
                            {filterData.map((subCategory) => (
                              <FormControlLabel
                                key={subCategory.id}
                                componentsProps={{
                                  typography: checkedItems.includes(
                                    subCategory.id
                                  )
                                    ? { fontWeight: 'bold' }
                                    : {},
                                }}
                                control={
                                  <Checkbox
                                    checked={checkedItems.includes(
                                      subCategory.id
                                    )}
                                    data-testid={`search-responsive-checkbox-${subCategory.name}`}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, subCategory.id)
                                    }
                                    name={subCategory.name}
                                    size="small"
                                    className=""
                                  />
                                }
                                label={subCategory.name}
                              />
                            ))}
                          </div>
                        </div>
                        <div className="mt-5">
                          <p className=" text-xl font-bold  dark:text-web_accent text-title_text">
                            {
                              ApplicationString.searchScreen.labels
                                .search_radius
                            }
                          </p>
                          <p className="mt-2 text-base text-title_text text-nowrap dark:text-web_accent">
                            {`${minValue}mi - ${maxValue}mi`}
                          </p>
                          <div className="">
                            <Box sx={{ paddingY: 1, width: 150 }} />
                            {radius && (
                              <Slider
                                valueLabelDisplay="auto"
                                onChangeCommitted={(e, value) => {
                                  handleRadiusChange(Number(value));
                                }}
                                data-testid="search-responsive-slider"
                                min={1}
                                max={100}
                                value={radius}
                              />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-row items-center justify-between mt-4 ">
                          <Button
                            variant="outlined"
                            data-testid="search-responsive-filter-clear-button"
                            className=" bg-slate-100 border border-slate-950 rounded-full px-10 py-3 text-black text-xs capitalize font-semibold dark:bg-dark_bg_primary dark:text-white  dark:border dark:border-dark_border_color"
                            onClick={handleClearFilters}
                          >
                            {ApplicationString.searchScreen.labels.clear}
                          </Button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
              <div className=" hidden text-nowrap cursor-pointer text-xs border border-slate-300 bg-white text-slate-450 dark:bg-dark_bg_primary dark:border-dark_border_color rounded-full lg:flex flex-row items-center gap-1 px-2 py-2.5 mr-2">
                <Typography
                  fontSize={14}
                  className="text-secondary_text min-w-max"
                >
                  {ApplicationString.searchScreen.labels.sort_by}
                </Typography>
                <FormControl>
                  <CustomSelect
                    variant="outlined"
                    value={searchParams.get('sortBy') || ''}
                    defaultValue={searchParams.get('sortBy') || ''}
                    sx={{
                      border: 'none',
                      padding: 0,
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiSelect-select': {
                        padding: 0,
                        marginRight: '4px',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '200px',
                          marginTop: '20px',
                          translate: '-28px',
                          paddingX: '8px',
                        },
                      },
                      disableScrollLock: true,
                      marginThreshold: null,
                    }}
                    data-testid="search-sort-by-select"
                    className="font-semibold text-sm bg-white dark:bg-dark_bg_primary cursor-pointer"
                    onChange={(event) => {
                      handleSorting(event.target.value as string);
                    }}
                  >
                    <MenuItem
                      value="asc"
                      className="dark:text-dart_web_accent p-2"
                    >
                      {
                        ApplicationString.searchScreen.labels.menuItems
                          .low_to_high
                      }
                    </MenuItem>
                    <MenuItem
                      value="desc"
                      className="dark:text-dart_web_accent p-2"
                    >
                      {
                        ApplicationString.searchScreen.labels.menuItems
                          .high_to_low
                      }
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </div>
            </div>
            {loading ? (
              <CircularProgress className="mx-auto mt-4" />
            ) : (
              <div className=" mt-2 bg-slate-100 dark:border-borderStroke  dark:bg-dark_bg_surface w-full rounded-2xl grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-4 mb-20 items-center justify-center">
                {data.length === 0 ? (
                  <Typography
                    variant="h5"
                    color="text.secondary"
                    className="text-center col-span-2 mt-20"
                  >
                    {ApplicationString.searchScreen.labels.no_data}
                  </Typography>
                ) : (
                  data.map((card) => {
                    let bookingRate;
                    if (card.bookingRateHourly) {
                      bookingRate = `${card.bookingRateHourly} ${ApplicationString.searchScreen.labels.bookingValues.hourly}`;
                    } else if (card.bookingRate12Hourly) {
                      bookingRate = `${card.bookingRate12Hourly} ${ApplicationString.searchScreen.labels.bookingValues.twelve_hourly}`;
                    } else {
                      bookingRate = `${card.bookingRate24Hourly} ${ApplicationString.searchScreen.labels.bookingValues.twentyFour_hourly}`;
                    }
                    const [amount, ...rest] = bookingRate.split(' ');
                    const text = rest.join(' ');
                    const formattedRating = card.rating
                      ? card.rating.toFixed(1)
                      : '';
                    const coverPhoto = getCoverPhoto(card.images);
                    return (
                      <Card
                        className="bg-white dark:bg-dark_bg_secondary dark:border-none dark:shadow-none rounded-xl hover:shadow-xl shadow-slate-100 border shadow-xl border-slate-100 hover:border-slate-300 cursor-pointer"
                        onClick={() => {
                          const path = InternalRoute.Vehicle_details.replace(
                            ':VehicleId',
                            card.id.toString()
                          );
                          navigate(path);
                        }}
                        key={card.id}
                        data-testid={`search-card-${card.title.toLowerCase()}}`}
                      >
                        {coverPhoto ? (
                          <CardMedia
                            className="rounded-xl m-2.5 h-50 xsm:h-65 md:h-70 xl:h-80 2xl:h-100"
                            image={coverPhoto}
                          />
                        ) : (
                          <CardMedia
                            className="rounded-xl m-2.5 h-50 xsm:h-65 sm:h-70 md:h-90 lg:h-100"
                            image={img2}
                          />
                        )}

                        <CardContent>
                          <div className="flex flex-row justify-between items-center text-nowrap">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              fontWeight={700}
                              className="dark:text-dart_web_accent text-title_text xl:text-2xl 2xl:text-3xl"
                            >
                              {card.title}
                            </Typography>
                            {card.rating && (
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="div"
                                className="items-center"
                              >
                                <GradeIcon
                                  fontSize="inherit"
                                  className="text-amber-300 mb-1 mr-1"
                                />
                                {formattedRating}
                              </Typography>
                            )}
                          </div>
                          <div className="flex flex-row justify-start items-center space-x-1 text-nowrap mt-1">
                            <LocationIcon />
                            <Typography
                              variant="body2"
                              fontSize={18}
                              fontWeight={450}
                              className="dark:text-dark_text_sub_primary text-secondary_text"
                            >
                              {card.state}, {card.country}
                            </Typography>
                          </div>
                          <div className="flex flex-row justify-start items-center space-x-4 mt-6">
                            <div className="flex flex-row items-center">
                              <VehicleSizeIcon />
                              <Typography
                                variant="body2"
                                fontSize={18}
                                fontWeight={450}
                                className="dark:text-darkPrimary ml-2"
                              >
                                {card.size}
                              </Typography>
                              <Typography
                                variant="body2"
                                fontSize={18}
                                fontWeight={450}
                                className="dark:text-dark_text_sub_primary ml-1 text-secondary_text text-nowrap"
                              >
                                {ApplicationString.searchScreen.labels.ft}
                              </Typography>
                            </div>

                            <div className="flex flex-row items-center justify-center">
                              <PersonIcon />
                              <Typography
                                variant="body2"
                                fontSize={18}
                                fontWeight={450}
                                className="dark:text-dark_text_sub_primary text-secondary_text ml-1 text-nowrap"
                              >
                                {ApplicationString.searchScreen.labels.upto}
                              </Typography>
                              <Typography
                                variant="body2"
                                fontSize={18}
                                fontWeight={450}
                                className="text-blue-600 ml-2"
                              >
                                {card.capacity}
                              </Typography>
                              <Typography
                                variant="body2"
                                fontSize={18}
                                fontWeight={450}
                                className="dark:text-dark_text_sub_primary text-secondary_text ml-1 text-nowrap"
                              >
                                {ApplicationString.searchScreen.labels.person}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                        <CardActions className="flex flex-row justify-between items-center mb-2">
                          <div>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              className="dark:text-darkPrimary text-blue-600 font-bold text-2xl ml-3 text-nowrap"
                            >
                              ${amount}
                            </Typography>
                            <Typography
                              variant="body2"
                              fontSize={16}
                              fontWeight={450}
                              className="dark:text-dark_text_sub_primary text-secondary_text ml-3 text-nowrap leading-5"
                            >
                              {text}
                            </Typography>
                          </div>
                          <Button
                            size="medium"
                            variant="contained"
                            data-testid="search-card-book-now-button"
                            className="rounded-full capitalize dark:text-dark_bg_surface mb-2 mr-2 text-base font-bold text-nowrap px-4.5 py-3 hover:bg-primary hover:text-white"
                            onClick={(e) => {
                              e.stopPropagation();
                              const path =
                                InternalRoute.Vehicle_details.replace(
                                  ':VehicleId',
                                  card.id.toString()
                                );
                              navigate(path);
                            }}
                          >
                            {ApplicationString.searchScreen.labels.book_now}
                            <ChevronRightIcon className="mb-0.5 dark:text-dark_bg_surface" />
                          </Button>
                        </CardActions>
                      </Card>
                    );
                  })
                )}
              </div>
            )}
            <div className="pagination absolute bottom-8  lg:bottom-0">
              <Pagination
                count={
                  filteredSearchedData.total === 0
                    ? 1
                    : Math.ceil(filteredSearchedData.total / 10)
                }
                page={selectedPage}
                onChange={(event, page) => handlePageChange(page)}
                renderItem={(item) => {
                  if (item.type === PaginationButtonEnums.previous) {
                    return (
                      <Button
                        className={`bg-white text-slate-500 hover:bg-white gap-1 font-semibold ${
                          selectedPage === 1 && 'cursor-not-allowed'
                        } rounded-md py-1.5 px-2 capitalize translate-y-8 md:translate-y-0 lg:translate-y-0 `}
                        onClick={() =>
                          item.page !== null &&
                          selectedPage !== 1 &&
                          handlePageChange(item.page)
                        }
                        data-testid="search-pagination-previous-button"
                      >
                        <LeftArrowSvg />
                        {
                          ApplicationString.searchScreen.buttonLabels.pagination
                            .previous
                        }
                      </Button>
                    );
                  }
                  if (item.type === PaginationButtonEnums.next) {
                    return (
                      <Button
                        className={`bg-white text-slate-500 hover:bg-white gap-1 font-semibold ${
                          selectedPage ===
                            Math.ceil(filteredSearchedData.total / 10) &&
                          'cursor-not-allowed'
                        }  rounded-md py-1.5 px-2 capitalize translate-y-8 md:translate-y-0 lg:translate-y-0 `}
                        onClick={() =>
                          item.page !== null &&
                          selectedPage !==
                            Math.ceil(filteredSearchedData.total / 10) &&
                          handlePageChange(item.page)
                        }
                        data-testid="search-pagination-next-button"
                      >
                        {
                          ApplicationString.searchScreen.buttonLabels.pagination
                            .next
                        }
                        <RightArrowSvg />
                      </Button>
                    );
                  }
                  return (
                    <PaginationItem
                      color="primary"
                      className={`mx-2 text-slate-500 font-semibold md:translate-y-0 lg:translate-y-0 translate-y-8 ${
                        item.selected && 'text-white py-1 rounded-md'
                      }`}
                      page={item.page}
                      onClick={() =>
                        item.page !== null && handlePageChange(item.page)
                      }
                      data-testid={`search-pagination-${item.page}-button`}
                      selected={item.selected}
                      type={item.type}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchScreenComponent;

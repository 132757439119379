/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../../Assets/Images/logo-min.png';
import LogoDarkTheme from '../../../Assets/logoWhite.png';
import cssUtils from '../../../Utils/cssUtils';
import Navbar from './links';
import {
  isLoggedIn,
  handleApiError,
  setLocalStorageInfo,
  removeLocalStorageItems,
  getVehicleListingFromLocalStorage,
  getDetailsFromLocalStorage,
  // getVehicleListingFromLocalStorage,
} from '../../../Utils/utils';
import listingIcon from '../../../Assets/listing.png';
import InternalRoute from '../../../Utils/internalRoutes';
import ApplicationString from '../../../Constants/applicationString';
import { ApiRequest } from '../../../Api/api';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import {
  categoryNameEnums,
  localStorageEnums,
  settingslatestVersion,
  settingsVersion,
} from '../../../Utils/enums';
import URLS from '../../../Constants/urls';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import ColorModeContext from '../../../Utils/ColorModeContext';
import ProfileMenuButton from '../ProfileMenu';

type LinkType = {
  key: string;
  label: string;
  route?: string;
  url?: string;
  children?: LinkType[];
};

const headerLink = ApplicationString.headerLinks;

export const getSettingsData = async (
  setCountryCodeOptions: React.Dispatch<
    React.SetStateAction<string[]>
  > = () => [],
  setSettings: React.Dispatch<
    React.SetStateAction<IUserSettingsData | null>
  > = () => {}
): Promise<void> => {
  try {
    const res = await ApiRequest.get(`${URLS.GET_USER_SETTINGS}`);
    const data: IUserSettingsData =
      settingsVersion === settingslatestVersion
        ? res.data[0]
        : res.data.filter(
            (item: IUserSettingsData) =>
              Number(item.version) === Number(settingsVersion)
          )[0];
    if (setCountryCodeOptions) {
      setCountryCodeOptions(
        data.setting.country.map((item) => item.countryCode)
      );
    }
    if (
      res?.status === HttpStatusCode.Ok &&
      data &&
      JSON.stringify(data) !== '{}'
    ) {
      if (setSettings) {
        setSettings(data);
      }
      setLocalStorageInfo(data, localStorageEnums.settings);
    }
  } catch (error) {
    const axiosError = error as AxiosError<IApiErrorResponse>;

    handleApiError(axiosError);
  }
};

// Global user setting data

const UserHeader: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentTheme } = useContext(ColorModeContext);
  const settingsInitialValue = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  );
  const [settings, setSettings] = useState<IUserSettingsData | null>(
    settingsInitialValue || null
  );
  const [exploreChildren, setExploreChildren] = useState<LinkType[]>([]);
  const Navigate = useNavigate();
  const location = useLocation();
  const isUserLoggedIn = isLoggedIn();

  const links: LinkType[] = [
    {
      key: headerLink.home.key,
      label: headerLink.home.label,
      route: InternalRoute.Home,
    },
    {
      key: headerLink.explore.key,
      label: headerLink.explore.label,
      url: `${InternalRoute.explore}`,
      children: exploreChildren,
    },
    {
      key: headerLink.aboutUs.key,
      label: headerLink.aboutUs.label,
      route: InternalRoute.AboutUs,
    },
    {
      key: headerLink.contact.key,
      label: headerLink.contact.label,
      route: InternalRoute.Contact,
    },
    {
      key: headerLink.faq.key,
      label: headerLink.faq.label,
      route: InternalRoute.FAQ,
    },
  ];

  const handleMobileMenu = (route: string) => {
    removeLocalStorageItems([
      localStorageEnums.skippedSteps,
      localStorageEnums.step,
      localStorageEnums.stepsData,
    ]);
    Navigate(route);
    setMobileMenuOpen(false);
  };
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const handleAccordionToggle = (key: string) => {
    setOpenKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
    if (
      key !== 'explore' &&
      key !== categoryNameEnums.watercraft &&
      key !== categoryNameEnums.landVehicles.replace(' ', '')
    ) {
      setMobileMenuOpen(false);
    }
  };
  const getCategoryParams = (() => {
    const state = {
      category: '',
      subcategory: '',
    };
    return (link: LinkType): string => {
      const { key } = link;
      if (key === 'explore') {
        state.category = 'explore';
        state.subcategory = '';
        return `${InternalRoute.explore}?category=${ApplicationString.searchScreen.categories.watercraft}`;
      }
      if (
        key === ApplicationString.searchScreen.categories.watercraft ||
        key === ApplicationString.searchScreen.categories.landVehicles
      ) {
        state.category = key;
        state.subcategory = '';
        return `${InternalRoute.explore}?category=${key}`;
      }
      if (state.category) {
        state.subcategory = key;
        return `${InternalRoute.explore}?category=${state.category}&subcategory=${key}`;
      }
      return '';
    };
  })();
  const isActive = (route: string): boolean => {
    const exploreRoute = `${location.pathname}${location.search || ''}`;
    if (location.pathname === InternalRoute.explore) {
      return exploreRoute.slice(0, 8) === route.slice(0, 8);
    }
    return location.pathname === route;
  };

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const category = getQueryParams().get('category');
  const subcategory = getQueryParams().get('subcategory');

  const renderLinks = (menulinks: LinkType[], level = 0) => {
    return menulinks.map((link) => {
      const isOpen = openKeys.includes(link.key);
      const isChild = level > 0;
      return (
        <li
          className={`${link.children ? 'flex gap-2' : 'mt-0'}`}
          key={link.key}
          data-testid={`link-for-children-${link.key}`}
        >
          {!link.route ? (
            <div className="border-gray-200 w-full">
              <Link
                to={getCategoryParams(link)}
                type="button"
                onClick={() => handleAccordionToggle(link.key)}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                data-testid={`header-link-${link.key}`}
                className={`
             ${isActive(link.route || link.url || '') ? 'font-bold' : ''}
             ${
               link?.key === category ||
               link?.key === subcategory ||
               (link?.key === subcategory && category)
                 ? 'font-semibold'
                 : ''
             }
              flex flex-row items-center w-full px-4 py-2 mt-2 text-left bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-dark_bg_surface dark:focus:text-white dark:hover:text-white dark:text-dark_text_sub_primary hover:bg-gray-200 md:w-auto md:inline md:mt-0 hover:text-gray-900 focus:text-gray-900  focus:outline-none focus:shadow-outline`}
              >
                <span>{link.label}</span>
                {link.children &&
                  (isChild ? (
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      style={{ rotate: isOpen ? '180deg' : '270deg' }}
                      className="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-0 ${
                        isOpen ? 'rotate-180' : 'rotate-0'
                      }`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ))}
              </Link>
              {link.children && isOpen && (
                // eslint-disable-next-line react/jsx-curly-brace-presence
                <ul className={`mt-2 flex gap-2 flex-col mx-4`}>
                  {renderLinks(link?.children, level + 1)}
                </ul>
              )}
            </div>
          ) : (
            <Link
              to={link.route || '#'}
              onClick={() => setMobileMenuOpen(false)}
              className={`block py-2 px-4 rounded-lg w-full hover:bg-slate-200  ${isActive(link.route || '') ? 'font-bold' : ''}`}
            >
              {link.label}
            </Link>
          )}
        </li>
      );
    });
  };

  const renderButtons = () => {
    if (isUserLoggedIn) {
      return <ProfileMenuButton handleMobileMenu={handleMobileMenu} />;
    }
    if (
      location.pathname === InternalRoute.EmailLogin ||
      location.pathname === InternalRoute.PhoneLogin
    ) {
      return (
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Signup)}
          data-testid="button-action-signup"
          className={cssUtils.button.primary}
        >
          {ApplicationString.header.button.signup}
        </button>
      );
    }

    const signUpRoutes = [
      InternalRoute.EmailSignup,
      InternalRoute.PhoneSignup,
      InternalRoute.EmailVerificationCode,
      InternalRoute.PhoneVerificationCode,
      InternalRoute.SignupEmailVerified,
      InternalRoute.SignupPhoneVerified,
      InternalRoute.SignUpNameAndPassword,
      InternalRoute.AccountCreated,
    ];
    if (signUpRoutes.includes(location.pathname)) {
      return (
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Login)}
          data-testid="button-action-login"
          className={cssUtils.button.secondary}
        >
          {ApplicationString.header.button.login}
        </button>
      );
    }

    return (
      <>
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Signup)}
          data-testid="button-action-signup"
          className={cssUtils.button.primary}
        >
          {ApplicationString.header.button.signup}
        </button>
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Login)}
          data-testid="button-action-login"
          className={cssUtils.button.secondary}
        >
          {ApplicationString.header.button.login}
        </button>
      </>
    );
  };

  // Get user settings data on header mount if not present in the local storage.
  useEffect(() => {
    if (!settings) {
      getSettingsData(undefined, setSettings);
    }
  }, [settings]);

  useEffect(() => {
    if (settings) {
      setExploreChildren([
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...getVehicleListingFromLocalStorage()?.children,
        {
          key: headerLink.explore.children.key,
          label: headerLink.explore.children.label,
          route: headerLink.explore.children.route,
        },
      ]);
    }
  }, [settings]);

  return (
    <header className="bg-white dark:bg-dark_bg_secondary fixed top-0 w-full left-0 z-30">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 2xl:px-0 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1/2">
          <Link
            to={InternalRoute.Home}
            className="-m-1.5 p-1.5"
            data-testid="link-to-home-common"
          >
            <img
              className="h-8 w-auto"
              src={currentTheme === 'dark' ? LogoDarkTheme : Logo}
              alt="Logo"
              data-testid="image-for-logo-common"
            />
          </Link>
        </div>
        <div className="hidden lg:block">
          <Navbar links={[...links]} />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
            data-testid="mobile-menu-open"
            aria-label="mobile-menu-open"
          >
            <Bars3Icon className="h-6 w-6 dark:text-white" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1/2 lg:justify-end gap-5">
          <button
            type="button"
            onClick={() => handleMobileMenu(InternalRoute.vehicleCreate)}
            data-testid="button-action-create-listing"
            className={`flex gap-2 justify-between items-center lg:px-3 2xl:px-5 ${cssUtils.button.primary}`}
          >
            {ApplicationString.header.button.createListing}
            <img src={listingIcon} alt="listing" />
          </button>
          {renderButtons()}
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        data-testid="dialog-userHeader-menu"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto dark:bg-dark_bg_primary bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex mb-10 items-center justify-between">
            <Link to={InternalRoute.Home} className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto"
                src={currentTheme === 'dark' ? LogoDarkTheme : Logo}
                alt="Logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5  text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
              data-testid="mobile-menu-close"
              aria-label="mobile-menu-close"
            >
              <XMarkIcon
                className="h-6 w-6 dark:text-white"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="flow-root">
            <div className="divide-gray-500/10 flex flex-col gap-4">
              <ul className=" flex-col flex gap-2 dark:text-white">
                {renderLinks(links)}
              </ul>
              <button
                type="button"
                onClick={() => handleMobileMenu(InternalRoute.vehicleCreate)}
                data-testid="button-action-signup"
                className={`flex gap-2 justify-between items-center lg:px-3 2xl:px-5 ${cssUtils.button.primary} pr-7`}
              >
                {ApplicationString.header.button.createListing}
                <img src={listingIcon} alt="listing" />
              </button>
              <div className="flex-col flex gap-5">{renderButtons()}</div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default UserHeader;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { OnArgs, Value, View } from 'react-calendar/dist/cjs/shared/types';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { dateFormat } from '../../../Constants/commonConst';
import cssUtils from '../../../Utils/cssUtils';
import ApplicationString from '../../../Constants/applicationString';
import ColorModeContext from '../../../Utils/ColorModeContext';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

type Val = string | [string, string] | null;

const parseDate = (dateString: string): Date => {
  return dayjs.utc(dateString).toDate();
};

const formatDate = (date: Date | null): string => {
  return date ? dayjs(date).utc().format('MM/DD/YYYY') : '';
};

const isISODateString = (dateString: string): boolean => {
  // Check if the date string is in ISO 8601 format
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/;
  return isoDatePattern.test(dateString);
};

interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

interface DateRangePickerProps {
  initialStartDate: string | null;
  initialEndDate: string | null;
  disabledDateRange: DateRange[];
  isDateSingle: boolean;
  useForVehicleDetails: boolean;
  onDateChange: (value: DateRange) => void;
  monthLimit: number;
}

const BookingDatePicker: React.FC<DateRangePickerProps> = ({
  initialStartDate,
  initialEndDate,
  disabledDateRange,
  useForVehicleDetails = true,
  isDateSingle = false,
  monthLimit,
  onDateChange,
}) => {
  const [value, setValue] = useState<Value | null>(
    isDateSingle
      ? (initialStartDate && parseDate(initialStartDate)) || null
      : initialStartDate && initialEndDate
        ? [parseDate(initialStartDate), parseDate(initialEndDate)]
        : null
  );
  const [prevActiveStartDate, setPrevActiveStartDate] = useState<Date | null>(
    new Date()
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [error, setError] = useState('');
  const { currentTheme } = useContext(ColorModeContext);
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date(minDate);
  maxDate.setMonth(minDate.getMonth() + monthLimit);

  const convertToString = (date: Date | null): string | null => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return null;
  };

  const isRangeDisabled = (start: Date, end: Date) => {
    for (
      let rangeIndex = 0;
      rangeIndex < disabledDateRange.length;
      rangeIndex += 1
    ) {
      const range = disabledDateRange[rangeIndex];
      const startingDate = new Date(start);
      for (
        let date = startingDate;
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        if (range.startDate && range.endDate) {
          if (convertToString(date) === range.startDate) return true;
        }
      }
    }
    return false;
  };

  const handleDateChange = (date: Value) => {
    let convertedValue: DateRange;

    if (Array.isArray(date)) {
      const [start, end] = date;
      if (start && end && convertToString(start) === convertToString(end)) {
        // If the same date is selected, show an error message and clear the end date
        onDateChange({
          startDate: convertToString(prevActiveStartDate),
          endDate: convertToString(prevActiveStartDate),
        });
        setError('Start and end dates cannot be the same.');
        return;
      }
      if (start && end && isRangeDisabled(start, end)) {
        onDateChange({
          startDate: convertToString(prevActiveStartDate),
          endDate: convertToString(prevActiveStartDate),
        });
        setError('Selected date range include disable dates.');
        return;
      }

      convertedValue = {
        startDate: convertToString(start),
        endDate: convertToString(end),
      };
      if (convertedValue.startDate && convertedValue.endDate)
        setValue([
          parseDate(convertedValue.startDate),
          parseDate(convertedValue.endDate),
        ]);
    } else {
      convertedValue = {
        startDate: convertToString(date),
        endDate: convertToString(date),
      };
      if (convertedValue.startDate && convertedValue.endDate)
        setValue(parseDate(convertedValue.startDate));
    }

    onDateChange(convertedValue);
    setError('');
    setIsCalendarOpen(false);
  };

  const handleDateChangeActiveStartDate = ({
    activeStartDate,
    view,
  }: OnArgs) => {
    // Get current date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Check if activeStartDate matches current month and year
    const activeMonth = activeStartDate?.getMonth();
    const activeYear = activeStartDate?.getFullYear();

    if (view === 'month') {
      if (activeMonth === currentMonth && activeYear === currentYear) {
        const convertedValue: DateRange = {
          startDate: convertToString(currentDate),
          endDate: convertToString(currentDate),
        };
        onDateChange(convertedValue);
      } else {
        const convertedValue: DateRange = {
          startDate: convertToString(activeStartDate),
          endDate: convertToString(activeStartDate),
        };
        onDateChange(convertedValue);
      }
      setPrevActiveStartDate(activeStartDate);
    }
  };

  const tileDisabled = ({ date, view }: { date: Date; view: View }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (view === 'month' && date < today) return true;
    if (disabledDateRange) {
      return disabledDateRange.some(
        (blockedDate) =>
          blockedDate.startDate &&
          date.getFullYear() ===
            new Date(blockedDate.startDate).getFullYear() &&
          date.getMonth() === new Date(blockedDate.startDate).getMonth() &&
          date.getDate() === new Date(blockedDate.startDate).getDate()
      );
    }
    return false;
  };

  const handleClear = () => {
    setValue(isDateSingle ? new Date() : [new Date(), new Date()]);
    onDateChange({ startDate: null, endDate: null });
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const formatSingleDate = (date: Date | null): string => {
    return dayjs(date).format(dateFormat);
  };
  const formatDateForInput = (date: Value, isSingle: boolean): string => {
    if (!date) return isSingle ? 'Select Date' : 'Select Dates';
    if (Array.isArray(date)) {
      const [startDate, endDate] = date;
      const formattedStartDate = formatSingleDate(startDate);
      const formattedEndDate = formatSingleDate(endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    return formatSingleDate(date);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        !document
          .querySelector('.calendar-popover')
          ?.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
        setError('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    const formatDateIfISO = (dateString: string | null): string | null => {
      if (dateString && isISODateString(dateString)) {
        return formatDate(parseDate(dateString));
      }
      return null; // Return null if not in ISO format
    };

    const parseAndFormatDateIfNotISO = (
      dateString: string | null
    ): string | null => {
      if (dateString && !isISODateString(dateString)) {
        return formatDate(parseDate(dateString));
      }
      return null; // Return null if it is in ISO format
    };

    const formattedStartDate =
      formatDateIfISO(initialStartDate) ||
      parseAndFormatDateIfNotISO(initialStartDate);
    const formattedEndDate =
      formatDateIfISO(initialEndDate) ||
      parseAndFormatDateIfNotISO(initialEndDate);

    const newValue: Val = isDateSingle
      ? formattedStartDate || null
      : formattedStartDate && formattedEndDate
        ? [formattedStartDate, formattedEndDate]
        : null;

    setValue(newValue as Value); // Ensure newValue matches the Value type
  }, [initialEndDate, initialStartDate, isDateSingle]);

  return (
    <div className="date-picker-wrapper" ref={inputRef}>
      <input
        data-testid="booking-datePicker-common"
        type="text"
        value={formatDateForInput(value, isDateSingle)}
        onFocus={() => setIsCalendarOpen(true)}
        readOnly
        className="mt-2 dark:bg-dark_bg_secondary z-10 dark:text-dart_web_accent py-1.5 pr-10 w-full focus:ring-0 border-0 focus:outline-none border-gray-0 rounded-md shadow-0 text-lg md:text-2xl text-title_text font-semibold"
      />
      {error && <div className="text-red-500 text-sm">{error}</div>}
      {isCalendarOpen && (
        <div
          className={`calendar-popover ${currentTheme === 'dark' ? 'calendar-dark-mode' : ''}  dark:bg-dark_bg_secondary dark:border-bg_Stroke dark:border dark:shadow-1 ${!useForVehicleDetails && 'edit-calendar-popover'} ${!isDateSingle ? 'multi_date_selection' : 'single_date_selection'}`}
        >
          <p className="text-lg md:text-2xl text-title_text dark:text-dark_web_accent font-semibold">
            {isDateSingle
              ? ApplicationString.bookingDateRangeData.singleDate
              : ApplicationString.bookingDateRangeData.MultiDate}
          </p>
          <Calendar
            data-testid="booking-date-picker"
            selectRange={!isDateSingle}
            showDoubleView={!isDateSingle}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            tileDisabled={tileDisabled}
            onChange={handleDateChange}
            onActiveStartDateChange={handleDateChangeActiveStartDate}
            // tileDisabled={tileDisabled}
            showNavigation
            formatShortWeekday={(locale, date) =>
              date.toLocaleDateString('en-US', { weekday: 'short' })
            }
            className=" dark:bg-dark_bg_secondary dark:text-dart_web_accent py-1.5 w-full focus:ring-0 border-0 focus:outline-none border-gray-0 rounded-md shadow-0 sm:text-sm text-lg md:text-xl text-title_text font-semibold"
          />
          <div className="flex w-full justify-end">
            <div className="flex gap-4">
              <button
                type="button"
                className="clear-button text-sm xl:text-lg font-semibold underline dark:text-dart_web_accent text-[#1A56DB]"
                onClick={handleClear}
                data-testid="datePicker-clear-button"
              >
                {isDateSingle
                  ? ApplicationString.buttonType.singleClearDate
                  : ApplicationString.buttonType.multiClearDate}
              </button>
              <button
                type="button"
                className={`close-button  ${cssUtils.button.primary}`}
                onClick={() => setIsCalendarOpen(false)}
                data-testid="datePicker-close-button"
              >
                {ApplicationString.buttonType.close}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingDatePicker;

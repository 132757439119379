import React from 'react';
import { Chip } from '@mui/material';
import TextHeading from '../TextHeading';
import ApplicationString from '../../../../Constants/applicationString';
import { localStorageEnums, stepsNumberEnum } from '../../../../Utils/enums';
import { getDetailsFromLocalStorage } from '../../../../Utils/utils';
import { IStepData } from '../../../../Interfaces/Stepper';

const PreviewVehiclePhoto = ({ isEdit }: { isEdit: boolean }): JSX.Element => {
  const stepsData = getDetailsFromLocalStorage<IStepData[]>(
    localStorageEnums.stepsData
  );

  const vehicleData = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step3
  );

  const coverImage = vehicleData?.data?.images?.find(
    (obj: { path: string; isCover: boolean; fileType: string }) =>
      obj.isCover === true
  )?.path;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <TextHeading
          label={ApplicationString.vehicleStepper.previewStep.previewStep3}
          buttonText={ApplicationString.vehicleStepper.previewStep.edit}
          navigatePathStep={stepsNumberEnum.step3}
          isEdit={isEdit}
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        {coverImage ? (
          <div className="relative w-full">
            <img
              src={coverImage}
              alt=""
              className="rounded-2xl h-[173px] w-full object-cover"
              crossOrigin="anonymous"
            />
            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-end w-full">
              <Chip
                label="Cover Image"
                variant="outlined"
                className="mb-4 w-1/2 justify-center items-center bg-white border-primary dark:bg-dark_secondary_text dark:text-white py-3"
              />
            </div>
          </div>
        ) : (
          <div className="rounded-2xl h-[173px] w-full bg-gray-300 flex justify-center items-center text-xl text-black">
            {ApplicationString.commonStrings.noImageFound}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewVehiclePhoto;

import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Slide } from 'react-slideshow-image';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-slideshow-image/dist/styles.css';
import img1 from '../../../Assets/Images/bgImg1.png';
import img2 from '../../../Assets/Images/bgImg2.png';
import ApplicationString from '../../../Constants/applicationString';

const images = [img1, img2];

const OwnerHeroSection: React.FC = () => {
  const {
    subtitle,
    //  description
  } = ApplicationString.ownerHeroSection;

  return (
    <div className="relative w-full">
      <Slide duration={4000} arrows={false}>
        {images.map((image, index) => (
          <div key={image}>
            <img
              className="w-full h-100 sm:h-100 xl:h-[524px] object-cover"
              src={image}
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </Slide>
      <div className="mx-auto max-w-7xl absolute inset-10 flex flex-col justify-center items-start gap-4 text-white px-6 sm:px-0 sm:py-8  lg:py-4">
        <p className="dark:text-dark_bg_secondary text-xl sm:text-2xl lg:text-4xl 2xl:text-6xl font-semibold leading-tight">
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default OwnerHeroSection;

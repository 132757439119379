/* eslint-disable react/void-dom-elements-no-children */
import { MoreHorizRounded, UploadFile } from '@mui/icons-material';
import {
  Button,
  Chip,
  CircularProgress,
  FormHelperText,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { AxiosError, HttpStatusCode } from 'axios';
import ApplicationString from '../../../Constants/applicationString';
import {
  localStorageEnums,
  stepsNumberEnum,
  updateProfilePhotoFilesFormats,
  uploadMaxFileSize,
} from '../../../Utils/enums';
import { ApiRequest } from '../../../Api/api';
import URLS from '../../../Constants/urls';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import {
  getDetailsFromLocalStorage,
  getLocalStorageAccountInfo,
  handleApiError,
  setLocalStorageInfo,
} from '../../../Utils/utils';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import {
  IStepData,
  IStepperErrorData,
  IStepperFormData,
} from '../../../Interfaces/Stepper';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
// interface ResponseData {
//   path: string;
//   fileType: string;
//   isCover: boolean;
// }

interface Props {
  responseData: IStepperFormData;
  setResponseData: React.Dispatch<React.SetStateAction<IStepperFormData>>;
  errorsData: IStepperErrorData;
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>;
}
const UploadImageStepperComponent: React.FC<Props> = ({
  responseData,
  setResponseData,
  errorsData,
  setErrorsData,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [imagePath, setImagePath] = React.useState<string[]>([]);
  const [maxImages, setMaxImages] = React.useState<number>(0);
  const [hoveredItem, setHoveredItem] = React.useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const coverImage = responseData?.step3?.images?.find(
    (image) => image.isCover === true
  )?.path;

  const settingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums?.settings
  );

  const maximumImages =
    settingsData &&
    settingsData?.setting?.vehicleListing?.gallery?.numberOfImages;
  const fileType =
    settingsData && settingsData?.setting?.vehicleListing?.gallery?.imageTypes;
  const maxFileSize =
    settingsData && settingsData?.setting?.vehicleListing?.gallery?.imageSize;
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    return new Promise(() => {
      const files = event?.target?.files;
      if (files?.length) {
        setErrorsData((prevState) => ({
          ...prevState,
          step3: {
            ...prevState.step3,
            images: {
              ValidationMessage: '',
              error: false,
            },
          },
        }));
      }

      if (files?.length && files.length > 0) {
        if (
          // eslint-disable-next-line no-unsafe-optional-chaining
          imagePath?.length + files.length > maxImages ||
          (maxImages && files.length > maxImages)
        ) {
          setErrorsData((prevState) => ({
            ...prevState,
            step3: {
              ...prevState.step3,
              images: {
                ValidationMessage:
                  ApplicationString.stepperErrorsData.step3.maxImage.replace(
                    '{maxImages}',
                    maxImages.toString()
                  ),
                error: true,
              },
            },
          }));
          return;
        }
      }

      if (
        !files?.length ||
        // eslint-disable-next-line no-unsafe-optional-chaining
        imagePath?.length + files.length > maxImages ||
        (maxImages && files.length > maxImages)
      ) {
        return;
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const isRightFormat = updateProfilePhotoFilesFormats.includes(
          files[i].type
        );
        const fileSize = files[i].size;
        const fileSizeInMB = fileSize / (1024 * 1024);

        if (!isRightFormat) {
          setErrorsData((prevState) => ({
            ...prevState,
            step3: {
              ...prevState.step3,
              images: {
                ValidationMessage:
                  ApplicationString.stepperErrorsData.step3.fileFormat,
                error: true,
              },
            },
          }));
          return;
        }

        if (fileSizeInMB > uploadMaxFileSize) {
          setErrorsData((prevState) => ({
            ...prevState,
            step3: {
              ...prevState.step3,
              images: {
                ValidationMessage:
                  ApplicationString.stepperErrorsData.step3.fileSize,
                error: true,
              },
            },
          }));
          return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(files[i]);

        img.onload = async () => {
          const formData = new FormData();
          formData.append('file', files[i]);
          formData.append('mediaUploadedFor', 'vehicleImage');
          try {
            setIsLoading(true);
            setIsUploaded(true);
            const res = await ApiRequest.post(
              URLS.UPLOAD_PROFILE_PHOTO,
              formData
            );
            if (res?.status === HttpStatusCode.Created) {
              setResponseData((prev) => ({
                ...prev,
                step3: {
                  ...prev.step3,
                  images: [
                    ...prev.step3.images,
                    {
                      path: res.data.path,
                      fileType: res.data.fileType,
                      isCover: false,
                    },
                  ],
                },
              }));
            }
            setIsLoading(false);
          } catch (error) {
            setIsUploaded(false);
            const axiosError = error as AxiosError<IApiErrorResponse>;
            handleApiError(axiosError);
          } finally {
            URL.revokeObjectURL(img.src);
          }
        };

        img.onerror = () => {
          URL.revokeObjectURL(img.src);
        };
      }
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setHoveredItem(null);
    }
  };

  useEffect(() => {
    if (hoveredItem !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hoveredItem]);

  useEffect(() => {
    if (responseData) {
      setImagePath(responseData?.step3?.images?.map((item) => item.path));
    }
  }, [responseData]);

  useEffect(() => {
    if (maximumImages) {
      setMaxImages(maximumImages);
    }
    const getLocalStorageResponseData = getDetailsFromLocalStorage(
      localStorageEnums.stepsData
    ) as IStepData[];
    const step3Data = getLocalStorageResponseData?.find(
      (obj: IStepData) => obj.step === stepsNumberEnum.step3
    )?.data?.images;
    if (step3Data) {
      setImagePath(step3Data?.map((obj) => obj?.path));
    }
    setIsUploaded(true);
  }, [maximumImages]);

  const setCoverPhoto = (index: number): void => {
    setHoveredItem(null);
    setErrorsData((prevState) => ({
      ...prevState,
      step3: {
        ...prevState.step3,
        images: {
          ValidationMessage: '',
          error: false,
        },
      },
    }));

    setResponseData((prev) => ({
      ...prev,
      step3: {
        ...prev.step3,
        images: prev.step3.images.map((item, i) => ({
          ...item,
          // eslint-disable-next-line no-unneeded-ternary
          isCover: i === index ? true : false,
        })),
      },
    }));
  };

  const deleteImage = (index: number): void => {
    setHoveredItem(null);
    setResponseData((prev) => ({
      ...prev,
      step3: {
        ...prev.step3,
        images: prev.step3.images.filter((item, i) => i !== index),
      },
    }));
    const stepsData = getDetailsFromLocalStorage<IStepData[]>(
      localStorageEnums.stepsData
    );
    if (stepsData) {
      const stepIndex = stepsData.findIndex(
        (localData) => localData.step === stepsNumberEnum.step3
      );
      if (stepIndex !== -1) {
        stepsData[stepIndex] = {
          ...stepsData[stepIndex],
          data: {
            ...stepsData[stepIndex].data,
            images: responseData?.step3?.images.filter(
              (item, i) => i !== index
            ),
          },
        };
        setLocalStorageInfo(stepsData, localStorageEnums.stepsData);
      }
    }
  };

  return (
    <div className="w-full flex flex-col lg:flex-row gap-5">
      <div className="w-full lg:w-1/2 flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <h1 className="font-bold text-primary_text text-lg">
            {ApplicationString.UploadPhotosStepperComponent.VehiclePhotosTitle}
          </h1>
          <p className="text-gray-500">
            {
              ApplicationString.UploadPhotosStepperComponent
                .VehiclePhotosDescription
            }
          </p>
          <p className="mt-5">
            {ApplicationString.UploadPhotosStepperComponent.UploadRuleDescription.replace(
              '15',
              maxImages.toString()
            )}
          </p>
        </div>
        <div>
          <Button
            className={`w-full p-15 border-dashed border-2 disabled:!cursor-not-allowed ${errorsData.step3.images.error ? 'border-error rounded-lg' : 'border-primary rounded-lg'}  bg-white dark:bg-dark_bg_secondary `}
            sx={{ textTransform: 'none' }}
            component="label"
            data-testid="step3-upload-images-button"
            disabled={imagePath?.length === maxImages}
          >
            <div className="flex flex-col gap-3">
              <div className="text-center text-2xl">
                <UploadFile />
              </div>
              <p className="font-medium text-gray-500">
                {ApplicationString.UploadPhotosStepperComponent.AcceptedFormat.replace(
                  '{fileType}',
                  fileType ?? '' // Provide an empty string as the default value if fileType is null
                ).replace('{maxFile}', maxFileSize?.toString() ?? '')}
              </p>
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                multiple
                accept="image/jpg,image/jpeg,image/png,image/webp"
                // inputProps={{ accept: 'application/pdf' }}
              />
            </div>
          </Button>
          {errorsData.step3.images.error && (
            <FormHelperText className="text-error">
              {errorsData.step3.images.ValidationMessage}
            </FormHelperText>
          )}
          <div className="w-full flex justify-center p-3">
            <p className="font-semibold">
              {ApplicationString.UploadPhotosStepperComponent.UploadPhoto}
              <span className="text-[#F30C0C]">*</span>
            </p>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-white z-10">
          <CircularProgress className="mt-4" />
        </div>
      )}
      {isUploaded && responseData?.step3?.images?.length > 0 && (
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
          {' '}
          <ImageList
            sx={{
              transform: 'translateZ(0)',
              width: 500,
              height: 450,
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridAutoRows: '220px',
            }}
            data-testid="step3-image-list"
            rowHeight={220}
            className="grid !grid-cols-1 md:!grid-cols-2 xl:!grid-cols-2"
          >
            {responseData?.step3?.images?.map((image, index) => {
              const srcset = `${image.path}?w=164&h=164&fit=crop&auto=format&dpr=1 1x, ${image.path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`;
              return (
                <ImageListItem
                  className="w-full"
                  data-testid="step3-image-list-item"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  key={index} // eslint-disable-line react/no-array-index-key
                >
                  <img
                    crossOrigin="anonymous"
                    srcSet={srcset}
                    src={`${image.path}?w=164&h=164&fit=crop&auto=format&dpr=2`}
                    alt={
                      ApplicationString.UploadPhotosStepperComponent
                        .ButtonaltTitle
                    }
                    loading="lazy"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    key={index} // eslint-disable-line react/no-array-index-key
                  />
                  <div className="relative w-full">
                    <div className="absolute bottom-0 left-0 right-0 flex justify-center items-end w-full">
                      <Chip
                        label="Cover Image"
                        variant="outlined"
                        className={` ${image && image?.path === coverImage ? 'flex' : 'hidden'} mb-4 w-1/2 justify-center items-center bg-white border-primary dark:bg-dark_secondary_text dark:text-white py-3`}
                      />
                    </div>
                  </div>

                  <ImageListItemBar
                    className="w-full bg-transparent"
                    position="top"
                    actionIcon={
                      <div className="flex w-full p-4">
                        <MoreHorizRounded
                          className="bg-white rounded-xl border cursor-pointer"
                          onClick={() => setHoveredItem(index)}
                        />
                        {hoveredItem === index && (
                          <div
                            className={`flex flex-col gap-1 rounded-xl p-4 px-3 border absolute right-2 mt-8 bg-white shadow-md transition-opacity duration-1000 ease-in-out ${hoveredItem === index ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                            ref={menuRef}
                          >
                            <Button
                              className="text-gray-500 py-0 font-semibold flex justify-start"
                              sx={{ textTransform: 'none' }}
                              data-testid="step3-delete-image-button"
                              onClick={() => deleteImage(index)}
                            >
                              {
                                ApplicationString.UploadPhotosStepperComponent
                                  .DeleteButton
                              }
                            </Button>
                            <Button
                              className="text-gray-500 py-0 font-semibold flex justify-start"
                              sx={{ textTransform: 'none' }}
                              onClick={() => setCoverPhoto(index)}
                              data-testid="step3-set-cover-photo-button"
                            >
                              {
                                ApplicationString.UploadPhotosStepperComponent
                                  .CoverPhotoButton
                              }
                            </Button>
                          </div>
                        )}
                      </div>
                    }
                    actionPosition="right"
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </div>
      )}
    </div>
  );
};

export default UploadImageStepperComponent;

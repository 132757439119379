import React from 'react';
import StackCards from './AboutUsCardStack';
import heroImg from '../../Assets/Images/AboutUsHeroImage.png';
import ApplicationString from '../../Constants/applicationString';
import cssUtils from '../../Utils/cssUtils';

const AboutUsComponent: React.FC = () => {
  return (
    <div className="min-h-max bg-bg_light_primary dark:bg-dark_bg_primary">
      <div className="hero_section relative h-80 xl:h-[284px]">
        <div className={`${cssUtils.layout.sectionLayout}`}>
          <span className="text-2xl sm:text-2xl lg:text-4xl 2xl:text-5xl font-bold text-white absolute top-[50%] -translate-y-2/4 z-1">
            {ApplicationString.aboutUs.title}
          </span>
        </div>
        <img
          src={heroImg}
          alt="about us"
          className="object-cover absolute top-0 h-80 w-full xl:h-[284px]"
        />
      </div>
      <div className="min-h-max pb-80">
        <StackCards />
      </div>
    </div>
  );
};

export default AboutUsComponent;

import React from 'react';
import TextHeading from '../TextHeading';
import PreviewContentComponent from '../PreviewContent';
import { IStepData } from '../../../../Interfaces/Stepper';
import {
  IUserSettingsData,
  IVehicleCategory,
} from '../../../../Interfaces/Settings/SettingsInterfaces';
import {
  checkIfArrayIsTruthyOrNot,
  getDetailsFromLocalStorage,
} from '../../../../Utils/utils';
import { defaultStringNAValue } from '../../../../Constants/commonConst';
import ApplicationString from '../../../../Constants/applicationString';
import { localStorageEnums, stepsNumberEnum } from '../../../../Utils/enums';

const PreviewVehicleDetails = ({
  settings,
  isEdit,
}: {
  settings: IUserSettingsData | null;
  isEdit: boolean;
}): JSX.Element => {
  const categoriesData: IVehicleCategory[] = checkIfArrayIsTruthyOrNot<
    IVehicleCategory[]
  >(settings?.setting?.vehicleCategory);

  const stepsData = getDetailsFromLocalStorage<IStepData[]>(
    localStorageEnums.stepsData
  );

  const vehicleData = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step1
  );

  const category = categoriesData?.find(
    (obj: { id: number }) => obj.id === vehicleData?.data?.category
  );
  const categoryName = category?.name || defaultStringNAValue;
  const subCategory =
    category?.subCategory?.find(
      (obj: { id: number }) => obj.id === vehicleData?.data?.subCategory
    )?.name || defaultStringNAValue;

  let trailerValue = defaultStringNAValue;

  if (vehicleData?.data?.trailer === true) {
    trailerValue = ApplicationString.searchScreen.labels.bookingValues.yes;
  } else if (vehicleData?.data?.trailer === false) {
    trailerValue = ApplicationString.searchScreen.labels.bookingValues.no;
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <TextHeading
          label={ApplicationString.vehicleStepper.previewStep.previewStep1}
          buttonText={ApplicationString.vehicleStepper.previewStep.edit}
          navigatePathStep={stepsNumberEnum.step1}
          isEdit={isEdit}
        />
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.title}
            description={vehicleData?.data?.title || defaultStringNAValue}
            isRequired
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.description}
            description={vehicleData?.data?.description || defaultStringNAValue}
            isRequired={false}
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.category}
            description={categoryName}
            isRequired
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.subCategory}
            description={subCategory}
            isRequired
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.maker}
            description={vehicleData?.data?.maker || defaultStringNAValue}
            isRequired
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.year}
            description={
              vehicleData?.data?.manufacturingYear || defaultStringNAValue
            }
            isRequired
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.model}
            description={vehicleData?.data?.model || defaultStringNAValue}
            isRequired
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.capacity}
            description={
              vehicleData?.data?.capacity
                ? `${vehicleData?.data?.capacity} ${ApplicationString.searchScreen.labels.person}`
                : defaultStringNAValue
            }
            isRequired={false}
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.length}
            description={
              vehicleData?.data?.size
                ? `${vehicleData?.data?.size} ${ApplicationString.searchScreen.labels.ft}`
                : defaultStringNAValue
            }
            isRequired={false}
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.owned}
            description={
              vehicleData?.data?.ownerShipType || defaultStringNAValue
            }
            isRequired
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.engineSize}
            description={vehicleData?.data?.engineSize || defaultStringNAValue}
            isRequired={false}
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.trailer}
            description={trailerValue}
            isRequired={false}
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step1Details.hitchSize}
            description={vehicleData?.data?.hitchSize || defaultStringNAValue}
            isRequired={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewVehicleDetails;

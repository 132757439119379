import { Tooltip } from '@mui/material';
import { defaultStringNAValue } from '../../../../Constants/commonConst';

const PreviewContentComponent = ({
  title,
  description,
  isRequired,
  isRightAligned = false,
}: {
  title: string;
  description: string;
  isRequired: boolean;
  // eslint-disable-next-line react/require-default-props
  isRightAligned?: boolean;
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-0">
      <div className="text-sm md:text-base text-secondary_text">
        {title}
        {isRequired && <span className="text-[#F30C0C]">*</span>}
      </div>
      <div
        className={`text-sm font-semibold md:font-bold w-full ${isRightAligned && 'flex justify-end'} ${description === defaultStringNAValue ? 'text-error' : 'text-outlineButton dark:text-white'}`}
      >
        {description.length > 30 ? (
          <Tooltip title={description}>
            <p
              className={`max-w-[400px] w-full ${description.length > 40 && 'overflow-hidden text-ellipsis whitespace-nowrap inline-block'}`}
            >
              {description}
            </p>
          </Tooltip>
        ) : (
          <p
            className={`max-w-[400px] w-full ${description.length > 10 && 'overflow-hidden text-ellipsis whitespace-nowrap inline-block'}`}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
export default PreviewContentComponent;

import React from 'react';
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  ErrorOutlined,
} from '@mui/icons-material';
import {
  TextField,
  Button,
  FormControl,
  InputAdornment,
  FormHelperText,
  IconButton,
} from '@mui/material';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import ApplicationString from '../../Constants/applicationString';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';

interface IcreateAccountData {
  name: string;
  password: string;
  confirmPassword: string;
  verificationId: string;
}

interface IPasswordQuality {
  passwordQuality: string;
  passwordMsgColor: string;
  passwordQualityIcon: JSX.Element | null;
}

interface IErrorsData {
  name: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  confirmPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface ISignUpNameAndPasswordComponentProps {
  onSignupSubmit: (e: React.FormEvent) => void;
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPasswordChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  formData: IcreateAccountData;
  errorsData: IErrorsData;
  isSigningUp: boolean;
  passwordQuality: IPasswordQuality;
}

const SignUpNameAndPasswordComponent: React.FC<
  ISignUpNameAndPasswordComponentProps
> = ({
  onSignupSubmit,
  handleNameChange,
  handlePasswordChange,
  handleConfirmPasswordChange,
  formData,
  errorsData,
  isSigningUp,
  passwordQuality,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <BackgroundLayout>
      <div className="xsm:w-100  max-h-[100vh] bg-white  border border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.NameAndPasswordSignUp.title}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              <div
                dangerouslySetInnerHTML={{
                  __html: ApplicationString.NameAndPasswordSignUp.subTitle,
                }}
              />
            </p>
          </div>
          <form onSubmit={onSignupSubmit}>
            <div data-testid="form-fields-container " className=" w-full  mt-8">
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
              >
                <FormControl>
                  <TextField
                    label={ApplicationString.NameAndPasswordSignUp.label.name}
                    className=" w-full rounded-4xl text-base font-medium "
                    InputProps={{ className: 'rounded-4xl px-2 ' }}
                    onChange={handleNameChange}
                    error={errorsData.name.error}
                    value={formData.name}
                  />
                  {errorsData.name.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.name.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    label={
                      ApplicationString.NameAndPasswordSignUp.label
                        .createPassword
                    }
                    type={showPassword ? 'text' : 'password'}
                    className="rounded-4xl border-none text-base font-medium  "
                    onChange={handlePasswordChange}
                    error={errorsData.password.error}
                    value={formData.password}
                    InputProps={{
                      className: `rounded-4xl px-2 border-none  `,
                      endAdornment: (
                        <InputAdornment position="end" className="pr-2">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            data-testid="signUp-password-toggle-button"
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlined className=" text-primary " />
                            ) : (
                              <VisibilityOffOutlined className=" text-primary  " />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errorsData.password.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.password.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    label={
                      ApplicationString.NameAndPasswordSignUp.label
                        .confirmPassword
                    }
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="rounded-4xl border-none text-base font-medium  "
                    onChange={handleConfirmPasswordChange}
                    error={errorsData.confirmPassword.error}
                    value={formData.confirmPassword}
                    InputProps={{
                      className: `rounded-4xl px-2 border-none  `,
                      endAdornment: (
                        <InputAdornment position="end" className="pr-2">
                          <IconButton
                            aria-label="toggle password visibility"
                            data-testid="signUp-confirmPassword-toggle-button"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOutlined className=" text-primary " />
                            ) : (
                              <VisibilityOffOutlined className=" text-primary  " />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(errorsData.confirmPassword.error ||
                    errorsData.confirmPassword.onlyErrorMsg) && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.confirmPassword.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </FormControl>
            </div>
            {passwordQuality.passwordQuality !== '' &&
              formData.password.trim() !== '' && (
                <div className="flex gap-2 mt-2">
                  <span>{passwordQuality.passwordQualityIcon}</span>
                  <span className={passwordQuality.passwordMsgColor}>
                    {`${ApplicationString.NameAndPasswordSignUp.passwordStrength.title} ${passwordQuality.passwordQuality}`}
                  </span>
                </div>
              )}
            <div className="mt-8 mb-4 flex flex-col gap-3">
              {isSigningUp ? (
                <LoadingButton
                  buttonText={
                    ApplicationString.signUpWithEmail.button.verifyingEmail
                  }
                />
              ) : (
                <Button
                  variant="contained"
                  data-testid="signUp-submit-button"
                  className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                  color="primary"
                  type="submit"
                >
                  {ApplicationString.signUpWithEmail.button.SignUp}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default SignUpNameAndPasswordComponent;

/* eslint-disable no-promise-executor-return */
import { Box, Modal } from '@mui/material';
import '@sendbird/uikit-react/dist/index.css';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import type { GroupChannelCreateParams } from '@sendbird/chat/groupChannel';

import './chatmodal.css';
import { useEffect, useState } from 'react';
import { getDetailsFromLocalStorage } from '../../Utils/utils';
import sendbirdService from '../../Services/sendbird.service';

const style = {
  position: 'absolute',
  top: '50%',
  height: 640,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 840,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 1,
  '@media (max-width:900px)': {
    width: '95%',
  },
};

interface ISendBirdChatModal {
  open: boolean;
  bookingId: number;
  hideChatModal: () => void;
  recipientId: string;
}

interface IUserInfo {
  accountId: string;
  avatarPath: string;
  userName: string;
}

const SendBirdChatModal: React.FC<ISendBirdChatModal> = (
  props
): JSX.Element => {
  const { open, bookingId, hideChatModal, recipientId } = props;

  const globalStore = useSendbirdStateContext();
  const [url, setUrl] = useState<string>();
  const createChannel = sendbirdSelectors.getCreateGroupChannel(globalStore);

  const createChannelWithUser = async () => {
    try {
      if (bookingId) {
        const {
          accountId: currentUserId,
          avatarPath,
          userName,
        } = getDetailsFromLocalStorage<IUserInfo>('userInfo') as IUserInfo;

        await sendbirdService.connectUser(currentUserId, globalStore);

        await sendbirdService.handleUserCreationOrUpdate({
          currentUserId,
          userName,
          avatarPath,
        });

        const params: GroupChannelCreateParams = {
          channelUrl: `booking_${bookingId}`,
          invitedUserIds: [currentUserId, recipientId],
        };
        const isChannelExist = await sendbirdService.getChannelByURL(
          params.channelUrl || ''
        );

        if (!isChannelExist) {
          await createChannel(params)
            .then((channel) => {
              setUrl(channel.url);
            })
            .catch(() => {
              setUrl(params.channelUrl);
            });
        } else {
          setUrl(params.channelUrl);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    createChannelWithUser();
  }, [bookingId, recipientId]);

  useEffect(() => {
    return () => {
      if (!open) {
        sendbirdService.disconnectUser(globalStore);
      }
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={hideChatModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      data-testid="parent-modal-testId"
    >
      <div>
        <Box sx={style} data-testid="chat-box-testId">
          <GroupChannel
            channelUrl={url || ``}
            replyType="QUOTE_REPLY"
            data-testid="chat-groupChannel-testId"
          />
        </Box>
      </div>
    </Modal>
  );
};

export default SendBirdChatModal;

import ApplicationString from '../../../Constants/applicationString';
import {
  IStepperErrorData,
  IStepperFormData,
} from '../../../Interfaces/Stepper';
import { localStorageEnums } from '../../../Utils/enums';
import {
  getMaxCharacterRegex,
  setLocalStorageInfo,
} from '../../../Utils/utils';
import commonRegexs from '../../../Utils/commonRegex';

const maxCharacterLimit = 200;
const maxCharacterStringLimit = 255;
const descriptionRegex = getMaxCharacterRegex(maxCharacterLimit);
const stringMaxCharacterRegex = getMaxCharacterRegex(maxCharacterStringLimit);

export const step1HandleValidation = (
  formData: IStepperFormData,
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>
): boolean => {
  let isError = false;
  if (formData.step1.title === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        title: {
          ValidationMessage: ApplicationString.stepperErrorsData.step1.title,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step1.title &&
    formData.step1.title.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step1.title)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        title: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step1.maxCharValidation,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step1.model &&
    formData.step1.model.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step1.model)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        model: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step1.maxCharValidation,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step1.description &&
    formData.step1.description.length > 200 &&
    !descriptionRegex.test(formData.step1.description)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        description: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step1.description,
          error: true,
        },
      },
    }));
  }
  if (formData.step1.category === null) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        category: {
          ValidationMessage: ApplicationString.stepperErrorsData.step1.category,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step1.subCategory === null &&
    !(formData?.step1.category === null)
  ) {
    isError = true;

    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        subCategory: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step1.subCategory,
          error: true,
        },
      },
    }));
  }
  if (formData.step1.maker === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        maker: {
          ValidationMessage: ApplicationString.stepperErrorsData.step1.maker,
          error: true,
        },
      },
    }));
  }
  if (formData.step1.manufacturingYear === '') {
    isError = true;

    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        manufacturingYear: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step1.manufacturingYear,
          error: true,
        },
      },
    }));
  }
  if (formData.step1.model === '') {
    isError = true;

    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        model: {
          ValidationMessage: ApplicationString.stepperErrorsData.step1.model,
          error: true,
        },
      },
    }));
  }
  if (!formData.step1.ownerShipType) {
    isError = true;

    setErrorsData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        ownerShipType: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step1.ownerShipType,
          error: true,
        },
      },
    }));
  }
  setErrorsData((prevState) => ({
    ...prevState,
    step1: {
      ...prevState.step1,
      isError,
    },
  }));
  return isError;
};

export const step2HandleValidation = (
  formData: IStepperFormData,
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>
): boolean => {
  let isError = false;
  if (formData.step2.country === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        country: {
          ValidationMessage: ApplicationString.stepperErrorsData.step2.country,
          error: true,
        },
      },
    }));
  }
  if (formData.step2.pickUp.address1 === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          address1: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.pickUp.address1,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.pickUp.city === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          city: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.pickUp.city,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.pickUp.state === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          state: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.pickUp.state,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.pickUp.zipCode === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          zipCode: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.pickUp.zipCode,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.dropOff.address1 === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          address1: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.dropOff.address1,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.dropOff.city === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          city: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.dropOff.city,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.dropOff.state === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          state: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.dropOff.state,
            error: true,
          },
        },
      },
    }));
  }
  if (formData.step2.dropOff.zipCode === '') {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          zipCode: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step2.dropOff.zipCode,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.pickUp.address1 &&
    formData.step2.pickUp.address1.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.pickUp.address1)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          address1: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.pickUp.address2 &&
    formData.step2.pickUp.address2.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.pickUp.address2)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          address2: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.pickUp.city &&
    formData.step2.pickUp.city.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.pickUp.city)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          city: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.pickUp.zipCode &&
    formData.step2.pickUp.zipCode.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.pickUp.zipCode)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        pickUp: {
          ...prevState.step2.pickUp,
          zipCode: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.dropOff.address1 &&
    formData.step2.dropOff.address1.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.dropOff.address1)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          address1: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.dropOff.address2 &&
    formData.step2.dropOff.address2.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.pickUp.address2)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          address2: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.dropOff.city &&
    formData.step2.dropOff.city.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.dropOff.city)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          city: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  if (
    formData.step2.dropOff.zipCode &&
    formData.step2.dropOff.zipCode.length > 255 &&
    !stringMaxCharacterRegex.test(formData.step2.dropOff.zipCode)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step2: {
        ...prevState.step2,
        dropOff: {
          ...prevState.step2.dropOff,
          zipCode: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      },
    }));
  }
  setErrorsData((prevState) => ({
    ...prevState,
    step2: {
      ...prevState.step2,
      isError,
    },
  }));
  return isError;
};

export const step3HandleValidation = (
  formData: IStepperFormData,
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>
): boolean => {
  let isError = false;
  if (formData.step3.images.length === 0) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step3: {
        ...prevState.step3,
        images: {
          ValidationMessage: ApplicationString.stepperErrorsData.step3.images,
          error: true,
        },
      },
    }));
  }
  if (formData.step3.images.length > 0) {
    const hasCover = formData.step3.images.some((item) => item.isCover);
    if (!hasCover) {
      isError = true;
      setErrorsData((prevState) => ({
        ...prevState,
        step3: {
          ...prevState.step3,
          images: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step3.coverImage,
            error: true,
          },
        },
      }));
    }
  }
  setErrorsData((prevState) => ({
    ...prevState,
    step3: {
      ...prevState.step3,
      isError,
    },
  }));
  return isError;
};

export const step4HandleValidation = (
  formData: IStepperFormData,
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>
): boolean => {
  let isError = false;
  if (formData.step4.amenities?.length === 0) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step4: {
        ...prevState.step4,
        amenities: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step4.amenities,
          error: true,
        },
      },
    }));
  }
  if (formData.step4.restrictions?.length === 0) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step4: {
        ...prevState.step4,
        restrictions: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step4.restrictions,
          error: true,
        },
      },
    }));
  }
  setErrorsData((prevState) => ({
    ...prevState,
    step4: {
      ...prevState.step4,
      isError,
    },
  }));
  return isError;
};

export const step5HandleValidation = (
  formData: IStepperFormData,
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>
): boolean => {
  let isError = false;
  const slotError =
    formData.step5.isHourly ||
    formData.step5.is12Hourly ||
    formData.step5.is24Hourly;

  setLocalStorageInfo(!slotError, localStorageEnums.isSlotError);
  if (!slotError) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        slot: {
          ValidationMessage: ApplicationString.stepperErrorsData.step5.slot,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step5.isHourly &&
    (formData.step5.rateHourly === null || formData.step5.rateHourly === 0)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        rateHourly: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5.rateHourly,
          error: true,
        },
      },
    }));
  }

  if (
    formData.step5.isHourly &&
    (formData.step5.rateHourly === null || formData.step5.rateHourly === 0)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        rate12Hourly: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5.rate12Hourly,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step5.isHourly &&
    (formData.step5.rateHourly === null || formData.step5.rateHourly === 0)
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        rate24Hourly: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5.rate24Hourly,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step5.is24Hourly &&
    formData.step5.rate24Hourly !== null &&
    !commonRegexs.floatValues.test(formData.step5.rate24Hourly.toString())
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        rate24Hourly: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5.numberRate,
          error: true,
        },
      },
    }));
  }
  if (
    formData.step5.is24Hourly &&
    formData.step5.maxDaysFor24Hourly &&
    !commonRegexs.floatValues.test(String(formData.step5.maxDaysFor24Hourly))
  ) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        rate24Hourly: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5.numberRate,
          error: true,
        },
      },
    }));
  }
  if (formData.step5.isMultipleBookingOnSameDay === null) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        isMultipleBookingOnSameDay: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5
              .isMultipleBookingOnSameDay,
          error: true,
        },
      },
    }));
  }
  if (!formData.step5.gapHour) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        gapHour: {
          ValidationMessage: ApplicationString.stepperErrorsData.step5.gapHour,
          error: true,
        },
      },
    }));
  }
  if (formData.step5?.monthLimit !== null && formData.step5.monthLimit > 120) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        monthLimit: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step5.monthLimit,
          error: true,
        },
      },
    }));
  }
  setErrorsData((prevState) => ({
    ...prevState,
    step5: {
      ...prevState.step5,
      isError,
    },
  }));
  return isError;
};
export const step6HandleValidation = (
  formData: IStepperFormData,
  setErrorsData: React.Dispatch<React.SetStateAction<IStepperErrorData>>
): boolean => {
  let isError = false;

  if (!formData.step6.termsAndCondition) {
    isError = true;
    setErrorsData((prevState) => ({
      ...prevState,
      step6: {
        ...prevState.step6,
        termsAndCondition: {
          ValidationMessage:
            ApplicationString.stepperErrorsData.step6.termsAndCondition,
          error: true,
        },
      },
    }));
  }
  setErrorsData((prevState) => ({
    ...prevState,
    step6: {
      ...prevState.step6,
      isError,
    },
  }));
  return isError;
};

export const isStepperError = (errorData: IStepperErrorData): boolean => {
  const { step1, step2, step3, step4, step5, step6 } = errorData;

  const areStepsValidationOnNextErrored =
    step1.isError ||
    step2.isError ||
    step3.isError ||
    step4.isError ||
    step5.isError ||
    step6.isError;

  return areStepsValidationOnNextErrored;
};

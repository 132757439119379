import React from 'react';
import cssUtils from '../../Utils/cssUtils';
import ApplicationString from '../../Constants/applicationString';
import img1 from '../../Assets/splashScreenImg/Group 1597881498.png';
import img2 from '../../Assets/splashScreenImg/Group 1597881499.png';
import img3 from '../../Assets/splashScreenImg/Group 1597881501.png';

const BeforeLoginComponent: React.FC = () => {
  return (
    <div className="w-auto h-full">
      <div className={cssUtils.layout.sectionLayout}>
        <div className="flex flex-col min-h-[60vh] 2xl:min-h-[70vh] gap-10  sm:gap-20 xl:gap-30 2xl:gap-54 items-center justify-between h-full py-10 2xl:py-15">
          <p className="text-[40px] sm:text-[60px] xl:text-[80px] 2xl:text-[100px] w-full font-semibold text-white">
            {ApplicationString.ownerSplashScreen.title}
          </p>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 justify-center">
            <div className="flex relative flex-col gap-2 items-center">
              <img className="" src={img1} alt="Owner icon 1" />
              <p className=" leading-tight absolute top-4 sm:text[20px] xl:text-[24px] 2xl:text-[26px]  font-semibold w-70 text-center text-white">
                {ApplicationString.ownerSplashScreen.imgTitle.img1Title}
              </p>
            </div>
            <div className="flex relative flex-col gap-2 items-center">
              <img className="" src={img2} alt="Owner icon 2" />
              <p className=" leading-tight absolute top-4 sm:text[20px] xl:text-[24px] 2xl:text-[26px]  font-semibold w-70 text-center text-white">
                {ApplicationString.ownerSplashScreen.imgTitle.img2Title}
              </p>
            </div>
            <div className="flex relative flex-col gap-2 items-center">
              <img className="" src={img3} alt="Owner icon 2" />
              <p className=" leading-tight absolute top-4 sm:text[20px] xl:text-[24px] 2xl:text-[26px]  font-semibold w-70 text-center text-white">
                {ApplicationString.ownerSplashScreen.imgTitle.img3Title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeLoginComponent;

import { AxiosError, HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import React from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { ApiRequest } from '../../Api/api';
import SignUpNameAndPasswordComponent from '../../Components/SignUpComponents/SignUpNameAndPasswordComponent';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import commmonRegexs from '../../Utils/commonRegex';
import {
  localStorageEnums,
  loginSignupFormEnums,
  signupPasswordStrengthEnums,
} from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import { setLocalStorageInfo } from '../../Utils/utils';
import SendBirdService from '../../Services/sendbird.service';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IcreateAccountData {
  name: string;
  password: string;
  confirmPassword: string;
  verificationId: string;
}

interface IcreateAccountPayload {
  name: string;
  password: string;
  verificationId: string;
  timeZone: string;
}

interface IErrorsData {
  name: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  confirmPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface IPasswordQuality {
  passwordQuality: string;
  passwordMsgColor: string;
  passwordQualityIcon: JSX.Element | null;
}

const SignUpNameAndPasswordContainer: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams({});
  const verificationId = atob(params.get('verificationId') || '');
  const [isSigningUp, setIsSigningUp] = React.useState<boolean>(false);
  const [passwordQuality, setPasswordQuality] =
    React.useState<IPasswordQuality>({
      passwordQuality: '',
      passwordMsgColor: '',
      passwordQualityIcon: null,
    });
  const globalStore = useSendbirdStateContext();
  const [formData, setFormData] = React.useState<IcreateAccountData>({
    name: '',
    password: '',
    confirmPassword: '',
    verificationId: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    name: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    password: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    confirmPassword: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
  });

  const onSignupSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (
      !commmonRegexs.signupPassword.test(formData.password) &&
      formData.password.trim() !== '' &&
      formData.confirmPassword.trim() !== '' &&
      formData.name !== ''
    )
      return;

    if (formData.name.trim().length > loginSignupFormEnums.maxTextLength)
      return;

    setErrorsData((prevState) => ({
      ...prevState,
      name: {
        error: !formData.name.trim(),
        ValidationMessage: !formData.name.trim()
          ? ApplicationString.NameAndPasswordSignUp.validationMsgs.requiredMsgs
              .name
          : '',
      },
      password: {
        error: !formData.password.trim(),
        ValidationMessage: !formData.password.trim()
          ? ApplicationString.NameAndPasswordSignUp.validationMsgs.requiredMsgs
              .password
          : '',
      },
      confirmPassword: {
        error: !formData.confirmPassword.trim(),
        ValidationMessage: !formData.confirmPassword.trim()
          ? ApplicationString.NameAndPasswordSignUp.validationMsgs.requiredMsgs
              .confirmPassword
          : '',
      },
    }));

    if (formData.password.trim() !== formData.confirmPassword.trim()) return;

    if (
      !formData.name.trim() ||
      !formData.password.trim() ||
      !formData.confirmPassword.trim()
    )
      return;

    const payload: IcreateAccountPayload = {
      name: formData.name,
      password: formData.password,
      verificationId,
      timeZone: dayjs().format('Z'),
    };

    try {
      setIsSigningUp(true);
      const response = await ApiRequest.post(`${URLS.USER_CREATE}`, payload);
      if (response?.status === HttpStatusCode.Ok && response?.data) {
        setLocalStorageInfo(response.data, localStorageEnums.userInfo);
        navigate(InternalRoute.AccountCreated);

        const {
          accountId: currentUserId,
          userName,
          avatarPath,
        } = response.data;

        await SendBirdService.connectUser(currentUserId, globalStore);

        await SendBirdService.handleUserCreationOrUpdate({
          currentUserId,
          userName,
          avatarPath,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      if (axiosError?.response && axiosError.response?.data) {
        if (axiosError.response.status === 400) {
          setErrorsData((prevState) => ({
            ...prevState,
            confirmPassword: {
              error: false,
              onlyErrorMsg: true,
              ValidationMessage: axiosError.response?.data?.message || '',
            },
          }));
        }
      }
    } finally {
      setIsSigningUp(false);
    }
  };

  // Form handler functions

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let err = false;
    let msg = '';
    if (e.target.value.trim().length > loginSignupFormEnums.maxTextLength) {
      err = true;
      msg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .nameLength;
    }
    setFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      name: {
        error: err,
        ValidationMessage: msg,
      },
    }));
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let err = false;
    let msg = '';

    let checkConfirmPassword = false;
    let checkConfirmPasswordMsg = '';

    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');

    // Check password strength
    const hasSpecialChar = commmonRegexs.hasSpecialChar.test(trimmedPassword);
    const hasCapitalLetter =
      commmonRegexs.hasCapitalLetter.test(trimmedPassword);
    const hasSmallLetter = commmonRegexs.hasSmallLetter.test(trimmedPassword);
    const hasDigit = commmonRegexs.hasDigit.test(trimmedPassword);

    let quality = '';
    let qualityColor = '';
    let qualityIcon: JSX.Element | null = null;

    if (
      trimmedPassword.length >= 6 &&
      hasSpecialChar &&
      hasCapitalLetter &&
      hasSmallLetter &&
      hasDigit
    ) {
      quality = signupPasswordStrengthEnums.strong;
      qualityColor = 'text-green-500';
      qualityIcon = <EmojiEmotionsOutlinedIcon className="text-green-500" />;
    } else if (
      trimmedPassword.length >= 6 &&
      (hasSpecialChar || hasCapitalLetter || hasDigit || hasSmallLetter)
    ) {
      quality = signupPasswordStrengthEnums.medium;
      qualityColor = 'text-yellow-500';
      qualityIcon = (
        <SentimentSatisfiedOutlinedIcon className="text-yellow-500" />
      );
    } else {
      quality = signupPasswordStrengthEnums.weak;
      qualityColor = 'text-red-500';
      qualityIcon = (
        <SentimentDissatisfiedOutlinedIcon className="text-red-500" />
      );
    }

    if (
      trimmedPassword !== '' &&
      formData.confirmPassword.trim() !== '' &&
      trimmedPassword !== formData.confirmPassword.trim()
    ) {
      checkConfirmPassword = true;
      checkConfirmPasswordMsg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .confirmPassword;
    }

    if (
      !commmonRegexs.signupPassword.test(trimmedPassword) &&
      trimmedPassword !== ''
    ) {
      err = true;
      msg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .password;
    }

    if (trimmedPassword.length > loginSignupFormEnums.maxPasswordLength) {
      err = true;
      msg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .passwordLength;
    }

    setFormData((prevState) => ({
      ...prevState,
      password: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      password: {
        error: err,
        ValidationMessage: msg,
      },
      confirmPassword: {
        error: checkConfirmPassword,
        ValidationMessage: checkConfirmPasswordMsg,
      },
    }));

    setPasswordQuality((prevState) => ({
      ...prevState,
      passwordQuality: quality,
      passwordMsgColor: qualityColor,
      passwordQualityIcon: qualityIcon,
    }));
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let err = false;
    let msg = '';
    if (formData.password !== e.target.value.trim()) {
      err = true;
      msg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .confirmPassword;
    }
    if (e.target.value.trim() === '') {
      err = false;
      msg = '';
    }
    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');
    setFormData((prevState) => ({
      ...prevState,
      confirmPassword: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      confirmPassword: {
        error: err,
        ValidationMessage: msg,
      },
    }));
  };

  return (
    <SignUpNameAndPasswordComponent
      onSignupSubmit={onSignupSubmit}
      handleNameChange={handleNameChange}
      handlePasswordChange={handlePasswordChange}
      handleConfirmPasswordChange={handleConfirmPasswordChange}
      formData={formData}
      errorsData={errorsData}
      isSigningUp={isSigningUp}
      passwordQuality={passwordQuality}
    />
  );
};

export default SignUpNameAndPasswordContainer;

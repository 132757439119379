import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Skeleton,
} from '@mui/material';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApplicationString from '../../Constants/applicationString';
import { timeFormat } from '../../Constants/commonConst';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { ITermsAndCondition } from '../../Interfaces/Settings/SettingsInterfaces';
import {
  UsdCurrency,
  formatSingleOrDateRange,
  getCoverPhoto,
} from '../../Utils/utils';
import cssUtils from '../../Utils/cssUtils';
import { myBookingsStatusEnums } from '../../Utils/enums';
import MybookingsRatingComponent from './MybookingsRatingComponent';
import ColorModeContext from '../../Utils/ColorModeContext';
import MyBookingCancelComponent from './MyBookingCancelComponent';
import InternalRoute from '../../Utils/internalRoutes';
import SendBirdChatModal from '../ChatModal';
import CommonAccordion from '../common/CommonAccordian';

interface MyBookingDetailsComponentProps {
  TermsAndConditions: ITermsAndCondition[];
  bookingDetails: IMyBookings;
  loading: boolean;
  getReviewDetails: (id: number) => void;
  reviewSubmitHandler: (rating: number) => void;
  ratingModal: boolean;
  setRatingModal: React.Dispatch<React.SetStateAction<boolean>>;
  reviewStar: number;
  isCancelBookingOpen: boolean;
  handleCloseCancelBookingModal: () => void;
  handleOpenCancelBookingModal: () => void;
  cancelBookingHandler: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

dayjs.extend(utc);

const MyBookingDetailsComponent: React.FC<MyBookingDetailsComponentProps> = ({
  TermsAndConditions,
  bookingDetails,
  loading,
  getReviewDetails,
  reviewSubmitHandler,
  ratingModal,
  setRatingModal,
  reviewStar,
  isCancelBookingOpen,
  handleCloseCancelBookingModal,
  handleOpenCancelBookingModal,
  cancelBookingHandler,
}) => {
  const {
    vehicleDetail,
    ownerDetails,
    startDate,
    endDate,
    confirmationCode,
    specialRequest,
    tax,
    total,
    rate,
    deposit,
    type,
    id,
  } = bookingDetails;
  const { title, city, country, dropCity, dropCountry, images } = vehicleDetail;
  const { currentTheme } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const coverPhoto = getCoverPhoto(images);

  const showRatingModal = () => {
    getReviewDetails(id);
    setRatingModal(true);
  };

  const hideRatingModal = () => {
    setRatingModal(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleNavigateToBookings = () => {
    const path = InternalRoute.Vehicle_details.replace(
      ':VehicleId',
      vehicleDetail.id.toString()
    );
    navigate(path);
  };

  const handleNavigateToContactUs = () => {
    navigate(InternalRoute.Contact);
  };

  const formatLocation = (str1: string, str2: string) => {
    if (str1 && str2) {
      return `${str1}, ${str2}`;
    }
    if (str1) {
      return `${str1}, ${ApplicationString.commonStrings.notAvailable}`;
    }
    if (str2) {
      return `${ApplicationString.commonStrings.notAvailable}, ${str2}`;
    }
    return ApplicationString.commonStrings.notAvailable;
  };

  const updatedAccordianTitles = [
    {
      id: 1,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies.securityDeposit,
    },
    {
      id: 2,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies
          .cancellationPolicy,
    },
  ];

  const showButtonBasedOnType = (): JSX.Element => {
    if (type === myBookingsStatusEnums.active) {
      return (
        <div className="flex-col flex xsm:flex-row gap-4">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenCancelBookingModal}
            className="w-full flex justify-center rounded-4xl normal-case sm:text-base font-bold sm:py-3 border-dark_bg_surface text-dark_bg_surface dark:border-dark_primary_text dark:text-dark_primary_text"
          >
            {ApplicationString.MyBookingDetailsComponent.buttons.cancelBooking}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            className="w-full flex justify-center rounded-4xl normal-case shadow-1 shadow-slate-50 sm:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
          >
            {ApplicationString.MyBookingDetailsComponent.buttons.chat}
          </Button>
        </div>
      );
    }

    if (type === myBookingsStatusEnums.completed) {
      return (
        <div className="flex-col flex xsm:flex-row gap-4 ">
          <Button
            variant="contained"
            color="primary"
            onClick={showRatingModal}
            className="w-full flex justify-center rounded-4xl normal-case shadow-1 shadow-slate-50 sm:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
          >
            {ApplicationString.MyBookingDetailsComponent.buttons.rating}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNavigateToBookings}
            className="w-full flex justify-center rounded-4xl normal-case sm:text-base font-bold sm:py-3 border-dark_bg_surface text-dark_bg_surface dark:border-dark_primary_text dark:text-dark_primary_text"
          >
            {ApplicationString.MyBookingDetailsComponent.buttons.bookAgain}
          </Button>
        </div>
      );
    }

    if (type === myBookingsStatusEnums.cancelled) {
      return (
        <div className="flex-col flex xsm:flex-row gap-4">
          <span className="w-full flex text-center flex-1 justify-center border-none text-error rounded-4xl normal-case text-base font-bold sm:py-3 border-dark_bg_surface _bg_surface dark:border-dark_primary_text ">
            {
              ApplicationString.MyBookingDetailsComponent.buttons
                .bookingCancelled
            }
          </span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigateToContactUs}
            className="w-full flex flex-1 justify-center rounded-4xl normal-case shadow-1 shadow-slate-50 sm:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
          >
            {ApplicationString.MyBookingDetailsComponent.buttons.help}
          </Button>
        </div>
      );
    }

    if (type === myBookingsStatusEnums.rejected) {
      return (
        <div className="flex-col flex xsm:flex-row gap-4 ">
          <span className="w-full flex text-center flex-1 justify-center border-none text-error rounded-4xl normal-case text-base font-bold sm:py-3 border-dark_bg_surface _bg_surface dark:border-dark_primary_text ">
            {
              ApplicationString.MyBookingDetailsComponent.buttons
                .bookingRejected
            }
          </span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigateToContactUs}
            className="w-full flex flex-1 justify-center rounded-4xl normal-case text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
          >
            {ApplicationString.MyBookingDetailsComponent.buttons.help}
          </Button>
        </div>
      );
    }

    return (
      <Button
        variant="contained"
        disabled
        color="primary"
        className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3 bg-dark_brand_300 hover:bg-dark_brand_300/100 shadow-none  dark:text-dark_bg_secondary "
      >
        {ApplicationString.MyBookingDetailsComponent.buttons.pendingApproval}
      </Button>
    );
  };

  return (
    <div className="bg-bg_secondary dark:bg-dark_bg_primary">
      {open && (
        <SendBirdChatModal
          open={open}
          hideChatModal={() => setOpen(false)}
          bookingId={bookingDetails.id}
          recipientId={bookingDetails.ownerDetails.id}
        />
      )}
      <Modal
        component="div"
        aria-labelledby="Rating Modal"
        aria-describedby="Rating Modal"
        disableScrollLock
        open={ratingModal}
        onClose={hideRatingModal}
        closeAfterTransition
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: `${currentTheme === 'light' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.4)'}`,
          },
        }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={ratingModal}>
          <Box sx={style}>
            <MybookingsRatingComponent
              booking={bookingDetails}
              setRatingModal={setRatingModal}
              // eslint-disable-next-line react/jsx-no-bind
              formatLocation={formatLocation}
              reviewSubmitHandler={reviewSubmitHandler}
              reviewStar={reviewStar}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        component="div"
        aria-labelledby="Booking Cancel"
        aria-describedby="Booking Cancel"
        disableScrollLock
        open={isCancelBookingOpen}
        onClose={handleCloseCancelBookingModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: `${currentTheme === 'light' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.4)'}`,
          },
        }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={isCancelBookingOpen}>
          <Box sx={style}>
            <MyBookingCancelComponent
              onCancelHandler={cancelBookingHandler}
              handleCloseCancelBookingModal={handleCloseCancelBookingModal}
              loading={loading}
            />
          </Box>
        </Fade>
      </Modal>
      <div className={cssUtils.layout.sectionLayout}>
        {loading ? (
          <div className="w-full flex flex-row gap-7">
            <div className="skeleton-booking-card flex flex-col gap-2 w-1/2 bg-card_bg dark:bg-dark_bg_secondary p-2.5 rounded-xl">
              <Skeleton variant="rounded" className="w-full h-70 rounded-xl" />
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                </div>
              </div>
            </div>
            <div className="skeleton-booking-details flex flex-col gap-2 w-1/2 bg-card_bg dark:bg-dark_bg_secondary p-2.5 rounded-xl h-fit">
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap">
                  <Skeleton
                    variant="rounded"
                    className="w-full h-10 rounded-full"
                  />
                </div>
                <div className="flex flex-wrap">
                  <Skeleton
                    variant="rounded"
                    className="w-full h-10 rounded-full"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-full"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-start">
              <Link
                to={InternalRoute.MyBookings}
                className="text-primary_text flex items-center gap-2 dark:text-dark_primary_text"
              >
                <ArrowBackIosNewIcon className="size-4 md:size-6" />
                <span className="text-xl sm:text-2xl 2xl:text-3xl font-medium ">
                  {
                    ApplicationString.MyBookingDetailsComponent
                      .goBackToBookingDetails
                  }
                </span>
              </Link>
            </div>
            <div className="flex flex-col lg:flex-row gap-7 mt-6">
              <div
                id="booking-card-with-img"
                className="rounded-xl p-2.5 flex flex-col gap-4 md:gap-7 flex-1 bg-card_bg dark:bg-dark_bg_secondary"
              >
                <div className="h-70">
                  {coverPhoto ? (
                    <img
                      src={coverPhoto}
                      alt="cardImg"
                      className="h-full w-full rounded-xl"
                    />
                  ) : (
                    <div className="h-full w-full bg-gray-300 dark:bg-dark_bg_secondary rounded-xl flex justify-center items-center text-secondary_text dark:text-dark_secondary_text text-2xl">
                      {ApplicationString.commonStrings.noImageFound}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-2 md:gap-4 px-2.5 text-lg max-w-full">
                  <h1 className="text-xl sm:text-2xl 2xl:text-3xl font-bold  text-title_text dark:text-dark_web_accent">
                    {title}
                  </h1>
                  <div className="flex flex-wrap md:flex-row gap-2 dark:text-dark_primary_text ">
                    <div className="flex items-center gap-1">
                      <CalendarMonthOutlinedIcon className="size-5 text-primary " />
                      <span className="text-base sm:text-lg font-medium dark:text-white ">
                        {formatSingleOrDateRange([startDate, endDate])}
                      </span>
                    </div>
                    <span className="text-borderStroke hidden sm:block">|</span>
                    <div className="flex items-center gap-1">
                      <AccessTimeOutlinedIcon className="size-5 text-primary" />
                      <span className="text-base sm:text-lg font-medium dark:text-white ">
                        {`${dayjs.utc(startDate).format(timeFormat)} - ${dayjs.utc(endDate).format(timeFormat)}`}
                      </span>
                    </div>
                    <span className="text-borderStroke hidden sm:block">|</span>
                    <div className="flex items-center gap-1">
                      <FmdGoodOutlinedIcon className="size-5 text-primary" />
                      <span className="text-base sm:text-lg font-medium">
                        {formatLocation(city, country)}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text ">
                    <span className="text-secondary_text dark:text-dark_secondary_text ">
                      {ApplicationString.MyBookingDetailsComponent.pickup}
                    </span>
                    <span className="text-right">
                      {formatLocation(city, country)}
                    </span>
                  </div>
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {ApplicationString.MyBookingDetailsComponent.dropOff}
                    </span>
                    <span className="text-right">
                      {formatLocation(dropCity, dropCountry)}
                    </span>
                  </div>
                  <Divider />
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text ">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {
                        ApplicationString.MyBookingDetailsComponent
                          .confirmationCode
                      }
                    </span>
                    <span className="text-right">{confirmationCode}</span>
                  </div>
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {
                        ApplicationString.MyBookingDetailsComponent
                          .specialRequest
                      }
                    </span>
                    <span className="text-right break-all md:max-w-75 max-w-40">
                      {specialRequest ||
                        ApplicationString.commonStrings.notAvailable}
                    </span>
                  </div>
                  <Divider />
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text pb-1">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {ApplicationString.MyBookingDetailsComponent.ownedBy}
                    </span>
                    <span className="text-right">
                      {ownerDetails.name ||
                        ApplicationString.commonStrings.notAvailable}
                    </span>
                  </div>
                </div>
              </div>
              <div
                id="booking-card-with-payment-details"
                className="p-5 bg-card_bg dark:bg-dark_bg_secondary flex-1 rounded-xl flex flex-col gap-4 dark:text-dark_primary_text h-fit"
              >
                <div
                  id="payment-calculation-details"
                  className="flex flex-col gap-4 dark:text-dark_primary_text "
                >
                  <h1 className="text-lg font-bold">
                    {ApplicationString.MyBookingDetailsComponent.paymentDetails}
                  </h1>
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">
                      {ApplicationString.MyBookingDetailsComponent.rate}
                    </span>
                    <span className="text-lg font-normal">
                      {UsdCurrency(rate)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">
                      {
                        ApplicationString.MyBookingDetailsComponent
                          .securityDeposit
                      }
                    </span>
                    <span className="text-lg font-normal">
                      {UsdCurrency(deposit)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">
                      {ApplicationString.MyBookingDetailsComponent.tax}
                    </span>
                    <span className="text-lg font-normal">
                      {UsdCurrency(tax)}
                    </span>
                  </div>
                  <Divider />
                  <div className="flex justify-between font-bold">
                    <span className="text-lg">
                      {ApplicationString.MyBookingDetailsComponent.total}
                    </span>
                    <span className="text-lg">{UsdCurrency(total)}</span>
                  </div>
                </div>
                <CommonAccordion
                  title={updatedAccordianTitles}
                  TermsAndConditions={TermsAndConditions}
                />
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    className="w-full h-full rounded-xl"
                  />
                ) : (
                  showButtonBasedOnType()
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyBookingDetailsComponent;

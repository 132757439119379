import React from 'react';
/* eslint-disable react/require-default-props */

interface SectionHeadingProps {
  title: string;
  description?: string;
  descTextColor: string;
  darkTheme?: boolean;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  description,
  descTextColor,
  darkTheme = true,
}) => {
  return (
    <div className="flex flex-col">
      <p
        className={`font-semibold darkdarkTheme dark:text-${darkTheme ? 'white' : 'black'}  text-xl sm:text-2xl 2xl:text-3xl leading-tight`}
      >
        {title}
      </p>
      <p
        className={`text-sm dark:text-slate-300 sm:text-lg 2xl:text-xl leading-tight text-${descTextColor}-600`}
      >
        {description}
      </p>
    </div>
  );
};

export default SectionHeading;

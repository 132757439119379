import React, { useEffect, useState } from 'react';
import { AxiosResponse, HttpStatusCode } from 'axios';

import SearchComponent from '../../Components/SearchComponent';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { IUserSettingsData } from '../../Interfaces/common/settings/settings';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import { commonConfig } from '../../Constants/commonConfig';
import { buildURL } from '../../Utils/utils';
import { localStorageEnums } from '../../Utils/enums';

interface FilteredSearchedData {
  data: IVehiclesData[];
  total: number;
  // ...
}

interface QueryParams {
  category?: string;
  latlong?: string;
  startDate?: string;
  endDate?: string;
  subCategory?: number[];
  pageNumber: number;
  sortBy?: string;
  sortDirection?: string;
  radius?: number;
}
const SearchContainer: React.FC = () => {
  const [isloading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<FilteredSearchedData>({
    data: [],
    total: 0,
  });

  const baseURL = `${commonConfig.ApiUrl}${URLS.SEARCH_QUERY}`;

  const getSearchResults = async (searchQuery: QueryParams): Promise<void> => {
    const finalURL = buildURL(baseURL, searchQuery);
    try {
      setIsLoading(true);
      const response: AxiosResponse = await ApiRequest.get(finalURL);
      if (response?.status === HttpStatusCode.Ok) {
        setFilteredData(response.data);
        if (response.data.length === 0 && searchQuery.pageNumber > 1) {
          const updatedSearchQuery = {
            ...searchQuery,
            pageNumber: 1,
          };
          getSearchResults(updatedSearchQuery);
          return;
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const getUserSettings = async () => {
    try {
      const response = await ApiRequest.get(URLS.GET_USER_SETTINGS);
      if (response?.status === HttpStatusCode.Ok) {
        const data: IUserSettingsData = response.data[0];
        localStorage.setItem(localStorageEnums.settings, JSON.stringify(data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUserSettings();
  }, []);

  return (
    <SearchComponent
      loading={isloading}
      getSearchResults={getSearchResults}
      filteredSearchedData={filteredData}
    />
  );
};

export default SearchContainer;

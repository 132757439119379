/* ---- Common Tailwind Class Utils ----*/

const cssUtils = {
  inputClasses:
    'w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary',
  labelClasses: 'mb-2.5 block font-medium text-black dark:text-white',
  button: {
    primary:
      'text-base font-semibold bg-white dark:border-dark_borderStroke dark:text-white dark:bg-dark_bg_secondary px-6 text-black border border-slate-300 p-4 py-3 rounded-full',
    secondary:
      'text-sm font-semibold bg-darkPrimary dark:text-dark_bg_secondary px-6 text-white p-4 py-3 rounded-full',
    tertiary: 'bg-tertiary text-white',
    disabled: 'bg-disabled text-disabled',
  },
  layout: {
    sectionLayout: 'mx-auto max-w-7xl px-6 py-8 lg:px-8 2xl:px-0 lg:py-12',
  },
  colors: {
    slate: 'slate',
    white: 'white',
  },
};

export const styles = StyleSheet;

export default cssUtils;

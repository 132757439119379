import { useState, useCallback, useEffect } from 'react';

interface IuseSelectOpenCloseHookReturn {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  MenuProps?: {
    disableScrollLock: boolean;
    marginThreshold: null;
    PaperProps: {
      style: {
        maxHeight: number;
      };
    };
  };
}

// This hook is used to open and close the select dropdown and solve MUI padding and scroll issue
// if you have defined the menuProps in your select then pass true in the argument but make sure it has all the properties mentioned above

const useSelectOpenClose = (
  dontReturnMenuProps = false
): IuseSelectOpenCloseHookReturn => {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const MenuProps = {
    disableScrollLock: true,
    marginThreshold: null,
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open, onClose]);

  if (dontReturnMenuProps) {
    return { open, onOpen, onClose };
  }
  return { open, onOpen, onClose, MenuProps };
};

export default useSelectOpenClose;

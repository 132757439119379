import React, { useContext } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Skeleton,
} from '@mui/material';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import ApplicationString from '../../Constants/applicationString';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { timeFormat } from '../../Constants/commonConst';
import { UsdCurrency, formatSingleOrDateRange } from '../../Utils/utils';
import { ITermsAndCondition } from '../../Interfaces/Settings/SettingsInterfaces';
import CommonAccordian from '../common/CommonAccordian';
import { myBookingsStatusEnums } from '../../Utils/enums';
// import InternalRoute from '../../Utils/internalRoutes';
import OwnerBookingRequestedComponent from './ownerBookingRequestedComponent';
import ColorModeContext from '../../Utils/ColorModeContext';
import SendBirdChatModal from '../ChatModal';
import InternalRoute from '../../Utils/internalRoutes';
import cssUtils from '../../Utils/cssUtils';

interface OwnerBookingDetailsCoponentProps {
  loading: boolean;
  ownerBookingDetails: IMyBookings;
  TermsAndConditions: ITermsAndCondition[];
  requestModal: boolean;
  handleCloseRequestModal: () => void;
  handleOpenRequestModal: () => void;
  onRejectHandler: () => void;
  onAcceptHandler: () => void;
}
const OwnerBookingComponent: React.FC<OwnerBookingDetailsCoponentProps> = ({
  loading,
  ownerBookingDetails,
  TermsAndConditions,
  requestModal,
  handleCloseRequestModal,
  handleOpenRequestModal,
  onRejectHandler,
  onAcceptHandler,
}) => {
  const [isAcceptBooking, setIsAcceptBooking] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const origin = location.state?.from || InternalRoute.ownerDashboard;
  const formatLocation = (str1: string, str2: string) => {
    if (str1 && str2) {
      return `${str1}, ${str2}`;
    }
    if (str1) {
      return `${str1}`;
    }
    if (str2) {
      return `${str2}`;
    }
    return ApplicationString.commonStrings.notAvailable;
  };
  const coverPhoto =
    ownerBookingDetails.vehicleDetail.images.filter((image) => image.isCover)[0]
      ?.path ||
    ownerBookingDetails.vehicleDetail.images.filter((image) => image.path)[0]
      ?.path;
  const updatedAccordianTitles = [
    {
      id: 1,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies.securityDeposit,
    },
    {
      id: 2,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies
          .cancellationPolicy,
    },
  ];
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const { currentTheme } = useContext(ColorModeContext);
  const handleRejectBooking = () => {
    setIsAcceptBooking(false);
    handleOpenRequestModal();
  };
  const handleAcceptBooking = () => {
    setIsAcceptBooking(true);
    handleOpenRequestModal();
  };

  const showButtonBasedOnType = (): JSX.Element => {
    let buttonElement;

    switch (ownerBookingDetails.type) {
      case myBookingsStatusEnums.active:
        buttonElement = (
          <div className="flex w-full justify-center">
            <Button
              variant="contained"
              color="primary"
              data-testid="Owner-booking-details-chat-button"
              className="w-3/6 flex justify-center rounded-4xl normal-case text-base font-bold py-3 dark:text-dark_bg_secondary "
              onClick={() => setOpen(true)}
            >
              {ApplicationString.MyBookingDetailsComponent.buttons.chat}
            </Button>
          </div>
        );
        break;

      case myBookingsStatusEnums.rejected:
        buttonElement = (
          <div className="flex gap-1">
            <span className="w-full flex text-center flex-1 justify-center border-none text-error rounded-4xl normal-case text-base font-bold sm:py-3 border-dark_bg_surface _bg_surface dark:border-dark_primary_text ">
              {
                ApplicationString.MyBookingDetailsComponent.buttons
                  .bookingRejected
              }
            </span>
          </div>
        );
        break;

      case myBookingsStatusEnums.cancelled:
        buttonElement = (
          <div className="flex gap-1">
            <span className="w-full flex text-center flex-1 justify-center border-none text-error rounded-4xl normal-case text-base font-bold sm:py-3 border-dark_bg_surface _bg_surface dark:border-dark_primary_text ">
              {
                ApplicationString.MyBookingDetailsComponent.buttons
                  .bookingCancelled
              }
            </span>
          </div>
        );
        break;

      case myBookingsStatusEnums.requested:
        buttonElement = (
          <div className="flex gap-2">
            <Button
              variant="outlined"
              color="primary"
              data-testid="Owner-booking-details-reject-button"
              onClick={handleRejectBooking}
              className="w-full flex justify-center rounded-4xl normal-case text-base font-bold sm:py-3 border-dark_bg_surface text-dark_bg_surface dark:border-dark_primary_text dark:text-dark_primary_text"
            >
              {
                ApplicationString.OwnerBookingDetails.bookingStatus
                  .rejectBooking
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-testid="Owner-booking-details-accept-button"
              onClick={handleAcceptBooking}
              className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3 dark:text-dark_bg_secondary "
            >
              {
                ApplicationString.OwnerBookingDetails.bookingStatus
                  .acceptBooking
              }
            </Button>
          </div>
        );
        break;

      default:
        buttonElement = <div />;
    }

    return buttonElement;
  };

  return (
    <>
      {open && (
        <SendBirdChatModal
          open={open}
          hideChatModal={() => setOpen(false)}
          bookingId={ownerBookingDetails.id}
          recipientId={ownerBookingDetails.renterDetails.id}
        />
      )}
      <Modal
        component="div"
        aria-labelledby="Rating Modal"
        aria-describedby="Rating Modal"
        disableScrollLock
        open={requestModal}
        onClose={handleCloseRequestModal}
        closeAfterTransition
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: `${currentTheme === 'light' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.4)'}`,
          },
        }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={requestModal}>
          <Box sx={style}>
            <OwnerBookingRequestedComponent
              onRejectHandler={onRejectHandler}
              onAcceptHandler={onAcceptHandler}
              handleCloseRequestModal={handleCloseRequestModal}
              loading={loading}
              isAcceptBooking={isAcceptBooking}
              // eslint-disable-next-line react/jsx-no-bind
            />
          </Box>
        </Fade>
      </Modal>
      <div className={cssUtils.layout.sectionLayout}>
        {loading ? (
          <div className="w-full flex flex-row gap-7">
            <div className="skeleton-booking-card flex flex-col gap-2 w-1/2 bg-card_bg dark:bg-dark_bg_secondary p-2.5 rounded-xl">
              <Skeleton variant="rounded" className="w-full h-70 rounded-xl" />
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                </div>
              </div>
            </div>
            <div className="skeleton-booking-details flex flex-col gap-2 w-1/2 bg-card_bg dark:bg-dark_bg_secondary p-2.5 rounded-xl h-fit">
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/4 h-10 rounded-xl"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-xl"
                  />
                </div>
                <div className="flex flex-wrap">
                  <Skeleton
                    variant="rounded"
                    className="w-full h-10 rounded-full"
                  />
                </div>
                <div className="flex flex-wrap">
                  <Skeleton
                    variant="rounded"
                    className="w-full h-10 rounded-full"
                  />
                </div>
                <Divider />
                <div className="flex flex-wrap md:flex-row gap-2 justify-between dark:text-dark_primary_text ">
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-full"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/3 h-10 rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="text-2xl cursor-pointer 2xl:text-3xl flex gap-3 items-center dark:text-white mb-6">
              <Link
                to={origin}
                className="text-primary_text  flex items-center gap-2 dark:text-dark_primary_text"
              >
                <ArrowBackIosNewIcon className="size-4 md:size-6" />
                {ApplicationString.OwnerBookingDetails.bookingDetails}
              </Link>
            </div>
            <div className="flex flex-col lg:flex-row gap-7 mt-6 lg:min-h-[700px]">
              <div
                id="booking-card-with-img"
                className="rounded-xl p-2.5 flex flex-col gap-4 md:gap-7 flex-1 bg-card_bg dark:bg-dark_bg_secondary"
              >
                <div className="h-70">
                  {coverPhoto ? (
                    <img
                      src={coverPhoto}
                      alt="cardImg"
                      className="h-full w-full rounded-xl"
                    />
                  ) : (
                    <div className="h-full w-full bg-gray-300 dark:bg-dark_bg_secondary rounded-xl flex justify-center items-center text-secondary_text dark:text-dark_secondary_text text-2xl">
                      {ApplicationString.commonStrings.noImageFound}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-2 md:gap-4 px-2.5 text-lg max-w-full">
                  <h1 className="text-xl sm:text-2xl 2xl:text-3xl font-bold  text-title_text dark:text-dark_web_accent">
                    {ownerBookingDetails?.vehicleDetail?.title}
                  </h1>
                  <div className="flex flex-wrap md:flex-row gap-2 dark:text-dark_primary_text ">
                    <div className="flex items-center gap-1">
                      <CalendarMonthOutlinedIcon className="size-5 text-primary " />
                      <span className="text-base sm:text-lg font-medium dark:text-white ">
                        {formatSingleOrDateRange([
                          ownerBookingDetails?.startDate,
                          ownerBookingDetails?.endDate,
                        ])}
                      </span>
                    </div>
                    <span className="text-borderStroke hidden sm:block">|</span>
                    <div className="flex items-center gap-1">
                      <AccessTimeOutlinedIcon className="size-5 text-primary" />
                      <span className="text-base sm:text-lg font-medium dark:text-white ">
                        {`${dayjs.utc(ownerBookingDetails?.startDate).format(timeFormat)} - ${dayjs.utc(ownerBookingDetails?.endDate).format(timeFormat)}`}{' '}
                      </span>
                    </div>
                    <span className="text-borderStroke hidden sm:block">|</span>
                    <div className="flex items-center gap-1">
                      <FmdGoodOutlinedIcon className="size-5 text-primary" />
                      <span className="text-base sm:text-lg font-medium">
                        {formatLocation(
                          ownerBookingDetails.vehicleDetail.city,
                          ownerBookingDetails.vehicleDetail.country
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text ">
                    <span className="text-secondary_text dark:text-dark_secondary_text ">
                      {ApplicationString.MyBookingDetailsComponent.pickup}
                    </span>
                    <span className="text-right">
                      {formatLocation(
                        ownerBookingDetails.vehicleDetail.city,
                        ownerBookingDetails.vehicleDetail.country
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {ApplicationString.MyBookingDetailsComponent.dropOff}
                    </span>
                    <span className="text-right">
                      {formatLocation(
                        ownerBookingDetails.vehicleDetail.dropCity,
                        ownerBookingDetails.vehicleDetail.dropCountry
                      )}
                    </span>
                  </div>
                  <Divider />
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {
                        ApplicationString.MyBookingDetailsComponent
                          .confirmationCode
                      }
                    </span>
                    <span className="text-right">
                      {ownerBookingDetails?.confirmationCode}
                    </span>
                  </div>
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {
                        ApplicationString.MyBookingDetailsComponent
                          .specialRequest
                      }
                    </span>
                    <span className="text-right break-all md:max-w-75 max-w-40">
                      {ownerBookingDetails?.specialRequest ||
                        ApplicationString.commonStrings.notAvailable}
                    </span>
                  </div>
                  <Divider />
                  <div className="flex justify-between text-base sm:text-lg font-medium dark:text-dark_primary_text pb-1">
                    <span className="text-secondary_text dark:text-dark_secondary_text">
                      {
                        ApplicationString.OwnerBookingDetails.bookingPolicies
                          .rentedBy
                      }
                    </span>
                    <span className="text-right w-3/6 break-all">
                      {ownerBookingDetails?.renterDetails?.name}
                    </span>
                  </div>
                </div>
              </div>
              <div
                id="booking-card-with-payment-details"
                className="p-5 bg-card_bg dark:bg-dark_bg_secondary flex-1 rounded-xl flex flex-col gap-4 dark:text-dark_primary_text h-fit"
              >
                <div
                  id="payment-calculation-details"
                  className="flex flex-col gap-4 dark:text-dark_primary_text "
                >
                  <h1 className="text-lg font-bold">
                    {ApplicationString.MyBookingDetailsComponent.paymentDetails}
                  </h1>
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">
                      {ApplicationString.MyBookingDetailsComponent.rate}
                    </span>
                    <span className="text-lg font-normal">
                      {UsdCurrency(ownerBookingDetails.rate)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">
                      {
                        ApplicationString.MyBookingDetailsComponent
                          .securityDeposit
                      }
                    </span>
                    <span className="text-lg font-normal">
                      {UsdCurrency(ownerBookingDetails?.deposit)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">
                      {ApplicationString.MyBookingDetailsComponent.tax}
                    </span>
                    <span className="text-lg font-normal">
                      {UsdCurrency(ownerBookingDetails?.tax)}
                    </span>
                  </div>
                  <Divider />
                  <div className="flex justify-between font-bold">
                    <span className="text-lg">
                      {ApplicationString.MyBookingDetailsComponent.total}
                    </span>
                    <span className="text-lg">
                      {UsdCurrency(ownerBookingDetails?.total)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <CommonAccordian
                    title={updatedAccordianTitles}
                    TermsAndConditions={TermsAndConditions}
                  />
                </div>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    className="w-full h-full rounded-xl"
                  />
                ) : (
                  showButtonBasedOnType()
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OwnerBookingComponent;

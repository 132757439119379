import React, { useContext, useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import MyListingsOwnerComponent from '../../Components/MyListings';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { getLocalStorageAccountInfo } from '../../Utils/utils';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/LoginInterfaces';
import { localStorageEnums, myListingsStatusEnums } from '../../Utils/enums';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import ToastContext from '../../Services/ToastService';
import ApplicationString from '../../Constants/applicationString';

const MyListingsOwnerContainer: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [listingData, setListingData] = useState<IVehiclesData[]>([]);
  const toast = useContext(ToastContext);
  const accountId = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.accountId;
  const getOwnerVehicleListings = async (): Promise<void> => {
    const sortDirection = 'desc';
    const sortBy = 'createdAt';
    try {
      setLoading(true);
      const res = await ApiRequest.get(
        `${URLS.VEHICLE_LISTING}?sortBy=${sortBy}&sortDirection=${sortDirection}&accountId=${accountId}&pageSize=1000`
      );
      if (res?.status === HttpStatusCode.Ok) {
        setListingData(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      console.error(axiosError.message);
      setLoading(false);
    }
  };

  const handleActivateVehicle = async (id: number) => {
    try {
      const res = await ApiRequest.put(
        URLS.ACTIVATE_VEHICLE.replace('#{vehicleId}', String(id)),
        { isEnabled: myListingsStatusEnums.activated }
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast?.success(
          `${ApplicationString.MyListingsComponent.toastMessages.vehicleActivated}`
        );
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        getOwnerVehicleListings();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      console.error(axiosError.message);
    }
  };
  useEffect(() => {
    getOwnerVehicleListings();
  }, []);

  return (
    <div>
      <MyListingsOwnerComponent
        handleActivateVehicle={handleActivateVehicle}
        listingDetails={listingData}
        isLoading={loading}
      />
    </div>
  );
};

export default MyListingsOwnerContainer;

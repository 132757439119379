import React from 'react';
import ApplicationString from '../../../Constants/applicationString';
import { UsdCurrency } from '../../../Utils/utils';

interface PaymentDetailsProps {
  securityDeposit: number;
  taxes: number;
  total: number;
  calculatedRate: number;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  securityDeposit,
  taxes,
  total,
  calculatedRate,
}) => {
  return (
    <div className="p-4 dark:text-white">
      <div className="flex dark:text-white justify-between mb-2">
        <span
          className="text-gray-700 dark:text-white"
          data-testid="payment-card-rate"
        >
          {ApplicationString.bookingDetailsData.vehicleRate}
        </span>
        <span
          className="text-gray-700 dark:text-white"
          data-testid="payment-card-total-rate"
        >
          {UsdCurrency(calculatedRate)}
        </span>
      </div>
      <div className="flex dark:text-white justify-between mb-2">
        <span
          className="text-gray-700 dark:text-white"
          data-testid="payment-card-security-deposit"
        >
          {ApplicationString.bookingDetailsData.securityDeposit}
        </span>
        <span
          className="text-gray-700 dark:text-white"
          data-testid="payment-card-security-deposit-value"
        >
          {UsdCurrency(securityDeposit)}
        </span>
      </div>
      <div className="flex justify-between mb-4">
        <span
          className="text-gray-700 dark:text-white"
          data-testid="payment-card-taxes"
        >
          {ApplicationString.bookingDetailsData.taxes}
        </span>
        <span
          className="text-gray-700 dark:text-white"
          data-testid="payment-card-taxes-value"
        >
          {UsdCurrency(taxes)}
        </span>
      </div>
      <div className="border-t dark:border-dark_borderStroke border-gray-200 mb-4" />
      <div className="flex justify-between font-bold text-gray-900 dark:text-white">
        <span data-testid="payment-card-total-label">
          {ApplicationString.bookingDetailsData.total}
        </span>
        <span data-testid="payment-card-total-value">{UsdCurrency(total)}</span>
      </div>
    </div>
  );
};

export default PaymentDetails;

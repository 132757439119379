import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { Modal, Typography } from '@mui/material';
import useLogoutHook from '../../../Hooks/useLogoutHook';
import { LogoutSvg } from '../../../Assets/Svgs';
import ApplicationString from '../../../Constants/applicationString';
import ColorModeContext from '../../../Utils/ColorModeContext';
import { setLocalStorageInfo } from '../../../Utils/utils';
import { localStorageEnums } from '../../../Utils/enums';

interface ILogout {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const LogoutPopupComponent: React.FC<ILogout> = ({ open, setOpen }) => {
  const logoutHook = useLogoutHook();
  const { currentTheme } = useContext(ColorModeContext);

  const handleLogoutClose = () => {
    logoutHook.logout();
    setLocalStorageInfo(
      window.location.pathname + window.location.search,
      localStorageEnums.lastAccessUrl
    );
    setOpen(false);
  };
  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        data-testid="modal-modal-title"
        className="flex items-center justify-center min-h-screen p-4"
      >
        <div className="bg-white px-6 py-4 rounded-xl w-full max-w-xs md:max-w-md mx-auto relative dark:bg-dark_bg_secondary">
          <div className="flex flex-col justify-between items-start ">
            <div className="flex flex-col">
              <div className="">
                <LogoutSvg />
              </div>
              <Typography className=" mt-2 font-bold text-2xl text-black text-nowrap dark:text-white">
                {ApplicationString.LogoutPopUp.logoutLabel}
              </Typography>
              <Typography
                data-testid="modal-modal-description"
                sx={{ mt: 2 }}
                className="md:text-nowrap dark:text-dark_text_sub_primary"
              >
                {ApplicationString.LogoutPopUp.content}
              </Typography>
            </div>
          </div>

          <div className="mt-4 w-full flex flex-row gap-4">
            <Button
              autoFocus
              onClick={handleLogoutClose}
              className="capitalize px-10 md:px-20 py-2 md:py-2.5 text-base font-bold rounded-full border"
              style={{
                border: `${currentTheme === 'dark' ? '1px solid white' : '1px solid black'}`,
                backgroundColor: 'transparent',
                color: `${currentTheme === 'dark' ? 'white' : 'black'}`,
              }}
              data-testid="button-modal-logout"
            >
              {ApplicationString.LogoutPopUp.buttons.logout}
            </Button>
            <Button
              onClick={handleCancelClose}
              autoFocus
              data-testid="button-modal-cancel"
              className="bg-primary text-white
          capitalize px-10 md:px-20 py-2 md:py-2.5 text-base font-bold rounded-full hover:bg-primary"
            >
              {ApplicationString.LogoutPopUp.buttons.cancel}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LogoutPopupComponent;

import TextHeading from '../TextHeading';
import PreviewContentComponent from '../PreviewContent';
import { IStepData } from '../../../../Interfaces/Stepper';
import { IUserSettingsData } from '../../../../Interfaces/Settings/SettingsInterfaces';
import { defaultStringNAValue } from '../../../../Constants/commonConst';
import ApplicationString from '../../../../Constants/applicationString';
import { localStorageEnums, stepsNumberEnum } from '../../../../Utils/enums';
import { getDetailsFromLocalStorage } from '../../../../Utils/utils';

const PreviewBookingDetails = ({
  settings,
  isEdit,
}: {
  settings: IUserSettingsData | null;
  isEdit: boolean;
}): JSX.Element => {
  const stepsData = getDetailsFromLocalStorage<IStepData[]>(
    localStorageEnums.stepsData
  );

  const vehicleData = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step5
  );
  const Hourly12TimeFrameLabels = vehicleData?.data?.timeFrameFor12Hourly?.map(
    (item2) => {
      const match = settings?.setting['12hourlyTimeFrame']?.find(
        (item1) =>
          item1.value.to === item2.to && item1.value.from === item2.from
      );
      return match?.label || defaultStringNAValue;
    }
  );

  let multipleBookingValue = defaultStringNAValue;

  if (vehicleData?.data?.isMultipleBookingSameDay === true) {
    multipleBookingValue =
      ApplicationString.searchScreen.labels.bookingValues.yes;
  } else if (vehicleData?.data?.isMultipleBookingSameDay === false) {
    multipleBookingValue =
      ApplicationString.searchScreen.labels.bookingValues.no;
  }
  return (
    <div className="flex flex-col gap-4">
      <div>
        <TextHeading
          label={ApplicationString.vehicleStepper.previewStep.previewStep5}
          buttonText={ApplicationString.vehicleStepper.previewStep.edit}
          navigatePathStep={stepsNumberEnum.step5}
          isEdit={isEdit}
        />
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.hourly}
            description={
              vehicleData?.data?.bookingRateHourly
                ? `$${vehicleData?.data?.bookingRateHourly?.toString()} ${ApplicationString.searchScreen.labels.bookingValues.hourly}
            `
                : defaultStringNAValue
            }
            isRequired={false}
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.twelveHourly}
            description={
              vehicleData?.data?.bookingRate12Hourly
                ? `$${vehicleData?.data?.bookingRate12Hourly?.toString()} ${ApplicationString.searchScreen.labels.bookingValues.twelve_hourly_preview}
            `
                : defaultStringNAValue
            }
            isRequired={false}
            isRightAligned
          />
        </div>
      </div>

      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.timeFrame}
            description={
              Hourly12TimeFrameLabels
                ? Hourly12TimeFrameLabels.join(', ')
                : defaultStringNAValue
            }
            isRequired={false}
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.daily}
            description={
              vehicleData?.data?.bookingRate24Hourly
                ? `$${vehicleData?.data?.bookingRate24Hourly?.toString()} ${ApplicationString.searchScreen.labels.bookingValues.twentyFour_hourly}
            `
                : defaultStringNAValue
            }
            isRequired={false}
            isRightAligned
          />
        </div>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.maxDays}
            description={
              vehicleData?.data?.maxDaysFor24Hourly
                ? `${vehicleData?.data?.maxDaysFor24Hourly?.toString()} ${ApplicationString.searchScreen.labels.bookingValues.maxDays}`
                : defaultStringNAValue
            }
            isRequired={false}
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={
              ApplicationString.vehicleStepper.step5Details.multipleBookings
            }
            description={multipleBookingValue}
            isRequired
            isRightAligned
          />
        </div>
      </div>

      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.gap}
            description={
              vehicleData?.data?.bookingGap
                ? `${vehicleData?.data?.bookingGap?.toString()} ${ApplicationString.searchScreen.labels.bookingValues.hours}`
                : defaultStringNAValue
            }
            isRequired
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step5Details.noticePeriod}
            description={
              vehicleData?.data?.shortNoticePeriod
                ? `${vehicleData?.data?.shortNoticePeriod?.toString()} ${ApplicationString.searchScreen.labels.bookingValues.hours}`
                : defaultStringNAValue
            }
            isRequired={false}
            isRightAligned
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewBookingDetails;

import React, { useState, useEffect } from 'react';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

interface IstarRatingProps {
  rating: number;
  currentRating: number;
  setCurrentRating: React.Dispatch<React.SetStateAction<number>>;
}

const StarRating: React.FC<IstarRatingProps> = ({
  rating,
  currentRating,
  setCurrentRating,
}) => {
  const [hoverRating, setHoverRating] = useState(0);

  useEffect(() => {
    setCurrentRating(Number(rating));
  }, [rating]);

  const handleMouseOver = (index: number) => {
    setHoverRating(index);
  };

  const handleMouseOut = () => {
    setHoverRating(0);
  };

  const handleClick = (index: number) => {
    setCurrentRating(index);
  };

  const renderStars = () => {
    const stars = [];
    const effectiveRating = hoverRating || currentRating;

    for (let i = 1; i <= 5; i += 1) {
      if (i <= effectiveRating) {
        stars.push(<StarIcon key={i} className="text-ratingStar size-10" />);
      } else if (i - 0.5 <= effectiveRating) {
        stars.push(
          <StarHalfIcon key={i} className="text-ratingStar size-10" />
        );
      } else {
        stars.push(
          <StarOutlineIcon key={i} className="text-ratingStar size-10" />
        );
      }
    }
    return stars;
  };

  return (
    <div className="flex space-x-1">
      {[...Array(5)].map((_, i) => (
        <div
          key={rating + Math.random()}
          tabIndex={0}
          role="button"
          data-testid="star-rating"
          onMouseOver={() => handleMouseOver(i + 1)}
          onFocus={() => handleMouseOver(i + 1)}
          onMouseOut={handleMouseOut}
          onBlur={handleMouseOut}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClick(i + 1);
            }
          }}
          onClick={() => handleClick(i + 1)}
          className="cursor-pointer"
        >
          {renderStars()[i]}
        </div>
      ))}
    </div>
  );
};

export default StarRating;

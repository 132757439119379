import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import OwnerDashboardComponent from '../../Components/OwnerDashboard';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { IOwnerStatistics } from '../../Interfaces/Owner/ownerDashboard.interface';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { useToast } from '../../Services/ToastService';
import {
  getDetailsFromLocalStorage,
  showApiError,
  userRoleEnums,
} from '../../Utils/utils';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { myBookingsStatusEnums } from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';

interface UpdateBookingStatusPayload {
  type: string;
}

const OwnerDashboardContainer: React.FC = (): JSX.Element => {
  const [ownerStatistics, setOwnerStatistics] = useState<IOwnerStatistics>();
  const [isStatisticsLoading, setIsStatisticsLoading] =
    useState<boolean>(false);
  const [instantBookings, setInstantBookings] = useState<IMyBookings[]>([]);
  const [allBookings, setAllBookings] = useState<IMyBookings[]>([]);
  const [isInstantBookingStatusUpdating, setIsInstantBookingStatusUpdating] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const toast = useToast();

  const getOwnerStatistics = async (): Promise<void> => {
    try {
      setIsStatisticsLoading(true);
      const ownerStatisticsResponse: AxiosResponse<IOwnerStatistics> =
        await ApiRequest.get(URLS.GET_OWNER_STATISTICS);

      if (ownerStatisticsResponse.status === HttpStatusCode.Ok) {
        setOwnerStatistics(ownerStatisticsResponse.data);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setIsStatisticsLoading(false);
    }
  };

  const getInstantBookings = async (): Promise<void> => {
    try {
      const pageNumber = '1';
      const pageSize = '1000';
      const { accountId } = getDetailsFromLocalStorage('userInfo') as {
        accountId: string;
      };
      const shortBy = 'from';
      const sortDirection = 'desc';
      setIsLoading(true);

      const instantBookingsResponse: AxiosResponse<{ data: IMyBookings[] }> =
        await ApiRequest.get(
          URLS.MY_BOOKINGS_BY_QUERY.replace('#{pageNumber}', pageNumber)
            .replace('#{pageSize}', pageSize)
            .replace('#{accountId}', accountId)
            .replace('#{userType}', userRoleEnums.OWNER)
            .replace('#{excludeSelfBooking}', 'true')
            .replace('#{sortBy}', shortBy)
            .replace('#{sortDirection}', sortDirection)
        );

      if (instantBookingsResponse.status === HttpStatusCode.Ok) {
        const instantBookingsFilterArray =
          instantBookingsResponse.data?.data.filter(
            (booking) => booking.type === myBookingsStatusEnums.requested
          );

        setAllBookings(instantBookingsResponse.data?.data);

        setInstantBookings(
          instantBookingsFilterArray.length > 0
            ? instantBookingsFilterArray
            : []
        );
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setIsLoading(false);
    }
  };

  const instantBookingActionsHandler = async (
    bookingId: number,
    payload: string
  ): Promise<void> => {
    try {
      if (isInstantBookingStatusUpdating) {
        return;
      }

      if (!bookingId) {
        toast.error(
          ApplicationString.OwnerDashboard.instantBooking
            .bookingIdNotFountErrorMessage
        );
        return;
      }

      const updateBookingStatusPayload: UpdateBookingStatusPayload = {
        type: payload,
      };

      setIsInstantBookingStatusUpdating(true);
      const instantBookingsResponse: AxiosResponse<{ data: IMyBookings[] }> =
        await ApiRequest.put(
          URLS.UPDATE_BOOKINGS_TYPE.replace('#{bookingId}', String(bookingId)),
          updateBookingStatusPayload
        );

      if (instantBookingsResponse.status === HttpStatusCode.NoContent) {
        await getInstantBookings();
      }
      setIsInstantBookingStatusUpdating(false);
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      setIsInstantBookingStatusUpdating(false);
      showApiError(axiosError, toast);
    }
  };

  useEffect(() => {
    getOwnerStatistics();
    getInstantBookings();
  }, []);

  return (
    <OwnerDashboardComponent
      ownerStatistics={ownerStatistics}
      isStatisticsLoading={isStatisticsLoading}
      instantBookings={instantBookings}
      instantBookingActionsHandler={instantBookingActionsHandler}
      allBookings={allBookings}
      isLoading={isLoading}
    />
  );
};

export default OwnerDashboardContainer;

import React, { useContext, useEffect } from 'react';
import { ToggleButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Img1 from '../../Assets/watercraft.png';
import Img2 from '../../Assets/landVehicle.png';
import ExploreListingComponent from './ExploreListingComponent';
import {
  categoryEnums,
  categoryNameEnums,
  localStorageEnums,
} from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';
import {
  checkIfArrayIsTruthyOrNot,
  getDetailsFromLocalStorage,
} from '../../Utils/utils';
import {
  IUserSettingsData,
  IVehicleCategory,
} from '../../Interfaces/Settings/SettingsInterfaces';
import ColorModeContext from '../../Utils/ColorModeContext';
import {
  fetchData,
  selectCategory,
} from '../../Store/Slices/categoryDataSlice';
import { AppDispatch, RootState } from '../../Store/store';

const ExploreComponent: React.FC = () => {
  const [toggleValue, setToggleValue] = React.useState<number>();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, subCategoriesData } = useSelector(
    (state: RootState) => state.data
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const check = (category: string): string => {
    if (category === ApplicationString.searchScreen.categories.watercraft) {
      return String(categoryEnums.watercraft);
    }
    if (category === ApplicationString.searchScreen.categories.landVehicles) {
      return String(categoryEnums.landVehicles);
    }
    return '';
  };

  const { currentTheme } = useContext(ColorModeContext);
  const vehicleSubCategory = searchParams.get('subcategory') || '';
  const strLower = (searchParams.get('category') || '').toLowerCase();
  const vehicleCategory = check(strLower.replace(/\s/g, ''));

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(selectCategory(Number(vehicleCategory)));
    setToggleValue(Number(vehicleCategory) || categoryEnums.watercraft);
  }, [vehicleCategory, dispatch]);

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      border: '1px solid transparent',
      borderRadius: 18,
      '&.Mui-selected': {
        backgroundColor: `${currentTheme === 'dark' ? '#1F1F1F' : 'white'}`,
        color: `${currentTheme === 'dark' ? 'white' : 'black'}`,
        fontWeight: 'bold',
      },
    },
  }));
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    value: number
  ) => {
    setSearchParams({
      ...searchParams,
      category:
        value === 1
          ? categoryNameEnums.watercraft
          : categoryNameEnums.landVehicles,
    });
    setToggleValue(value);
    dispatch(selectCategory(value));
  };

  const userSettingsData = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;
  let categoriesData: IVehicleCategory[] = [];
  if (userSettingsData) {
    categoriesData = checkIfArrayIsTruthyOrNot<IVehicleCategory[]>(
      userSettingsData?.vehicleCategory
    );
  }

  return (
    <div className="w-full">
      <div className="w-full relative flex flex-col justify-center items-center">
        {toggleValue === 1 ? (
          <>
            <img
              src={Img1}
              alt=""
              className="w-full h-40 sm:h-52 md:h-67 lg:h-100"
            />

            <Typography
              variant="h4"
              sx={{ textAlign: 'center' }}
              className="absolute top-5 sm:top-13 md:top-10 lg:top-20 text-white text-2xl sm:text-2xl lg:text-4xl 2xl:text-6xl font-semibold leading-tight"
            >
              {
                ApplicationString.exploreScreen.exploreLabels.toggleImageLabels
                  .waterCraft
              }
            </Typography>
          </>
        ) : (
          <>
            <img
              src={Img2}
              alt=""
              className="w-full h-40 sm:h-52 md:h-67 lg:h-100"
            />
            <Typography
              variant="h4"
              sx={{ textAlign: 'center' }}
              className="absolute top-5 sm:top-13 md:top-10 lg:top-20 text-white text-2xl sm:text-2xl lg:text-4xl 2xl:text-6xl font-semibold leading-tight"
            >
              {
                ApplicationString.exploreScreen.exploreLabels.toggleImageLabels
                  .landVehicle
              }
            </Typography>
          </>
        )}

        <StyledToggleButtonGroup
          value={toggleValue || categoryEnums.watercraft}
          exclusive
          className="absolute bottom-10 md:bottom-16 lg:bottom-30 rounded-full bg-button_bg dark:bg-outlineButton w-57 h-10  md:w-72 md:h-15"
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          {categoriesData?.map((item) => {
            return (
              <ToggleButton
                style={{ borderRadius: 'inherit' }}
                key={item.id}
                className="text-sm md:text-base capitalize text-nowrap md:w-48 md:h-13"
                value={item.id}
              >
                {item.name}
              </ToggleButton>
            );
          })}
        </StyledToggleButtonGroup>
      </div>
      <div>
        <ExploreListingComponent
          subCategory={vehicleSubCategory}
          loading={isLoading}
          categorySelected={toggleValue || categoryEnums.watercraft}
          subcategoriesData={subCategoriesData}
        />
      </div>
    </div>
  );
};

export default ExploreComponent;

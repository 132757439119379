import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const PaymentDetailsComponent: React.FC<{ goBack: () => void }> = ({
  goBack,
}) => {
  return (
    <div>
      <ChevronLeftIcon onClick={goBack} className="md:hidden text-2xl mb-8" />
    </div>
  );
};

export default PaymentDetailsComponent;

import {
  PaletteMode,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Provider } from 'react-redux';
import React, { ReactElement, useMemo, useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import AppRouter from './Routes/appRouter';
import oneLifeThemeColors from './oneLifeThemeColors';
import ColorModeContext from './Utils/ColorModeContext';
import './index.css';
// eslint-disable-next-line import/no-named-as-default
import store from './Store/store';
import ToastProvider from './Components/common/Toast/ToastProvider';
import { localStorageEnums } from './Utils/enums';
import {
  getDetailsFromLocalStorage,
  getDetailsFromLocalStorageForString,
  setLocalStorageInfoForString,
} from './Utils/utils';

const App = (): ReactElement => {
  const colorTheme = getDetailsFromLocalStorageForString('theme');
  const [mode, setMode] = useState<PaletteMode>(
    (colorTheme as PaletteMode) || 'light'
  );
  if (mode === 'light') {
    document.body.classList.add(mode);
    document.body.classList.remove('dark');
  } else {
    document.body.classList.add(mode);
    document.body.classList.remove('light');
  }
  const colorMode = useMemo(
    () => ({
      toggleColorMode: (currentTheme: string) => {
        setLocalStorageInfoForString(currentTheme, localStorageEnums.theme);
        setMode(currentTheme as PaletteMode);
      },
    }),
    [mode]
  );
  const currentTheme = useMemo(() => mode, [mode]);
  const getMuiTheme = () => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: oneLifeThemeColors.lightTheme.primary,
            },
            text: {
              primary: oneLifeThemeColors.lightTheme.primary_text,
            },
            error: {
              main: oneLifeThemeColors.lightTheme.error,
            },
          }
        : {
            primary: {
              main: oneLifeThemeColors.darkTheme.primary,
            },
            text: {
              primary: oneLifeThemeColors.darkTheme.primary_text,
            },
            error: {
              main: oneLifeThemeColors.darkTheme.error,
            },
          }),
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor:
              mode === 'light'
                ? oneLifeThemeColors.lightTheme.formFieldBg
                : oneLifeThemeColors.darkTheme.formFieldBg,

            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${currentTheme === 'light' ? '#D1D5DB' : '#515151'}`,
              },
              '&:hover fieldset': {
                borderColor: `${currentTheme === 'light' ? '#D1D5DB' : '#515151'}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${currentTheme === 'light' ? '#D1D5DB' : '#515151'}`,
              },
            },
            '& .MuiInputLabel-root': {
              color: `${currentTheme === 'light' ? '#6B7280' : 'white'}`,
              '&.Mui-focused': {
                color: `${currentTheme === 'light' ? '#6B7280' : 'white'}`,
              },
            },
            '& .MuiInputBase-input': {
              color: `${currentTheme === 'light' ? 'black' : 'white'}`,
              '&::placeholder': {
                color: `${currentTheme === 'light' ? 'black' : 'white'}`,
              },
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: KeyboardArrowDownOutlinedIcon,
        },
        styleOverrides: {
          root: {
            '.MuiSvgIcon-root': {
              color:
                mode === 'light'
                  ? oneLifeThemeColors.lightTheme.primary
                  : oneLifeThemeColors.darkTheme.primary,
            },
            backgroundColor:
              mode === 'light'
                ? oneLifeThemeColors.lightTheme.formFieldBg
                : oneLifeThemeColors.darkTheme.formFieldBg,

            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${currentTheme === 'light' ? '#D1D5DB' : '#515151'}`,
              },
              '&:hover fieldset': {
                borderColor: `${currentTheme === 'light' ? '#D1D5DB' : '#515151'}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${currentTheme === 'light' ? '#D1D5DB' : '#515151'}`,
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            color: `${currentTheme === 'light' ? '#6B7280' : 'white'}`,
            '&.MuiInputLabel-shrink': {
              color: `${currentTheme === 'light' ? '#6B7280' : 'white'}`,
            },
          },
        },
      },
    },
  });

  const colorSet = {
    '--sendbird-light-primary-500': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-light-primary-400': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-light-primary-300': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-light-primary-200': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-light-primary-100': '#80808021',
    '--sendbird-light-background-100': '#a4cafe',
    '--sendbird-light-background-200': '#a4cafe',
    '--sendbird-dark-primary-500': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-dark-primary-400': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-dark-primary-300': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-dark-primary-200': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-dark-primary-100': oneLifeThemeColors.lightTheme.primary,
    '--sendbird-dark-background-100': '#a4cafe',
    '--sendbird-dark-background-200': '#a4cafe',
    '--sendbird-dark-onlight-01': 'white',
  };

  const contextValue = useMemo(
    () => ({ colorMode, currentTheme }),
    [colorMode, currentTheme]
  );
  const theme = useMemo(() => createTheme(getMuiTheme()), [mode]);
  const sendBirdAppId = process.env.REACT_APP_SENDBIRD_APP_ID as string;
  const currentUserId = getDetailsFromLocalStorage<{ accountId: string }>(
    'userInfo'
  )?.accountId as string;

  return (
    <SendbirdProvider
      appId={sendBirdAppId}
      userId={currentUserId}
      colorSet={colorSet}
      theme={
        getDetailsFromLocalStorageForString('theme') as
          | 'light'
          | 'dark'
          | undefined
      }
    >
      <StyledEngineProvider injectFirst>
        <ColorModeContext.Provider value={contextValue}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <ToastProvider>
                <AppRouter />
              </ToastProvider>
            </Provider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </StyledEngineProvider>
    </SendbirdProvider>
  );
};

export default App;

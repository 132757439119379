import { AxiosError, HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiRequest } from '../../Api/api';
import MyBookingDetailsComponent from '../../Components/MybookingComponent/MyBookingDetailsComponent';
import ApplicationString from '../../Constants/applicationString';
import { myBookingsDefaultValues } from '../../Constants/commonConst';
import URLS from '../../Constants/urls';
import {
  IMyBookings,
  ImyBookingsReview,
} from '../../Interfaces/common/MyBookings/MyBookings';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/LoginInterfaces';
import {
  ITermsAndCondition,
  IUserSettingsData,
} from '../../Interfaces/Settings/SettingsInterfaces';
import { useToast } from '../../Services/ToastService';
import {
  localStorageEnums,
  myBookingsRatingType,
  myBookingsStatusEnums,
} from '../../Utils/enums';
import { getLocalStorageAccountInfo, showApiError } from '../../Utils/utils';

const MyBookingDetailsContainer: React.FC = () => {
  const toast = useToast();
  const [TermsAndConditions, setTermsAndConditions] = React.useState<
    ITermsAndCondition[]
  >([]);
  const { id: bookingId } = useParams<{ id: string }>();
  const [bookingDetails, setBookingDetails] = React.useState<IMyBookings>(
    myBookingsDefaultValues
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isReviewDetailAvailable, setIsReviewDetailsAvailable] =
    useState<boolean>(false);
  const [reviewId, setReviewId] = useState<number>(0);
  const [reviewStar, setReviewStar] = useState<number>(0);
  const [ratingModal, setRatingModal] = useState<boolean>(false);
  const [isCancelBookingOpen, setIsCancelBookingOpen] =
    useState<boolean>(false);

  const userInfo = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const fetchBookingDetailsById = async (): Promise<void> => {
    // fetch booking details by id
    try {
      setLoading(true);
      const res = await ApiRequest.get(
        URLS.MY_BOOKINGS_DETAILS.replace('#{id}', bookingId ?? '')
      );
      setBookingDetails(res.data);
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Get bookings review on rate modal open
  // Will get review id and know that user gave rating or not, so if user never gave rating post new rating otherwise use review id and make put req and update it.
  const getReviewDetails = async (id: number): Promise<void> => {
    try {
      const res = await ApiRequest.get(
        URLS.MY_BOOKINGS_GET_REVIEW.replace('#{id}', String(id))
      );
      if (res?.data?.data && res.data.data?.length > 0) {
        setIsReviewDetailsAvailable(true);
        const reviewID = res.data.data?.find(
          (review: ImyBookingsReview) =>
            review?.type === myBookingsRatingType &&
            review?.reviewerAccountId === userInfo?.accountId
        );
        setReviewId(reviewID?.id);
        setReviewStar(reviewID?.star);
      } else {
        setIsReviewDetailsAvailable(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  const cancelBookingHandler = async () => {
    try {
      setLoading(true);
      const res = await ApiRequest.put(
        URLS.MY_BOOKINGS_CANCEL.replace('#{id}', String(bookingId)),
        {
          type: myBookingsStatusEnums.cancelled,
        }
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast.success(
          ApplicationString.MyBookingsCancelComponent
            .successfulMsgCancelBookings
        );
        scrollToTop();
        setIsCancelBookingOpen(false);
        fetchBookingDetailsById();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setLoading(false);
    }
  };

  const reviewSubmitHandler = async (rating: number): Promise<void> => {
    let res;
    const payload = {
      type: myBookingsRatingType,
      star: rating,
    };
    try {
      if (isReviewDetailAvailable) {
        res = await ApiRequest.put(
          URLS.MY_BOOKINGS_PUT_REVIEW.replace(
            '#{id}',
            String(bookingId)
          ).replace('#{reviewId}', String(reviewId)),
          payload
        );
      } else {
        res = await ApiRequest.post(
          URLS.MY_BOOKINGS_POST_REVIEW.replace('#{id}', String(bookingId)),
          payload
        );
      }
      if (
        res?.status === HttpStatusCode.NoContent ||
        res?.status === HttpStatusCode.Created
      ) {
        scrollToTop();
        toast.success(ApplicationString.MyBookingsRatingComponent.successMsg);
        setRatingModal(false);
        fetchBookingDetailsById();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  const handleCloseCancelBookingModal = () => {
    setIsCancelBookingOpen(false);
  };

  const handleOpenCancelBookingModal = () => {
    setIsCancelBookingOpen(true);
  };

  useEffect(() => {
    fetchBookingDetailsById();
    const settingsData = getLocalStorageAccountInfo<IUserSettingsData>(
      localStorageEnums.settings
    )?.setting;
    if (settingsData) {
      const { securityDeposit, termsAndCondition } = settingsData;

      // Define titles for "Regular Bookings" and "Short Notice Bookings"
      const regularBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.RegularBookings;
      const shortNoticeBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.ShortNoticeBookings;

      // Extract details for "Regular Bookings"
      const regularBookingsDetails =
        termsAndCondition.find((term) => term.title === regularBookingsTitle)
          ?.details || [];

      // Extract details for "Short Notice Bookings"
      const shortNoticeBookingsDetails =
        termsAndCondition.find(
          (term) => term.title === shortNoticeBookingsTitle
        )?.details || [];

      // Ensure securityDeposit is an array
      const securityDepositDetails = Array.isArray(securityDeposit)
        ? securityDeposit
        : [securityDeposit];

      // Combine details, ensuring they are flat arrays
      const combinedDetails = [
        regularBookingsTitle,
        ...regularBookingsDetails,
        shortNoticeBookingsTitle,
        ...shortNoticeBookingsDetails,
      ];

      // Update terms and conditions
      const updatedTermsAndConditions: ITermsAndCondition[] =
        termsAndCondition.map((term) => {
          if (term.title === regularBookingsTitle) {
            return {
              ...term,
              details: securityDepositDetails,
            };
          }
          if (term.title === shortNoticeBookingsTitle) {
            return {
              ...term,
              details: combinedDetails.flat(), // Flatten the combined details array if it's nested
            };
          }
          return term;
        });

      setTermsAndConditions(updatedTermsAndConditions);
    } else {
      setTermsAndConditions([]);
    }
    scrollToTop();
  }, []);

  return (
    <MyBookingDetailsComponent
      TermsAndConditions={TermsAndConditions}
      bookingDetails={bookingDetails}
      loading={loading}
      getReviewDetails={getReviewDetails}
      reviewSubmitHandler={reviewSubmitHandler}
      ratingModal={ratingModal}
      reviewStar={reviewStar}
      setRatingModal={setRatingModal}
      isCancelBookingOpen={isCancelBookingOpen}
      handleCloseCancelBookingModal={handleCloseCancelBookingModal}
      handleOpenCancelBookingModal={handleOpenCancelBookingModal}
      cancelBookingHandler={cancelBookingHandler}
    />
  );
};

export default MyBookingDetailsContainer;

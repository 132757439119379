import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { VerifiedCardSvg } from '../../../Assets/Svgs';
import ApplicationString from '../../../Constants/applicationString';
import InternalRoute from '../../../Utils/internalRoutes';
import { removeLocalStorageItems } from '../../../Utils/utils';
import { localStorageEnums } from '../../../Utils/enums';

interface SuccessCardProps {
  title: string;
  subTitle: string;
}

const SuccessCard = ({ title, subTitle }: SuccessCardProps): JSX.Element => {
  const navigate = useNavigate();

  const handleDoneClick = () => {
    navigate(InternalRoute.my_listings);
    const localStorageKeys = [
      localStorageEnums.step,
      localStorageEnums.stepsData,
      localStorageEnums.skippedSteps,
    ];
    removeLocalStorageItems(localStorageKeys);
  };
  return (
    <div className="w-100 border bg-card_bg border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E] mt-2 mx-auto max-w-7xl">
      <div className="pt-8 px-6 flex flex-col">
        <div className="flex justify-center">
          <VerifiedCardSvg />
        </div>
        <div className="flex flex-col gap-2 items-center mt-5">
          <h1 className="text-base md:text-2xl font-bold text-primary_text text-center dark:text-dark_primary_text">
            {title}
          </h1>
          <p className="text-secondary_text font-normal text-center text-sm md:text-base dark:text-dark_secondary_text">
            {subTitle}
          </p>
        </div>
        <div className="mt-10 mb-8 flex flex-col gap-3">
          <Button
            variant="contained"
            data-testid="verification-done-button"
            className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
            color="primary"
            onClick={handleDoneClick}
          >
            {ApplicationString.VerifiedCard.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessCard;

import Star from '../Assets/starliveit.png';
import PagesIcon from '../Assets/pages.png';
import BoatIcon from '../Assets/boat.png';
import atvImg from '../Assets/Images/atv-min.png';
import img1 from '../Assets/TopRecommendedImg/img1.png';
import img2 from '../Assets/TopRecommendedImg/img2.png';
import img3 from '../Assets/TopRecommendedImg/img3.png';
import img4 from '../Assets/TopRecommendedImg/img4.png';
import img5 from '../Assets/TopRecommendedImg/img5.png';
import img6 from '../Assets/TopRecommendedImg/img6.png';
import img7 from '../Assets/TopRecommendedImg/img7.png';
import img8 from '../Assets/TopRecommendedImg/img8.png';
import img9 from '../Assets/TopRecommendedImg/img9.png';
import img10 from '../Assets/TopRecommendedImg/img10.png';
import testimonialImg1 from '../Assets/Images/Rectangle 23809 (7).png';
import testimonialImg2 from '../Assets/Images/Rectangle 23809 (9).png';
import testimonialImg3 from '../Assets/Images/Rectangle 23809 (10).png';
import testimonialImg4 from '../Assets/Images/Rectangle 23809 (8).png';
import testimonialImg5 from '../Assets/Images/Rectangle 23809 (11).png';
import testimonialImg6 from '../Assets/Images/Rectangle 23809 (12).png';
import testimonialImg7 from '../Assets/Images/Rectangle 23809 (15).png';
import testimonialImg8 from '../Assets/Images/Rectangle 23809 (14).png';
import testimonialImg9 from '../Assets/Images/Rectangle 23809 (13).png';
import { EditProfileFormEnums, loginSignupFormEnums } from '../Utils/enums';
import InternalRoute from '../Utils/internalRoutes';
import AboutUsCard1 from '../Assets/Images/AboutUsCard1.png';
import AboutUsCard2 from '../Assets/Images/AboutUsCard2.png';
import AboutUsCard3 from '../Assets/Images/AboutUsCard3.png';
import AboutUsCard4 from '../Assets/Images/AboutUsCard4.png';
import MobileAboutUsCard1 from '../Assets/Images/MobileAboutUsCard1.png';
import MobileAboutUsCard2 from '../Assets/Images/MobileAboutUsCard2.png';
import MobileAboutUsCard3 from '../Assets/Images/MobileAboutUsCard3.png';
// import listingImg1 from './../Assets/Frame 6356469 (3).png'
// import listingImg2 from './../Assets/Frame 6356469 (4).png'
// import listingImg3 from './../Assets/Frame 6356469 (5).png'
// import listingImg4 from './../Assets/Rectangle 23809 (16).png'

const ApplicationString = {
  ownerSplashScreen: {
    title: 'LET’S START EARNING',
    imgTitle: {
      img1Title: 'Tell us about your Vehicle',
      img2Title: 'Upload photos of your Vehicle',
      img3Title: 'Set Vehicle bookings Time and Price',
    },
  },
  buttonType: {
    singleClearDate: 'Clear Date',
    multiClearDate: 'Clear Dates',
    close: 'Close',
    clear: 'clear',
    submit: 'Submit',
    reset: 'Reset',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    search: 'Search',
    add: 'Add',
    login: 'Login',
    register: 'Register',
    logout: 'Logout',
    continue: 'Continue',
    viewDetails: 'View Details',
    bookNow: 'Book Now',
    book: 'Book',
    moreDetails: 'More Details',
    contact: 'Contact',
    viewAll: 'View All',
    readMore: 'Read More',
    readLess: 'Read Less',
    apply: 'Apply',
    applyNow: 'Apply Now',
    applyFor: 'Apply for',
    view: 'View',
    viewMore: 'View More',
    viewLess: 'View Less',
    viewAllListings: 'View All Listings',
    viewAllReviews: 'View All Reviews',
  },
  bookingDateRangeData: {
    singleDate: 'Choose Date',
    MultiDate: 'Choose Dates',
  },
  notFoundPage: {
    title: '404',
    subTitle: 'Not Found',
    description1: `Whoops! That page doesn’t exist.`,
    description2: `Sorry about that! Please visit our homepage to get where you need to
      go.`,
    button: 'Back to Homepage',
  },
  notAuthorize: {
    title: '403',
    subTitle: 'You are not authorized',
    description1: `Whoops! You tried to access a page you did not have prior authorization for.`,
    description2: `Sorry about that! Please visit our homepage to get where you need to
      go.`,
    button: 'Back to Homepage',
  },
  CreateVehicleFirstScreen: {
    titleText: 'Create First Listing',
    buttonTitle: ' Create new Listing',
  },
  bookingDetailsData: {
    requestBook: 'Request to Book',
    editLabel: 'Edit',
    bookingDetails: 'Booking Details',
    dates: 'Dates',
    specialRequest: 'Special Request',
    addListingDescription: 'Add listing description',
    wordsMax: '255 words max',
    confirmAndPay: 'Confirm & Pay',
    confirmAndPayLabel: 'Review & Complete Payment',
    paymentDetails: 'Payment Details',
    days: 'day',
    hr: 'hr',
    securityDeposit: 'Security Deposit',
    taxes: 'Taxes',
    total: 'Total',
    vehicleRate: 'Vehicle Rate',
  },
  timeSlotData: {
    label: 'Availability',
    slots: {
      hr1: '1hr Slots',
      hr12: '12hr Slots',
    },
  },
  vehicleBookingStaticData: {
    error: {
      time: 'Select time',
      date: 'Select date',
    },
    bookingDetailsData: {
      dates: 'Select Dates',
      date: 'Select Date',
      confirmAndPay: 'Confirm and Pay',
      bookNow: 'Book Now',
      save: 'Save',
    },
    rates: {
      perHour: '/ per hour',
      per24Hour: '/ per day',
      per12Hour: '/ 12 hours',
    },
    bookingDuration: {
      singleDay: 'Single Day',
      multiDay: 'Multi Day',
    },
    placeholders: {
      calendarIconAlt: 'Calendar Icon',
    },
    timeSlots: {
      timeSlot1: '06:00 AM to 6:00 PM',
      timeSlot2: '08:00 AM to 8:00 PM',
      timeSlot3: '10:00 AM to 10:00 PM',
    },
    errors: {
      selectDateAndTime:
        'Please select both dates and times before proceeding.',
    },
  },
  timeDropdownData: {
    pickUpLabel: 'Pick up Time',
    dropOffLabel: 'Drop off Time',
    selectPickUp: 'Select Time',
    selectDropOff: 'Select Time',
    editLabel: 'Edit',
  },
  passengerDropdown: {
    passengers: 'Passenger',
    edit: 'Edit',
    choosePassengers: 'Choose Passengers',
    noOfPassengers: 'No. of Passengers',
    close: 'Close',
  },
  headerLinks: {
    home: {
      key: 'home',
      label: 'Home',
      route: 'InternalRoute.Home',
    },
    explore: {
      key: 'explore',
      label: 'Explore',
      children: {
        key: 'allVehicles',
        label: 'All Vehicles',
        route: `${InternalRoute.search}?category=allVehicles`,
      },
    },
    aboutUs: {
      key: 'aboutUs',
      label: 'About Us',
    },
    contact: {
      key: 'contact',
      label: 'Contact Us',
    },
    faq: {
      key: 'faq',
      label: 'FAQs',
    },
  },
  ownerHeaderLinks: {
    Dashboard: {
      key: 'dashboard',
      label: 'Dashboard',
      route: 'InternalRoute.dashboard',
    },
    Calendar: {
      key: 'calendar',
      label: 'Calendar',
      route: 'InternalRoute.OwnerMyCalendar',
    },
    CreateListing: {
      key: 'createListing',
      label: 'Create a listing',
      route: 'InternalRoute.myListings',
    },
    MyListings: {
      key: 'myListings',
      label: 'My Listings',
      route: 'InternalRoute.myListings',
    },
    ContactUs: {
      key: '/owner/contact',
      label: 'Contact Us',
      route: 'InternalRoute.contact',
    },
    FAQs: {
      key: 'faqs',
      label: 'FAQs',
      route: 'InternalRoute.faqs',
    },
  },
  bgLayoutInfo: {
    createMem: 'Create Mem',
    riesOn: 'ries on',
    landAndWater: 'Land and Water',
    description: 'Discover easy rentals on land and water',
  },
  dummyUserName: 'U',
  myProfileText: 'My Profile',
  userProfileInfo: {
    myBooking: {
      key: 'myBookings',
      label: 'My Bookings',
    },
    switchToOwner: {
      key: 'switchToOwner',
      label: 'Switch to Owner',
    },
    switchToUser: {
      key: 'switctToUser',
      label: 'Switch to User',
    },
    darkToggle: {
      key: 'darkToggle',
      label: 'Toggle Theme',
    },
    logOut: {
      key: 'logout',
      label: 'Log Out',
    },
    myAccount: {
      key: 'myAccount',
      label: 'My Account',
    },
    deleteMyAccount: {
      key: 'deleteMyAccount',
      label: 'Delete My Account',
    },
  },
  header: {
    button: {
      rentVehicle: 'Rent Vehicle',
      listMyVehicle: 'List My Vehicle',
      listVehivle: 'List Vehicle',
      signup: 'Sign Up',
      login: 'Log In',
      createListing: 'Create a Listing',
      Owner: 'Become an Owner',
    },
  },
  searchLabels: {
    placeHolder: 'Search location',
    location: 'Location',
    date: 'Date',
    category: 'Category',
    vehicleCategory: 'Vehicle Category',
    search: 'Search',
    reset: 'Reset',
  },
  commonButton: {
    search: 'Search',
  },
  exploreSection: {
    vehicleCategory: {
      landVehicle: 'Land Vehicle',
      waterCraft: 'Watercraft',
    },
  },
  footer: {
    text: {
      connectWithUs: 'Connect with us',
      downloadOnlifeApp: 'Download onelife app now!',
      privacy: 'Privacy',
      terms: 'Terms & Conditions',
    },
  },
  categoryOptions: [
    {
      label: 'Land Vehicle',
      value: 'land Vehicles',
    },
    {
      label: 'Watercraft',
      value: 'watercraft',
    },
    {
      label: 'All Vehicles',
      value: 'allVehicles',
    },
  ],
  bookingAccordianData: [
    {
      id: 1,
      question: 'Security deposit',
      answer: `<ul class="list-disc pl-5">
      <li >The deposit will be collected from the renter 48 hours before the adventure begins.</li>
      <li >The deposit amount will be either $250 or 10% of the renting rate, whichever is higher.</li>
    </ul>`,
    },
    {
      id: 2,
      question: 'Cancellation policy',
      answer: `<ul class="list-disc pl-5">
      <li >If a renter cancels the booking 14 days before the start of an adventure, they receive a full refund.</li>
      <li >If the renter cancels between 14 days to 48 hours of the start of an adventure, they receive a 50% refund.</li>
      <li >If the renter cancels in less than 48 hours before the start of an adventure, they receive no refund.</li>
    </ul>`,
    },
  ],
  accordion: {
    title: 'Frequently Asked Questions',
    description:
      'Explore our well-kept boats, from yachts to pontoons, for an enhanced maritime adventure.',
    accordionData: [
      {
        id: 1,
        question: 'How much does it cost to rent?',
        answer: 'Click “book now” to show the most up-to-date pricing!',
      },
      {
        id: 2,
        question: 'How do I book?',
        answer: 'You can book directly on our website 24/7!',
      },
      {
        id: 3,
        question: 'Do I need to book in advance?',
        answer:
          'Yes! We tend to book out a few days in advance, so reservation slots are available. You can see our availability in our booking system.',
      },
      {
        id: 4,
        question: 'What is short notice booking or instant booking?',
        answer:
          'The bookings that are to begin within less than 48 hours of the actual booking start time are considered Instant Bookings. In this type of booking, the renter will have to wait for the vehicle owner’s approval and only after that, the booking will be confirmed. If the booking is not accepted by the owner within two hours of submitting a booking request, it will automatically be canceled.',
      },
      {
        id: 5,
        question: 'How is the security deposit calculated?',
        answer:
          'The renter will collect the deposit 48 hours before the adventure begins. The deposit amount will be either $250 or 10% of the rental rate.',
      },
      {
        id: 6,
        question: 'What is your cancellation policy?',
        answer: `
        <div>
        <p><strong>Regular Bookings:</strong></p>
        <ul class="list-disc pl-5">
          <li >If a renter cancels the booking 14 days before the start of an adventure, they receive a full refund.</li>
          <li >If the renter cancels between 14 days to 48 hours of the start of an adventure, they receive a 50% refund.</li>
          <li >If the renter cancels in less than 48 hours before the start of an adventure, they receive no refund.</li>
        </ul>
        <p class="mt-2"><strong>Instant Bookings:</strong></p>
        <ul class="list-disc pl-5">
          <li >If the vehicle is booked and the adventure is to begin in less than 48 hours from the time of booking, the owner is notified of the booking. The owner will have 2 hours to accept the booking.</li>
          <li >If the owner accepts the short-notice booking, the renter will be notified of the confirmed booking.</li>
          <li >If the owner fails to accept/reject the booking in 2 hours, then the booking will be canceled automatically.</li>
        </ul>
      </div>
`,
      },
    ],
  },

  userlistingCard: {
    backgroundImage:
      'https://images.unsplash.com/photo-1628104079324-7ac5295c7619?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Do you want to list your own Vehicle?',
    description:
      'Onelife is a community of outdoor enthusiasts who use our sharing platform to offset the cost of ownership. Join us today!',
    buttonText: 'Create My Listing Now',
  },
  OwnerlistingCard: {
    backgroundImage:
      'https://images.unsplash.com/photo-1628104079324-7ac5295c7619?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: ' Do you want to list your own Vehicle?',
    description:
      'Onelife is a community of outdoor enthusiasts who use our sharing platform to offset the cost of ownership. Join us today!',
    buttonText: 'Create My Listing Now',
  },
  newsletterForm: {
    backgroundImage:
      'https://images.unsplash.com/photo-1565733293285-77aa342b22dd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Subscribe to Newsletter',
    description: 'Stay in the loop',
    buttonText: 'Subscribe',
    subscribedText: 'Subscribed',
    formDiscription: 'Please fill the subscribe form',
    inputLabel: {
      email: 'Email :',
      name: 'Name :',
    },
    inputTitle: {
      email: 'Please enter a valid email address',
      name: 'Please enter your name',
    },
    placeHolder: {
      email: 'Enter your email',
      name: 'Enter your name',
    },
    subscribedCard: 'Subscribed!',
    subscribedCardText: {
      p1: 'Congratulations! You have officially joined our email',
      p2: 'newsletter subscription list.',
    },
    cardButtonText: 'Done',
    invalidNameFieldText: 'Please enter your name',
    subscribingLoader: 'Subscribing...',
    successMessage: 'Thank you for subscribing to onelife newsletter!!',
    validationMessage: {
      requiredMsgs: {
        email: 'Email is required',
      },
      inValidMsgs: {
        fullName: 'Please enter a valid name',
      },
    },
  },
  mobileSection: {
    title: 'Download Our Mobile App',
    description: 'Use OneLife’s mobile app to get an amazing experience...',
  },
  featureCards: {
    buttonTitle: 'Book My Ride Now',
    title: 'Let’s go!',
    description:
      'Simple booking process for your desired watercraft or land vehicle.',
    featureData: [
      {
        icon: BoatIcon,
        title: 'Explore it',
        description:
          'Effortlessly explore a wide range of vehicles on land and water with a swift and user-friendly search through our comprehensive listings.',
      },
      {
        icon: PagesIcon,
        title: 'Book it',
        description:
          'Secure instant bliss on the waves with our swift and hassle-free Vehicle booking service – your land & Aquatic adventure is just a click away.',
      },
      {
        icon: Star,
        title: 'Live it',
        description:
          'Indulge in the best of both worlds as you savor the thrilling experiences of land and watercraft adventures.',
      },
    ],
  },

  userHeroSection: {
    image: atvImg,
    title: 'Earn Money with onelife',
    subtitle: 'List Your Vehicle Now',
    description:
      'Discover easy rentals for both land and water trips, ensuring a memorable journey with us',
  },
  priceCard: {
    title: 'Earn on OneLife as an Owner',
    description:
      'Discover stunning landscapes and vibrant culture. Immerse yourself in rich history and unforgettable experiences.',
    buttonText: 'List My Vehicle',
    cards: [
      {
        color: 'blue',
        amount: '$~299+',
        description: 'Avg. Earning on 1 Vehicle Per Day',
      },
      {
        color: 'slate',
        amount: '$~1000+',
        description: 'Avg. Earning on 5 Vehicle Per Day',
      },
      {
        color: 'green',
        amount: '$~5000+',
        description: 'Avg. Earning on 10 Vehicle Per Day',
      },
    ],
  },
  ownerHeroSection: {
    title: 'Create MemOOries on Land and Water',
    subtitle: 'Rent Your Ride Now',
    description:
      'Discover easy rentals for both land and water trips, ensuring a memorable journey with us',
  },
  popularCategories: {
    heading: {
      title: 'Popular Categories',
      description:
        'Discover stunning landscapes and vibrant culture. Immerse yourself in rich history and unforgettable experiences.',
    },
    categories: [
      {
        id: '1',
        title: 'ATV',
        imageSrc:
          'https://images.unsplash.com/photo-1628104079324-7ac5295c7619?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: '2',
        title: 'UTV',
        imageSrc:
          'https://images.unsplash.com/photo-1705571136100-19f1c0f1e69d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: '3',
        title: 'Yacht',
        imageSrc:
          'https://images.unsplash.com/photo-1567899378494-47b22a2ae96a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: '4',
        title: 'HouseBoat',
        imageSrc:
          'https://images.unsplash.com/photo-1634141693341-9d51836aa188?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
    ],
  },
  testimonials: {
    heading: {
      title: 'What People Says About Onelife',
      description:
        'Explore our well-kept boats, from yachts to pontoons, for an enhanced maritime adventure.',
    },
    testimonials: [
      {
        quote:
          'Had an unforgettable day on one of their boats. Clean, well-maintained, and the staff was incredibly helpful.',
        author: 'Theresa Webb',
        position: 'Chief Marketing Officer',
        image: testimonialImg1,
      },

      {
        quote:
          'Booking a boat with this fleet was the highlight of our vacation. The options available were impressive, catering to every preference and budget. From sleek yachts to spacious pontoons, we found the perfect vessel for our family outing. The entire process, from reservation to return, was seamless and stress-free. Will definitely be recommending this service to friends and family.',
        author: 'Darrell Steward',
        position: 'Business Analyst',
        image: testimonialImg4,
      },

      {
        quote:
          'Booking a boat with this fleet was the highlight of our vacation. ',
        author: 'Courtney Henry',
        position: 'Chief Marketing Officer',
        image: testimonialImg7,
      },
      {
        quote: `Our maritime adventure exceeded all expectations! The fleet offered a diverse range of boats, ensuring an enjoyable experience for our group. The customer service was exceptional, with staff going above and beyond to accommodate our needs. Highly satisfied and eagerly looking forward to our next excursion.`,
        author: 'Ronald Richards',
        position: 'Trainee Engineer',
        image: testimonialImg2,
      },

      {
        quote: `From start to finish, everything was seamless. The boats were immaculate, and the booking process was hassle-free. Can't wait to return for another excursion.`,
        author: 'Kristin Watson',
        position: 'Technical Specialist',
        image: testimonialImg5,
      },
      {
        quote: `Absolutely loved our experience with the fleet! The boats were in pristine condition, and we had a blast cruising the waters. The attention to detail was evident in every aspect, from the cleanliness of the vessels to the professionalism of the staff.`,
        author: 'Ralph Edwards',
        position: 'Chief Marketing Officer',
        image: testimonialImg8,
      },
      {
        quote: `Highly impressed with the quality of boats and service provided by this fleet. Whether you're a seasoned sailor or a first-time boater, they have something for everyone. Can't wait to return for another adventure.`,
        author: 'Cameron Williamson',
        position: 'Program Manager',
        image: testimonialImg3,
      },
      {
        quote:
          'Booking a boat with this fleet was the highlight of our vacation. The options available were impressive, catering to every preference and budget. From sleek yachts to spacious pontoons, we found the perfect vessel for our family outing. The entire process, from reservation to return, was seamless and stress-free. Will definitely be recommending this service to friends and family.',
        author: 'Kathryn Murphy',
        position: 'System Analyst',
        image: testimonialImg6,
      },

      {
        quote:
          'Absolutely loved our experience with the fleet! The boats were in pristine condition, and we had a blast cruising the waters. Highly recommend.',
        author: 'Devon Lane',
        position: 'CEO',
        image: testimonialImg9,
      },
    ],
  },
  destinations: {
    heading: {
      title: 'Top Recommended Destinations',
      description: 'Create the moments that become memories.',
    },
    unitData: {
      maxRating: '5',
      Vehicles: 'Vehicles',
    },
    destinationData: [
      {
        imageUrl: img10,
        title: 'Ahmedabad',
        searchText: 'Ahmedabad',
        vehicleCount: 30,
        rating: 4.9,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img4,
        vehicleCount: 25,
        rating: 4.8,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img5,
        title: 'Fort Wayne Indiana',
        searchText: 'fortWayneIndiana',
        vehicleCount: 20,
        rating: 4.7,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img3,
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img1,
        title: 'Fresno California',
        searchText: 'fresnoCalifornia',
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img6,
        title: 'Florida',
        searchText: 'Florida',
        vehicleCount: 25,
        rating: 4.8,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img7,
        vehicleCount: 20,
        rating: 4.7,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img8,
        title: 'Fayetteville North Carolina',
        searchText: 'fayettevilleNorthCarolina',
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img2,
        title: 'Fontana California',
        searchText: 'fontanaCalifornia',
        vehicleCount: 30,
        rating: 4.9,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img9,
        vehicleCount: 25,
        rating: 4.8,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img1,
        title: 'Florida State',
        searchText: 'floridaState',
        vehicleCount: 20,
        rating: 4.7,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img3,
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
    ],
  },
  listingNearMe: {
    heading: {
      title: 'Listing Near Me',
    },
    listingData: [
      {
        id: 1,
        name: 'Jet Ski',
        thumbPath: 'https://onelife.blobstation.dev/assets/Jet_ski.jpeg',
        vehicleType: 'Watercraft',
        location: 'New Jersey,45463',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per night',
        imageUrl:
          'https://images.unsplash.com/photo-1623251349546-9fc522667b71?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 3,
        name: 'Pontoon',
        thumbPath: 'https://onelife.blobstation.dev/assets/phontoon_boat.jpg',
        vehicleType: 'Watercraft',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1677354135616-5dd7a4a4a438?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Ym9hdHxlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 4,
        name: 'Fishing',
        thumbPath: 'https://onelife.blobstation.dev/assets/Fishing_boat.jpg',
        vehicleType: 'Land vehicle',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://images.unsplash.com/photo-1511316695145-4992006ffddb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fGNydXNlJTIwc2hpcHxlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 5,
        name: 'Bowrider',
        thumbPath: 'https://onelife.blobstation.dev/assets/Bowrider.jpg',
        vehicleType: 'Watercraft',
        location: 'New Jersey,45463',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per night',
        imageUrl:
          'https://images.unsplash.com/photo-1623251349546-9fc522667b71?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 6,
        name: 'Catamaran',
        thumbPath: 'https://onelife.blobstation.dev/assets/Catamaran.jpg',
        vehicleType: 'Land vehicle',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1683742899898-e39333fe4b28?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTI1fHxiaWtlc3xlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 7,
        name: 'Cuddy Cabin',
        thumbPath: 'https://onelife.blobstation.dev/assets/Cuddy_cabin.jpg',
        vehicleType: 'Watercraft',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1677354135616-5dd7a4a4a438?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Ym9hdHxlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 8,
        name: 'Yacht',
        thumbPath: 'https://onelife.blobstation.dev/assets/Yacht.jpg',
        vehicleType: 'Land vehicle',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://images.unsplash.com/photo-1511316695145-4992006ffddb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fGNydXNlJTIwc2hpcHxlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 9,
        name: 'Houseboat',
        thumbPath: 'https://onelife.blobstation.dev/assets/Houseboat.jpg',
        vehicleType: 'Watercraft',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1623251349546-9fc522667b71?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 10,
        name: 'Sailboat',
        thumbPath: 'https://onelife.blobstation.dev/assets/Sailboat.jpg',
        vehicleType: 'Land vehicle',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1683742899898-e39333fe4b28?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTI1fHxiaWtlc3xlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 11,
        name: 'Speedboat/offshore',
        thumbPath: 'https://onelife.blobstation.dev/assets/Speedboat.jpg',
        vehicleType: 'Watercraft',
        location: 'New Jersey',
        amenities: '25+ Amenities',
        rating: '4.9/5',
        price: '$ 249.99',
        priceChargesType: '/ per day',
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1677354135616-5dd7a4a4a438?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Ym9hdHxlbnwwfHwwfHx8MA%3D%3D',
      },
    ],
  },
  ownerListing: {
    heading: {
      title: 'Popular Listings',
      description:
        'Discover stunning landscapes and vibrant culture. Immerse yourself in rich history and unforgettable experiences.',
    },
    unitData: {
      cabins: 'cabins',
      personCapacity: 'person capacity',
      length: 'ft length',
      hour: 'per hour',
      currency: '$',
    },
    listingData: [
      {
        id: 1,
        name: 'eBikes',
        location: 'New Jersey',
        cabins: 6,
        capacity: 12,
        length: 24,
        description:
          'With modern amenities, spacious interiors, and panoramic views, this boat offers an unforgettable aquatic experience.',
        price: 249.99,
        imageUrl: 'https://onelife.blobstation.dev/assets/eBikes.jpg',
      },
      {
        id: 2,
        name: 'Snowmobiles',
        location: 'California',
        cabins: 4,
        capacity: 8,
        length: 20,
        description:
          'Explore the beauty of the ocean with this luxurious boat equipped with state-of-the-art facilities.',
        price: 199.99,
        imageUrl: 'https://onelife.blobstation.dev/assets/Snowmobiles.jpg', // Replace with actual URL
      },
      {
        id: 3,
        name: `ATV's`,
        location: 'Florida',
        cabins: 5,
        capacity: 10,
        length: 22,
        description:
          'Enjoy your sailing experience with comfort and elegance on this magnificent yacht.',
        price: 299.99,
        imageUrl: 'https://onelife.blobstation.dev/assets/ATV.jpg', // Replace with actual URL
      },
      {
        id: 4,
        name: 'Snowbikes',
        location: 'NY',
        cabins: 0, // Adjust as needed for your data
        capacity: 1, // Adjust as needed for your data
        length: 0, // Adjust as needed for your data
        description:
          'Explore the city or countryside on these efficient and eco-friendly electric bikes.',
        price: 49.99, // Adjust as needed for your data
        imageUrl: 'https://onelife.blobstation.dev/assets/Snowbikes.jpg', // Replace with actual URL
      },
      // Add more listings from subcategory as needed
    ],
  },
  contactUs: {
    faqTitle: 'FAQs',
    lable: 'Contact Us',
    details: {
      p1: 'Getting in touch with Onelife is really easy now. You can call us',
      p2: ' or send us an email, and we will respond to you as quickly as we',
      p3: ' can!',
    },
    contactUsDetails: {
      emailLable: 'Email',
      email: 'extherhoward@gmail.com',
      phoneLable: 'Phone',
      phone: '1-333-345-6868',
      addressLable: 'Office',
      address: '8502 Preston Rd. Inglewood, Maine 98380',
    },
    fields: {
      nameLable: 'Full Name',
      nameTitle: 'Please enter a Full Name',
      emailLable: 'Email Address',
      emailTitle: 'Please enter your valid Email',
      messageLable: 'Message',
      messageTitle: 'Please enter a message',
      uploadAttachment: 'Upload an Attachment',
    },
    buttons: {
      clear: 'Clear Form',
      submit: 'Submit',
      submitting: 'Submitting...',
    },
    invalidNameFieldText: 'Please enter your name',
    validationMessages: {
      requiredMsgs: {
        fullName: 'Full name is required',
        email: 'Email is required',
      },
      formatMsgs: {
        fullName: 'Please enter a valid name',
      },
      fileMsgs: {
        format: 'File format not supported',
        size: 'File size too large',
      },
    },
    supportedFileMsg:
      '*Only PDF, JPEG, JPG and PNG Files Allowed. Maximum File size is 5 MB.',
    successMessage:
      'Thank you for contacting us. we will get back to you shortly',
  },
  loginWithPhone: {
    title: {
      mainTitle: `Let's Get Started!`,
      subTitle:
        'Please enter your country code with your phone number and password.',
    },
    label: {
      code: 'Code',
      phone: 'Phone Number',
      password: 'Password',
    },
    links: {
      forgotPassword: 'Forgot password?',
      signUp: 'Sign Up',
    },
    buttons: {
      login: 'Log In',
      loggingIn: 'Logging in...',
      continueWithEmail: 'Continue with Email',
    },
    otherText: {
      dontHaveAccount: `Don't have an account?`,
      or: 'or',
    },
    validationMessages: {
      requiredMsgs: {
        phone: 'Country code & Phone number is required',
        password: 'Password is required',
        code: 'Country code is required',
      },
      invalidMsgs: {
        maxPhoneNumberLength: `Phone number should be less than ${loginSignupFormEnums.maxPhoneNumberLength} digits`,
        minPhoneNumberLength: `Phone number should be ${loginSignupFormEnums.maxPhoneNumberLength} digits`,
        maxPasswordLength: `Password should be less than ${loginSignupFormEnums.maxPasswordLength} characters`,
        invalidPhoneOrPassword: 'Incorrect phone number or password.',
      },
    },
  },
  loginWithEmail: {
    title: {
      mainTitle: `Let's Get Started!`,
      subTitle: 'Enter your email address and password.',
    },
    label: {
      email: 'Email Address',
      password: 'Password',
    },
    links: {
      forgotPassword: 'Forgot password?',
      signUp: 'Sign Up',
    },
    buttons: {
      login: 'Log In',
      loggingIn: 'Logging in...',
      continueWithPhone: 'Continue with Phone',
    },
    otherText: {
      dontHaveAccount: `Don't have an account?`,
      or: 'or',
    },
    validationMessages: {
      requiredMsgs: {
        email: 'Email address is required',
        password: 'Password is required',
      },
      invalidMsgs: {
        invalidEmail: 'Enter a valid email address',
        maxPasswordLength: `Password should be less than ${loginSignupFormEnums.maxPasswordLength} characters`,
        maxEmailLength: `Email should be less than ${loginSignupFormEnums.maxEmailLength} characters`,
        invalidEmailOrPassword: 'Incorrect email or password.',
      },
    },
  },
  signUpWithEmail: {
    title: {
      mainTitle: 'Sign Up',
      subTitle:
        'Please enter your email address, and we will send you a confirmation code there.',
    },
    label: {
      email: 'Email ID',
    },
    button: {
      SignUp: 'Sign Up',
      verifyingEmail: 'Signing Up...',
      continueWithPhone: 'Continue with Phone',
    },
    validationMessages: {
      requiredMsgs: {
        email: 'Email address is required',
      },
      invalidMsgs: {
        invalidEmail: 'Enter a valid email address',
        maxLengthEmail: 'Email must be less than 255 characters',
        alreadyExistEmail: 'Email already exists',
        alreadyExistMobile: 'Mobile number already exists',
      },
    },
    otherText: {
      haveAccount: 'Already have an account?',
      or: 'or',
    },
    links: {
      login: 'Log In',
    },
  },
  signUpWithPhone: {
    title: {
      mainTitle: 'Sign Up',
      subTitle:
        'Please enter your country code and phone number, and we will send you a verification code there.',
    },
    label: {
      code: 'Code',
      phone: 'Phone Number',
    },
    button: {
      signUp: 'Sign Up',
      verifyingPhone: 'Signing Up...',
      continueWithEmail: 'Continue with Email',
    },
    otherText: {
      haveAccount: 'Already have an account?',
      or: 'or',
    },
    links: {
      login: 'Log In',
    },
    validationMessages: {
      alreadyExists: 'Phone number already exists',
      invalid: 'Invalid phone number',
    },
  },
  API: {
    refreshToken: {
      error: 'Invalid response from server',
    },
  },
  otpScreen: {
    email: {
      title: 'Enter Code',
      subTitle: "We've sent a verification code to your email address.",
    },
    phone: {
      title: 'Enter Code',
      subTitle:
        "We've sent an SMS with a verification code to your phone number.",
    },
    button: {
      verify: 'Verify',
      verifying: 'Verifying...',
    },
    didNotReceiveCode: 'I didn’t receive the code',
    resendCode: 'Resend it',
    sendCodeAgain: 'Send code again',
    validationMessages: {
      invalidMsgs: {
        minOtpLength: 'Code must be 6 digits',
        wrongCode: 'Wrong code, please try again',
      },
      requiredMsgs: {
        code: 'Code is required',
      },
    },
  },
  VerifiedCard: {
    email: {
      title: 'Email Verified',
      subTitle: 'Your email has been verified successfully.',
    },
    phone: {
      title: 'Number Verified',
      subTitle: 'Your phone number has been verified successfully.',
    },
    AccountCreated: {
      title: 'Account Created',
      subTitle: 'Your account has been created successfully.',
    },
    buttonText: 'Done',
  },
  AccountCreatedCard: {
    title: 'Account Created',
    subTitle: 'Your account has been created successfully.',
    buttonText: 'Done',
  },
  searchScreen: {
    categories: {
      watercraft: 'watercraft',
      landVehicles: 'landvehicles',
    },
    queryParams: {
      sortDirection: {
        ascending: 'asc',
        descending: 'desc',
      },
      sortBy: 'searchPrice',
    },
    buttonLabels: {
      filter: 'Filter By',
      sort: 'Sort By',
      pagination: {
        previous: 'Previous',
        next: 'Next',
      },
    },
    labels: {
      filterby: 'Filter By:',
      clear_filters: 'Clear Filters',
      clear: 'Clear',
      apply: 'Apply',
      type_of_vehicles: 'Type of Vehicle',
      search_radius: 'Search Radius',
      available_vehicles: 'Available Vehicles:',
      sort_by: 'Sort By:',
      no_data: ' No data available',
      ft: 'ft',
      upto: 'Up to',
      person: 'Person',
      book_now: 'Book Now',
      bookingValues: {
        hourly: 'per hour',
        twelve_hourly: '12 hours',
        twelve_hourly_preview: 'per 12-hour',
        twentyFour_hourly: 'per day',
        maxDays: 'days',
        yes: 'Yes',
        no: 'No',
        hours: 'hours',
      },
      menuItems: {
        low_to_high: 'Price: Low to High',
        high_to_low: 'Price: High to Low',
      },
    },
  },
  exploreScreen: {
    bookingValues: {
      hourly: '/ per hour',
      twelve_hourly: '/ 12 hours',
      twentyFour_hourly: '/ per day',
    },
    exploreLabels: {
      toggleImageLabels: {
        waterCraft: 'Rent Your Watercraft',
        landVehicle: 'Rent Your Land Vehicle',
      },
      amenitiesCount: 'Amenities',
    },
  },
  NameAndPasswordSignUp: {
    title: 'Name and Password',
    subTitle: `Please enter your name and Create a password with a minimum of <span class="text-primary">6 characters</span>, including <span class="text-primary">a special character</span>, <span class="text-primary">a capital letter</span>, and <span class="text-primary">a digit.</span> `,
    label: {
      name: 'Name',
      createPassword: 'Create Password',
      confirmPassword: 'Confirm Password',
    },
    passwordStrength: {
      title: 'Password strength:',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      excellent: 'Excellent',
    },
    validationMsgs: {
      requiredMsgs: {
        name: 'Name is required',
        password: 'Password is required',
        confirmPassword: 'Confirm Password is required',
      },
      invalidMsgs: {
        password: 'Invalid password',
        confirmPassword: 'Passwords do not match',
        passwordLength: `Password should be less than ${loginSignupFormEnums.maxPasswordLength} characters`,
        nameLength: `Name should be less than ${loginSignupFormEnums.maxTextLength} characters`,
      },
    },
  },
  ForgotPasword: {
    title: 'Forgot Password',
    titleResetPasswordForm: 'Reset Password',
    subTitleResetPasswordForm: `Please enter your name and Create a password with a minimum of <span class="text-primary">6 characters</span>, including <span class="text-primary">a special character</span>, <span class="text-primary">a capital letter</span>, and <span class="text-primary">a digit.</span> `,
    subTitle:
      'Please enter your email address, and we will send a verification code there.',
    label: {
      emailId: 'Email ID',
      createNewPassword: 'Create New Password',
      confirmPassword: 'Confirm Password',
    },
    button: {
      continue: 'Continue',
      verify: 'Verify',
      changingPassword: 'Changing Password...',
      verifying: 'Verifying...',
      resetPassword: 'Reset Password',
    },
    passwordStrength: {
      title: 'Password strength:',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      excellent: 'Excellent',
    },
    validationMsgs: {
      requiredMsgs: {
        password: 'Password is required',
        confirmPassword: 'Confirm Password is required',
      },
      invalidMsgs: {
        phoneNumberDoesNotExist: 'Phone number does not exist',
        emailDoesNotExist: 'Email does not exist',
        password: 'Invalid password',
        confirmPassword: 'Passwords do not match',
        passwordLength: `Password should be less than ${loginSignupFormEnums.maxPasswordLength} characters`,
      },
    },
    SuccessCard: {
      title: 'Password Changed',
      subTitle: 'Your password has been successfully changed.',
      buttonText: 'Done',
    },
  },
  commonInvalidMsg: {
    somethingWentWrong: 'Something went wrong, Please try again',
  },

  vehicleDetails: {
    ButtonText: {
      viewMore: 'View More',
      viewAll: 'View All',
      showLess: 'View Less',
      back: 'Back to details',
    },
    typoGraphyText: {
      guest: 'passenger',
      guests: 'Passengers',
      length: 'length',
      size: 'ft',
      upto: 'Upto',
      people: 'People',
      About: 'About',
      ownedBy: 'Owned by',
      JoinedIn: 'Joined in',
      rating: 'Rating',
      amenities: 'Amenities',
      details: 'Specifications',
      Manufacturer: 'Manufacturer',
      year: 'Year',
      Model: 'Model',
      hitchSize: 'Hitch Size',
      engineSize: 'Engine Size',
      isTrailerIncluded: 'Trailer Included',
      lengthCaps: 'Length',
      capacity: ' Guest Capacity',
      person: 'Person',
      restrictions: 'Restrictions',
    },
  },
  MyCalendarStatusBar: {
    notAvailable: 'Not Available',
    approved: 'Approved Bookings',
  },
  MyBookingsListing: {
    title: 'My Bookings',
    noData: 'No Bookings Found',
    bookingButtons: {
      cancelled: 'Cancelled',
      rejected: 'Rejected',
      completed: 'Completed',
      requested: 'Requested',
      pendingApproval: 'Pending Approval',
      rating: 'Give Rating',
      active: 'Active',
      bookAgain: 'Book Again',
      chat: 'Chat',
    },
    otherText: {
      perHour: 'per hour',
      per12Hour: 'per 12 hour',
      per24Hour: 'per 24 hour',
      filterBy: 'Filter By:',
    },
  },
  MyBookingsRatingComponent: {
    title: 'Rate Experience',
    confirmationCode: 'Confirmation Code',
    submitButton: 'Submit Rating',
    successMsg: 'Rating submitted successfully',
    errorMsg: 'You cannot submit a rating for your own vehicle.',
  },
  CreateBookingComponent: {
    successMsg: 'Booking created successfully',
  },
  MyBookingsCancelComponent: {
    title: 'Cancel Booking',
    subTitle: 'Are you sure you want to cancel booking?',
    cancelBookingBtn: 'Cancel Booking',
    goBackBtn: 'Go Back',
    cancelBtn: 'Cancel',
    successfulMsgCancelBookings: 'Booking cancelled successfully',
    cancelingBooking: 'Canceling Booking...',
  },
  MyBookingDetailsComponent: {
    RegularBookings: 'Regular bookings:',
    ShortNoticeBookings: 'Short notice bookings:',
    goBackToBookingDetails: 'Booking Details',
    pickup: 'Pick up',
    dropOff: 'Drop off',
    confirmationCode: 'Confirmation Code',
    specialRequest: 'Special Request',
    paymentDetails: 'Payment Details',
    rate: 'Rate',
    securityDeposit: 'Security Deposit',
    tax: 'Tax',
    total: 'Total',
    ownedBy: 'Owned By',

    buttons: {
      cancelled: 'Cancelled',
      cancelBooking: 'Cancel Booking',
      rejected: 'Rejected',
      completed: 'Completed',
      pendingApproval: 'Pending Approval',
      rating: 'Give Rating',
      active: 'Active',
      bookAgain: 'Book Again',
      chat: 'Chat',
      bookingRejected: 'Booking Rejected',
      bookingCancelled: 'Booking Cancelled',
      help: 'Help',
    },
    errorMessage: {
      starError: 'Star should not be empty',
    },
  },
  commonStrings: {
    notAvailable: 'N/A',
    noData: 'No Data Found',
    noImageFound: 'No Image Found',
    somethingWentWrong: 'Something Went Wrong!',
  },
  aboutUs: {
    title: 'About Us',
    cardsDetails: [
      {
        title: 'Our Story',
        imgSrc: AboutUsCard1,
        mobileImgSrc: MobileAboutUsCard1,
        description:
          "At Onelife, we're dedicated to unleashing the thrill of exploration and adventure. Our story began with a simple yet profound idea: to provide a platform where individuals can seamlessly book and rent land vehicles and watercraft, opening doors to endless adventures on both land and sea.",
        description2:
          'Our journey commenced with a group of passionate outdoor enthusiasts who felt the need to bridge the gap between people and their desire for adventure.',
      },
      {
        title: 'Our Mission',
        imgSrc: AboutUsCard2,
        mobileImgSrc: MobileAboutUsCard2,
        description:
          'Our mission is to offer a seamless platform where users can easily book and rent land vehicles and watercraft. We aim to make adventure accessible to everyone, regardless of their experience level or background. Through innovation, reliability, and a commitment to safety, we strive to empower individuals to embrace the spirit of adventure and embark on unforgettable journeys.',
      },
      {
        title: 'The Onelife Experience',
        imgSrc: AboutUsCard3,
        mobileImgSrc: MobileAboutUsCard3,
        description:
          "Onelife offers a diverse range of vehicles and watercraft to suit every adventurer's needs. From rugged off-road vehicles for adrenaline junkies to sleek yachts for those seeking luxury on the water, we have something for everyone. Our user-friendly platform makes it easy to browse, book, and embark on your next adventure with confidence.",
      },
      {
        title: 'Safety First',
        imgSrc: AboutUsCard4,
        mobileImgSrc: MobileAboutUsCard3,
        description:
          'At Onelife, safety is our top priority. We understand the importance of peace of mind when embarking on outdoor adventures, which is why we meticulously inspect and maintain all our vehicles and watercraft to ensure they meet the highest safety standards. Our team of experienced professionals is also available to provide guidance and assistance whenever needed, so you can focus on enjoying your adventure without worries.',
      },
    ],
    footerCard: {
      title: 'Create Memories',
      description:
        'Rent your ride now. Join us at Onelife and discover a world of possibilities.',
      rentNowBtn: 'Rent Vehicle Now',
      listNowBtn: 'List My Vehicle Now',
    },
  },
  LogoutPopUp: {
    logoutLabel: 'Log Out',
    buttons: {
      logout: 'Logout',
      cancel: 'Cancel',
    },
    content: ' Are you sure you want to logout?',
  },
  DeletePopUp: {
    deleteLabel: 'Delete Account',
    buttons: {
      delete: 'Delete',
      cancel: 'Cancel',
    },
    content: 'Are you sure you want to delete your account ?',
  },
  EditProfile: {
    validationMsgs: {
      nameLength: `Name should be less than ${EditProfileFormEnums.maxEmailLength} characters`,
      emailLength: `Email should be less than ${EditProfileFormEnums.maxEmailLength} characters`,
      zipCodeLength: `Zip code should not be more than ${EditProfileFormEnums.maxZipCodeLength} characters or digits`,
      addressLength: `Email should be less than ${EditProfileFormEnums.maxEmailLength} characters`,
      minPhoneNumberLength: `Phone number should be of ${EditProfileFormEnums.maxPhoneNumberLength} characters`,
      phoneNumberLength: `Phone number should be less than ${EditProfileFormEnums.maxPhoneNumberLength} characters`,
      invalidEmail: 'Enter a valid email address',
    },
  },
  settingsScreenLabels: {
    settings: 'Settings',
    darkMode: 'Dark Mode',
    notifications: 'Notifications',
  },
  changePasswordlabels: {
    changePassword: 'Change Password',
    passwordUpdated: 'Password changed Successfully',
    currentPassword: 'Current password',
    newPassword: 'Create new password',
    confirmPassword: 'Confirm new password',
    save: 'Save Password',
    descMsg:
      'Create a new password with a minimum of 6 characters, including a',
    descMsgBold: 'special character, a capital letter, and a digit',
    forgotPass: 'Forgot Password?',
    validationMessages: {
      requiredMsgs: {
        password: 'Password is required',
        currentPassword: 'Current Password is required',
        confirmPassword: 'Confirm Password is required',
      },
      maxPasswordLength: `Password should be less than ${EditProfileFormEnums.maxPasswordLength} characters`,
      minPasswordLength: `Password should be more than ${EditProfileFormEnums.minPasswordLength} characters`,
      defaultDetailsNotFoundMsg:
        'No email or mobile number found for the user.',
    },
  },
  ProfileScreenLables: {
    accountInfo: 'Account Information',
    profileUpdated: 'Profile Updated Successfully',
    changePhoto: 'Change Photo',
    name: 'Name',
    mobile: 'Mobile Number',
    email: 'Email',
    address: 'Address',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    country: 'Country',
    state: 'State',
    city: 'City',
    zip: 'Zip',
    save: 'Save',
    cancel: 'Cancel',
    reset: 'Reset',
    edit: 'Edit',
    editProfile: 'Edit Profile',
    tabLabels: {
      accountInfo: 'Account Information',
      settings: 'Settings',
      changePassword: 'Change Password',
      Payment: 'Payout Preference',
    },
    errorMsgs: {
      fileUpload: {
        fileSizeExceeds: '! File size exceeds the maximum limit of 10 MB.',
        fileTypeError:
          '! Unsupported file format. Please upload a valid image.',
      },
    },
  },
  EditProfileForm: {
    validationMessages: {
      requiredMessages: {
        name: 'Name is required',
        email: 'Email is required',
        address: 'Address is required',
      },
    },
  },
  OwnerDashboard: {
    cardLabels: {
      totalCompletedBookings: 'Total Completed Bookings',
      totalEarning: 'Total Earnings',
      yourListing: 'Your Listings',
      missedBookings: 'Missed Bookings',
    },
    instantBooking: {
      mainHeading: 'Short Notice Bookings',
      actionRequiredTxt: 'Action Required',
      instantTxt: 'Instant',
      bookingPrice: 'Booking Price',
      acceptButtonTxt: 'Accept',
      acceptingButtonTxt: 'Accepting',
      rejectButtonTxt: 'Reject',
      rejectingButtonTxt: 'Rejecting',
      moreDetailsChevronAltTxt: 'view details',
      bookingIdNotFountErrorMessage: 'Booking id not found!',
      vehicleName: 'Vehicle Name',
      dateAndTime: 'Date and Time',
      price: 'Price',
      bookedBy: 'Booked By',
      action: 'Action',
    },
    bookings: {
      mainHeadBookings: 'Bookings',
      filterButtonTxt: 'Filter',
      popOverText: 'Bookings that start or end within following dates:',
      startDate: 'Start Date',
      endDate: 'End Date',
      dateValidationMessage: 'End date should be after start date.',
      clear: 'Clear',
      apply: 'Apply',
      confirmed: 'Confirmed',
      completed: 'Completed',
      cancelled: 'Cancelled',
      rejected: 'Rejected',
      noBookingsAvailable: 'No bookings available for selected date!',
      noBookings: 'No Bookings!',
      chat: 'Chat',
    },
  },
  OwnervehicleDetailsComponent: {
    editVehicle: 'Edit Vehicle',
    activate: 'Activate Vehicle',
    deactivate: 'Deactivate Vehicle',
    deleteVehicle: 'Delete Vehicle',
    buttons: {
      moreActions: 'More Actions',
    },
  },
  DeleteVehiclePopUpComponent: {
    deleteVehicle: 'Delete Vehicle',
    deleteVehicleContent: 'Are you sure you want to delete your vehicle?',
    confirmDelete: 'Yes, I want to delete my vehicle',
  },
  OwnerBookingDetails: {
    bookingDetails: 'Booking Details',
    bookingPolicies: {
      securityDeposit: 'Security Deposit',
      cancellationPolicy: 'Cancellation Policy',
      rentedBy: 'Rented By',
    },
    bookingStatus: {
      acceptBooking: 'Accept',
      rejectBooking: 'Reject',
    },
    OwnerBookingRequestStatus: {
      acceptBooking: 'Booking accepted successfully',
      rejectBooking: 'Booking rejected successfully',
      rejectConfirmation: 'Are you sure you want to reject this booking?',
      acceptConfirmation: 'Are you sure you want to accept this booking?',
      requestBookingTitle: 'Requested Booking',
      onAcceptLoadingTitle: 'Accepting Booking...',
      onRejectLoadingTitle: 'Rejecting Booking...',
    },
    noBookings: 'Currently no bookings are shown',
  },
  MyListingsComponent: {
    title: 'My Listings',
    noListings: 'No Listings Found',
    noImageFound: 'No Image Found',
    noData: 'No Data Found',
    actions: 'Actions',
    toastMessages: {
      vehicleActivated: 'Vehicle Activated Successfully',
    },
    ButtonTexts: {
      addVehicle: 'Add a New vehicle',
    },
    statusTexts: {
      active: 'Active',
      deactivated: 'Deactivated',
    },
    MyListingsCardDetails: {
      ButtonTexts: {
        edit: 'Edit',
        activate: 'Activate',
      },
    },
  },
  vehicleStepper: {
    stepperLabel: {
      finished: 'Finished',
      inprogress: 'In Progress',
      pending: 'Pending',
    },
    stepperSteps: {
      step1: {
        label: 'Vehicle Details',
        label2: 'Vehicle',
      },
      step2: {
        label: 'Location Details',
        label2: 'Location',
      },
      step3: {
        label: 'Vehicle Photos',
        label2: 'Upload',
      },
      step4: {
        label: 'Additional Details',
        label2: 'Additional',
      },
      step5: {
        label: 'Booking Details',
        label2: 'Booking',
      },
      step6: {
        label: 'Policies',
        label2: 'Policies',
      },
      step7: {
        label: 'Preview & Publish',
        label2: 'Preview',
      },
      success: {
        title: 'Listing is Published',
        subTitle:
          'Your Listing has been Published. You can check your details in My Listings.',
      },
      editSuccess: {
        title: 'Listing is Updated',
        subTitle:
          'Your Listing has been Updated. You can check your details in My Listings.',
      },
    },
    stepperInfo: {
      stepLabel: 'Step ',
      startListing: 'Start your Listing here : ',
      editListing: 'Edit your Listing here : ',
    },
    stepperButtons: {
      next: 'Next',
      back: 'Back',
      finish: 'Submit',
      finishing: 'Submitting',
      update: 'Update & Next',
      updating: 'Updating & Next',
      finalUpdate: 'Update',
      finalUpdating: 'Update',
      skip: 'Skip',
      skipInfo:
        'Skipping this step will not save the entered data. It will only be saved when you click on {buttonNameData}.',
    },
    previewStep: {
      edit: 'Edit',
      previewStep1: 'VEHICLE DETAILS',
      previewStep2: 'LOCATION DETAILS',
      previewStep3: 'VEHICLE PHOTOS',
      previewStep4: 'ADDITIONAL DETAILS',
      previewStep5: 'BOOKING DETAILS',
      previewStep6: 'POLICIES',
      previewStep7: 'PREVIEW & PUBLISH',
      alertMessage:
        ' Please fill the details which are missing. After filling the required details you can submit your listing.',
      alertMessageWithSlotError:
        ' Please fill the details which are missing. After filling the required details you can submit your listing. Also, please select atleast one slot for booking. ',
    },
    step1Details: {
      title: 'Vehicle Title',
      description: 'Vehicle Description',
      category: 'Vehicle Category',
      engineSize: 'Engine Size',
      subCategory: 'Vehicle Type',
      maker: 'Vehicle Make',
      model: 'Vehicle Model',
      year: 'Vehicle Year',
      capacity: 'Vehicle Capacity',
      length: 'Vehicle Length',
      owned: 'Vehicle Owned',
      trailer: 'Vehicle Trailer',
      hitchSize: 'Vehicle Hitch Size',
      yes: 'Yes',
      no: 'No',
      labels: {
        listing: 'Add Listing title',
        description: 'Add Listing description',
        category: 'Vehicle Category',
        subCategory: 'Vehicle Type',
        maker: 'Vehicle Make',
        manufacturingYear: 'Manufacturing Year',
        model: 'Vehicle Model',
        year: 'Vehicle Year *',
        capacity: 'Max. no of Person allowed',
        length: 'Vehicle Length (ft)',
        engine: 'Recommended Engine size',
        owned: 'Vehicle Owned *',
        hitchSize: 'Hitch Size',
        commercial: 'Commercially Owned',
        private: 'Privately Owned',
      },
      information: {
        listing:
          'This name is what renters will see when they search for a vehicle',
        description:
          ' Write a brief and interesting description of your vehicle here. Maximum 200 characters.',
        engine:
          'This will help the renter to bring the appropriate vehicle in case your vehicle needs to be towed to another place (if required).',
        trailer: 'TRAILER INCLUDED',
        vehicleOwnerShip: 'VEHICLE OWNERSHIP',
        maker:
          'In case if the vehicle maker is not in the list, you can directly enter the maker name and press enter. I will be added to the dropdown options.',
      },
    },
    step2Details: {
      country: 'Country',
      pickUp: 'Pick-Up Location',
      dropOff: 'Drop-Off Location',
    },
    step5Details: {
      hourly: 'Hourly',
      twelveHourly: '12 Hours',
      timeFrame: '12 Hourly Time Frame',
      daily: '24 Hours',
      maxDays: 'Max Days For 24 Hourly',
      multipleBookings: 'Multiple Bookings',
      gap: 'Time In Between Bookings',
      noticePeriod: 'Set Short Notice Period',
      title: {
        slotTypes: 'SELECT BOOKING SLOTS AND SET PRICING',
        multipleBooking: 'MULTIPLE BOOKINGS ON SAME DAY?',
        gap: 'TIME NEEDED IN BETWEEN BOOKINGS',
      },
      selected: 'selected',
      info: {
        hourly: 'Accept hourly bookings between 8 am to 10 pm in a day',
        twelveHourly:
          'You can put your vehicle for 12-hour rent with this option. Select one or multiple timeframes that suit you for accepting bookings.',
        multipleBooking:
          'By selecting the Yes option, you will be able to accept multiple bookings on the same day.',
        gap: 'Set a gap between two bookings so the next booking does not get disturbed due to the previous booking, and you get adequate time for maintenance if required.',
        shortNoticePerios:
          'The option set here allows you to keep a sufficient gap between receiving the booking till the start of the adventure. For example, if you allow booking with a minimum of 4 hours of notice, then you will not receive bookings whose start time is less than 4 hours.',
        monthLimit:
          'You can accept bookings for your vehicle for up to a specified number of months in the future.',
      },
      slotType: {
        hourly: 'hourly',
        twelveHourly: '12hourly',
        twentyFourHourly: '24hourly',
      },
      gapHours: {
        one: '1 hour',
        two: '2 hours',
        four: '4 hours',
        eight: '8 hours',
        twelve: '12 hours',
        twentyFour: '24 hours',
      },
      yes: 'Yes',
      no: 'No',
      shortNoticePeriod: [
        { label: 'Allow booking with 4 hr notice', value: 4 },
        { label: 'Allow booking with 8 hrs notice', value: 8 },
        { label: 'Allow booking with 12 hrs notice', value: 12 },
        { label: 'Allow booking with 24 hrs notice', value: 24 },
      ],
      errorMessage: {
        slotType: 'Please select atleast one booking slot type',
        multipleBooking: 'Please select atleast one option',
        hourlyRate: 'Please enter hourly rate',
        twelveHourlyRate: 'Please enter 12 hourly rate',
        twentyFourHourlyRate: 'Please enter 24 hourly rate',
      },
      label: {
        setPrice: 'Set price',
        noOfDays: 'Add no. of Days',
        shortNoticePeriod: 'Set short notice period',
        timeFrame: 'Select Time Frame',
        monthLimit: 'Future Booking Month Limit ',
      },
      next: 'Next',
    },
    step4Details: {
      amenities: 'Amenities',
      restricted: 'Restricted',
    },
    step4AdditionalDetails: {
      vehicleAmenitiesHead: 'VEHICLE AMENITIES',
      vehicleRestrictionsHead: 'VEHICLE RESTRICTIONS',
      addAmenities: 'Add Amenities',
      addAmenity: 'Add Amenity',
      addRestriction: 'Add Restriction',
      addAmenityError: 'Please enter vehicle amenity',
      addRestrictionError: 'Please enter vehicle restriction',
      addRestrictedItems: 'Add Restricted Items',
      sameAminityError: 'Cannot add same amenity',
      sameRestrictionsError: 'Cannot add same restriction',
    },
    vehicleSuccessMessage: 'Vehicle created successfully',
  },

  LocationDetailsStepperComponent: {
    fieldLabels: {
      state: 'State/Province',
      country: 'Country',
      address1: 'Street Address 1',
      address2: 'Street Address 2',
      city: 'City',
      zipCode: 'Zip Code',
    },
    titleTexts: {
      pickUp: 'PICK-UP LOCATION',
      dropOff: 'DROP-OFF LOCATION (IF DIFFERENT)',
    },
    validationMessages: {
      address: 'Address must not more than 255 characters',
      city: 'City must not more than 255 characters',
      zipCode: 'Zip code must be 6 digits',
      isRequired: 'is required',
    },
    buttonTexts: {
      save: 'Save',
    },
  },
  PoliciesStepperComponent: {
    titleTexts: {
      cancellationPolicy: 'CANCELLATION POLICY',
      securityDeposit: 'SECURITY DEPOSIT POLICY',
      termsAndConditions:
        'I agree to Security Deposit Policy and Cancellation Policy',
    },
    securityDepositDetails: {
      firstParagraph:
        'The deposit will be collected from the renter 48 hours before the adventure begins.',
      secondParagraph:
        'The deposit amount will be either $250 or 10% of the renting rate, whichever is higher.',
    },
  },
  UploadPhotosStepperComponent: {
    VehiclePhotosTitle: 'VEHICLE PHOTOS',
    VehiclePhotosDescription:
      "In addition to the overall photo, you should add images that show the Vehicle's interior and details, especially any cool or unique features.",
    UploadRuleDescription:
      'At least one cover photo of the vehicle is mandatory, you can always change the cover photo later.A maximum of 15 photos can be uploaded. You can add or delete the photos or new ones whenever required.',
    AcceptedFormat: 'Accepted format: {fileType}. Max file: {maxFile} MB',
    UploadPhoto: 'Upload Photo',
    coverPhoto: 'Cover Image',
    CoverPhotoButton: 'Make it Cover Photo',
    DeleteButton: 'Delete',
    ButtonaltTitle: 'Vehicle List',
  },

  OwnerCalendar: {
    OwnerCalendarTitle: 'My Calendar',
    PersonListTitle: 'Bookings',
    SelectedDatesBookingConfirmation:
      'Are you sure you want to block selected dates?',
    SelectedDatesBookingUnblockConfirmation:
      'Are you sure you want to unblock selected dates?',
    SelectedDatesBookingConfirmationSuccess:
      'Booking has been confirmed successfully.',
    SelectedDatesBookingConfirmationFailed: 'Booking has not been confirmed.',
    BookDatesTitle: 'Book Dates',
    LoadingButtonText: 'Please wait...',
    BlockDatesTitle: 'Block Dates',
    UnblockDatesTitle: 'Unblock Dates',
    successMessage: 'Selected Date Blocked successfully',
  },
  stepperErrorsData: {
    step1: {
      title: 'Please enter vehicle title',
      category: 'Please select category',
      subCategory: 'Please select sub category',
      maker: 'Please select maker',
      manufacturingYear: 'Please select manufacturing year',
      model: 'Please enter vehicle model',
      ownerShipType: 'Please select ownership type',
      description: 'Please enter less than 200 characters',
      maxCharValidation: 'Please enter less than 255 characters',
    },
    step2: {
      country: 'Please select country',
      countryValidation: 'Please select country first',
      pickUp: {
        address1: 'Please enter pickup address 1',
        address2: 'Please enter pickup address 2',
        city: 'Please enter pickup city',
        state: 'Please select pickup state',
        zipCode: 'Please enter pickup zip code',
      },
      dropOff: {
        address1: 'Please enter dropoff address 1',
        address2: 'Please enter dropoff address 2',
        city: 'Please enter dropoff city',
        state: 'Please select dropoff state',
        zipCode: 'Please enter dropoff zip code',
      },
    },
    step3: {
      images: 'Please upload at least one image',
      coverImage: 'Please make one image as cover image',
      fileFormat: 'Please upload images in jpg or png format',
      maxImage: 'Only {maxImages} images can be uploaded',
      fileSize: 'Please upload images less than 5 MB',
    },
    step4: {
      amenities: 'Please select at least one amenity',
      restrictions: 'Please select at least one restriction',
    },
    step5: {
      slot: 'Please select at least one slot type',
      rateHourly: 'Please enter hourly rate',
      numberRate: 'Rate should be in numbers.',
      numberInput: 'Input should be in numbers.',
      rate12Hourly: 'Please enter 12-hourly rate',
      rate24Hourly: 'Please enter 24-hourly rate',
      isMultipleBookingOnSameDay: 'Please select multiple booking on same day',
      gapHour: 'Please select gap hour',
      monthLimit: 'Please enter month less than 120',
    },
    step6: {
      termsAndCondition: 'Please check terms and condition',
    },
    unblockSuccessMessage: 'Selected Date Unblocked successfully',
    unblockErrorMessage:
      'You can not select unblocked and blocked dates together',
  },
  specialRequestErrorMessage:
    'Special request exceeds the maximum length of 255 characters',
  dateErrorMessage: 'Select date',
  bookingSameDateErrorMessage: 'Start and end dates cannot be the same.',
  bookingIncludesDisabledDateErrorMessage:
    'Selected date range includes disabled dates.',
  bookingMaxDaysError:
    'You cannot select more than $maxDays days for this booking',
};
export default ApplicationString;

/* eslint-disable consistent-return */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import addSvg from '../../../Assets/Svgs/add.svg';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import {
  getDetailsFromLocalStorage,
  isNullOrEmpty,
} from '../../../Utils/utils';
import { ICommonStepsProps } from '../../../Interfaces/Stepper';
import { CheckedBoxIcon, CheckedRadioIcon } from '../../../Assets/Svgs';
import ApplicationString from '../../../Constants/applicationString';

interface IErrorObject {
  amenities: {
    isError: boolean;
    validationMessage: string;
  };
  restrictions: {
    isError: boolean;
    validationMessage: string;
  };
}

const AdditionalDetailsStepperComponent: React.FC<ICommonStepsProps> = ({
  formData,
  setFormData,
  errorsData,
  setErrorsData,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const [amenities, setAmenities] = useState<
    { name: string; isChecked: boolean }[] | undefined
  >([]);
  const [restrictions, setVehicleRestrictions] = useState<
    { name: string; isChecked: boolean }[] | undefined
  >([]);
  const [isFirstTimeFormRendered, setIsFirstTimeFormRendered] =
    useState<boolean>(true);

  const [amenityValue, setAmenityValue] = useState<string>('');
  const [errorData, setErrorData] = useState<IErrorObject>({
    amenities: {
      isError: false,
      validationMessage:
        ApplicationString.vehicleStepper.step4AdditionalDetails.addAmenityError,
    },
    restrictions: {
      isError: false,
      validationMessage:
        ApplicationString.vehicleStepper.step4AdditionalDetails
          .addRestrictionError,
    },
  });
  const [vehicleRestrictionValue, setVehicleRestrictionValue] =
    useState<string>('');

  useEffect(() => {
    const { setting: settings } = getDetailsFromLocalStorage(
      'settings'
    ) as IUserSettingsData;

    const aminityInput = document.getElementById('amiInput') as HTMLElement;
    const restrictionInput = document.getElementById(
      'restrictionInput'
    ) as HTMLElement;

    aminityInput.setAttribute('maxLength', '35');
    restrictionInput.setAttribute('maxLength', '35');

    if (isFirstTimeFormRendered) {
      setIsFirstTimeFormRendered(false);
      const defaultAmenitiesSettings = settings.amenities?.map((amenity) => {
        if (formData?.step4.amenities?.includes(amenity.name)) {
          return { name: amenity.name, isChecked: true };
        }
        return { name: amenity.name, isChecked: false };
      });

      const newAddedAmenities =
        formData?.step4?.amenities
          ?.map((amenity) => {
            return { name: amenity, isChecked: true };
          })
          // eslint-disable-next-line array-callback-return
          .filter((rest) => {
            if (!settings.amenities.some((res) => res.name === rest.name)) {
              return { name: rest, isChecked: true };
            }
          }) || [];

      const defaultRestrictionSettings = settings.restrictedOnVehicle?.map(
        (restriction) => {
          if (formData?.step4?.restrictions?.includes(restriction.name)) {
            return { name: restriction.name, isChecked: true };
          }
          return { name: restriction.name, isChecked: false };
        }
      );
      const newAddedRes =
        formData?.step4?.restrictions
          ?.map((restriction) => {
            return { name: restriction, isChecked: true };
          })
          // eslint-disable-next-line array-callback-return
          .filter((restriction) => {
            if (
              !settings.restrictedOnVehicle.some(
                (rest) => rest.name === restriction.name
              )
            ) {
              return { name: restriction, isChecked: true };
            }
          }) || [];

      if (!isNullOrEmpty(formData.step4)) {
        setAmenities([...defaultAmenitiesSettings, ...newAddedAmenities]);
        setVehicleRestrictions([...defaultRestrictionSettings, ...newAddedRes]);
        return;
      }

      if (!isNullOrEmpty(settings)) {
        setAmenities(
          settings?.amenities?.map((amenity) => {
            return { name: amenity.name, isChecked: false };
          })
        );
        setVehicleRestrictions(
          settings?.restrictedOnVehicle?.map((restrict) => {
            return { name: restrict.name, isChecked: false };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.step4]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line array-callback-return, consistent-return
    const currentAmenities = amenities?.map((amenity) => {
      if (amenity.name === event.target.name) {
        return { ...amenity, isChecked: event.target.checked };
      }
      return amenity;
    });
    if (currentAmenities?.length) {
      setErrorsData((prevState) => ({
        ...prevState,
        step4: {
          ...prevState.step4,
          amenities: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (currentAmenities?.length && currentAmenities?.length > 0) {
      setAmenities(currentAmenities);
    }
    setFormData({
      ...formData,
      step4: {
        ...formData.step4,
        amenities: currentAmenities
          ?.filter((amenity) => amenity.isChecked)
          .map((amenity) => amenity.name),
      },
    });
  };

  const handleRestrictionCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // eslint-disable-next-line array-callback-return, consistent-return
    const currentVehicleRestrictions = restrictions?.map((amenity) => {
      if (amenity.name === event.target.name) {
        return { ...amenity, isChecked: event.target.checked };
      }
      return amenity;
    });
    if (currentVehicleRestrictions?.length) {
      setErrorsData((prevState) => ({
        ...prevState,
        step4: {
          ...prevState.step4,
          restrictions: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (
      currentVehicleRestrictions?.length &&
      currentVehicleRestrictions?.length > 0
    ) {
      setVehicleRestrictions(currentVehicleRestrictions);
    }
    setFormData({
      ...formData,
      step4: {
        ...formData.step4,
        restrictions: currentVehicleRestrictions
          ?.filter((restriction) => restriction.isChecked)
          .map((restriction) => restriction.name),
      },
    });
  };

  const addAmenityHandler = (): void => {
    if (amenityValue) {
      // restrict user to not add same entries
      const isAmenityExist = amenities?.find(
        (amenity) => amenity.name.toLowerCase() === amenityValue.toLowerCase()
      );
      if (isAmenityExist) {
        setErrorData({
          ...errorData,
          amenities: {
            isError: true,
            validationMessage:
              ApplicationString.vehicleStepper.step4AdditionalDetails
                .sameAminityError,
          },
        });
        return;
      }
      setAmenities((previousState) => {
        if (previousState?.length) {
          return [
            ...previousState,
            { name: amenityValue.trimEnd(), isChecked: false },
          ];
        }
      });
      setAmenityValue('');
    } else {
      setErrorData({
        ...errorData,
        amenities: {
          ...errorData.amenities,
          isError: true,
        },
      });
    }
  };
  const addVehicleRestrictionsHandler = (): void => {
    if (vehicleRestrictionValue) {
      const isRestrictionExist = restrictions?.find(
        (restriction) =>
          restriction.name.toLowerCase() ===
          vehicleRestrictionValue.toLowerCase()
      );
      if (isRestrictionExist) {
        setErrorData({
          ...errorData,
          restrictions: {
            isError: true,
            validationMessage:
              ApplicationString.vehicleStepper.step4AdditionalDetails
                .sameRestrictionsError,
          },
        });
        return;
      }
      setVehicleRestrictions((previousState) => {
        if (previousState?.length) {
          return [
            ...previousState,
            { name: vehicleRestrictionValue.trimEnd(), isChecked: false },
          ];
        }
      });
      setVehicleRestrictionValue('');
    } else {
      setErrorData({
        ...errorData,
        restrictions: {
          ...errorData.restrictions,
          isError: true,
        },
      });
    }
  };

  useEffect(() => {
    // re-validate fields
    if (amenityValue) {
      setErrorData({
        ...errorData,
        amenities: {
          ...errorData.amenities,
          isError: false,
        },
      });
    }
    if (vehicleRestrictionValue) {
      setErrorData({
        ...errorData,
        restrictions: {
          ...errorData.restrictions,
          isError: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amenityValue, vehicleRestrictionValue]);

  return (
    <div className="flex w-full gap-4 flex-col lg:flex-row">
      <div className="flex flex-1 flex-col gap-4">
        <b className="text-[14px]">
          {
            ApplicationString.vehicleStepper.step4AdditionalDetails
              .vehicleAmenitiesHead
          }
          <span className="text-[#FF0000]">*</span>
        </b>
        <FormControl className="w-full">
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#D1D5DB',
                },
              },
            }}
            label={
              ApplicationString.vehicleStepper.step4AdditionalDetails
                .addAmenities
            }
            data-testid="input-addAmenities"
            className="w-full bg-[#F9FAFB] rounded-4xl h-[60.5px] text-base font-medium "
            onChange={(event) =>
              setAmenityValue(event.target.value.trimStart())
            }
            value={amenityValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addAmenityHandler();
              }
            }}
            InputProps={{
              id: 'amiInput',
              className: 'rounded-4xl px-2 h-[77.5px]',
              endAdornment: (
                <InputAdornment position="end" className="pr-2">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => addAmenityHandler()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addAmenityHandler();
                      }
                    }}
                    data-testid="add-amenities-button"
                  >
                    <img
                      src={addSvg}
                      alt={
                        ApplicationString.vehicleStepper.step4AdditionalDetails
                          .addAmenities
                      }
                      className="cursor-pointer"
                      width={25}
                    />
                  </div>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: 'left-[10px] leading-7',
                focused: 'leading-6 left-[4px]',
              },
            }}
          />
        </FormControl>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {amenities?.map((amenity) => (
            <FormControlLabel
              key={amenity.name}
              className={`${amenity.isChecked ? 'bg-[#F7FBFF]' : 'bg-[#F9FAFB]'} dark:bg-dark_bg_primary dark:border-none`}
              style={{
                wordBreak: 'break-word',
              }}
              sx={{
                margin: '0',
                color: amenity.isChecked ? '#1A56DB' : '#6B7280',
                backgroundColor: '#F7FBFF',
                border: '1px solid #D1D5DB',
                borderRadius: '50px',
                padding: '8px 14px',
              }}
              control={
                <Checkbox
                  name={amenity.name}
                  checked={amenity.isChecked}
                  icon={<CheckedBoxIcon />}
                  checkedIcon={<CheckedRadioIcon />}
                  data-testid="amenityName-step4-checkbox"
                  sx={{ color: '#1A56DB' }}
                  onChange={handleCheckboxChange}
                />
              }
              label={amenity.name}
            />
          ))}
        </div>
        {errorsData.step4.amenities.error && (
          <FormHelperText className="text-error">
            {errorsData.step4.amenities.ValidationMessage}
          </FormHelperText>
        )}
      </div>
      <div className="flex flex-1 flex-col gap-4">
        <b className="text-[14px]">
          {
            ApplicationString.vehicleStepper.step4AdditionalDetails
              .vehicleRestrictionsHead
          }
          <span className="text-[#FF0000]">*</span>
        </b>
        <FormControl className="w-full">
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#D1D5DB',
                },
              },
            }}
            data-testid="input-addRestrictions"
            label={
              ApplicationString.vehicleStepper.step4AdditionalDetails
                .addRestrictedItems
            }
            value={vehicleRestrictionValue}
            className="w-full bg-[#F9FAFB] rounded-4xl h-[60.5px] text-base font-medium"
            onChange={(event) =>
              setVehicleRestrictionValue(event.target.value.trimStart())
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addVehicleRestrictionsHandler();
              }
            }}
            InputProps={{
              id: 'restrictionInput',
              className: 'rounded-4xl px-2 h-[77.5px]',
              endAdornment: (
                <InputAdornment position="end" className="pr-2 border-error">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => addVehicleRestrictionsHandler()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addVehicleRestrictionsHandler();
                      }
                    }}
                    data-testid="add-restrictions-button"
                  >
                    <img
                      src={addSvg}
                      alt={
                        ApplicationString.vehicleStepper.step4AdditionalDetails
                          .addRestriction
                      }
                      className="cursor-pointer"
                      width={25}
                    />
                  </div>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: 'left-[10px] leading-7',
                focused: 'leading-6 left-[4px]',
              },
            }}
          />
        </FormControl>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {restrictions?.map((restriction, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FormControlLabel
              key={restriction.name}
              className={`${restriction.isChecked ? 'bg-[#F7FBFF]' : 'bg-[#F9FAFB]'} dark:bg-dark_bg_primary dark:border-none`}
              style={{
                wordBreak: 'break-word',
              }}
              sx={{
                color: restriction.isChecked ? '#1A56DB' : '#6B7280',
                margin: '0',
                backgroundColor: '#F7FBFF',
                border: '1px solid #D1D5DB',
                borderRadius: '50px',
                padding: '8px 14px',
              }}
              control={
                <Checkbox
                  // eslint-disable-next-line react/no-array-index-key
                  id={`${restriction.name}${index}`}
                  name={restriction.name}
                  checked={restriction.isChecked}
                  icon={<CheckedBoxIcon />}
                  checkedIcon={<CheckedRadioIcon />}
                  sx={{ color: '#1A56DB' }}
                  onChange={handleRestrictionCheckBox}
                  data-testid="restrictionName-step4-checkbox"
                />
              }
              label={restriction.name}
            />
          ))}
        </div>
        {errorsData.step4.restrictions.error && (
          <FormHelperText className="text-error">
            {errorsData.step4.restrictions.ValidationMessage}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default AdditionalDetailsStepperComponent;

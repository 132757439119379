import React, { useState, Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { Button } from '@mui/material';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import ApplicationString from '../../Constants/applicationString';
import StarRating from './RatingComponent';
import { formatSingleOrDateRange, getCoverPhoto } from '../../Utils/utils';

interface ImyBookingRatingModalProps {
  booking: IMyBookings;
  setRatingModal: Dispatch<SetStateAction<boolean>>;
  formatLocation: (str1: string, str2: string) => string;
  reviewSubmitHandler: (rating: number, isOwnRating: boolean) => void;
  reviewStar: number;
}

const MybookingsRatingComponent: React.FC<ImyBookingRatingModalProps> = ({
  booking,
  formatLocation,
  setRatingModal,
  reviewSubmitHandler,
  reviewStar,
}) => {
  const {
    startDate,
    endDate,
    vehicleDetail,
    confirmationCode,
    ownerDetails,
    renterDetails,
  } = booking;
  const { title, city, country, images } = vehicleDetail;
  const coverPhoto = getCoverPhoto(images);
  const [currentRating, setCurrentRating] = useState(0);

  const onRatingSubmit = () => {
    const isOwnRating = ownerDetails?.id === renterDetails?.id;
    reviewSubmitHandler(currentRating, isOwnRating);
  };

  return (
    <div className="max-h-min max-w-125 w-[90vw] md:w-125 bg-card_bg dark:bg-dark_bg_secondary rounded-xl p-6 flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-xl sm:text-2xl font-bold dark:text-white">
          {ApplicationString.MyBookingsRatingComponent.title}
        </h1>
        <CloseIcon
          onClick={() => setRatingModal(false)}
          className="cursor-pointer dark:text-white"
        />
      </div>
      <div className="h-75 mt-3 sm:mt-5 mb-2 sm:mb-4">
        {coverPhoto ? (
          <img src={coverPhoto} alt="" className="h-full w-full rounded-xl" />
        ) : (
          <div className="h-full w-full bg-gray-300 rounded-xl flex justify-center items-center text-2xl">
            {ApplicationString.commonStrings.noImageFound}
          </div>
        )}
      </div>
      <div className=" font-bold text-title_text dark:text-web_accent text-xl sm:text-2xl 2xl:text-3xl">
        <span>{title}</span>
      </div>
      <div className="flex gap-4 font-medium flex-wrap">
        <div className="flex gap-1 items-center">
          <CalendarMonthOutlinedIcon className="text-primary size-5" />
          <span className="text-lg dark:text-dark_primary_text whitespace-nowrap">
            {/* {`${dayjs(startDate).format(dateFormat)} - ${dayjs(endDate).format(dateFormat)}`} */}
            {formatSingleOrDateRange([startDate, endDate])}
          </span>
        </div>
        <span className="text-secondary_text hidden md:block">|</span>
        <div>
          <FmdGoodOutlinedIcon className="text-primary size-5" />
          <span className="dark:text-dark_primary_text">
            {formatLocation(city, country)}
          </span>
        </div>
      </div>
      <div className="flex flex-wrap justify-between font-medium ">
        <span className="text-secondary_text dark:text-dark_secondary_text ">
          {ApplicationString.MyBookingsRatingComponent.confirmationCode}
        </span>
        <span className="dark:text-dark_primary_text">
          {confirmationCode || ''}
        </span>
      </div>
      <div className="flex sm:mt-2 2xl:mt-4 justify-center">
        <StarRating
          rating={Number(reviewStar) || 0}
          currentRating={currentRating}
          setCurrentRating={setCurrentRating}
        />
      </div>
      <div className="sm:mt-2 2xl:mt-4">
        <Button
          variant="contained"
          color="primary"
          data-testid="my-bookings-rating-submit-button"
          className="w-full flex justify-center shadow-1 shadow-slate-50 rounded-4xl normal-case sm:text-base font-bold sm:py-3  dark:text-dark_bg_secondary "
          onClick={onRatingSubmit}
        >
          {ApplicationString.MyBookingsRatingComponent.submitButton}
        </Button>
      </div>
    </div>
  );
};

export default MybookingsRatingComponent;

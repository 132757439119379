import React from 'react';

import { useNavigate } from 'react-router-dom';
import ApplicationString from '../../../Constants/applicationString';
import Img from '../../../Assets/backgroundImg.png';
import cssUtils from '../../../Utils/cssUtils';
import SectionHeading from '../../common/SectionHeading';
import InternalRoute from '../../../Utils/internalRoutes';

const ExploreCardComponent: React.FC = () => {
  const { featureCards } = ApplicationString;
  const Navigate = useNavigate();
  return (
    <div
      className="w-full bg-blue-950 dark:bg-dart_web_accent"
      style={{
        backgroundImage: `url(${Img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className={cssUtils.layout.sectionLayout}>
        <div className="xl:flex items-center gap-4 justify-between text-white">
          <SectionHeading
            title={featureCards.title}
            descTextColor="text-white"
            description={featureCards.description}
          />
          <button
            type="button"
            onClick={() =>
              Navigate(
                `${InternalRoute.search}?category=${ApplicationString.categoryOptions[2].value}&startDate=&endDate=`
              )
            }
            className={`dark:text-white ${cssUtils.button.secondary}`}
            data-testid="ExploreCard-List-vehicle-button"
            aria-label="ExploreCard-List-vehicle"
          >
            {featureCards.buttonTitle}
          </button>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 justify-center mt-5 sm:mt-2">
          {featureCards.featureData.map((card) => (
            <div key={card.title} className="bg-white rounded-xl">
              <div className="p-6 h-full flex justify-between flex-col gap-2">
                <div>
                  <img className="" src={card.icon} alt="" />
                </div>
                <p className="font-semibold text-2sm 2xl:text-2xl leading-tight text-blue-950 dark:text-dart_web_accent">
                  {card.title}
                </p>
                {/* <p className="font-normal sm:text-sm 2xl:text-base text-slate-500 leading-tight">
                  {card.description}
                </p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExploreCardComponent;

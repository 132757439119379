import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import ApplicationString from '../../Constants/applicationString';
import InternalRoute from '../../Utils/internalRoutes';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';
import useSelectOpenClose from '../../Hooks/useSelectOpenClose';

interface IsignUpPhoneFormData {
  countryCode: string;
  phoneNumber: string;
}

interface IErrorsData {
  countryCode: {
    error: boolean;
    ValidationMessage: string;
  };
  phoneNumber: {
    error: boolean;
    ValidationMessage: string;
  };
}

interface ISignUpWithPhoneComponentProps {
  countryCodeOptions: string[];
  onSignupPhoneSubmit: (e: React.FormEvent) => Promise<void>;
  errorsData: IErrorsData;
  formData: IsignUpPhoneFormData;
  isSigningUp: boolean;
  countryCodeChangeHandler: (e: SelectChangeEvent) => void;
  phoneNumberChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SignUpWithPhoneComponent: React.FC<ISignUpWithPhoneComponentProps> = ({
  countryCodeOptions,
  onSignupPhoneSubmit,
  errorsData,
  formData,
  isSigningUp,
  countryCodeChangeHandler,
  phoneNumberChangeHandler,
}) => {
  const selectProps = useSelectOpenClose();

  return (
    <BackgroundLayout>
      <div className="xsm:w-100 max-h-[100vh] bg-white border border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.signUpWithPhone.title.mainTitle}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.signUpWithPhone.title.subTitle}
            </p>
          </div>
          <div data-testid="form-fields-container " className=" w-full mt-8">
            <form onSubmit={onSignupPhoneSubmit}>
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
                data-testid="loginPhoneForm"
              >
                <div>
                  <div className="flex gap-1">
                    <FormControl
                      className="w-20 sm:w-30"
                      error={errorsData.countryCode.error}
                    >
                      <InputLabel
                        data-testid="countryCodeLoginSelect"
                        className="text-base font-medium"
                      >
                        {ApplicationString.signUpWithPhone.label.code}
                      </InputLabel>
                      <Select
                        data-testid="countryCodeLoginSelect"
                        className="w-20 sm:w-30 rounded-4xl text-base font-medium"
                        label={ApplicationString.signUpWithPhone.label.code}
                        defaultValue=""
                        open={selectProps.open}
                        onOpen={selectProps.onOpen}
                        onClose={selectProps.onClose}
                        aria-describedby="countryCodeLoginPhoneSelect"
                        onChange={countryCodeChangeHandler}
                      >
                        {countryCodeOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {errorsData.countryCode.error && (
                        <FormHelperText className="text-error flex items-center gap-1">
                          <ErrorOutlined className="size-3.5" />
                          {errorsData.countryCode.ValidationMessage}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                    <FormControl className="flex-1 border">
                      <TextField
                        label={ApplicationString.signUpWithPhone.label.phone}
                        className="rounded-4xl  text-base font-medium "
                        InputProps={{ className: 'rounded-4xl px-2 ' }}
                        value={formData.phoneNumber}
                        error={errorsData.phoneNumber.error}
                        onChange={phoneNumberChangeHandler}
                      />
                    </FormControl>
                  </div>
                  <div>
                    {errorsData.phoneNumber.error && (
                      <FormHelperText className="text-error flex items-center gap-1">
                        <ErrorOutlined className="size-3.5" />
                        <span>{errorsData.phoneNumber.ValidationMessage}</span>
                      </FormHelperText>
                    )}
                  </div>
                </div>

                <div className="mt-6 flex flex-col gap-3">
                  {isSigningUp ? (
                    <LoadingButton
                      buttonText={
                        ApplicationString.signUpWithPhone.button.verifyingPhone
                      }
                    />
                  ) : (
                    <Button
                      variant="contained"
                      data-testid="signUp-with-phone-button"
                      className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                      color="primary"
                      type="submit"
                    >
                      {ApplicationString.signUpWithPhone.button.signUp}
                    </Button>
                  )}

                  <span className="text-secondary_text text-base font-bold flex justify-center">
                    {ApplicationString.signUpWithPhone.otherText.or}
                  </span>
                  {isSigningUp ? (
                    <Button
                      disabled={isSigningUp}
                      variant="outlined"
                      data-testid="signUp-continue-with-email-button"
                      className="w-full normal-case cursor-not-allowed  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                    >
                      <span className="flex gap-1 items-center font-bold text-base">
                        <EmailOutlinedIcon className="h-[16px] w-[18px]" />
                        {
                          ApplicationString.signUpWithPhone.button
                            .continueWithEmail
                        }
                      </span>
                    </Button>
                  ) : (
                    <Link to={InternalRoute.EmailSignup}>
                      <Button
                        variant="outlined"
                        className="w-full normal-case  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                      >
                        <span className="flex gap-1 items-center font-bold text-base">
                          <EmailOutlinedIcon className="h-[16px] w-[18px]" />
                          {
                            ApplicationString.signUpWithPhone.button
                              .continueWithEmail
                          }
                        </span>
                      </Button>
                    </Link>
                  )}
                </div>
              </FormControl>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-borderStroke flex justify-center items-center  dark:border-dark_borderStroke">
          <span className="p-5 flex gap-1 text-sm sm:text-base ">
            {ApplicationString.signUpWithPhone.otherText.haveAccount}
            <Link
              to={InternalRoute.PhoneLogin}
              data-testid="existing-user-login-link"
              className="text-primary font-bold"
            >
              {ApplicationString.signUpWithPhone.links.login}
            </Link>
          </span>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default SignUpWithPhoneComponent;

import { Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import React from 'react';
import ApplicationString from '../../Constants/applicationString';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';

interface IForgotPasswordEmailComponentProps {
  formData: { email: string };
  errorsData: {
    email: {
      error: boolean;
      ValidationMessage: string;
    };
  };
  onFormSubmit: (e: React.FormEvent) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isVerifying: boolean;
}

const ForgotPasswordEmailComponent: React.FC<
  IForgotPasswordEmailComponentProps
> = ({
  formData,
  errorsData,
  onFormSubmit,
  handleEmailChange,
  isVerifying,
}: IForgotPasswordEmailComponentProps) => {
  return (
    <BackgroundLayout>
      <div className="xsm:w-100  max-h-[100vh]  border bg-white border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.ForgotPasword.title}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.ForgotPasword.subTitle}
            </p>
          </div>
          <form onSubmit={onFormSubmit}>
            <div data-testid="form-fields-container " className=" w-full mt-8">
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
              >
                <FormControl>
                  <TextField
                    label={ApplicationString.signUpWithEmail.label.email}
                    className=" w-full rounded-4xl text-base font-medium "
                    InputProps={{ className: 'rounded-4xl px-2 ' }}
                    onChange={handleEmailChange}
                    error={errorsData.email.error}
                    value={formData.email}
                  />
                  {errorsData.email.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.email.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </FormControl>
            </div>
            <div className="my-8 flex flex-col gap-3">
              {isVerifying ? (
                <LoadingButton
                  buttonText={ApplicationString.ForgotPasword.button.verifying}
                />
              ) : (
                <Button
                  variant="contained"
                  className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                  color="primary"
                  type="submit"
                >
                  {ApplicationString.ForgotPasword.button.continue}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default ForgotPasswordEmailComponent;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { commonConfig } from '../../Constants/commonConfig';

interface DataState {
  data: IVehiclesData[];
  isLoading: boolean;
  error: string | null;
  selectedCategory: number | null | undefined;
  subCategoriesData: { [key: number]: IVehiclesData[] };
}

const initialState: DataState = {
  data: [],
  isLoading: false,
  error: null,
  selectedCategory: null,
  subCategoriesData: {},
};

export const fetchData = createAsyncThunk<IVehiclesData[], void>(
  'data/fetchData',
  async () => {
    const response = await ApiRequest.get<{ data: IVehiclesData[] }>(
      `${commonConfig.ApiUrl}${URLS.SEARCH_QUERY}?pageNumber=1&pageSize=1000`
    );

    return response.data.data;
  }
);

const filterAndGroupByCategory = (data: IVehiclesData[], category?: number) => {
  return data.reduce(
    (acc, item) => {
      if (!category || item.category === category) {
        // eslint-disable-next-line prefer-destructuring
        const subCategory = item.subCategory;
        if (!acc[subCategory]) {
          acc[subCategory] = [];
        }
        acc[subCategory].push(item);
      }
      return acc;
    },
    {} as { [key: number]: IVehiclesData[] }
  );
};
const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
      state.subCategoriesData = filterAndGroupByCategory(
        state.data,
        action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        if (
          state.selectedCategory !== null &&
          state.selectedCategory !== undefined
        ) {
          state.subCategoriesData = filterAndGroupByCategory(
            action.payload,
            state.selectedCategory
          );
        }
      })
      .addCase(fetchData.rejected, (state, action) => {
        const axiosError = action.error.message;
        state.isLoading = false;
        state.error = axiosError as string;
      });
  },
});

export const { selectCategory } = dataSlice.actions;
export default dataSlice.reducer;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import cssUtils from '../../../Utils/cssUtils';
import InternalRoute from '../../../Utils/internalRoutes';
import ApplicationString from '../../../Constants/applicationString';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';

interface OwnerVehicleGalleryProps {
  vehicleDetails: IVehiclesData;
  isLoading: boolean;
}
const OwnerVehicleDetailsGallery: React.FC<OwnerVehicleGalleryProps> = ({
  vehicleDetails,
  isLoading,
}) => {
  const { vehicleId = '' } = useParams();
  const navigate = useNavigate();
  return (
    <div className="w-full dark:bg-primary_text">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress disableShrink />;
        </div>
      ) : (
        <div className={cssUtils.layout.sectionLayout}>
          <Button
            onClick={() => {
              const path = InternalRoute.owner_vehicle_details.replace(
                ':vehicleId',
                vehicleId.toString()
              );
              navigate(path);
            }}
            className="text-primary_text dark:text-white text-center font-medium text-xl capitalize cursor-pointer"
          >
            <ChevronLeftIcon className="dark:fill-white" />{' '}
            {ApplicationString.vehicleDetails.ButtonText.back}
          </Button>
          <ImageList variant="masonry" cols={3} gap={8} className="mt-4">
            {vehicleDetails.images &&
              vehicleDetails.images.map((item) => (
                <ImageListItem key={item.path}>
                  <img
                    className="rounded-lg"
                    srcSet={`${item.path}?w=248&fit=crop&auto=format&dpr=2`}
                    src={`${item.path}?w=248&fit=crop&auto=format`}
                    alt=""
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
          </ImageList>
        </div>
      )}
    </div>
  );
};

export default OwnerVehicleDetailsGallery;

import React, { useContext, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OwnerVehicleDetailsComponent from '../../../Components/OwnerComponent/OwnerVehicleDetails';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';
import { ApiRequest } from '../../../Api/api';
import URLS from '../../../Constants/urls';
import { myListingsStatusEnums } from '../../../Utils/enums';
import ToastContext from '../../../Services/ToastService';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import InternalRoute from '../../../Utils/internalRoutes';
// eslint-disable-next-line import/no-named-as-default
import OwnerVehicleDetailsGallery from '../../../Components/OwnerComponent/OwnerVehicleDetails/VehicleDetailsGallery';

const OwnerVehicleDetailsContainer: React.FC = () => {
  const [vehicleDetails, setVehicleDetails] = useState<IVehiclesData>(
    {} as IVehiclesData
  );
  const location = useLocation();
  const isPhotoGallery = location.pathname.includes('photoGallery');
  const [showActions, setShowActions] = useState(false);
  const [expandActions, setExpandActions] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const { vehicleId = '' } = useParams();
  const getVehicleDetails = async (): Promise<void> => {
    const finalURL = `${URLS.VEHICLE_DETAILS.replace(
      '#{vehicleId}',
      vehicleId
    )}`;
    try {
      setLoading(true);
      const response: AxiosResponse = await ApiRequest.get(finalURL);
      if (response?.status === HttpStatusCode.Ok) {
        setVehicleDetails(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleDeactivateVehicle = async (id: number) => {
    try {
      const res = await ApiRequest.put(
        URLS.ACTIVATE_VEHICLE.replace('#{vehicleId}', String(id)),
        { isEnabled: myListingsStatusEnums.deactivate }
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast?.success(`vehicle deactivated successfully.`);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setShowActions(false);
        setExpandActions(false);
        getVehicleDetails();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      console.error(axiosError.message);
    }
  };

  const handleAcivateVehicle = async (id: number) => {
    try {
      const res = await ApiRequest.put(
        URLS.ACTIVATE_VEHICLE.replace('#{vehicleId}', String(id)),
        { isEnabled: myListingsStatusEnums.activated }
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast?.success(`vehicle activated successfully.`);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setShowActions(false);
        setExpandActions(false);
        getVehicleDetails();
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      console.error(axiosError.message);
    }
  };

  const handleDeleteVehicle = async (id: number) => {
    try {
      const res = await ApiRequest.delete(
        URLS.VEHICLE_DELETE.replace('#{vehicleId}', String(id))
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast?.success(`vehicle deleted successfully.`);
        navigate(InternalRoute.my_listings);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      console.error(axiosError.message);
      if (axiosError?.response?.status === HttpStatusCode.BadRequest) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setShowActions(false);
        setExpandActions(false);
        toast?.error(`${axiosError?.response?.data?.message}`);
      }
    }
  };
  useEffect(() => {
    getVehicleDetails();
  }, [vehicleId]);
  return (
    <div>
      {isPhotoGallery ? (
        <OwnerVehicleDetailsGallery
          vehicleDetails={vehicleDetails}
          isLoading={loading}
        />
      ) : (
        <OwnerVehicleDetailsComponent
          handleAcivateVehicle={handleAcivateVehicle}
          handleDeleteVehicle={handleDeleteVehicle}
          handleDeactivateVehicle={handleDeactivateVehicle}
          vehicleDetails={vehicleDetails}
          isLoading={loading}
          showActions={showActions}
          expandActions={expandActions}
          setShowActions={setShowActions}
          setExpandActions={setExpandActions}
        />
      )}
    </div>
  );
};

export default OwnerVehicleDetailsContainer;

import React, { useState } from 'react';
import SectionHeading from '../SectionHeading';
import cssUtils from '../../../Utils/cssUtils';

interface AccordionData {
  title: string;
  darkTheme: string;
  accordionData: AccordionItem[];
}

interface AccordionItem {
  id: number;
  question: string;
  answer: string;
}

const Accordion: React.FC<AccordionData> = ({
  accordionData,
  title,
  darkTheme,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full">
      <div className={`lg:py-13 ${cssUtils.layout.sectionLayout}`}>
        <SectionHeading
          title={title}
          // description={accordionData.description} // Commented out, ensure to handle this if required
          descTextColor={cssUtils.colors.slate}
        />
        <div
          data-testid="accordion-collapse"
          data-accordion="collapse"
          className="mt-5"
        >
          {accordionData.map((item) => (
            <div key={item.id}>
              <h2 data-testid={`accordion-collapse-heading-${item.id}`}>
                <button
                  type="button"
                  className={`flex outline-none my-2 items-center ${darkTheme || 'dark:bg-dark_formFieldBg'}  dark:border-dark_borderStroke justify-between w-full p-5 font-medium rtl:text-right border border-gray-200 rounded-full dark:focus:ring-gray-800   bg-white  dark:text-white text-[#374151] gap-3 `}
                  data-accordion-target={`#accordion-collapse-body-${item.id}`}
                  aria-expanded={activeIndex === item.id ? 'true' : 'false'}
                  aria-controls={`accordion-collapse-body-${item.id}`}
                  data-testid={`accordion-button-${item.id}`}
                  onClick={() => handleClick(item.id)}
                >
                  <span className="font-medium text-md leading-tight text-left">
                    {item.question}
                  </span>
                  {activeIndex === item.id ? (
                    <svg
                      data-accordion-icon
                      className="w-3 h-3 rotate-0 shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  ) : (
                    <svg
                      data-accordion-icon
                      className="w-3 h-3 rotate-180 shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  )}
                </button>
              </h2>
              <div
                data-testid={`accordion-collapse-body-${item.id}`}
                className={activeIndex === item.id ? 'block' : 'hidden'}
                aria-labelledby={`accordion-collapse-heading-${item.id}`}
              >
                <div
                  className={`p-5 border my-2 rounded-xl  dark:bg-dark_formFieldBg  bg-white border-slate-200 dark:border-dark_borderStroke `}
                >
                  <p
                    className={` mb-2 text-secondary_text dark:text-dark_text_sub_primary font-medium`}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accordion;

import React, {
  ChangeEvent,
  // useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  ErrorOutlined,
} from '@mui/icons-material';
import '../../index.css';
import { Link } from 'react-router-dom';
import ApplicationString from '../../Constants/applicationString';
import InternalRoute from '../../Utils/internalRoutes';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import BackgroundLayout from './BackgroundLayout';
import useSelectOpenClose from '../../Hooks/useSelectOpenClose';
// import ColorModeContext from '../../Utils/ColorModeContext';
import { onRefreshOverlapHandler } from '../../Utils/utils';

interface ILoginFormData {
  countryCode: string;
  phoneNumber: string;
  password: string;
}

interface IErrorsData {
  countryCode: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  phoneNumber: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface ILoginWithPhoneComponentProps {
  formData: ILoginFormData;
  onPhoneLoginSubmit: (e: React.FormEvent) => void;
  setPhoneNumberHasValue: Dispatch<SetStateAction<boolean>>;
  setPasswordHasValue: Dispatch<SetStateAction<boolean>>;
  phoneNumberHasValue: boolean;
  passwordHasValue: boolean;
  errorsData: IErrorsData;
  countryCodeOptions: string[];
  countryCodeChangeHandler: (e: SelectChangeEvent<string>) => void;
  phoneNumberChangeHandler: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  passwordChangeHandler: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isLoggingIn: boolean;
}

const LogInWithPhoneComponent: React.FC<ILoginWithPhoneComponentProps> = ({
  formData,
  onPhoneLoginSubmit,
  setPasswordHasValue,
  passwordHasValue,
  setPhoneNumberHasValue,
  phoneNumberHasValue,
  errorsData,
  countryCodeOptions,
  countryCodeChangeHandler,
  phoneNumberChangeHandler,
  passwordChangeHandler,
  isLoggingIn,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const selectProps = useSelectOpenClose();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const { currentTheme } = useContext(ColorModeContext);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <BackgroundLayout>
      <div className="xsm:w-100 max-h-[100vh] bg-white border border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.loginWithPhone.title.mainTitle}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.loginWithPhone.title.subTitle}
            </p>
          </div>
          <div data-testid="form-fields-container" className="w-full mt-8">
            <form onSubmit={onPhoneLoginSubmit}>
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
                data-testid="loginPhoneForm"
              >
                <div>
                  <div className="flex gap-1">
                    <FormControl
                      className="w-20 sm:w-30"
                      error={errorsData.countryCode.error}
                    >
                      <InputLabel
                        data-testid="countryCodeLoginSelect"
                        className="text-base font-medium"
                      >
                        {ApplicationString.loginWithPhone.label.code}
                      </InputLabel>
                      <Select
                        data-testid="countryCodeLoginSelect"
                        open={selectProps.open}
                        onOpen={selectProps.onOpen}
                        onClose={selectProps.onClose}
                        className="w-20 sm:w-30 rounded-4xl text-base font-medium"
                        label={ApplicationString.loginWithPhone.label.code}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        // {...selectProps}
                        defaultValue=""
                        aria-describedby="countryCodeLoginPhoneSelect"
                        onChange={countryCodeChangeHandler}
                      >
                        {countryCodeOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {errorsData.countryCode.error && (
                      <FormHelperText className="text-error flex items-center gap-1">
                        <ErrorOutlined className="size-3.5" />
                        {errorsData.countryCode.ValidationMessage}
                      </FormHelperText>
                    )} */}
                    </FormControl>
                    <FormControl className="flex-1 border">
                      <TextField
                        variant="outlined"
                        label={ApplicationString.ProfileScreenLables.mobile}
                        className="rounded-4xl border-none"
                        InputLabelProps={{
                          shrink:
                            formData?.phoneNumber.length > 0 ||
                            phoneNumberHasValue,
                        }}
                        InputProps={{
                          onAnimationStart: onRefreshOverlapHandler(
                            setPhoneNumberHasValue
                          ),
                          className:
                            'rounded-4xl border-none text-base text-input_text dark:bg-dark_formFieldBg dark:text-white',
                        }}
                        error={errorsData.phoneNumber.error}
                        onChange={phoneNumberChangeHandler}
                        value={formData.phoneNumber}
                      />
                    </FormControl>
                  </div>
                  <div>
                    {(errorsData.phoneNumber.error ||
                      errorsData.countryCode.error) && (
                      <FormHelperText className="text-error flex items-center gap-1">
                        <ErrorOutlined className="size-3.5" />
                        <span>{errorsData.phoneNumber.ValidationMessage}</span>
                      </FormHelperText>
                    )}
                  </div>
                </div>
                <FormControl className="flex-1 border">
                  <TextField
                    label={ApplicationString.loginWithPhone.label.password}
                    type={showPassword ? 'text' : 'password'}
                    className="customInputBase rounded-4xl border-none relative"
                    InputLabelProps={{
                      shrink: formData?.password.length > 0 || passwordHasValue,
                    }}
                    onChange={passwordChangeHandler}
                    error={errorsData.password.error}
                    value={formData.password}
                    InputProps={{
                      onAnimationStart:
                        onRefreshOverlapHandler(setPasswordHasValue),
                      className:
                        'rounded-4xl border-none text-base text-input_text dark:bg-dark_formFieldBg dark:text-white',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="pr-2 absolute right-4"
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            data-testid="login-show-password-icon"
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlined className=" text-primary " />
                            ) : (
                              <VisibilityOffOutlined className=" text-primary  " />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(errorsData.password.error ||
                    errorsData.password.onlyErrorMsg) && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.password.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <div className="flex justify-end">
                  <Link
                    data-testid="login-phone-forgot-password-link"
                    to={InternalRoute.PhoneForgotPassword}
                    className=" text-sm font-medium text-primary_text dark:text-dark_primary_text flex justify-end mt-2"
                  >
                    {ApplicationString.loginWithPhone.links.forgotPassword}
                  </Link>
                </div>
                <div className="mt-6 flex flex-col gap-3">
                  {isLoggingIn ? (
                    <LoadingButton
                      buttonText={
                        ApplicationString.loginWithPhone.buttons.loggingIn
                      }
                    />
                  ) : (
                    <Button
                      variant="contained"
                      className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                      color="primary"
                      type="submit"
                    >
                      {ApplicationString.loginWithPhone.buttons.login}
                    </Button>
                  )}

                  <span className="text-secondary_text text-base font-bold flex justify-center">
                    {ApplicationString.loginWithPhone.otherText.or}
                  </span>
                  {isLoggingIn ? (
                    <Button
                      disabled={isLoggingIn}
                      variant="outlined"
                      data-testid="login-with-email-button"
                      className="w-full normal-case cursor-not-allowed  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                    >
                      <span className="flex gap-1 items-center font-bold text-base">
                        <EmailOutlinedIcon className="h-[16px] w-[18px]" />
                        {
                          ApplicationString.loginWithPhone.buttons
                            .continueWithEmail
                        }
                      </span>
                    </Button>
                  ) : (
                    <Link to={InternalRoute.EmailLogin}>
                      <Button
                        variant="outlined"
                        className="w-full normal-case  flex justify-center rounded-4xl py-3 hover:border-slate-950 border-outlineButton text-outlineButton font-bold dark:border-dark_outlineButton dark:text-dark_outlineButton"
                      >
                        <span className="flex gap-1 items-center font-bold text-base">
                          <EmailOutlinedIcon className="h-[16px] w-[18px]" />
                          {
                            ApplicationString.loginWithPhone.buttons
                              .continueWithEmail
                          }
                        </span>
                      </Button>
                    </Link>
                  )}
                </div>
              </FormControl>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-borderStroke flex justify-center items-center  dark:border-dark_borderStroke">
          <span className="p-5 flex gap-1  text-sm sm:text-base ">
            {ApplicationString.loginWithPhone.otherText.dontHaveAccount}
            <Link
              data-testid="login-phone-signUp-link"
              to={InternalRoute.PhoneSignup}
              className="text-primary font-bold"
            >
              {ApplicationString.loginWithPhone.links.signUp}
            </Link>
          </span>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default LogInWithPhoneComponent;

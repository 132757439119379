import { AxiosError, HttpStatusCode } from 'axios';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ApiRequest } from '../../Api/api';
import MybookingComponent from '../../Components/MybookingComponent/MybookingComponent';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import {
  IMyBookings,
  ImyBookingsReview,
} from '../../Interfaces/common/MyBookings/MyBookings';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/LoginInterfaces';
import { useToast } from '../../Services/ToastService';
import { localStorageEnums, myBookingsRatingType } from '../../Utils/enums';
import {
  DefaultPaginationValueMyBookings,
  getLocalStorageAccountInfo,
  showApiError,
} from '../../Utils/utils';

const MybookingContainer: React.FC = () => {
  const [myBookings, setMyBookings] = useState<IMyBookings[]>([]);
  const [searchByType, setSearchByType] = useState<string>('all');
  const [paginationValues, setPaginationValues] = useState(
    DefaultPaginationValueMyBookings
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalBookings, setTotalBookings] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isReviewDetailAvailable, setIsReviewDetailsAvailable] =
    useState<boolean>(false);
  const [reviewId, setReviewId] = useState<number>(0);
  const [ratingModal, setRatingModal] = useState<boolean>(false);
  const [reviewStar, setReviewStar] = useState<number>(0);
  const [currentBookingId, setCurrentBookingId] = useState<number>(0);
  const userInfo = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  );

  // Error Popup
  const toast = useToast();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const constructMyBookingsURL = (
    query = '',
    value: string | number = ''
  ): string => {
    const sortBy = 'createdAt';
    const sortDirection = 'desc';
    let url = `${URLS.MY_BOOKINGS}?pageNumber=${paginationValues.PAGE}&pageSize=${paginationValues.PAGE_SIZE}&sortBy=${sortBy}&sortDirection=${sortDirection}&accountId=${userInfo?.accountId}&excludeSelfBooking=true`;
    if (query) {
      url += `&${query}=${value}`;
    }
    return url;
  };

  const fetchMyBookings = async (url: string): Promise<void> => {
    try {
      // Fetch my bookings
      setLoading(true);
      const res = await ApiRequest.get(url);

      if (res?.status === HttpStatusCode.Ok) {
        setMyBookings(res.data.data);
        setTotalBookings(res.data.total);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setLoading(false);
    }
  };

  // Get bookings review on rate modal open
  // Will get review id and know that user gave rating or not, so if user never gave rating post new rating otherwise use review id and make put req and update it.
  const getReviewDetails = async (id: number): Promise<void> => {
    try {
      const res = await ApiRequest.get(
        URLS.MY_BOOKINGS_GET_REVIEW.replace('#{id}', String(id))
      );
      const vehicleReview = res.data.data?.find(
        (review: ImyBookingsReview) =>
          review?.type === myBookingsRatingType &&
          review?.reviewerAccountId === userInfo?.accountId
      );
      if (vehicleReview) {
        setIsReviewDetailsAvailable(true);
        setReviewId(vehicleReview?.id);
        setReviewStar(vehicleReview?.star);
      } else {
        setIsReviewDetailsAvailable(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  const reviewSubmitHandler = async (
    rating: number,
    isOwnRating: boolean
  ): Promise<void> => {
    if (isOwnRating) {
      toast.error(ApplicationString.MyBookingsRatingComponent.errorMsg);
      return;
    }
    let res;
    const payload = {
      type: myBookingsRatingType,
      star: rating,
    };
    try {
      if (isReviewDetailAvailable) {
        res = await ApiRequest.put(
          URLS.MY_BOOKINGS_PUT_REVIEW.replace(
            '#{id}',
            String(currentBookingId)
          ).replace('#{reviewId}', String(reviewId)),
          payload
        );
      } else {
        if (!payload.star) {
          toast.error(
            ApplicationString.MyBookingDetailsComponent.errorMessage.starError
          );
          return;
        }
        res = await ApiRequest.post(
          URLS.MY_BOOKINGS_POST_REVIEW.replace(
            '#{id}',
            String(currentBookingId)
          ),
          payload
        );
      }

      if (
        res?.status === HttpStatusCode.NoContent ||
        res?.status === HttpStatusCode.Created
      ) {
        scrollToTop();
        toast.success(ApplicationString.MyBookingsRatingComponent.successMsg);
        setRatingModal(false);
        let url = '';
        url =
          searchByType === 'all' || !searchByType
            ? constructMyBookingsURL()
            : constructMyBookingsURL('type', searchByType);

        fetchMyBookings(url);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  const handlePageChange = (page: number): void => {
    setPaginationValues((prevState) => ({
      ...prevState,
      PAGE: page,
    }));
    searchParams.set('pageNumber', String(page));
    setSearchParams(searchParams);
  };

  const handleFilter = (value: string): void => {
    const newValue = value === 'all' ? '' : value;
    setSearchByType(newValue);
    setSearchParams({ type: value, pageNumber: '1' });
  };

  useEffect(() => {
    const typeParam = searchParams.get('type') || '';
    if (typeParam) {
      setSearchByType(typeParam);
    }

    const pageNumberParam = searchParams.get('pageNumber');

    let url: string;
    let currentValidPageNumber = 1;

    const fetchAndValidatePage = async () => {
      setLoading(true);
      url =
        typeParam === 'all' || !typeParam
          ? constructMyBookingsURL()
          : constructMyBookingsURL('type', typeParam);

      const res = await ApiRequest.get(url);

      if (res?.status === HttpStatusCode.Ok) {
        setMyBookings(res.data.data);
        setTotalBookings(res.data.total);

        const maxPage = Math.ceil(res.data.total / paginationValues.PAGE_SIZE);
        if (Number(pageNumberParam) > maxPage) {
          currentValidPageNumber = 1;
          setSearchParams({ type: typeParam, pageNumber: '1' });
        } else {
          currentValidPageNumber = Number(pageNumberParam) || 1;
        }

        setPaginationValues((prevState) => ({
          ...prevState,
          PAGE: currentValidPageNumber,
        }));
      }
      setLoading(false);
    };

    fetchAndValidatePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, paginationValues.PAGE]);

  useEffect(() => {
    scrollToTop();
  }, [paginationValues.PAGE]);

  return (
    <MybookingComponent
      myBookings={myBookings}
      handleFilter={handleFilter}
      totalBookings={totalBookings}
      handlePageChange={handlePageChange}
      paginationValues={paginationValues}
      searchByType={searchByType}
      loading={loading}
      getReviewDetails={getReviewDetails}
      reviewSubmitHandler={reviewSubmitHandler}
      ratingModal={ratingModal}
      reviewStar={reviewStar}
      setRatingModal={setRatingModal}
      setCurrentBookingId={setCurrentBookingId}
    />
  );
};

export default MybookingContainer;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dataReducer from './Slices/categoryDataSlice';

export const store = configureStore({
  reducer: {
    data: dataReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ApplicationString from '../../../Constants/applicationString';
import { IMyBookings } from '../../../Interfaces/common/MyBookings/MyBookings';
import chevronSvg from '../../../Assets/Svgs/chevron.svg';
import { formatSingleOrDateRange } from '../../../Utils/utils';
import { timeFormat } from '../../../Constants/commonConst';
import InternalRoute from '../../../Utils/internalRoutes';
import SendBirdChatModal from '../../ChatModal';
import { myBookingsStatusEnums } from '../../../Utils/enums';

interface IOwnerDashboardTableComponentProps {
  booking: IMyBookings;
}

const OwnerDashboardBookingsTable: React.FC<
  IOwnerDashboardTableComponentProps
> = (props): JSX.Element => {
  const { booking } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const navigateToBookingDetailsHandler = (target: HTMLElement): void => {
    const actionButtons = Array.from(
      document.getElementsByClassName('action-button')
    );

    // skip navigation when any action button clicked
    const skipNavigate = actionButtons.some((element) => target === element);

    if (!skipNavigate) {
      const bookingDetailsURL = InternalRoute.OwnerBookingDetails.replace(
        ':id',
        String(booking.id)
      );
      navigate(bookingDetailsURL);
    }
  };

  const vehicleImagePath = (): string => {
    // find the cover image and set as a vehicle image
    return booking.vehicleDetail.images.find((img) => img.isCover)?.path || '';
  };

  return (
    <>
      {open && (
        // just to avoid initial rendering of modal component
        <SendBirdChatModal
          open={open}
          bookingId={booking.id}
          recipientId={booking.renterDetails.id}
          hideChatModal={() => setOpen(false)}
        />
      )}
      <div
        tabIndex={0}
        role="button"
        className="w-full bg-white p-4 rounded-md midXL:last:rounded-br-3xl midXL:last:rounded-bl-3xl midXL:rounded-[0px] dark:bg-[#292929] relative midXL:shadow-none shadow-[0px_4px_16px_0px_#1212120F] midXL:hover:bg-slate-200 midXL:hover:dark:bg-slate-700"
        onClick={(event) =>
          navigateToBookingDetailsHandler(event.target as HTMLElement)
        }
        data-testid="dashboard-booking-vehicle-card"
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === 'Enter') {
            navigateToBookingDetailsHandler(event.target as HTMLElement);
          }
        }}
      >
        <div className="flex max-[1200px]:flex-col flex-row gap-2">
          <div className="flex gap-2 w-[75%] max-[1200px]:w-full">
            <div
              className="flex items-center gap-5 w-[39%] max-[1200px]:w-full cursor-pointer"
              tabIndex={0}
              role="button"
              data-testid="dashboard-vehicle-img-button"
            >
              <img
                src={vehicleImagePath()}
                alt=""
                className="aspect-square w-[75px] rounded-xl"
              />
              <div className="w-full flex gap-3 midXL:flex-row flex-col flex-wrap">
                <h2 className="font-bold break-all">
                  {booking.vehicleDetail.title}
                </h2>
                <div className="hidden flex-col w-[30%] justify-center max-[1200px]:flex max-[1200px]:w-full sm:text-base text-xs gap-1 sm:gap-0">
                  <span className="font-medium">
                    {formatSingleOrDateRange([
                      booking?.startDate,
                      booking?.endDate,
                    ])}{' '}
                  </span>
                  <span className="text-secondary_text dark:text-[#B8B8B8]">
                    {`${dayjs.utc(booking?.startDate).format(timeFormat)} - ${dayjs.utc(booking?.endDate).format(timeFormat)}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[33%] justify-center max-[1200px]:hidden">
              <span className="text-base font-medium break-all">
                {formatSingleOrDateRange([
                  booking?.startDate,
                  booking?.endDate,
                ])}{' '}
              </span>
              <span className="text-secondary_text dark:text-[#B8B8B8] break-all">
                {`${dayjs.utc(booking?.startDate).format(timeFormat)} - ${dayjs.utc(booking?.endDate).format(timeFormat)}`}
              </span>
            </div>
            <div className="flex items-center gap-4 w-[13%] max-[1200px]:hidden break-all">
              <span className="font-medium block">${booking.total || 0}</span>
            </div>
            <div className="flex items-center gap-4 w-[13%] max-[1200px]:hidden">
              <span className="font-medium block break-all">
                {booking.renterDetails.name}
              </span>
            </div>
          </div>
          <hr className="hidden max-[1200px]:block dark:opacity-20" />
          <div className="flex items-center gap-1 w-[22.5%] max-[1200px]:w-[100%] max-[1200px]:justify-between">
            <div className="hidden max-[1200px]:block text-secondary_text dark:text-[#B8B8B8] sm:text-sm text-xs">
              {ApplicationString.OwnerDashboard.instantBooking.bookingPrice}:
              <span className="text-black dark:text-white font-bold break-all">
                {' '}
                &nbsp; ${booking.total || 0}
              </span>
            </div>
            <div
              className={`flex items-center ${booking.type === myBookingsStatusEnums.active ? 'justify-between' : 'justify-end'} gap-4 midXL:w-full px-4`}
            >
              {booking.type === myBookingsStatusEnums.active && (
                <div className="flex gap-2 sm:gap-4">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(true)}
                    data-testid="dashboard-sendBird-chat-btn"
                    className="sendBird-chat-btn action-button flex justify-center rounded-4xl hover:bg-primary/80 normal-case sm:text-sm text-xs font-bold sm:py-3 py-2 px-6  text-white bg-primary"
                  >
                    {ApplicationString.OwnerDashboard.bookings.chat}
                  </Button>
                </div>
              )}
              <div
                tabIndex={0}
                role="button"
                className="cursor-pointer max-[1200px]:absolute right-4 top-[39%]"
                onClick={(event) =>
                  navigateToBookingDetailsHandler(event.target as HTMLElement)
                }
                data-testid="dashboard-more-details-btn"
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter') {
                    navigateToBookingDetailsHandler(
                      event.target as HTMLElement
                    );
                  }
                }}
              >
                <img
                  src={chevronSvg}
                  aria-label="button"
                  alt={
                    ApplicationString.OwnerDashboard.instantBooking
                      .moreDetailsChevronAltTxt
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDashboardBookingsTable;

import { AxiosError, HttpStatusCode } from 'axios';
import React from 'react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequest } from '../../Api/api';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import commmonRegexs from '../../Utils/commonRegex';
import {
  loginSignupFormEnums,
  signupPasswordStrengthEnums,
} from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import ForgotPasswordFormComponent from '../../Components/ForgotPasswordComponent/ForgotPasswordFormComponent';

interface IErrorsData {
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  confirmPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface IPasswordQuality {
  passwordQuality: string;
  passwordMsgColor: string;
  passwordQualityIcon: JSX.Element | null;
}

interface IFormData {
  password: string;
  confirmPassword: string;
}

interface IForgotPasswordPayload {
  password: string;
}

const ForgotPasswordFormContainer: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams({});
  const verificationId = atob(params.get('verificationId') || '');
  const [isSigningUp, setIsSigningUp] = React.useState<boolean>(false);
  const [passwordQuality, setPasswordQuality] =
    React.useState<IPasswordQuality>({
      passwordQuality: '',
      passwordMsgColor: '',
      passwordQualityIcon: null,
    });
  const [formData, setFormData] = React.useState<IFormData>({
    password: '',
    confirmPassword: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    password: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    confirmPassword: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
  });

  const onSignupSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (
      !commmonRegexs.signupPassword.test(formData.password) &&
      formData.password.trim() !== '' &&
      formData.confirmPassword.trim() !== ''
    )
      return;

    setErrorsData((prevState) => ({
      ...prevState,
      password: {
        error: !formData.password.trim(),
        ValidationMessage: !formData.password.trim()
          ? ApplicationString.NameAndPasswordSignUp.validationMsgs.requiredMsgs
              .password
          : '',
      },
      confirmPassword: {
        error: !formData.confirmPassword.trim(),
        ValidationMessage: !formData.confirmPassword.trim()
          ? ApplicationString.NameAndPasswordSignUp.validationMsgs.requiredMsgs
              .confirmPassword
          : '',
      },
    }));

    if (formData.password.trim() !== formData.confirmPassword.trim()) {
      setErrorsData((prevState) => ({
        ...prevState,
        confirmPassword: {
          error: true,
          ValidationMessage:
            ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
              .confirmPassword,
        },
      }));
      return;
    }

    if (!formData.password.trim() || !formData.confirmPassword.trim()) return;

    const payload: IForgotPasswordPayload = {
      password: formData.password,
    };

    try {
      setIsSigningUp(true);
      const response = await ApiRequest.put(
        `${URLS.USER_FORGOT_PASSWORD_CREATE_NEW.replace('#{id}', verificationId)}`,
        payload
      );
      if (response?.status === HttpStatusCode.NoContent) {
        navigate(InternalRoute.ForgotPasswordSuccess);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      if (axiosError?.response && axiosError.response?.data) {
        if (axiosError.response.status === 400) {
          setErrorsData((prevState) => ({
            ...prevState,
            confirmPassword: {
              error: false,
              onlyErrorMsg: true,
              ValidationMessage: axiosError.response?.data?.message || '',
            },
          }));
        }
      }
    } finally {
      setIsSigningUp(false);
    }
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let showError = false;
    let errorMsg = '';

    let checkConfirmPassword = false;
    let checkConfirmPasswordMsg = '';

    const password = e.target.value.trim();

    // Check password strength
    const hasSpecialChar = commmonRegexs.hasSpecialChar.test(password);
    const hasCapitalLetter = commmonRegexs.hasCapitalLetter.test(password);
    const hasSmallLetter = commmonRegexs.hasSmallLetter.test(password);
    const hasDigit = commmonRegexs.hasDigit.test(password);

    let quality = '';
    let qualityColor = '';
    let qualityIcon: JSX.Element | null = null;

    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');

    if (
      password.length >= 6 &&
      hasSpecialChar &&
      hasCapitalLetter &&
      hasSmallLetter &&
      hasDigit
    ) {
      quality = signupPasswordStrengthEnums.strong;
      qualityColor = 'text-green-500';
      qualityIcon = <EmojiEmotionsOutlinedIcon className="text-green-500" />;
    } else if (
      password.length >= 6 &&
      (hasSpecialChar || hasCapitalLetter || hasDigit || hasSmallLetter)
    ) {
      quality = signupPasswordStrengthEnums.medium;
      qualityColor = 'text-yellow-500';
      qualityIcon = (
        <SentimentSatisfiedOutlinedIcon className="text-yellow-500" />
      );
    } else {
      quality = signupPasswordStrengthEnums.weak;
      qualityColor = 'text-red-500';
      qualityIcon = (
        <SentimentDissatisfiedOutlinedIcon className="text-red-500" />
      );
    }

    if (
      trimmedPassword !== '' &&
      formData.confirmPassword.trim() !== '' &&
      trimmedPassword !== formData.confirmPassword.trim()
    ) {
      checkConfirmPassword = true;
      checkConfirmPasswordMsg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .confirmPassword;
    }

    if (
      !commmonRegexs.signupPassword.test(trimmedPassword) &&
      trimmedPassword !== ''
    ) {
      showError = true;
      errorMsg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .password;
    }

    if (trimmedPassword.length > loginSignupFormEnums.maxPasswordLength) {
      showError = true;
      errorMsg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .passwordLength;
    }

    setFormData((prevState) => ({
      ...prevState,
      password: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      password: {
        error: showError,
        ValidationMessage: errorMsg,
      },
      confirmPassword: {
        error: checkConfirmPassword,
        ValidationMessage: checkConfirmPasswordMsg,
      },
    }));

    setPasswordQuality((prevState) => ({
      ...prevState,
      passwordQuality: quality,
      passwordMsgColor: qualityColor,
      passwordQualityIcon: qualityIcon,
    }));
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let showError = false;
    let errorMsg = '';

    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');

    if (formData.password !== trimmedPassword) {
      showError = true;
      errorMsg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .confirmPassword;
    }
    if (trimmedPassword === '') {
      showError = false;
      errorMsg = '';
    }
    setFormData((prevState) => ({
      ...prevState,
      confirmPassword: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      confirmPassword: {
        error: showError,
        ValidationMessage: errorMsg,
      },
    }));
  };

  return (
    <ForgotPasswordFormComponent
      onSignupSubmit={onSignupSubmit}
      handlePasswordChange={handlePasswordChange}
      handleConfirmPasswordChange={handleConfirmPasswordChange}
      formData={formData}
      errorsData={errorsData}
      isSigningUp={isSigningUp}
      passwordQuality={passwordQuality}
    />
  );
};

export default ForgotPasswordFormContainer;

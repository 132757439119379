import React from 'react';
import cssUtils from '../../Utils/cssUtils';
import OwnerBookingComponent from './ownerBookingDetailsComponent';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { ITermsAndCondition } from '../../Interfaces/Settings/SettingsInterfaces';

interface OwnerBookingDetailsCoponentProps {
  loading: boolean;
  ownerBookingDetails: IMyBookings;
  TermsAndConditions: ITermsAndCondition[];
  requestModal: boolean;
  handleCloseRequestModal: () => void;
  handleOpenRequestModal: () => void;
  onRejectHandler: () => void;
  onAcceptHandler: () => void;
}
const OwnerBookingDetailsComponent: React.FC<
  OwnerBookingDetailsCoponentProps
> = ({
  loading,
  ownerBookingDetails,
  TermsAndConditions,
  requestModal,
  handleCloseRequestModal,
  handleOpenRequestModal,
  onRejectHandler,
  onAcceptHandler,
}) => {
  return (
    <div className="w-full bg-bg_light_primary dark:bg-dark_bg_surface">
      <div className={cssUtils.layout.sectionLayout}>
        <OwnerBookingComponent
          loading={loading}
          ownerBookingDetails={ownerBookingDetails}
          TermsAndConditions={TermsAndConditions}
          requestModal={requestModal}
          handleCloseRequestModal={handleCloseRequestModal}
          handleOpenRequestModal={handleOpenRequestModal}
          onRejectHandler={onRejectHandler}
          onAcceptHandler={onAcceptHandler}
        />
      </div>
    </div>
  );
};

export default OwnerBookingDetailsComponent;

import { Button } from '@mui/material';
import React from 'react';
import ApplicationString from '../../Constants/applicationString';
import { CancelBookingCalenderIcon } from '../../Assets/Svgs';
import LoadingButton from '../common/LoadingButton/LoadingButton';

type RequestedStatusProps = {
  onRejectHandler: () => void;
  handleCloseRequestModal: () => void;
  loading: boolean;
  onAcceptHandler: () => void;
  isAcceptBooking: boolean;
};

const OwnerBookingRequestedComponent: React.FC<RequestedStatusProps> = ({
  onRejectHandler,
  handleCloseRequestModal,
  loading,
  onAcceptHandler,
  isAcceptBooking,
}) => {
  return (
    <div className="w-[90vw] max-w-125 min-h-max bg-white dark:bg-dark_bg_secondary p-6 rounded-2xl flex flex-col gap-1">
      <div>
        <CancelBookingCalenderIcon />
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-xl sm:text-2xl font-bold text-primary_text dark:text-dark_primary_text ">
          {
            ApplicationString.OwnerBookingDetails.OwnerBookingRequestStatus
              .requestBookingTitle
          }
        </h1>
        <h3 className="text-base font-normal text-secondary_text dark:text-dark_secondary_text">
          {isAcceptBooking
            ? ApplicationString.OwnerBookingDetails.OwnerBookingRequestStatus
                .acceptConfirmation
            : ApplicationString.OwnerBookingDetails.OwnerBookingRequestStatus
                .rejectConfirmation}
        </h3>
      </div>
      <div className="flex gap-2 mt-6">
        {loading ? (
          <LoadingButton
            buttonText={
              isAcceptBooking
                ? ApplicationString.OwnerBookingDetails
                    .OwnerBookingRequestStatus.onAcceptLoadingTitle
                : ApplicationString.OwnerBookingDetails
                    .OwnerBookingRequestStatus.onRejectLoadingTitle
            }
            extraBtnClasses="bg-transparent border border-black"
            extraTxtClasses="text-primary_text dark:text-dark_primary_text"
          />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            data-testid="Owner-booking-details-accept-button"
            onClick={isAcceptBooking ? onAcceptHandler : onRejectHandler}
            className="w-full flex justify-center rounded-4xl normal-case sm:text-base font-bold sm:py-3 border-dark_bg_surface text-dark_bg_surface dark:border-dark_primary_text dark:text-dark_primary_text"
          >
            {isAcceptBooking
              ? ApplicationString.OwnerBookingDetails.bookingStatus
                  .acceptBooking
              : ApplicationString.OwnerBookingDetails.bookingStatus
                  .rejectBooking}
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          data-testid="Owner-booking-details-go-back-button"
          onClick={handleCloseRequestModal}
          className="w-full flex justify-center rounded-4xl normal-case sm:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
        >
          {ApplicationString.MyBookingsCancelComponent.goBackBtn}
        </Button>
      </div>
    </div>
  );
};

export default OwnerBookingRequestedComponent;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from '../../../Assets/Images/OwnerFirstv3.png';
import cssUtils from '../../../Utils/cssUtils';
import ApplicationString from '../../../Constants/applicationString';
import './style.css';
import InternalRoute from '../../../Utils/internalRoutes';

const CreateVehicleFirstScreen: React.FC = () => {
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(InternalRoute.vehicleCreate);
  };

  return (
    <div className="flex create-listing-Container  flex-col items-center justify-center gap-8">
      <div className="relative flex justify-center items-center rounded-full">
        <div className="">
          <img
            src={img1}
            alt="Yacht"
            className="rounded-lg object-cover w-full h-full"
          />
        </div>
      </div>
      <div className="lg:mt-20 flex flex-col gap-4 items-center">
        <p className="text-black  sm:text-3xl dark:text-white font-bold">
          {ApplicationString.CreateVehicleFirstScreen.titleText}
        </p>
        <button
          type="button"
          onClick={onNavigate}
          data-testid="first-listing-vehicle-btn"
          className={`w-full sm:min-w-100 lg:mt-10 ${cssUtils.button.secondary}`}
        >
          {ApplicationString.CreateVehicleFirstScreen.buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default CreateVehicleFirstScreen;

import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';
import URLS from '../Constants/urls';
import { removeLocalStorageAccountInfoOnDeleteOrLogout } from '../Utils/utils';
import { ApiRequest } from '../Api/api';
import { navigationKeys } from '../Utils/enums';

interface ILogoutHook {
  logout: () => void;
}

const useLogoutHook = (): ILogoutHook => {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      const res = await ApiRequest.post(URLS.USER_LOGOUT, {
        appType: 'renter',
      });
      if (res?.status === HttpStatusCode.NoContent) {
        removeLocalStorageAccountInfoOnDeleteOrLogout(
          navigate,
          navigationKeys.logout
        );
      }
    } catch (error) {
      console.error('Error while logging out:', error);
    }
  };

  return { logout };
};

export default useLogoutHook;

import React from 'react';
import Accordion from '../../common/Accordian';
import ApplicationString from '../../../Constants/applicationString';

interface AccordionData {
  accordionData: AccordionItem[];
}

interface AccordionItem {
  id: number;
  question: string;
  answer: string;
}

const FaqsComponent: React.FC<AccordionData> = ({ accordionData }) => {
  const settingFaqs = accordionData;
  return (
    <div className="dark:bg-dark_bg_surface">
      <Accordion
        darkTheme=""
        title={ApplicationString.accordion.title}
        accordionData={settingFaqs}
      />
    </div>
  );
};

export default FaqsComponent;

import axios from 'axios';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { getDetailsFromLocalStorage } from '../Utils/utils';

interface ISendBirdUserPayload {
  user_id: string;
  nickname: string;
  profile_url: string;
}

interface ICreateOrUpdateUserPayload {
  currentUserId: string;
  userName: string;
  avatarPath: string;
}

class SendBirdService {
  static APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID as string;

  static API_TOKEN = process.env.REACT_APP_SENDBIRD_API_TOKEN as string;

  static API_URL_USER = `https://api-${this.APP_ID}.sendbird.com/v3/users`;

  static API_URL_CHANNEL = `https://api-${this.APP_ID}.sendbird.com/v3/group_channels`;

  static getUserId = (): string => {
    const currentUserId = getDetailsFromLocalStorage<{ accountId: string }>(
      'userInfo'
    )?.accountId as string;
    return currentUserId;
  };

  // eslint-disable-next-line consistent-return, @typescript-eslint/explicit-module-boundary-types
  static getUser = async (): Promise<boolean> => {
    try {
      await axios.get(`${this.API_URL_USER}/${this.getUserId()}`, {
        headers: {
          'Api-Token': this.API_TOKEN,
        },
      });

      return true;
    } catch (error) {
      // console.log(error, 'Error While getting user');
      return false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  static connectUser = async (userId: string, globalStore: any) => {
    const connect = sendbirdSelectors.getConnect(globalStore);
    try {
      await connect(userId);
    } catch (error) {
      // console.log('error while connecting user', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  static disconnectUser = async (globalStore: any) => {
    const disconnect = sendbirdSelectors.getDisconnect(globalStore);
    try {
      await disconnect();
    } catch (error) {
      // console.log('error while disconnecting user', error);
    }
  };

  // eslint-disable-next-line consistent-return, @typescript-eslint/explicit-module-boundary-types
  static createUser = async (payload: ISendBirdUserPayload) => {
    try {
      const user = await axios.post(this.API_URL_USER, payload, {
        headers: {
          'Api-Token': this.API_TOKEN,
        },
      });

      return user.data;
    } catch (error) {
      console.log(error, 'Error While creating user');
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static updateUser = async (payload: Partial<ISendBirdUserPayload>) => {
    try {
      const user = await axios.put(
        `${this.API_URL_USER}/${this.getUserId()}`,
        payload,
        {
          headers: {
            'Api-Token': this.API_TOKEN,
          },
        }
      );

      return user.data;
    } catch (error) {
      // console.log(error, 'Error While updating user');
      return false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static handleUserCreationOrUpdate = async ({
    currentUserId,
    userName,
    avatarPath,
  }: ICreateOrUpdateUserPayload) => {
    try {
      // await this.connectUser(currentUserId);
      const isUserExist: boolean = await this.getUser();

      if (!isUserExist && currentUserId) {
        await this.createUser({
          user_id: currentUserId,
          nickname: userName,
          profile_url: avatarPath,
        });
      } else {
        await this.updateUser({
          nickname: userName,
          profile_url: avatarPath,
        });
      }
    } catch (error) {
      console.log(error, 'error while handle User Creation Or Update');
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getChannelByURL = async (channelUrl: string) => {
    try {
      // GET https://api-{application_id}.sendbird.com/v3/group_channels/{channel_url}
      const channelResponse = await axios.get(
        `${this.API_URL_CHANNEL}/${channelUrl}`,
        {
          headers: {
            'Api-Token': this.API_TOKEN,
          },
        }
      );
      return channelResponse.data;
    } catch (error) {
      return false;
    }
  };
}

export default SendBirdService;

import React, { Dispatch, SetStateAction } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { IProfile } from '../../../Interfaces/common/Profile';
import ApplicationString from '../../../Constants/applicationString';
import EditProfileComponent from '../EditProfile';
import { IDisableFields, IerrorsData } from '../../../Interfaces/interfaces';

interface IProfileFormData {
  name: string;
  mobile: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  countryCode: string;
}
interface IErrorsData {
  countryCode: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  address: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  name: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  city: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  state: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  country: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  zip: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  email: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  mobile: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}
interface ProfileFormProps {
  profileData: IProfile;
  goBack: () => void;
  handleReset: () => void;
  resetError: () => void;
  isDataLoading: boolean;
  countryCodeOptions: string[];
  formData: IProfileFormData;
  isLoading: boolean;
  isEditing: boolean;
  errorsData: IErrorsData;
  fileUploadError: string;
  setIsUploadingProfilePicture: Dispatch<SetStateAction<boolean>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  handleNameChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handlePhoneNumberChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleEmailChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAddressChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAddress2Change: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCityChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleStateChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCountryChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleZipCodeChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCountryCodeChange: (e: SelectChangeEvent<string>) => void;
  onAccountDetailsSubmit: (e: React.FormEvent) => void;
  uploadProfilePicture: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  imagePath: string;
  isUploadingProfilePicture: boolean;
  handleVerification: () => void;
  handleClose: () => void;
  verifyTheOtp: () => Promise<void>;
  setOtp: Dispatch<SetStateAction<string>>;
  otp: string;
  isModalOpen: boolean;
  isVerify: boolean;
  isVerifyingOtp: boolean;
  verificationerror: IerrorsData;
  setIsVerify: Dispatch<SetStateAction<boolean>>;
  isFieldDisabled: IDisableFields;
  setIsFieldDisabled: Dispatch<SetStateAction<IDisableFields>>;
}
const ProfileFormComponent: React.FC<ProfileFormProps> = ({
  profileData,
  goBack,
  isDataLoading,
  handleReset,
  resetError,
  countryCodeOptions,
  formData,
  isLoading,
  isEditing,
  setIsEditing,
  errorsData,
  imagePath,
  isUploadingProfilePicture,
  setIsUploadingProfilePicture,
  handleNameChange,
  handlePhoneNumberChange,
  handleEmailChange,
  handleAddressChange,
  handleCityChange,
  handleStateChange,
  handleCountryChange,
  handleZipCodeChange,
  handleCountryCodeChange,
  fileUploadError,
  handleAddress2Change,
  uploadProfilePicture,
  onAccountDetailsSubmit,
  handleVerification,
  handleClose,
  verifyTheOtp,
  setOtp,
  otp,
  isModalOpen,
  isVerify,
  isVerifyingOtp,
  verificationerror,
  setIsVerify,
  isFieldDisabled,
  setIsFieldDisabled,
}) => {
  // const actualNumber = profileData.mobile?.toString().split('-')[1];
  const startEdit = () => {
    setIsEditing(true);
    setIsVerify(false);
    setOtp('');
  };
  const cancelEdit = () => {
    setOtp('');
    setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: false });
    setIsEditing(false);
    handleReset();
    resetError();
    setIsVerify(false);
  };
  function getAddressString(data: IProfile) {
    const addressParts = [
      data?.address?.trim(),
      data?.city?.trim(),
      data?.state?.trim(),
      data?.country?.trim(),
      data?.zip?.trim(),
    ];

    const filteredParts = addressParts.filter((part) => part !== '');

    const addressString = filteredParts.reduce((acc, part) => {
      if (part) {
        return acc ? `${acc}, ${part}` : part;
      }
      return acc;
    }, '');

    return addressString || 'N/A';
  }
  return (
    <div className="w-full">
      {isDataLoading ? (
        <CircularProgress
          size={50}
          className="flex md:justify-center mx-auto"
        />
      ) : (
        <div className="">
          {!isEditing ? (
            <>
              <ChevronLeftIcon
                onClick={goBack}
                data-testid="my-account-back-button"
                className="md:hidden text-2xl mb-8"
              />
              <Typography
                fontSize={28}
                fontWeight={400}
                className="text-nowrap text-[#1F1F1F] dark:text-white uppercase text-lg lg:text-xl 2xl:text-2xl"
              >
                {ApplicationString.ProfileScreenLables.accountInfo}
              </Typography>
              <Divider className="mt-2" />
              <div className=" flex flex-row justify-start items-center mt-4">
                <img
                  src={profileData.avatarPath}
                  alt=""
                  className=" w-30
            h-30 rounded-full object-cover"
                />
              </div>
              <Divider className="mt-4" />
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <Typography className="text-secondary_text dark:text-dark_secondary_text  text-lg sm:text-xl 2xl:text-2xl font-normal">
                    {ApplicationString.ProfileScreenLables.name}
                  </Typography>
                  <Typography className="text-black text-sm sm:text-lg 2xl:text-xl dark:text-white font-bold">
                    {profileData && profileData.name !== null
                      ? profileData.name
                      : 'N/A'}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography className="text-secondary_text dark:text-dark_secondary_text  text-lg sm:text-xl 2xl:text-2xl font-normal">
                    {ApplicationString.ProfileScreenLables.email}
                  </Typography>
                  <Typography className="text-black dark:text-white text-sm sm:text-lg 2xl:text-xl font-bold">
                    {profileData && profileData.email !== null
                      ? profileData.email
                      : 'N/A'}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography className="text-secondary_text  text-lg sm:text-xl 2xl:text-2xl dark:text-dark_secondary_text font-normal">
                    {ApplicationString.ProfileScreenLables.mobile}
                  </Typography>
                  <Typography className="text-black text-sm sm:text-lg 2xl:text-xl dark:text-white font-bold">
                    {profileData && profileData.mobile !== null
                      ? profileData.mobile
                      : 'N/A'}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography className="text-secondary_text  text-lg sm:text-xl 2xl:text-2xl dark:text-dark_secondary_text font-normal">
                    {ApplicationString.ProfileScreenLables.address}
                  </Typography>
                  <Typography className="text-black text-sm sm:text-lg 2xl:text-xl dark:text-white font-bold">
                    {getAddressString(profileData && profileData)}
                  </Typography>
                </div>
              </div>
              <Divider className="mt-4" />
              <div className="mt-6">
                <Button
                  className=" capitalize rounded-full px-20 gap-4 text-white bg-primary hover:bg-primary"
                  data-testid="my-account-edit-button"
                  onClick={startEdit}
                >
                  {ApplicationString.ProfileScreenLables.edit}
                </Button>
              </div>
            </>
          ) : (
            <EditProfileComponent
              profileData={profileData}
              countryCodeOptions={countryCodeOptions}
              formData={formData}
              fileUploadError={fileUploadError}
              isLoading={isLoading}
              imagePath={imagePath}
              isUploadingProfilePicture={isUploadingProfilePicture}
              errorsData={errorsData}
              uploadProfilePicture={uploadProfilePicture}
              handleNameChange={handleNameChange}
              handlePhoneNumberChange={handlePhoneNumberChange}
              handleEmailChange={handleEmailChange}
              handleAddressChange={handleAddressChange}
              handleAddress2Change={handleAddress2Change}
              handleCityChange={handleCityChange}
              handleZipCodeChange={handleZipCodeChange}
              onAccountDetailsSubmit={onAccountDetailsSubmit}
              handleCountryChange={handleCountryChange}
              handleStateChange={handleStateChange}
              onBack={cancelEdit}
              handleCountryCodeChange={handleCountryCodeChange}
              setIsUploadingProfilePicture={setIsUploadingProfilePicture}
              handleVerification={handleVerification}
              handleClose={handleClose}
              verifyTheOtp={verifyTheOtp}
              isVerifyingOtp={isVerifyingOtp}
              setOtp={setOtp}
              otp={otp}
              isVerify={isVerify}
              verificationerror={verificationerror}
              isModalOpen={isModalOpen}
              isFieldDisabled={isFieldDisabled}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileFormComponent;

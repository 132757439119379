const oneLifeThemeColors = {
  // All light theme colors
  lightTheme: {
    primary: '#1A56DB',
    primary_text: '#1F1F1F',
    secondary_text: '#6B7280',
    title_text: '#283B64',
    button_bg: '#E5E7EB',
    page_bg: '#F3F4F6',
    error: '#F30C0C',
    odd_bg: '#E4EBF1',
    formFieldBg: '#F7FBFF',
    outlineButton: '#292929',
    borderStroke: '#D1D5DB',
    otpBoxBorder: '#D8DADC',
    ratingStar: '#F2B42C',
    bg_secondary: '#F3F4F6',
    card_bg: '#FFFFFF',
    web_accent: '#4968AC',
    bg_light_primary: '#F3F4F6',
    light_text: '#6E6E6E',
  },
  // All dark theme colors
  darkTheme: {
    primary: '#1A56DB',
    blue_primary: '#272D3B',
    bg_primary: '#1F1F1F',
    bg_gray_primary: '##E4EBF1',
    text_web_accent: '#4968AC',
    text_sub_primary: '#B8B8B8',
    bg_secondary: '#292929',
    bg_surface: '#1F1F1F',
    brand_300: '#A4CAFE',
    primary_text: '#FFFFFF',
    secondary_text: '#B8B8B8',
    border_color: '#515151',
    error: '#F30C0C',
    outlineButton: '#FAFAFA',
    formFieldBg: '#333333',
    borderStroke: '#515151',
    bg_Stroke: '#515151',
    otpBoxBorder: '#D8DADC',
    title_text: '#4968AC',
    web_accent: '#4968AC',
  },
};

export const name = 'react';
export default oneLifeThemeColors;

import React, { useContext, useState } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import DoneIcon from '@mui/icons-material/Done';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { Backdrop, Box, Button, Fade } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import MybookingsRatingComponent from './MybookingsRatingComponent';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import ApplicationString from '../../Constants/applicationString';
import { myBookingsStatusEnums } from '../../Utils/enums';
import ColorModeContext from '../../Utils/ColorModeContext';
import InternalRoute from '../../Utils/internalRoutes';
import { formatSingleOrDateRange, getCoverPhoto } from '../../Utils/utils';
import SendBirdChatModal from '../ChatModal';

interface ImyBookingCardProps {
  booking: IMyBookings;
  getReviewDetails: (id: number) => void;
  reviewSubmitHandler: (rating: number, isOwnRating: boolean) => void;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  ratingModal?: boolean;
  reviewStar: number;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  setRatingModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentBookingId: React.Dispatch<React.SetStateAction<number>>;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

dayjs.extend(utc);

const MybookingsCard: React.FC<ImyBookingCardProps> = ({
  booking,
  getReviewDetails,
  reviewSubmitHandler,
  reviewStar,
  setCurrentBookingId,
}) => {
  const navigate = useNavigate();
  const { currentTheme } = useContext(ColorModeContext);
  const [ratingModal, setRatingModal] = useState<boolean>(false);
  const { id, type, startDate, endDate, vehicleDetail } = booking;
  const {
    title,
    rating,
    city,
    country,
    bookingRateHourly,
    bookingRate12Hourly,
    bookingRate24Hourly,
    images,
    reviewCount,
  } = vehicleDetail;
  const coverPhoto = getCoverPhoto(images);

  function formatLocation(str1: string, str2: string) {
    if (str1 && str2) {
      return `${str1}, ${str2}`;
    }
    if (str1) {
      return `${str1}, ${ApplicationString.commonStrings.notAvailable}`;
    }
    if (str2) {
      return `${ApplicationString.commonStrings.notAvailable}, ${str2}`;
    }
    return ApplicationString.commonStrings.notAvailable;
  }

  const [open, setOpen] = React.useState(false);

  const showBookingRate = () => {
    if (bookingRateHourly) {
      return (
        <>
          <span
            className={`text-lg sm:text-xl 2xl:text-[22px] 4xl:text-2xl ${type === myBookingsStatusEnums.completed ? 'text-secondary_text' : 'text-primary'} font-bold`}
          >
            ${bookingRateHourly}
          </span>
          <span className="text-sm sm:text-base 2xl:text-[18px] 4xl:text-[20px] text-secondary_text dark:text-dark_secondary_text ">
            {ApplicationString.MyBookingsListing.otherText.perHour}
          </span>
        </>
      );
    }

    if (bookingRate12Hourly) {
      return (
        <>
          <span
            className={`text-xl sm:text-2xl 2xl:text-[22px] 4xl:text-2xl ${type === myBookingsStatusEnums.completed ? 'text-secondary_text' : 'text-primary'} font-bold`}
          >
            ${bookingRate12Hourly}
          </span>
          <span className="text-sm sm:text-base 2xl:text-[18px] 4xl:text-[20px] text-secondary_text dark:text-dark_secondary_text ">
            {ApplicationString.MyBookingsListing.otherText.per12Hour}
          </span>
        </>
      );
    }

    return (
      <>
        <span
          className={`text-xl sm:text-2xl  2xl:text-[22px] 4xl:text-2xl ${type === myBookingsStatusEnums.completed ? 'text-secondary_text' : 'text-primary'} font-bold`}
        >
          ${bookingRate24Hourly}
        </span>
        <span className="text-sm sm:text-base 2xl:text-[18px] 4xl:text-[20px] text-secondary_text dark:text-dark_secondary_text ">
          {ApplicationString.MyBookingsListing.otherText.per24Hour}
        </span>
      </>
    );
  };

  const showStatusBadgeBasedOnType = (): JSX.Element => {
    if (type === myBookingsStatusEnums.completed) {
      return (
        <div className="flex gap-1 absolute right-2 bottom-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-green-500 items-center px-2 py-0.5">
          <DoneIcon className="text-green-500 size-4" />
          <h2 className="font-bold text-sm 4xl:text-base">
            {ApplicationString.MyBookingsListing.bookingButtons.completed}
          </h2>
        </div>
      );
    }

    if (type === myBookingsStatusEnums.active) {
      return (
        <div className="flex gap-1 absolute right-2 bottom-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-primary items-center px-2 py-0.5">
          <RunningWithErrorsIcon className="text-primary size-4" />
          <h2 className="font-bold text-sm 4xl:text-base">
            {ApplicationString.MyBookingsListing.bookingButtons.active}
          </h2>
        </div>
      );
    }

    if (type === myBookingsStatusEnums.rejected) {
      return (
        <div className="flex gap-1 absolute right-2 bottom-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-error items-center px-2 py-0.5">
          <DoDisturbAltIcon className="text-error size-4" />
          <h2 className="font-bold text-sm 4xl:text-base">
            {ApplicationString.MyBookingsListing.bookingButtons.rejected}
          </h2>
        </div>
      );
    }

    if (type === myBookingsStatusEnums.requested) {
      return (
        <div className="flex gap-1 absolute right-2 bottom-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-yellow-400 items-center px-2 py-0.5">
          <PendingActionsIcon className="text-yellow-400 size-4" />
          <h2 className="font-bold text-sm 4xl:text-base">
            {ApplicationString.MyBookingsListing.bookingButtons.requested}
          </h2>
        </div>
      );
    }

    return (
      <div className="flex gap-1 absolute right-2 bottom-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-error items-center px-2 py-0.5">
        <CloseIcon className="text-error size-4" />
        <h2 className="font-bold text-sm 4xl:text-base">
          {ApplicationString.MyBookingsListing.bookingButtons.cancelled}
        </h2>
      </div>
    );
  };

  const showRatingModal = () => {
    setRatingModal(true);
    setCurrentBookingId(id);
    getReviewDetails(id);
  };

  const hideRatingModal = () => {
    setRatingModal(false);
  };

  const handleNavigateToBookings = () => {
    const path = InternalRoute.Vehicle_details.replace(
      ':VehicleId',
      vehicleDetail.id.toString()
    );
    navigate(path);
  };

  const showButtonBasedOnType = (): JSX.Element => {
    if (type === myBookingsStatusEnums.requested) {
      return (
        <Button
          variant="contained"
          color="primary"
          data-testid="my-bookings-approve-booking-button"
          onClick={() =>
            navigate(InternalRoute.MyBookingsDetails.replace(':id', `${id}`))
          }
          className="w-full flex justify-center shadow-1 shadow-slate-50 rounded-4xl normal-case text-sm 4xl:text-base font-bold sm:py-3 bg-dark_brand_300 hover:bg-dark_brand_300/100  dark:text-dark_bg_secondary "
        >
          {ApplicationString.MyBookingsListing.bookingButtons.pendingApproval}
        </Button>
      );
    }

    if (type === myBookingsStatusEnums.cancelled) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(InternalRoute.MyBookingsDetails.replace(':id', `${id}`))
          }
          className="w-full flex justify-center shadow-1 shadow-slate-50 rounded-4xl normal-case text-sm 4xl:text-base font-bold sm:py-3 bg-primary_text hover:bg-gray-700  dark:text-dark_bg_secondary dark:bg-white"
        >
          {ApplicationString.MyBookingsListing.bookingButtons.cancelled}
        </Button>
      );
    }

    if (type === myBookingsStatusEnums.rejected) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(InternalRoute.MyBookingsDetails.replace(':id', `${id}`))
          }
          className="w-full flex justify-center shadow-1 shadow-slate-50 rounded-4xl normal-case text-sm 4xl:text-base font-bold sm:py-3 bg-primary_text hover:bg-gray-700  dark:text-dark_bg_secondary dark:bg-white"
        >
          {ApplicationString.MyBookingsListing.bookingButtons.rejected}
        </Button>
      );
    }

    if (type === myBookingsStatusEnums.completed) {
      return (
        <div className="flex gap-3">
          <Button
            variant="contained"
            color="primary"
            data-testid={`${id}-bookings-rating-button`}
            onClick={(e) => {
              e.preventDefault();
              showRatingModal();
            }}
            className="w-full flex justify-center  shadow-1 shadow-slate-50 rounded-4xl normal-case text-sm 4xl:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
          >
            {ApplicationString.MyBookingsListing.bookingButtons.rating}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleNavigateToBookings();
            }}
            data-testid="my-bookings-book-again-button"
            className="w-full flex justify-center  rounded-4xl normal-case text-sm 4xl:text-base font-bold sm:py-3 border-dark_bg_surface text-dark_bg_surface dark:border-dark_primary_text dark:text-dark_primary_text"
          >
            {ApplicationString.MyBookingsListing.bookingButtons.bookAgain}
          </Button>
        </div>
      );
    }

    const openChatModalHandler = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      setOpen(true);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        data-testid="my-bookings-chat-button"
        onClick={openChatModalHandler}
        className="w-full flex justify-center shadow-1 shadow-slate-50 rounded-4xl normal-case text-sm 4xl:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
      >
        {ApplicationString.MyBookingsListing.bookingButtons.chat}
      </Button>
    );
  };

  return (
    <>
      {open && (
        <SendBirdChatModal
          open={open}
          hideChatModal={() => setOpen(false)}
          bookingId={booking.id}
          recipientId={booking.ownerDetails.id}
        />
      )}
      <Modal
        component="div"
        aria-labelledby="Rating Modal"
        aria-describedby="Rating Modal"
        disableScrollLock
        open={ratingModal}
        onClose={hideRatingModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: `${currentTheme === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'}`,
          },
        }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={ratingModal}>
          <Box sx={style}>
            <MybookingsRatingComponent
              booking={booking}
              setRatingModal={setRatingModal}
              // eslint-disable-next-line react/jsx-no-bind
              formatLocation={formatLocation}
              reviewStar={reviewStar}
              reviewSubmitHandler={reviewSubmitHandler}
            />
          </Box>
        </Fade>
      </Modal>
      <Link to={InternalRoute.MyBookingsDetails.replace(':id', `${id}`)}>
        <div
          className="h-100 rounded-xl border border-slate-50 p-2.5 bg-card_bg dark:bg-dark_bg_secondary dark:border-dark_border_color"
          key={`${id}-booking-card`}
        >
          <div className="img-container mb-4 h-45 relative ">
            {coverPhoto ? (
              <img
                src={coverPhoto}
                alt="cardImg"
                className="h-full w-full rounded-xl "
              />
            ) : (
              <div className="h-full w-full bg-gray-300 rounded-xl flex justify-center items-center text-xl 4xl:text-2xl">
                {ApplicationString.commonStrings.noImageFound}
              </div>
            )}
            {showStatusBadgeBasedOnType()}
            {type === myBookingsStatusEnums.completed && (
              <div className="absolute top-0 h-full w-full backdrop-invert-0 bg-white/30 z-97" />
            )}
          </div>
          <div className="text-content-container flex flex-col gap-2 sm:gap-0 px-2 sm:px-3 ">
            <div className="flex justify-between max-w-full">
              <h1
                className={`text-xl sm:text-2xl 2xl:text-[28px] 4xl:text-3xl font-bold  overflow-hidden whitespace-nowrap text-ellipsis  ${type === myBookingsStatusEnums.completed ? 'text-secondary_text' : 'text-title_text'} dark:text-dark_web_accent`}
                title={title}
                style={{ maxWidth: 'calc(100% - 5rem)' }}
              >
                {title}
              </h1>
              <p className="flex flex-col text-right">{showBookingRate()}</p>
            </div>
            <div className="flex items-center gap-1">
              <CalendarMonthOutlinedIcon className="size-5 text-primary " />
              <span className="text-base text-[#1F1F1F] sm:text-[16px] 2xl:text-[18px] 4xl:text-[20px]  font-medium dark:text-white ">
                {formatSingleOrDateRange([startDate, endDate])}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <FmdGoodOutlinedIcon className="size-5 text-primary" />
              <span
                title={formatLocation(city, country)}
                className="text-base sm:text-[16px] 2xl:text-[18px] 4xl:text-[20px]  text-secondary_text max-w-full overflow-hidden whitespace-nowrap text-ellipsis dark:text-dark_secondary_text "
              >
                {formatLocation(city, country)}
              </span>
              {rating && (
                <>
                  <span className="text-borderStroke">|</span>
                  <span className="flex gap-1 items-center">
                    <StarIcon className="size-5 text-ratingStar" />
                    <span className="text-base sm:text-[16px] 2xl:text-[18px] 4xl:text-[20px] text-secondary_text dark:text-dark_secondary_text">
                      {rating}
                    </span>
                    <span className="text-base sm:text-[16px] 2xl:text-[18px] 4xl:text-[20px] text-secondary_text dark:text-dark_secondary_text">
                      {` (${reviewCount})`}
                    </span>
                  </span>
                </>
              )}
            </div>
            <div className="mt-5">{showButtonBasedOnType()}</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default MybookingsCard;

import {
  Box,
  Divider,
  TextField,
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  FormHelperText,
  Fade,
  Backdrop,
  Modal,
} from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { Dispatch, SetStateAction } from 'react';
import { IProfile } from '../../../Interfaces/common/Profile';
import ApplicationString from '../../../Constants/applicationString';
import MuiOtpWrapper from '../../MUI Custom Components/MuiOtpWrapper';
import LoadingButton from '../../common/LoadingButton/LoadingButton';
import { IDisableFields, IerrorsData } from '../../../Interfaces/interfaces';

interface IProfileFormData {
  name: string;
  mobile: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  countryCode: string;
}
interface IErrorsData {
  countryCode: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  address: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  name: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  city: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  state: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  country: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  zip: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  email: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  mobile: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}
interface EditProfileProps {
  profileData: IProfile;
  countryCodeOptions: string[];
  formData: IProfileFormData;
  errorsData: IErrorsData;
  isLoading: boolean;
  fileUploadError: string;
  onBack: () => void;
  setIsUploadingProfilePicture: Dispatch<SetStateAction<boolean>>;
  handleNameChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handlePhoneNumberChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleEmailChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAddressChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAddress2Change: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCityChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCountryChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleStateChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleZipCodeChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCountryCodeChange: (e: SelectChangeEvent<string>) => void;
  uploadProfilePicture: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  onAccountDetailsSubmit: (e: React.FormEvent) => void;
  imagePath: string;
  isUploadingProfilePicture: boolean;
  handleVerification: () => void;
  handleClose: () => void;
  verifyTheOtp: () => Promise<void>;
  setOtp: Dispatch<SetStateAction<string>>;
  otp: string;
  isModalOpen: boolean;
  isVerify: boolean;
  isVerifyingOtp: boolean;
  verificationerror: IerrorsData;
  isFieldDisabled: IDisableFields;
}

const EditProfileComponent: React.FC<EditProfileProps> = ({
  profileData,
  isLoading,
  countryCodeOptions,
  errorsData,
  fileUploadError,
  handleNameChange,
  imagePath,
  isUploadingProfilePicture,
  handlePhoneNumberChange,
  handleEmailChange,
  handleAddressChange,
  handleCityChange,
  handleCountryChange,
  handleZipCodeChange,
  handleStateChange,
  handleCountryCodeChange,
  setIsUploadingProfilePicture,
  uploadProfilePicture,
  formData,
  handleAddress2Change,
  onBack,
  onAccountDetailsSubmit,
  verifyTheOtp,
  handleClose,
  handleVerification,
  setOtp,
  otp,
  isModalOpen,
  isVerify,
  isVerifyingOtp,
  verificationerror,
  isFieldDisabled,
}) => {
  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'grey',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Modal
        component="div"
        aria-labelledby="Rating Modal"
        aria-describedby="Rating Modal"
        disableScrollLock
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          '& .MuiModal-backdrop': {
            // backgroundColor: `${currentTheme === 'light' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.4)'}`,
          },
        }}
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={isModalOpen}>
          <Box sx={modalStyle}>
            <div className="xsm:w-100  max-h-[100vh]">
              <div className="pt-5 px-4 flex flex-col gap-5">
                <div className="flex flex-col gap-2">
                  <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
                    {ApplicationString.otpScreen.phone.title}
                  </h1>
                  <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
                    {ApplicationString.otpScreen.phone.subTitle}
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <MuiOtpWrapper
                    otp={otp}
                    setOtp={setOtp}
                    err={verificationerror.otp.error}
                  />
                </div>
                {verificationerror.otp.error && (
                  <div className="font-medium text-sm text-error mt-4 flex justify-center">
                    {verificationerror.otp.validationMessage}
                  </div>
                )}
                <div className="flex flex-col gap-3">
                  {isVerifyingOtp ? (
                    <LoadingButton
                      buttonText={ApplicationString.otpScreen.button.verifying}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                      color="primary"
                      onClick={verifyTheOtp}
                      data-testid="my-account-verify-otp-button"
                    >
                      {ApplicationString.otpScreen.button.verify}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <div className="w-full">
        <Typography
          fontSize={14}
          fontWeight={700}
          className="mt-2 dark:text-dark_secondary_text"
        >
          {ApplicationString.ProfileScreenLables.accountInfo}
        </Typography>
        <div className=" flex flex-col md:flex-row md:justify-start items-start md:items-center mt-4 gap-6">
          <img
            src={imagePath || profileData?.avatarPath}
            alt={profileData.name}
            className=" w-30
            h-30 rounded-full object-cover"
            onLoad={() => {
              setIsUploadingProfilePicture(false);
            }}
          />

          <div className="flex flex-col items-start">
            <label
              className="capitalize rounded-full px-12 py-2.5 text-white bg-primary hover:bg-primary"
              htmlFor="avatar"
            >
              {isUploadingProfilePicture && (
                <CircularProgress size={20} className="text-white mr-2" />
              )}
              {ApplicationString.ProfileScreenLables.changePhoto}
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg"
                id="avatar"
                className="hidden"
                data-testid="my-account-change-profile-image"
                onChange={uploadProfilePicture}
              />
            </label>
            <Typography className=" mt-2 text-error ">
              {fileUploadError}
            </Typography>
          </div>
          <Divider className="mt-4" />
        </div>
        <form onSubmit={onAccountDetailsSubmit}>
          <div className="flex flex-col justify-center items-center mt-6">
            <FormControl sx={{ width: '100%' }} size="small">
              <div>
                <FormControl className=" w-full ">
                  <TextField
                    label={ApplicationString.ProfileScreenLables.name}
                    className="rounded-4xl border-none"
                    InputProps={{
                      className:
                        'rounded-4xl border-none text-base font-medium !text-text_input_label dark:bg-dark_formFieldBg dark:text-white',
                    }}
                    error={errorsData.name.error}
                    value={formData.name ? formData.name : ''}
                    onChange={handleNameChange}
                  />
                </FormControl>
                {errorsData.name.error && (
                  <FormHelperText className="text-error flex items-center gap-1">
                    <ErrorOutlined className="size-3.5" />
                    {errorsData.name.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full flex flex-col md:flex-row  gap-3 mt-4">
                <FormControl
                  className="w-34 mr-10"
                  // error={errorsData.countryCode.error}
                >
                  <InputLabel
                    data-testid="countryCodeEditSelect"
                    className="text-base font-medium"
                  >
                    {ApplicationString.loginWithPhone.label.code}
                  </InputLabel>
                  <Select
                    data-testid="countryCodeEditSelect"
                    className="w-25 rounded-4xl text-base font-medium dark:bg-dark_formFieldBg dark:text-white"
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: null,
                    }}
                    value={formData.countryCode ? formData.countryCode : ''}
                    label={ApplicationString.loginWithPhone.label.code}
                    disabled={isFieldDisabled.isEmailChanged}
                    aria-describedby="countryCodeEditSelect"
                    onChange={handleCountryCodeChange}
                  >
                    {countryCodeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="flex flex-col w-full">
                  <FormControl className=" w-full ">
                    <TextField
                      label={ApplicationString.ProfileScreenLables.mobile}
                      className="rounded-4xl border-none"
                      InputProps={{
                        className:
                          'rounded-4xl border-none text-base font-medium text-text_input_label dark:bg-dark_formFieldBg dark:text-white',
                      }}
                      disabled={isFieldDisabled.isEmailChanged}
                      error={errorsData.mobile.error}
                      onChange={handlePhoneNumberChange}
                      value={formData.mobile ? formData.mobile : ''}
                    />
                  </FormControl>
                  {errorsData.mobile.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.mobile.ValidationMessage}
                    </FormHelperText>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <FormControl className=" w-full ">
                    <TextField
                      label={ApplicationString.ProfileScreenLables.email}
                      InputProps={{
                        className:
                          'rounded-4xl border-none text-base font-medium text-text_input_label dark:bg-dark_formFieldBg dark:text-white',
                      }}
                      className="rounded-4xl  border-none"
                      value={formData.email ? formData.email : ''}
                      error={errorsData.email.error}
                      disabled={isFieldDisabled.isMobileChanged}
                      onChange={handleEmailChange}
                    />
                  </FormControl>
                  {errorsData.email.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.email.ValidationMessage}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-3 mt-4">
                <div className="w-full flex flex-col">
                  <FormControl className=" w-full ">
                    <TextField
                      label={ApplicationString.ProfileScreenLables.address1}
                      InputProps={{
                        className:
                          'rounded-4xl border-none text-base dark:bg-dark_formFieldBg dark:text-white',
                      }}
                      className="rounded-4xl border-none"
                      value={formData.address ? formData.address : ''}
                      onChange={handleAddressChange}
                      error={errorsData.address.error}
                    />
                  </FormControl>
                  {errorsData.address.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.address.ValidationMessage}
                    </FormHelperText>
                  )}
                </div>
                <FormControl className=" w-full ">
                  <TextField
                    label={ApplicationString.ProfileScreenLables.address2}
                    InputProps={{
                      className:
                        'rounded-4xl border-none text-base dark:bg-dark_formFieldBg dark:text-white',
                    }}
                    className="rounded-4xl border-none"
                    value={formData.address2 ? formData.address2 : ''}
                    onChange={handleAddress2Change}
                    error={errorsData.address.error}
                  />
                </FormControl>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-3 mt-4">
                <FormControl className=" w-full ">
                  <TextField
                    label={ApplicationString.ProfileScreenLables.city}
                    InputProps={{
                      className:
                        'rounded-4xl border-none text-base  dark:bg-dark_formFieldBg dark:text-white',
                    }}
                    className="rounded-4xl border-none"
                    value={formData.city ? formData.city : ''}
                    onChange={handleCityChange}
                  />
                </FormControl>
                <FormControl className="w-full">
                  <TextField
                    label={ApplicationString.ProfileScreenLables.state}
                    InputProps={{
                      className:
                        'rounded-4xl border-none text-base  dark:bg-dark_formFieldBg dark:text-white',
                    }}
                    className="rounded-4xl border-none"
                    value={formData.state ? formData.state : ''}
                    onChange={handleStateChange}
                  />
                </FormControl>
                <FormControl className="w-full">
                  <TextField
                    label={ApplicationString.ProfileScreenLables.country}
                    InputProps={{
                      className:
                        'rounded-4xl border-none text-base  dark:bg-dark_formFieldBg dark:text-white',
                    }}
                    className="rounded-4xl border-none"
                    value={formData.country ? formData.country : ''}
                    onChange={handleCountryChange}
                  />
                </FormControl>
                <div className="w-full flex flex-col">
                  <FormControl className=" w-full ">
                    <TextField
                      label={ApplicationString.ProfileScreenLables.zip}
                      InputProps={{
                        className:
                          'rounded-4xl border-none text-base dark:bg-dark_formFieldBg dark:text-white',
                      }}
                      className="rounded-4xl border-none"
                      value={formData.zip ? formData.zip : ''}
                      onChange={handleZipCodeChange}
                    />
                  </FormControl>
                  {errorsData.zip.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.zip.ValidationMessage}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-row items-center gap-4 mt-8">
                <Button
                  type="submit"
                  className=" capitalize rounded-full px-22 py-2.5 text-white bg-primary hover:bg-primary disabled:!bg-secondary_text disabled:!cursor-not-allowed"
                  disabled={isVerify}
                >
                  {isLoading && (
                    <CircularProgress size={20} className="text-white mr-2" />
                  )}
                  {ApplicationString.ProfileScreenLables.save}
                </Button>

                {isVerify &&
                  !(errorsData.email.error || errorsData.mobile.error) && (
                    <Button
                      type="button"
                      className=" capitalize rounded-full px-22 py-2.5 text-white bg-primary hover:bg-primary"
                      onClick={handleVerification}
                    >
                      {isLoading && (
                        <CircularProgress
                          size={20}
                          className="text-white mr-2"
                        />
                      )}
                      {ApplicationString.otpScreen.button.verify}
                    </Button>
                  )}

                <Button
                  className=" capitalize text-black font-bold bg-transparent dark:text-white"
                  onClick={onBack}
                >
                  {ApplicationString.ProfileScreenLables.cancel}
                </Button>
              </div>
            </FormControl>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProfileComponent;

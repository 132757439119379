import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';
import URLS from '../Constants/urls';
import {
  getLocalStorageAccountInfo,
  removeLocalStorageAccountInfoOnDeleteOrLogout,
} from '../Utils/utils';
import { ApiRequest } from '../Api/api';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/LoginInterfaces';
import { localStorageEnums, navigationKeys } from '../Utils/enums';

interface ILogoutHook {
  deleteAccount: () => void;
}

const useDeleteAccountHook = (): ILogoutHook => {
  const accountId = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.accountId;
  const navigate = useNavigate();
  const deleteAccount = async () => {
    try {
      const res = await ApiRequest.delete(
        URLS.USER_DELETE.replace('#{accountId}', accountId ?? '')
      );
      if (res?.status === HttpStatusCode.NoContent) {
        removeLocalStorageAccountInfoOnDeleteOrLogout(
          navigate,
          navigationKeys.delete
        );
      }
    } catch (error) {
      console.error('Error while deleting account:', error);
    }
  };
  return { deleteAccount };
};

export default useDeleteAccountHook;

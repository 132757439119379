import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError, HttpStatusCode } from 'axios';
import React, { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRequest } from '../../Api/api';
import { getSettingsData } from '../../Components/common/UserHeader';
import SignUpWithPhoneComponent from '../../Components/SignUpComponents/SignUpWithPhoneComponent';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { IUserSettingsData } from '../../Interfaces/Settings/SettingsInterfaces';
import {
  localStorageEnums,
  loginSignupFormEnums,
  verificationTypeEnums,
} from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import { getDetailsFromLocalStorage, handleApiError } from '../../Utils/utils';

interface IsignUpPhoneFormData {
  countryCode: string;
  phoneNumber: string;
}

interface IApiPayload {
  verificationOf: string;
  verificationType: string;
}

interface IErrorsData {
  countryCode: {
    error: boolean;
    ValidationMessage: string;
  };
  phoneNumber: {
    error: boolean;
    ValidationMessage: string;
  };
}

const SignUpWithPhoneContainer: React.FC = () => {
  const [countryCodeOptions, setCountryCodeOptions] = React.useState<string[]>(
    []
  );
  const navigate = useNavigate();
  const [isSigningUp, setIsSigningUp] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<IsignUpPhoneFormData>({
    countryCode: '',
    phoneNumber: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    countryCode: {
      error: false,
      ValidationMessage: '',
    },
    phoneNumber: {
      error: false,
      ValidationMessage: '',
    },
  });

  // Form change handlers

  const countryCodeChangeHandler = (e: SelectChangeEvent<string>) => {
    setFormData({ ...formData, countryCode: e.target.value });
    setErrorsData((prevState) => ({
      ...prevState,
      countryCode: { error: false, ValidationMessage: '' },
    }));
  };

  const phoneNumberChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        phoneNumber: e.target.value.trim(),
      }));
      const validationMessages =
        e.target.value.trim().length > loginSignupFormEnums.maxPhoneNumberLength
          ? ApplicationString.loginWithPhone.validationMessages.invalidMsgs
              .maxPhoneNumberLength
          : '';

      setErrorsData({
        ...errorsData,
        phoneNumber: {
          error: validationMessages !== '',
          ValidationMessage: validationMessages,
        },
      });
    }
  };

  // get verification id
  const getVerificationId = async (payload: IApiPayload) => {
    try {
      const res = await ApiRequest.post(
        `${URLS.USER_GET_VERIFICATION_ID}`,
        payload
      );
      const verificationId = window.btoa(res.data.verificationId);
      const encodedPhone = window.btoa(
        `${formData.countryCode}-${formData.phoneNumber}`
      );
      navigate(
        `${InternalRoute.PhoneVerificationCode}?verificationId=${verificationId}&phone=${encodedPhone}`
      );
    } catch (error) {
      setErrorsData((prevState) => ({
        ...prevState,
        phoneNumber: {
          error: true,
          ValidationMessage:
            ApplicationString.commonInvalidMsg.somethingWentWrong,
        },
      }));
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
    }
  };

  const onSignupPhoneSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (formData.phoneNumber.length > loginSignupFormEnums.maxPhoneNumberLength)
      return;

    setErrorsData((prevState) => ({
      ...prevState,
      countryCode: {
        error: !formData.countryCode.trim(),
        ValidationMessage: '',
      },
      phoneNumber: {
        error: !formData.phoneNumber.trim() || !formData.countryCode.trim(),
        ValidationMessage:
          !formData.phoneNumber.trim() || !formData.countryCode.trim()
            ? ApplicationString.loginWithPhone.validationMessages.requiredMsgs
                .phone
            : '',
      },
    }));

    if (!formData.countryCode.trim() || !formData.phoneNumber.trim()) return;
    const payload = {
      verificationOf: `${formData.countryCode}-${formData.phoneNumber}`,
      verificationType: verificationTypeEnums.phone,
    };
    try {
      setIsSigningUp(true);
      const res = await ApiRequest.post(`${URLS.USER_EXISTS}`, payload);
      if (res?.status === HttpStatusCode.Ok) {
        if (res?.data && JSON.stringify(res?.data) !== '{}') {
          if (!res.data.isExists) {
            getVerificationId(payload);
          } else {
            setErrorsData((prevState) => ({
              ...prevState,
              phoneNumber: {
                error: true,
                ValidationMessage:
                  ApplicationString.signUpWithPhone.validationMessages
                    .alreadyExists,
              },
            }));
          }
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      setErrorsData((prevState) => ({
        ...prevState,
        phoneNumber: {
          error: true,
          ValidationMessage:
            ApplicationString.signUpWithPhone.validationMessages.invalid,
        },
      }));
      handleApiError(axiosError);
    } finally {
      setIsSigningUp(false);
    }
  };

  // Get user settings data on header mount
  useEffect(() => {
    const userSettings = getDetailsFromLocalStorage<IUserSettingsData>(
      localStorageEnums.settings
    );
    if (userSettings) {
      setCountryCodeOptions(
        userSettings.setting?.country.map((item) => item.countryCode)
      );
    } else {
      getSettingsData(setCountryCodeOptions);
    }
  }, []);

  return (
    <SignUpWithPhoneComponent
      countryCodeOptions={countryCodeOptions}
      countryCodeChangeHandler={countryCodeChangeHandler}
      phoneNumberChangeHandler={phoneNumberChangeHandler}
      onSignupPhoneSubmit={onSignupPhoneSubmit}
      formData={formData}
      errorsData={errorsData}
      isSigningUp={isSigningUp}
    />
  );
};

export default SignUpWithPhoneContainer;

import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import OwnerHeader from '../OwnerHeader';
import UserFooter from '../UserFooter';
import InternalRoute from '../../../Utils/internalRoutes';

const OwnerDefaultLayout: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  return (
    <div className=" dark:text-bodydark flex flex-col min-h-screen">
      <OwnerHeader />
      <main className="mt-20 lg:mt-24 z-20 relative flex-1 min-h-0 bg-page_bg dark:bg-dark_bg_surface">
        {children}
      </main>
      {!location.pathname.includes(InternalRoute.vehicleCreate) && (
        <UserFooter />
      )}
    </div>
  );
};

export default OwnerDefaultLayout;

import React, { useEffect, useState } from 'react';

interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const CommingSoon: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const countDownEndDate = new Date('June 28, 2024 09:00:00').getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownEndDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({
        days: days < 10 ? `0${days}` : days.toString(),
        hours: hours < 10 ? `0${hours}` : hours.toString(),
        minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
        seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
      });
    };

    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      className="bg-cover w-full"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1500829243541-74b677fecc30?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
      }}
    >
      <div className="w-full min-h-screen bg-gradient-to-b from-gray-900/90 to-gray-900/95 p-10 flex">
        <div className="w-full text-center my-auto">
          <h1 className="font-bold text-7xl font-Comforter-Brush text-amber-500 mb-10 ">
            {/* <img src={logo} /> */}
            One life
          </h1>
          <h6 className="font-Montserrat justify-center font-bold uppercase text-4xl md:text-5xl lg:text-6xl mb-14 text-white ">
            Coming <span className="text-white/70 md:-mr-20">soon</span>
          </h6>

          <div className="w-full justify-center flex mb-10">
            <ul className=" flex gap-5 text-gray-50">
              {['Days', 'Hours', 'Minutes', 'Seconds'].map((label, index) => (
                <li key={label}>
                  <div className="font-bold text-white rounded-full border-dotted border-gray-700 border-4 flex items-center justify-center text-2xl lg:text-5xl h-16 lg:h-30 w-16 lg:w-30">
                    {Object.values(timeLeft)[index]}
                  </div>
                  <p className="text-center text-white text-xl mt-2">{label}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;

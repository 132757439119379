import React from 'react';
import { IMyBookings } from '../../../Interfaces/common/MyBookings/MyBookings';
import ApplicationString from '../../../Constants/applicationString';
import InstantBookingCard from '../InstantBookingCard';

interface IInstantBookingProps {
  instantBookings: IMyBookings[];
  instantBookingActionsHandler: (
    bookingId: number,
    payload: string
  ) => Promise<void>;
}

const InstantBooking: React.FC<IInstantBookingProps> = (props): JSX.Element => {
  const { instantBookings, instantBookingActionsHandler } = props;
  return (
    <div className="flex justify-center pb-8">
      <div className="flex w-[94%] max-w-midXL flex-col">
        <h1 className="font-bold text-[28px] md:text-[2rem] dark:text-white mb-6 flex items-baseline flex-wrap">
          {ApplicationString.OwnerDashboard.instantBooking.mainHeading}
          <span className="inline-block text-secondary_text text-base font-normal dark:text-[#B8B8B8]">
            &nbsp; (
            {ApplicationString.OwnerDashboard.instantBooking.actionRequiredTxt})
          </span>
        </h1>

        <div className="flex justify-center flex-col midXL:shadow-[0px_4px_16px_0px_#1212120F] shadow-none rounded-3xl gap-4 midXL:gap-0">
          <header className="flex gap-2 max-[1200px]:hidden bg-formFieldBg rounded-tr-3xl rounded-tl-3xl px-4 py-3 text-secondary_text dark:bg-[#333333] dark:text-[#B8B8B8] shadow-[0px_4px_16px_0px_#1212120F]">
            <span className="w-[30%]">
              {ApplicationString.OwnerDashboard.instantBooking.vehicleName}
            </span>
            <span className="w-[25%]">
              {' '}
              {ApplicationString.OwnerDashboard.instantBooking.dateAndTime}
            </span>
            <span className="w-[10%]">
              {' '}
              {ApplicationString.OwnerDashboard.instantBooking.price}
            </span>
            <span className="w-[10%]">
              {' '}
              {ApplicationString.OwnerDashboard.instantBooking.bookedBy}
            </span>
            <span className="w-[25%]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             /12">
              {ApplicationString.OwnerDashboard.instantBooking.action}
            </span>
          </header>
          {instantBookings.map((booking) => (
            <InstantBookingCard
              key={booking.id}
              booking={booking}
              instantBookingActionsHandler={instantBookingActionsHandler}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstantBooking;

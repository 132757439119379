import React from 'react';
import Accordion from '../common/Accordian';
import ContactUs from '../ContactUsComponent';
import { getDetailsFromLocalStorage } from '../../Utils/utils';
import { IUserSettingsData } from '../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums } from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';

const Contact: React.FC = () => {
  const userSettings = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  );
  const settingFaqs = userSettings?.setting.faq;
  const defaultFaqs = ApplicationString.accordion.accordionData;

  const filterSettingFaqs = settingFaqs?.map((item, index) => ({
    id: index + 1,
    question: item.question,
    answer: item.answer,
  }));
  return (
    <div className="bg-[#F3F4F6] dark:bg-dark_bg_surface">
      <ContactUs />
      <Accordion
        darkTheme="dark:bg-dark_bg_secondary"
        accordionData={filterSettingFaqs || defaultFaqs}
        title={ApplicationString.contactUs.faqTitle}
      />
    </div>
  );
};

export default Contact;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError, HttpStatusCode } from 'axios';
import BookingDetailsComponent from '../../Components/BookingDetails';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import {
  Booking,
  DateRange,
} from '../../Components/BookingDetails/bookingInterface';
import {
  DefaultPaginationValues,
  getLocalStorageSettingData,
  getMonthDateRange,
  showApiError,
} from '../../Utils/utils';
import {
  get12HourlyBlockedSlots,
  getBlockedDates,
  getBlockedSlots,
  getSlotForMultipleDay,
} from '../../Utils/blockBookings';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import { useToast } from '../../Services/ToastService';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import ApplicationString from '../../Constants/applicationString';
import InternalRoute from '../../Utils/internalRoutes';
import { dayRange } from '../../Constants/commonConst';
import { bookingsPageSize } from '../../Utils/enums';

const BookingDetailsContainer: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [vehicleDetails, setVehicleDetails] = useState<IVehiclesData>(
    {} as IVehiclesData
  );
  const [bookings, setBookings] = useState<[]>([]);
  const [isChange, setChange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const hasVehicleMounted = useRef(false);
  const hasBookingsMounted = useRef(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const getVehicleDetailsById = async (vehicleId: string) => {
    try {
      const response = await ApiRequest.get(
        `${URLS.GET_VEHICLE_DETAILS_BY_ID.replace('#{id}', vehicleId)}`
      );
      setVehicleDetails(response.data);
    } catch (err) {
      console.error('Error fetching vehicle details:', err);
    } finally {
      hasVehicleMounted.current = false;
    }
  };
  const toast = useToast();
  const getAllBookings = async (
    vehicleId: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      // Step 2: Fetch all bookings using the total count as pageSize
      const fetchAllBookings = async (pageSize: number) => {
        let bookingPageSize = DefaultPaginationValues.PAGE_SIZE;
        if (pageSize > 0) {
          bookingPageSize = pageSize;
        }
        const response = await ApiRequest.get(
          `${URLS.GET_ALL_BOOKINGS_BY_QUERY}?vehicleId=${vehicleId}&pageSize=${bookingPageSize}&type=active&startDate=${startDate}&endDate=${endDate}`
        );
        return response.data; // Assuming the API returns bookings data
      };

      // Step 3: Get total count and then fetch all bookings
      const allBookings = await fetchAllBookings(bookingsPageSize);

      // Step 4: Set bookings state or process the data as needed
      setBookings(allBookings.data);
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      hasBookingsMounted.current = false;
    }
  };
  const createBooking = async (data: Booking) => {
    setIsSubmitting(true);
    try {
      const res = await ApiRequest.post(URLS.CREATE_BOOKING_REQUEST, data);
      if (res?.status === HttpStatusCode.Created) {
        toast.success(ApplicationString.CreateBookingComponent.successMsg);
        navigate(`${InternalRoute.myBookings}`);
        localStorage.removeItem('booking');
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (!hasVehicleMounted.current && id) {
      hasVehicleMounted.current = true;
      getVehicleDetailsById(id);
    }
  }, [id]);

  useEffect(() => {
    const { startDate, endDate } = getMonthDateRange(
      isChange.startDate,
      isChange.endDate
    );

    if (!hasBookingsMounted.current && id && startDate && endDate) {
      hasBookingsMounted.current = true;
      getAllBookings(id, startDate, endDate);
    }
  }, [id, isChange]);

  // const disabledDateRageForBooking = getBlockedDates(bookings,);
  const localStorageSettingData = getLocalStorageSettingData();
  const setting = localStorageSettingData?.setting || null;

  const bookingTimeConstraints = setting?.bookingTimeConstraints || null;
  const hourlyBookingStartHour =
    bookingTimeConstraints?.hourlyBookingStartHour ?? 8; // Default value if not set
  const hourlyBookingEndHour =
    bookingTimeConstraints?.hourlyBookingEndHour ?? 22; // Default value if not set

  const slots = setting ? setting['12hourlyTimeFrame'] : [];

  const disabledDateRange = getBlockedDates(
    bookings,
    hourlyBookingStartHour,
    hourlyBookingEndHour,
    vehicleDetails?.bookingGap || 0,
    vehicleDetails?.isMultipleBookingSameDay,
    slots,
    vehicleDetails?.bookingRateHourly,
    vehicleDetails?.timeFrameFor12Hourly || [],
    isChange.selectionType
  );

  const disabledHourlySlots = getBlockedSlots(
    bookings,
    isChange.startDate || new Date().toISOString(),
    hourlyBookingStartHour,
    hourlyBookingEndHour,
    vehicleDetails?.bookingGap || 0
  );

  const disabled12HourlySlots = get12HourlyBlockedSlots(
    slots,
    bookings,
    isChange.startDate || new Date().toISOString(),
    dayRange.startHour,
    dayRange.endHour,
    vehicleDetails?.bookingGap || 0
  );

  const disabledSlots = disabledHourlySlots.concat(disabled12HourlySlots);
  const disabledMultipleDayStartSlots = getSlotForMultipleDay(
    bookings,
    isChange.startDate || new Date().toISOString(),
    dayRange.startHour,
    dayRange.endHour,
    vehicleDetails?.bookingGap || 0
  );
  const disabledMultipleDayEndSlots = getSlotForMultipleDay(
    bookings,
    isChange.endDate || new Date().toISOString(),
    dayRange.startHour,
    dayRange.endHour,
    vehicleDetails?.bookingGap || 0
  );
  return (
    <BookingDetailsComponent
      disabledDateRange={disabledDateRange}
      vehicleDetails={vehicleDetails}
      onCreateBooking={createBooking}
      disabledSlots={disabledSlots}
      setChange={setChange}
      disabledMultipleDayStartSlots={disabledMultipleDayStartSlots}
      disabledMultipleDayEndSlots={disabledMultipleDayEndSlots}
      isSubmitting={isSubmitting}
      monthLimit={
        vehicleDetails?.monthLimit && vehicleDetails?.monthLimit !== null
          ? (vehicleDetails?.monthLimit as number)
          : 12
      }
    />
  );
};

export default BookingDetailsContainer;

import React from 'react';
import { Button } from '@mui/material';
import ApplicationString from '../../Constants/applicationString';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';
import MuiOtpWrapper from '../MUI Custom Components/MuiOtpWrapper';

interface IerrorsData {
  otp: {
    error: boolean;
    validationMessage: string;
  };
}

interface IForgotPasswordOtpComponentProps {
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  verifyTheOtp: () => Promise<void>;
  canResendOtp: boolean;
  countDownTimeNumber: number;
  errorsData: IerrorsData;
  isVerifyingOtp: boolean;
  resendOtp: () => void;
}

const ForgotPasswordOtpComponent: React.FC<
  IForgotPasswordOtpComponentProps
> = ({
  otp,
  setOtp,
  verifyTheOtp,
  canResendOtp,
  countDownTimeNumber,
  errorsData,
  isVerifyingOtp,
  resendOtp,
}) => {
  return (
    <BackgroundLayout>
      <div className="xsm:w-100  max-h-[100vh] border bg-white border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.otpScreen.email.title}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.otpScreen.email.subTitle}
            </p>
          </div>
          <div className="mt-8 flex justify-center w-full">
            <MuiOtpWrapper
              otp={otp}
              setOtp={setOtp}
              err={errorsData.otp.error}
            />
          </div>
          {errorsData.otp.error && (
            <div className="font-medium text-sm text-error mt-4 flex justify-center">
              {errorsData.otp.validationMessage}
            </div>
          )}
          {canResendOtp && (
            <div className="flex justify-between mt-6 font-medium text-sm">
              <button
                type="button"
                disabled={isVerifyingOtp}
                className={`${isVerifyingOtp ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                {ApplicationString.otpScreen.didNotReceiveCode}
              </button>
              <button
                type="button"
                disabled={isVerifyingOtp}
                onClick={resendOtp}
                className={`text-primary  ${isVerifyingOtp ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                {ApplicationString.otpScreen.resendCode}
              </button>
            </div>
          )}
          <div className={`mt-8 ${canResendOtp && 'mb-5'} flex flex-col gap-3`}>
            {isVerifyingOtp ? (
              <LoadingButton
                buttonText={ApplicationString.otpScreen.button.verifying}
              />
            ) : (
              <Button
                variant="contained"
                className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                color="primary"
                onClick={verifyTheOtp}
              >
                {ApplicationString.otpScreen.button.verify}
              </Button>
            )}
          </div>
        </div>
        <div className=" flex justify-center items-center  dark:border-dark_borderStroke">
          {!canResendOtp && (
            <div className="p-5 flex gap-1">
              <span className="text-sm font-medium text-secondary_text dark:text-dark_secondary_text">
                {ApplicationString.otpScreen.sendCodeAgain}
              </span>{' '}
              <span className=" text-sm font-medium text-primary_text dark:text-dark_primary_text ">
                {`00:${countDownTimeNumber.toString().padStart(2, '0')}`}
              </span>
            </div>
          )}
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default ForgotPasswordOtpComponent;

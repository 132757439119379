import React from 'react';
import MyListingsOwnerContainer from '../../Containers/MyListings';

const MyListingsOwnerScreen: React.FC = () => {
  return (
    <div>
      <MyListingsOwnerContainer />
    </div>
  );
};

export default MyListingsOwnerScreen;

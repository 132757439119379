import { ChangeEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';
import {
  getDetailsFromLocalStorage,
  getMaxCharacterRegex,
  populateYears,
} from '../../../Utils/utils';
import { localStorageEnums } from '../../../Utils/enums';
import {
  IUserSettingsData,
  IVehicleCategory,
} from '../../../Interfaces/Settings/SettingsInterfaces';
import { ICommonStepsProps } from '../../../Interfaces/Stepper';
import VehicleDetailsStepComponent from '../VehicleDetailsStepComponent';
import ApplicationString from '../../../Constants/applicationString';

const VehicleDetailsStep: React.FC<ICommonStepsProps> = ({
  formData,
  setFormData,
  errorsData,
  setErrorsData,
}) => {
  const settings = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  );
  const maxCharacterLimit = 200;
  const maxStringLimit = 255;
  const descriptionRegex = getMaxCharacterRegex(maxCharacterLimit);
  const stringRegex = getMaxCharacterRegex(maxStringLimit);
  const vehicleCategory = settings?.setting?.vehicleCategory;

  const getMaker = (vehicleCategories: IVehicleCategory[]): string[] => {
    const maker: string[] = [];
    vehicleCategories.forEach((category) => {
      return maker.push(...category.maker);
    });
    return maker;
  };
  const maker = getMaker(vehicleCategory || []);
  const manufacturingYear = populateYears(
    settings?.setting?.manufacturingYear?.min
  );
  const engineSize = settings?.setting?.engineSize;
  const hitchSize = settings?.setting?.hitchSize;
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          title: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (value && value.length > maxStringLimit && !stringRegex.test(value)) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          title: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          title: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        title: e.target.value,
      },
      // title: e.target.value,
    }));
  };
  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (
      value &&
      value.length > maxCharacterLimit &&
      !descriptionRegex.test(value)
    ) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          description: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.description,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          description: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }

    if (value || value === '') {
      setFormData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          description: value,
        },
      }));
    }
  };
  const handleCategoryChange = (e: SelectChangeEvent<string>): void => {
    if (e.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          category: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        category: Number(e.target.value),
      },
    }));
  };
  const handleSubCategoryChange = (e: SelectChangeEvent<string>): void => {
    if (e.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          subCategory: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        subCategory: Number(e.target.value),
      },
    }));
  };
  // const handleMakerChange = (e: SelectChangeEvent<string>): void => {
  //   if (e.target.value) {
  //     setErrorsData((prevState) => ({
  //       ...prevState,
  //       step1: {
  //         ...prevState.step1,
  //         maker: {
  //           ValidationMessage: '',
  //           error: false,
  //         },
  //       },
  //     }));
  //   }
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     step1: {
  //       ...prevState.step1,
  //       maker: e.target.value,
  //     },
  //   }));
  // };

  const handleManufacturingYearChange = (
    e: SelectChangeEvent<string>
  ): void => {
    if (e.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          manufacturingYear: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        manufacturingYear: e.target.value,
      },
    }));
  };
  const handleModelChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          model: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (value && value.length > maxStringLimit && !stringRegex.test(value)) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          model: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step1.maxCharValidation,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          model: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        model: e.target.value,
      },
    }));
  };
  const handleMaxPersonChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        capacity: e.target.value ? Number(e.target.value) : null,
      },
    }));
  };
  const handleVehicleLengthChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        size: Number(e.target.value),
      },
    }));
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        size: e.target.value ? Number(e.target.value) : null,
      },
    }));
  };
  const handleEngineSizeChange = (e: SelectChangeEvent<string>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        engineSize: e.target.value,
      },
    }));
  };
  const handleIsTrailerChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        trailer: e.target.value !== null ? e.target.value === 'yes' : null,
      },
    }));
  };
  const handleHitchSizeChange = (e: SelectChangeEvent<string>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        hitchSize: e.target.value,
      },
    }));
  };
  const handleOwnerShipTypeChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          ownerShipType: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        ownerShipType: e.target.value !== null ? e.target.value : null,
      },
    }));
  };
  const clearMaker = () => {
    setFormData((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        maker: '',
      },
    }));
  };
  const getSubCategories = () => {
    return vehicleCategory?.find(
      (category) => category.id === formData.step1.category
    )?.subCategory;
  };
  const vehicleSubCategory = getSubCategories();
  // const handleNext = (e: React.FormEvent): void => {
  //   e.preventDefault();
  //   const vehicleDetails = {
  //     step: 1,
  //     data: formData,
  //   };
  //   const stepsData = getDetailsFromLocalStorage(localStorageEnums.stepsData);
  //   if (stepsData instanceof Array) {
  //     stepsData.push(vehicleDetails);
  //   }
  //   setLocalStorageInfo(stepsData, localStorageEnums.stepsData);
  //   console.log('form data', formData);
  // };
  return (
    <VehicleDetailsStepComponent
      formData={formData}
      setFormData={setFormData}
      setErrorsData={setErrorsData}
      vehicleCategory={vehicleCategory}
      vehicleSubCategory={vehicleSubCategory}
      maker={maker}
      manufacturingYear={manufacturingYear}
      engineSize={engineSize}
      hitchSize={hitchSize}
      handleNameChange={handleNameChange}
      handleDescriptionChange={handleDescriptionChange}
      handleCategoryChange={handleCategoryChange}
      handleSubCategoryChange={handleSubCategoryChange}
      handleManufacturingYearChange={handleManufacturingYearChange}
      handleModelChange={handleModelChange}
      handleMaxPersonChange={handleMaxPersonChange}
      handleVehicleLengthChange={handleVehicleLengthChange}
      handleEngineSizeChange={handleEngineSizeChange}
      handleIsTrailerChange={handleIsTrailerChange}
      handleHitchSizeChange={handleHitchSizeChange}
      handleOwnerShipTypeChange={handleOwnerShipTypeChange}
      errorsData={errorsData}
      clearMaker={clearMaker}
      // onNextSubmit={handleNext}
    />
  );
};

export default VehicleDetailsStep;

const URLS = {
  AUTH_API: '/auth/api',
  ACCOUNT_REFRESHTOKEN: '/account/refresh-token',
  ACCOUNT_DETAILS_BY_ID: '/accounts/#{accountId}',
  UPDATE_PROFILE: '/accounts/#{accountId}',
  UPDATE_PASSWORD: '/accounts/#{accountId}/changepassword',
  UPLOAD_PROFILE_PHOTO: '/files/upload',
  GET_USER_SETTINGS: '/settings',
  // Accounts API
  USER_LOGIN: '/accounts/login',
  USER_LOGOUT: '/accounts/logout',
  USER_DELETE: '/accounts/#{accountId}',
  USER_EXISTS: '/accounts/exists',
  USER_GET_VERIFICATION_ID: '/accounts/verification',
  USER_FORGOT_PASSWORD_VERIFICATION_ID: '/accounts/forgotpassword',
  USER_VERIFY_OTP: '/accounts/verification/#{id}',
  USER_CREATE: '/accounts',
  USER_FORGOT_PASSWORD_CREATE_NEW: '/accounts/forgotpassword/#{id}',
  // My Bookings API
  MY_BOOKINGS: '/bookings',
  MY_BOOKINGS_DETAILS: '/bookings/#{id}',
  MY_BOOKINGS_GET_REVIEW: '/bookings/#{id}/reviews',
  MY_BOOKINGS_POST_REVIEW: '/bookings/#{id}/reviews',
  MY_BOOKINGS_PUT_REVIEW: '/bookings/#{id}/reviews/#{reviewId}',
  MY_BOOKINGS_CANCEL: '/bookings/#{id}/status',
  REFRESH_TOKEN: '/accounts/refreshtoken',
  SEARCH_QUERY: 'vehicles/get/search',
  VEHICLE_DETAILS: 'vehicles/#{vehicleId}',
  VEHICLE_DELETE: 'vehicles/#{vehicleId}',
  CONTACT_US: '/contactus',
  UPLOAD_FILE: '/files/upload',
  NEWS_LETTER: 'subscribers',
  CREATE_BOOKING_REQUEST: '/bookings',
  GET_VEHICLE_DETAILS_BY_ID: '/vehicles/#{id}',
  GET_ALL_BOOKINGS: '/bookings',
  GET_ALL_BOOKINGS_BY_QUERY: '/bookings',
  VEHICLE_LISTING: '/vehicles',
  ACTIVATE_VEHICLE: '/vehicles/#{vehicleId}/status',
  // Owner Dashboard
  VEHICLE_LISTING_PAGE: '/vehicles?accountId=#{accountId}&pageSize=#{pageSize}',
  GET_OWNER_STATISTICS: '/bookings/earnings',
  UPDATE_BOOKINGS_TYPE: '/bookings/#{bookingId}/status',
  BOOKING_LISTING: '/bookings?vehicleId=#{vehicleId}',
  MY_BOOKINGS_BY_QUERY:
    '/bookings?pageNumber=#{pageNumber}&pageSize=#{pageSize}&sortBy=#{sortBy}&sortDirection=#{sortDirection}&accountId=#{accountId}&userType=#{userType}&excludeSelfBooking=#{excludeSelfBooking}',
  VEHICLE_CREATE: '/vehicles',
  GET_ALL_BOOKING_BY_DATES:
    '/bookings?startDate=#{startDate}&endDate=#{endDate}&userType=#{userType}&vehicleId=#{vehicleId}&pageSize=#{pageSize}&type=#{type}',
  BLOCK_UNBLOCK_DATE: '/bookings/#{vehicleId}/blockunblock',
};

export default URLS;

import React from 'react';
import { Link } from 'react-router-dom';
import ApplicationString from '../../../Constants/applicationString';
import unauthorize from '../../../Assets/unauthorize.png';
import cssUtils from '../../../Utils/cssUtils';

const NotAuthorized: React.FC = () => {
  return (
    <div
      className={`${cssUtils.layout.sectionLayout} items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16`}
    >
      <div className=" w-full 2xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <h1 className="mb-4 text-5xl tracking-tight font-extrabold lg:text-7xl text-primary-600 dark:text-primary-500 flex items-end gap-3">
            {ApplicationString.notAuthorize.title}
          </h1>
          <h1 className="my-2 text-gray-800 font-bold text-2xl dark:text-gray-500">
            {ApplicationString.notAuthorize.description1}
          </h1>
          <Link to="/">
            <button
              type="button"
              className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-blue-600 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
            >
              {ApplicationString.notAuthorize.button}
            </button>
          </Link>
        </div>
      </div>
      <div>
        <img src={unauthorize} alt="" />
      </div>
    </div>
  );
};

export default NotAuthorized;

import React, { useEffect, useRef, useState } from 'react';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VehicleDetailsComponent from '../../Components/VehicleDetails';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import {
  getMonthDateRange,
  getLocalStorageSettingData,
  DefaultPaginationValues,
  setLocalStorageInfo,
} from '../../Utils/utils';
import {
  Booking,
  DateRange,
} from '../../Components/BookingDetails/bookingInterface';
import {
  get12HourlyBlockedSlots,
  getBlockedDates,
  getBlockedSlots,
  getSlotForMultipleDay,
} from '../../Utils/blockBookings';
import VehiclePhotoGallery from '../../Components/VehicleDetails/ImageGallery/VehiclePhotoGallery';
import { bookingsPageSize, localStorageEnums } from '../../Utils/enums';
import { dayRange } from '../../Constants/commonConst';
import { IApiErrorResponse } from '../../Interfaces/interfaces';

const VehicleDetailsContainer: React.FC = () => {
  const navigate = useNavigate();

  const [vehicleDetails, setVehicleDetails] = useState<IVehiclesData>(
    {} as IVehiclesData
  );
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState<[]>([]);
  const [isChange, setChange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const hasVehicleMounted = useRef(false);
  const hasBookingsMounted = useRef(false);
  const location = useLocation();
  const isPhotoGallery = location.pathname.includes('photoGallery');
  const { VehicleId = '' } = useParams();
  const getVehicleDetails = async (): Promise<void> => {
    const finalURL = `${URLS.VEHICLE_DETAILS.replace(
      '#{vehicleId}',
      VehicleId
    )}`;
    try {
      setLoading(true);
      const response: AxiosResponse = await ApiRequest.get(finalURL);
      if (response?.status === HttpStatusCode.Ok) {
        setVehicleDetails(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      hasVehicleMounted.current = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasVehicleMounted.current) {
      hasVehicleMounted.current = true;
      getVehicleDetails();
    }
  }, [VehicleId]);

  const getAllBookings = async (
    vehicleId: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      // eslint-disable-next-line consistent-return
      const fetchAllBookings = async (pageSize: number) => {
        try {
          let bookingPageSize = DefaultPaginationValues.PAGE_SIZE;
          if (pageSize > 0) {
            bookingPageSize = pageSize;
          }
          const response = await ApiRequest.get(
            `${URLS.GET_ALL_BOOKINGS_BY_QUERY}?vehicleId=${vehicleId}&pageSize=${bookingPageSize}&type=active&startDate=${startDate}&endDate=${endDate}`
          );
          // Assuming the API returns bookings data
          if (response.status === HttpStatusCode.Ok) {
            return response.data;
          }
        } catch (error) {
          const axiosError = error as AxiosError<IApiErrorResponse>;
          console.error(axiosError.message);
        } finally {
          hasBookingsMounted.current = false;
        }
      };

      // Step 3: Get total count and then fetch all bookings
      // totalRecords = await getTotalRecords();
      const allBookings = await fetchAllBookings(bookingsPageSize);
      // console.log('inside function', allBookings);
      // Step 4: Set bookings state or process the data as needed
      setBookings(allBookings.data);
    } catch (err) {
      console.error('Error fetching bookings:', err);
    }
  };
  const createBooking = async (data: Booking) => {
    try {
      // Save booking data to localStorage
      setLocalStorageInfo(data, localStorageEnums.booking);
      // Redirect to bookingDetails/vehicleId
      navigate(`/bookingDetails/${data.vehicleId}`);
    } catch (error) {
      console.error('Error saving booking:', error);
      // Handle error as needed
    }
  };
  const localStorageSettingData = getLocalStorageSettingData();
  const setting = localStorageSettingData?.setting || null;

  const bookingTimeConstraints = setting?.bookingTimeConstraints || null;
  const hourlyBookingStartHour =
    bookingTimeConstraints?.hourlyBookingStartHour ?? 8; // Default value if not set
  const hourlyBookingEndHour =
    bookingTimeConstraints?.hourlyBookingEndHour ?? 22; // Default value if not set

  const slots = setting ? setting['12hourlyTimeFrame'] : [];

  const disabledDateRange = getBlockedDates(
    bookings,
    hourlyBookingStartHour,
    hourlyBookingEndHour,
    vehicleDetails?.bookingGap || 0,
    vehicleDetails?.isMultipleBookingSameDay,
    slots,
    vehicleDetails?.bookingRateHourly,
    vehicleDetails?.timeFrameFor12Hourly || [],
    isChange.selectionType
  );

  const disabledHourlySlots = getBlockedSlots(
    bookings,
    isChange.startDate || new Date().toISOString(),
    hourlyBookingStartHour,
    hourlyBookingEndHour,
    vehicleDetails?.bookingGap || 0
  );

  const disabled12HourlySlots = get12HourlyBlockedSlots(
    slots,
    bookings,
    isChange.startDate || new Date().toISOString(),
    dayRange.startHour,
    dayRange.endHour,
    vehicleDetails?.bookingGap || 0
  );

  const disabledSlots = disabledHourlySlots.concat(disabled12HourlySlots);
  const disabledMultipleDayStartSlots = getSlotForMultipleDay(
    bookings,
    isChange.startDate || new Date().toISOString(),
    dayRange.startHour,
    dayRange.endHour,
    vehicleDetails?.bookingGap || 0
  );
  const disabledMultipleDayEndSlots = getSlotForMultipleDay(
    bookings,
    isChange.endDate || new Date().toISOString(),
    dayRange.startHour,
    dayRange.endHour,
    vehicleDetails?.bookingGap || 0
  );
  useEffect(() => {
    if (!hasBookingsMounted.current) {
      hasBookingsMounted.current = true;
      const { startDate, endDate } = getMonthDateRange(
        isChange.startDate,
        isChange.endDate
      );
      if (startDate && endDate) {
        getAllBookings(VehicleId, startDate, endDate);
      }
    }
  }, [VehicleId, isChange]);

  return (
    <div>
      {isPhotoGallery ? (
        <VehiclePhotoGallery
          vehicleDetails={vehicleDetails}
          isLoading={loading}
        />
      ) : (
        <VehicleDetailsComponent
          disabledSlots={disabledSlots}
          disabledDateRange={disabledDateRange}
          vehicleDetails={vehicleDetails}
          onCreateBooking={createBooking}
          isLoading={loading}
          setChange={setChange}
          disabledMultipleDayStartSlots={disabledMultipleDayStartSlots}
          disabledMultipleDayEndSlots={disabledMultipleDayEndSlots}
        />
      )}
    </div>
  );
};

export default VehicleDetailsContainer;

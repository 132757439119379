import React, { useEffect, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import userIcon from '../../Assets/personIcon.png';
import PaymentCard from './paymentCardComponent';
import clockIcon from '../../Assets/clock.png';
import calenderIcon from '../../Assets/Calendar.png';
import CommonAccordian from '../common/CommonAccordian';
import InputArea from './inputArea';
import ApplicationString from '../../Constants/applicationString';
import {
  BookingLocalstorage,
  bookingCalculation,
  getBookingFromLocalStorage,
  getLocalStorageSettingData,
  getLocalStorageAccountInfo,
  checkMaxWordLength,
  setLocalStorageInfo,
} from '../../Utils/utils';
import {
  BookingDetails,
  BookingParams,
  ChildComponentProps,
} from './bookingInterface';
import { TaxAndDepositData } from '../../Interfaces/LocalStorageSettings';
import VehicleBookingComponent from '../VehicleDetails/vehicleBookingComponent';
import InternalRoute from '../../Utils/internalRoutes';
import InfoBlock from './InfoBlock';
import { dateFormat, maxWorkLen } from '../../Constants/commonConst';
import { localStorageEnums } from '../../Utils/enums';
import {
  ITermsAndCondition,
  IUserSettingsData,
} from '../../Interfaces/Settings/SettingsInterfaces';
import PaymentConfirmModal from './paymentConfirmModal';

dayjs.extend(utc);

const BookingComponent: React.FC<ChildComponentProps> = ({
  vehicleDetails,
  disabledDateRange,
  onCreateBooking,
  disabledSlots,
  setChange,
  disabledMultipleDayStartSlots,
  disabledMultipleDayEndSlots,
  isSubmitting,
  monthLimit,
}) => {
  const storedBooking = getBookingFromLocalStorage();
  const navigate = useNavigate();
  // const [dateRange, setDateRange] = useState<{
  //   startDate: string | null;
  //   endDate: string | null;
  // }>({
  //   startDate: StaticDates.startDate,
  //   endDate: StaticDates.endDate,
  // });
  // const [passengerCount, setPassengerCount] = useState<number>(1);
  const [pickUpTime, setPickUpTime] = useState<string | null>(null);
  const [dropOffTime, setDropOffTime] = useState<string | null>(null);
  const [specialRequest, setSpecialRequest] = useState<string>('');
  const [isPaymentCardOpen, setIsPaymentCardOpen] = useState<boolean>(false);
  const [specialRequestError, setSpecialRequestError] = useState<
    string | boolean
  >('' || false);
  const [TermsAndConditions, setTermsAndConditions] = React.useState<
    ITermsAndCondition[]
  >([]);

  const [editBookingDetails, setEditBookingDetails] =
    useState<BookingLocalstorage | null>(storedBooking);
  const [openModal, setOpenModal] = useState(false); // State for modal open/close
  const taxAndDepositData: TaxAndDepositData =
    getLocalStorageSettingData()?.setting?.taxAndDepositData;

  const params: BookingParams = {
    dateRangeCal: {
      startDate: storedBooking?.startDate || null,
      endDate: storedBooking?.endDate || null,
    },
    pickupTime: pickUpTime,
    dropoffTime: dropOffTime,
    hrRate: vehicleDetails?.bookingRateHourly || 0,
    hr12Rate: vehicleDetails?.bookingRate12Hourly || 0,
    hr24Rate: vehicleDetails?.bookingRate24Hourly || 0,
    depositRate: taxAndDepositData?.minimumDeposit || 0,
    taxRatePercentage: taxAndDepositData?.taxPercentage || 0,
    depositPercentage: taxAndDepositData?.depositPercentage || 0,
  };
  const bookingDetails: BookingDetails = bookingCalculation(params);

  const vehicleImageUrl =
    vehicleDetails &&
    vehicleDetails.images &&
    vehicleDetails.images.length > 0 &&
    vehicleDetails.images[0].path
      ? vehicleDetails.images[0].path
      : 'https://via.placeholder.com/64';
  const paymentDetails = {
    imageUrl: vehicleImageUrl,
    title: vehicleDetails?.title || '',
    rating: vehicleDetails?.rating || '',
    reviewsCount: 0,
    calculatedRate: bookingDetails.vehicleRate,
    dailyRate: bookingDetails.hrRateUsed || 0,
    days: bookingDetails.includedTime.days,
    hr: bookingDetails.includedTime.hours,
    securityDeposit: bookingDetails.depositRate,
    taxes: bookingDetails.taxAmount,
    total: bookingDetails.totalCost,
  };

  const handleSaveBookingDetails = (data: BookingLocalstorage) => {
    setLocalStorageInfo(data, localStorageEnums.booking);
    setEditBookingDetails(storedBooking);
    setOpenModal(false);
  };

  const handleBack = () => {
    if (vehicleDetails && vehicleDetails.id) {
      navigate(
        `${InternalRoute.Vehicle_details.replace(':VehicleId', String(vehicleDetails.id))}`
      );
    }
  };

  const handleConfirmAndPay = () => {
    // setIsPaymentCardOpen(true);

    const specialRequestMessage = checkMaxWordLength(
      specialRequest,
      maxWorkLen.specialRequest,
      ApplicationString.specialRequestErrorMessage
    );
    if (specialRequestMessage) {
      setSpecialRequestError(specialRequestMessage); // Handle the error message appropriately (e.g., show to user)
      return; // Prevent further processing if there is an error
    }

    if (editBookingDetails) {
      const bookingData = {
        startDate: editBookingDetails?.startDate,
        endDate: editBookingDetails?.endDate,
        specialRequest,
        numberOfPerson: editBookingDetails?.numberOfPerson || 1,
        rate: editBookingDetails.rate,
        tax: editBookingDetails.tax,
        deposit: editBookingDetails.deposit,
        total: editBookingDetails.total,
        vehicleId: Number(editBookingDetails?.vehicleId),
      };
      onCreateBooking(bookingData);
      setOpenModal(false); // Close modal after confirming
    }
  };
  useEffect(() => {
    const settingsData = getLocalStorageAccountInfo<IUserSettingsData>(
      localStorageEnums.settings
    )?.setting;

    if (settingsData) {
      const { securityDeposit, termsAndCondition } = settingsData;

      // Define titles for "Regular Bookings" and "Short Notice Bookings"
      const regularBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.RegularBookings;
      const shortNoticeBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.ShortNoticeBookings;

      // Extract details for "Regular Bookings"
      const regularBookingsDetails =
        termsAndCondition.find((term) => term.title === regularBookingsTitle)
          ?.details || [];

      // Extract details for "Short Notice Bookings"
      const shortNoticeBookingsDetails =
        termsAndCondition.find(
          (term) => term.title === shortNoticeBookingsTitle
        )?.details || [];

      // Ensure securityDeposit is an array
      const securityDepositDetails = Array.isArray(securityDeposit)
        ? securityDeposit
        : [securityDeposit];

      // Combine details, ensuring they are flat arrays
      const combinedDetails = [
        regularBookingsTitle,
        ...regularBookingsDetails,
        shortNoticeBookingsTitle,
        ...shortNoticeBookingsDetails,
      ];

      // Update terms and conditions
      const updatedTermsAndConditions: ITermsAndCondition[] =
        termsAndCondition.map((term) => {
          if (term.title === regularBookingsTitle) {
            return {
              ...term,
              details: securityDepositDetails,
            };
          }
          if (term.title === shortNoticeBookingsTitle) {
            return {
              ...term,
              details: combinedDetails.flat(), // Flatten the combined details array if it's nested
            };
          }
          return term;
        });

      setTermsAndConditions(updatedTermsAndConditions);
    } else {
      setTermsAndConditions([]);
    }
  }, []);

  useEffect(() => {
    if (editBookingDetails) {
      setPickUpTime(editBookingDetails?.startTime || '');
      setDropOffTime(editBookingDetails?.endTime || '');
    }
  }, [editBookingDetails, openModal]);
  const handleEditClick = () => {
    setOpenModal(true); // Open modal on edit click
  };

  useEffect(() => {
    if (storedBooking) {
      setEditBookingDetails(storedBooking);
    }
  }, [openModal]);

  const updatedAccordianTitles = [
    {
      id: 1,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies.securityDeposit,
    },
    {
      id: 2,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies
          .cancellationPolicy,
    },
  ];

  return (
    <>
      <div
        aria-hidden="true"
        onClick={handleBack}
        data-testid="vehicle-booking-back-button"
        className="text-2xl cursor-pointer 2xl:text-3xl flex gap-3 items-center dark:text-white mb-6"
      >
        <ArrowBackIosNewIcon className="size-4 md:size-6" />
        {ApplicationString.bookingDetailsData.requestBook}
      </div>
      <div className="flex gap-8 2xl:gap-15 justify-between 2xl:flex-row flex-col">
        <div className="flex w-full flex-col flex-6 gap-2 p-6 shadow-lg dark:border dark:border-dark_borderStroke rounded-2xl bg-white dark:bg-dark_bg_secondary">
          <div className="flex justify-between">
            <div className="font-semibold dark:text-white">
              {ApplicationString.bookingDetailsData.bookingDetails}
            </div>
            <button
              type="button"
              disabled={!vehicleDetails?.id}
              className="text-lg font-semibold underline dark:text-white text-primary_text cursor-pointer"
              onClick={handleEditClick}
              aria-hidden="true"
              data-testid="edit-bookingDetails-button"
            >
              {ApplicationString.bookingDetailsData.editLabel}
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <InfoBlock
              icon={calenderIcon}
              label={ApplicationString.bookingDetailsData.dates}
              value={
                editBookingDetails?.bookingType === 'single'
                  ? `${dayjs.utc(editBookingDetails?.startDate).format(dateFormat)}`
                  : `${dayjs.utc(editBookingDetails?.startDate).format(dateFormat)} - ${dayjs.utc(editBookingDetails?.endDate).format(dateFormat)}`
              }
            />
            {editBookingDetails?.bookingType === 'single' ? (
              <InfoBlock
                icon={clockIcon}
                label={ApplicationString.timeSlotData.label}
                value={editBookingDetails?.timeSlot || ''}
              />
            ) : (
              <div className="flex sm:flex-row flex-col gap-2">
                <InfoBlock
                  icon={clockIcon}
                  label={ApplicationString.timeDropdownData.pickUpLabel}
                  value={editBookingDetails?.startTimeLabel || '00:00'}
                />
                <InfoBlock
                  icon={clockIcon}
                  label={ApplicationString.timeDropdownData.dropOffLabel}
                  value={editBookingDetails?.endTimeLabel || '00:00'}
                />
              </div>
            )}
            <InfoBlock
              icon={userIcon}
              label={ApplicationString.passengerDropdown.passengers}
              value={
                editBookingDetails
                  ? `${editBookingDetails.numberOfPerson} ${
                      ApplicationString.passengerDropdown.passengers
                    }${editBookingDetails.numberOfPerson > 1 ? 's' : ''}`
                  : ''
              }
            />
          </div>
          <InputArea
            specialRequestError={specialRequestError}
            setSpecialRequestError={setSpecialRequestError}
            specialRequest={specialRequest}
            setSpecialRequest={setSpecialRequest}
          />
          <CommonAccordian
            title={updatedAccordianTitles}
            TermsAndConditions={TermsAndConditions}
          />
        </div>
        <div className="px-0 w-full 2xl:w-1/2 py-0">
          {editBookingDetails && (
            <PaymentCard
              handleConfirmAndPay={handleConfirmAndPay}
              calculatedRate={editBookingDetails.rate}
              imageUrl={
                paymentDetails?.imageUrl || 'https://via.placeholder.com/64'
              }
              title={paymentDetails?.title}
              hr={paymentDetails?.hr}
              rating={Number(paymentDetails?.rating) || 0}
              // reviewsCount={paymentDetails.reviewsCount}
              dailyRate={paymentDetails.dailyRate}
              days={paymentDetails.days}
              securityDeposit={editBookingDetails.deposit}
              taxes={editBookingDetails.tax}
              total={editBookingDetails.total}
              isSubmitting={isSubmitting || false}
            />
          )}
        </div>
      </div>

      <Modal
        disableAutoFocus
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="absolute max-h-200 top-1/2 left-1/2 transform -translate-x-1/2 dark:bg-dark_bg_secondary border-0 -translate-y-1/2 w-11/12 max-w-md bg-white shadow-xl rounded-lg">
          <VehicleBookingComponent
            editBookingDetails={editBookingDetails}
            useForVehicleDetails={false}
            vehicleDetails={vehicleDetails}
            onCreateBooking={handleSaveBookingDetails}
            disabledDateRange={disabledDateRange}
            disabledSlots={disabledSlots}
            setChange={setChange}
            disabledMultipleDayStartSlots={disabledMultipleDayStartSlots}
            disabledMultipleDayEndSlots={disabledMultipleDayEndSlots}
            monthLimit={monthLimit}
          />
        </div>
      </Modal>
      <Modal
        data-testid="payment-modal"
        disableAutoFocus
        open={isPaymentCardOpen}
        onClose={() => setIsPaymentCardOpen(false)}
      >
        <div className="absolute max-h-200 top-1/2 left-1/2 transform -translate-x-1/2 dark:bg-dark_bg_secondary border-0 -translate-y-1/2 w-11/12 max-w-md bg-white shadow-xl rounded-lg">
          <PaymentConfirmModal handleConfirmAndPay={handleConfirmAndPay} />
        </div>
      </Modal>
    </>
  );
};

export default BookingComponent;

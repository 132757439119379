import React, { useState } from 'react';
import { ITermsAndCondition } from '../../../Interfaces/Settings/SettingsInterfaces';
import ApplicationString from '../../../Constants/applicationString';

interface TitleForAccordion {
  id: number;
  title: string;
}
interface CommonAccordionProps {
  title: TitleForAccordion[];
  TermsAndConditions: ITermsAndCondition[];
}

const CommonAccordion: React.FC<CommonAccordionProps> = ({
  title,
  TermsAndConditions,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderDetails = (index: number) => {
    const terms = TermsAndConditions[index];
    if (terms) {
      return (
        <ul className="list-disc pl-5 flex flex-col gap-2 ">
          {terms?.details.map((detail) => (
            <li
              data-testid={`accordion-collapse-body-${detail}`}
              key={detail}
              className={`text-sm font-medium ${
                detail?.includes(
                  ApplicationString.MyBookingDetailsComponent.RegularBookings
                ) ||
                detail?.includes(
                  ApplicationString.MyBookingDetailsComponent
                    .ShortNoticeBookings
                )
                  ? 'font-semibold text-black'
                  : 'text-secondary_text dark:text-dark_secondary_text'
              }`}
            >
              {detail}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="w-full">
      <div data-testid="accordion-collapse" data-accordion="collapse">
        {title.map((item, index) => (
          <div key={item.id}>
            <h2 data-testid={`accordion-collapse-heading-${item.id}`}>
              <button
                type="button"
                className={`flex outline-none my-2 items-center justify-between w-full p-5 dark:border dark:border-dark_borderStroke font-semibold rtl:text-right border text-Light_bg_surface rounded-full dark:focus:ring-gray-800 dark:text-white dark:bg-Light_bg_surface dark:hover:bg-dark_bg_surface gap-3 ${activeIndex === item.id ? 'bg-black hover:bg-black text-slate-100' : 'bg-bg_light_primary '}`}
                data-accordion-target={`#accordion-collapse-body-${item.id}`}
                aria-expanded={activeIndex === item.id ? 'true' : 'false'}
                aria-controls={`accordion-collapse-body-${item.id}`}
                onClick={() => handleClick(item.id)}
                data-testid={`accordion-collapse-button-${item.id}`}
              >
                <span className="font-medium text-md leading-tight">
                  {item.title}
                </span>
                {activeIndex === item.id ? (
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-0 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                ) : (
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                )}
              </button>
            </h2>
            <div
              data-testid={`accordion-collapse-body-${item.id}`}
              className={activeIndex === item.id ? 'block' : 'hidden'}
              aria-labelledby={`accordion-collapse-heading-${item.id}`}
            >
              <div className="p-5 border my-2 rounded-xl dark:border dark:border-dark_borderStroke dark:bg-dark_bg_surface border-slate-300">
                {renderDetails(index)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonAccordion;

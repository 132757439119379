import { styled } from '@mui/system';
import themeColors from '../../oneLifeThemeColors';

const MuiOtpStyledInput = styled('input')<{ error?: boolean }>(({
  theme,
  error,
}) => {
  const col = error
    ? 'red'
    : `${theme.palette.mode === 'dark' ? themeColors.darkTheme.otpBoxBorder : themeColors.lightTheme.otpBoxBorder}`;
  return `
      max-width: 70px;
      width: 37px;
      height: 72px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.5;
      padding: 5px 5px;
      border-radius: 24px;
      text-align: center;
      color: ${theme.palette.mode === 'dark' ? themeColors.darkTheme.primary_text : themeColors.lightTheme.primary_text};
      background: transparent;
      border: 1px solid ${col};
  
    
      &:hover {
        border-color: ${themeColors.lightTheme.primary};
      }
    
      &:focus {
        border-color: ${themeColors.lightTheme.primary};
      }
    
      // firefox
      &:focus-visible {
        outline: 0;
      }
    
    
      @media (min-width: 375px) {
        width: 45px;
      }
    
      @media (min-width: 425px) {
        width: 55px;
      }
    `;
});

export default MuiOtpStyledInput;

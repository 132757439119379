import React, { useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { FormControl, FormHelperText, TextField, Button } from '@mui/material';
import ApplicationString from '../../../Constants/applicationString';
import bgNewsLetterImg from '../../../Assets/bgNewsletter.png';
import cssUtils from '../../../Utils/cssUtils';
import { ApiRequest } from '../../../Api/api';

import commmonRegexs from '../../../Utils/commonRegex';
import { handleApiError } from '../../../Utils/utils';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import URLS from '../../../Constants/urls';

interface INewsLetterFormData {
  email: string;
}

interface IErrorsData {
  email: {
    error: boolean;
    ValidationMessage: string;
  };
}

const NewsLetterComponent: React.FC = () => {
  const newsletterContent = ApplicationString.newsletterForm;

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [formData, setFormData] = React.useState<INewsLetterFormData>({
    email: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    email: {
      error: false,
      ValidationMessage: '',
    },
  });

  const newsLetterEmailChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const email = e.target.value;
    const emailRegex = commmonRegexs.email;

    let emailError = false;
    let validationMessage = '';

    if (!emailRegex.test(email) && formData.email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .invalidEmail;
    }
    if (email.trim() === '') {
      emailError = false;
      validationMessage = '';
    }
    setFormData((prevState) => ({
      ...prevState,
      email: email.trim(),
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: emailError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.email) {
      setErrorsData({
        email: {
          error: true,
          ValidationMessage:
            ApplicationString.newsletterForm.validationMessage.requiredMsgs
              .email,
        },
      });
    }
    if (!formData.email || errorsData.email.error) {
      return;
    }
    try {
      const res = await ApiRequest.post(URLS.NEWS_LETTER, {
        email: formData.email,
      });
      if (res?.status === HttpStatusCode.Created) {
        setIsSubscribed(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
    }
  };

  return (
    <div className="relative dark:bg-dark_bg_secondary w-full pt-15 pb-15 ">
      <div className="mx-8">
        <form
          data-testid="form-newsletter"
          onSubmit={handleSubmit}
          className=" bg-slate-800 rounded-3xl mx-auto flex max-w-7xl items-center justify-between relative"
          style={{
            backgroundImage: `url(${bgNewsLetterImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            objectFit: 'contain',
          }}
        >
          <div className="3xsm:py-6 3xsm:px-6 xsm:px-8 xsm:py-8 lg:px-12 lg:py-12 lg:flex flex flex-col lg:flex-row gap-4 w-full justify-between items-center relative z-10 dark:border-dark_borderStroke">
            <div className="text-white">
              <p className="font-semibold text-3xl lg:text-3xl 2xl:text-4xl leading-tight">
                {newsletterContent.title}
              </p>
              <p className="sm:text-base text-mb leading-normal">
                {newsletterContent.description}
              </p>
            </div>
            {!isSubscribed ? (
              <div className="sm:w-auto max-w-80 min-h-[118px] lg:min-h-unset lg:max-w-auto 2xl:w-auto sm:max-w-fit w-full flex flex-col lg:flex-row lg:items-center gap-2">
                <FormControl className="w-full md:w-[368px] h-[46px]">
                  <TextField
                    placeholder={ApplicationString.contactUs.fields.emailLable}
                    className="w-full h-full rounded-[50px] text-base font-medium boxShadow-none"
                    InputProps={{
                      className: 'rounded-[50px] p-0 h-full',
                    }}
                    autoComplete="off"
                    InputLabelProps={{
                      className: 'left-[10px]',
                    }}
                    value={formData.email}
                    error={errorsData.email.error}
                    onChange={newsLetterEmailChangeHandler}
                  />
                  {errorsData.email.error && (
                    <FormHelperText className="text-error">
                      {errorsData.email.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <div>
                  <Button
                    data-testid="newletter-subscribe-button"
                    variant="contained"
                    className={`${cssUtils.button.secondary} capitalize ${errorsData.email.error ? 'mt-[20px] lg:mt-0' : ''}`}
                    type="submit"
                  >
                    {isSubscribed
                      ? newsletterContent.subscribedText
                      : newsletterContent.buttonText}
                  </Button>
                </div>
              </div>
            ) : (
              <p className="font-semibold 2xl:text-2xl leading-tight text-white sm:text-center sm:text-xl">
                {newsletterContent.successMessage}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsLetterComponent;

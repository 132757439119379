/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { CircularProgress } from '@mui/material';
import StaticCard from '../StaticCard';
import staticCalender from '../../../Assets/Svgs/staticCalender.svg';
import dollarSign from '../../../Assets/Svgs/dollarSign.svg';
import missedBookingSvg from '../../../Assets/Svgs/missedBooking.svg';
import listingSvg from '../../../Assets/Svgs/listIcon.svg';
import ApplicationString from '../../../Constants/applicationString';
import { IOwnerStatistics } from '../../../Interfaces/Owner/ownerDashboard.interface';

interface IOwnerStaticsProps {
  ownerStatistics: IOwnerStatistics | undefined;
  isStatisticsLoading: boolean;
}

const OwnerStatics: React.FC<IOwnerStaticsProps> = (props): JSX.Element => {
  const { ownerStatistics, isStatisticsLoading } = props;
  return (
    <>
      {isStatisticsLoading ? (
        <div className="flex justify-center min-h-20">
          <CircularProgress className="mt-4" />
        </div>
      ) : (
        <div className="flex justify-center px-4 md:px-8 lg:px-10 xl:px-8">
          <div className="py-14 grid grid-cols-1 place-items-center sm:grid-cols-2 xl:grid-cols-4 max-w-[1280px] w-full gap-6">
            <StaticCard
              svg={staticCalender}
              StatisticsValue={ownerStatistics?.totalCompletedBookingCount || 0}
              cardTitle={
                ApplicationString.OwnerDashboard.cardLabels
                  .totalCompletedBookings
              }
            />
            <StaticCard
              svg={dollarSign}
              StatisticsValue={`$${ownerStatistics?.totalEarning || 0}`}
              cardTitle={
                ApplicationString.OwnerDashboard.cardLabels.totalEarning
              }
            />
            <StaticCard
              svg={listingSvg}
              StatisticsValue={ownerStatistics?.totalVehicleCount || 0}
              cardTitle={
                ApplicationString.OwnerDashboard.cardLabels.yourListing
              }
            />
            <StaticCard
              svg={missedBookingSvg}
              StatisticsValue={
                ownerStatistics?.totalSystemRejectedBookingCount || 0
              }
              cardTitle={
                ApplicationString.OwnerDashboard.cardLabels.missedBookings
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OwnerStatics;

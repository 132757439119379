import { AxiosError, HttpStatusCode } from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { ApiRequest } from '../../Api/api';
import LoginWithEmailComponent from '../../Components/LoginComponents/LoginWithEmail';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import commmonRegexs from '../../Utils/commonRegex';
import { localStorageEnums, loginSignupFormEnums } from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import {
  getDetailsFromLocalStorage,
  handleApiError,
  setLocalStorageInfo,
} from '../../Utils/utils';
import SendBirdService from '../../Services/sendbird.service';

interface ILoginFormData {
  email: string;
  password: string;
}

interface IErrorsData {
  email: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

const LoginWithEmailContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [emailHasValue, setEmailHasValue] = React.useState(false);
  const [passwordHasValue, setPasswordHasValue] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasValue = (value: any) => value !== '';
  const [formData, setFormData] = React.useState<ILoginFormData>({
    email: '',
    password: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    email: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    password: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
  });
  const globalStore = useSendbirdStateContext();

  // Show validation errors based on api response
  const showValidationErrors = (err: AxiosError<IApiErrorResponse>) => {
    if (
      (err?.response?.status === HttpStatusCode.BadRequest ||
        err?.response?.status === HttpStatusCode.NotFound) &&
      formData.password.trim() &&
      formData.email.trim()
    ) {
      setErrorsData((prevState) => ({
        ...prevState,
        email: {
          error: false,
          ValidationMessage: '',
        },
        password: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage:
            ApplicationString.loginWithEmail.validationMessages.invalidMsgs
              .invalidEmailOrPassword,
        },
      }));
    }
  };

  // Form submit handler
  const onEmailSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!commmonRegexs.email.test(formData.email) && formData.email !== '')
      return;
    if (
      formData.email.length > loginSignupFormEnums.maxEmailLength ||
      formData.password.length > loginSignupFormEnums.maxPasswordLength
    )
      return;
    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: !formData.email.trim(),
        ValidationMessage: !formData.email.trim()
          ? ApplicationString.loginWithEmail.validationMessages.requiredMsgs
              .email
          : '',
      },
      password: {
        error: !formData.password.trim(),
        ValidationMessage: !formData.password.trim()
          ? ApplicationString.loginWithEmail.validationMessages.requiredMsgs
              .password
          : '',
      },
    }));
    if (!formData.email.trim() || !formData.password.trim()) return;

    const payload = {
      userName: formData.email,
      password: formData.password,
    };
    try {
      setIsLoggingIn(true);
      const res = await ApiRequest.post(`${URLS.USER_LOGIN}`, payload);

      if (res?.status === HttpStatusCode.Ok) {
        setLocalStorageInfo(res.data, localStorageEnums.userInfo);
        const lastAccessUrl = getDetailsFromLocalStorage(
          localStorageEnums.lastAccessUrl
        );
        navigate(lastAccessUrl || InternalRoute.Home);

        const { accountId: currentUserId, userName, avatarPath } = res.data;

        await SendBirdService.connectUser(currentUserId, globalStore);

        await SendBirdService.handleUserCreationOrUpdate({
          currentUserId,
          userName,
          avatarPath,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showValidationErrors(axiosError);
      handleApiError(axiosError);
    } finally {
      setIsLoggingIn(false);
    }
  };

  // Form change handlers

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const email = e.target.value;
    const emailRegex = commmonRegexs.email;

    let emailError = false;
    let validationMessage = '';
    if (!emailRegex.test(email) && email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .invalidEmail;
    }

    if (email.length > loginSignupFormEnums.maxEmailLength) {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .maxEmailLength;
    }

    setFormData((prevState) => ({
      ...prevState,
      email: email.trim(),
    }));
    setEmailHasValue(hasValue(email));
    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: emailError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');
    setFormData((prevState) => ({
      ...prevState,
      password: trimmedPassword,
    }));
    setPasswordHasValue(hasValue(trimmedPassword));
    if (trimmedPassword.length > loginSignupFormEnums.maxPasswordLength) {
      setErrorsData((prevState) => ({
        ...prevState,
        password: {
          error: true,
          ValidationMessage:
            ApplicationString.loginWithEmail.validationMessages.invalidMsgs
              .maxPasswordLength,
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        password: {
          error: false,
          ValidationMessage: '',
        },
      }));
    }
  };

  return (
    <LoginWithEmailComponent
      formData={formData}
      onEmailSubmit={onEmailSubmit}
      errorsData={errorsData}
      setEmailHasValue={setEmailHasValue}
      emailHasValue={emailHasValue}
      passwordHasValue={passwordHasValue}
      setPasswordHasValue={setPasswordHasValue}
      handleEmailChange={handleEmailChange}
      handlePasswordChange={handlePasswordChange}
      loggingIn={isLoggingIn}
    />
  );
};

export default LoginWithEmailContainer;

import React from 'react';

interface IColorModeContext {
  colorMode: {
    toggleColorMode: (color: string) => void;
  };
  currentTheme: string;
}

const ColorModeContext = React.createContext<IColorModeContext>({
  colorMode: { toggleColorMode: () => {} },
  currentTheme: '',
});

export default ColorModeContext;

import React from 'react';
import infoIcon from '../../Assets/infoIcon.png';
import ApplicationString from '../../Constants/applicationString';
import { checkMaxWordLength } from '../../Utils/utils';
import { maxWorkLen } from '../../Constants/commonConst';

interface InputAreaProps {
  specialRequest: string;
  specialRequestError: string | boolean;
  setSpecialRequest: (value: string) => void;
  setSpecialRequestError: (value: string | boolean) => void;
}

const InputArea: React.FC<InputAreaProps> = ({
  specialRequest,
  specialRequestError,
  setSpecialRequest,
  setSpecialRequestError,
}) => {
  const handleInputChange = (value: string) => {
    const specialRequestMessage = checkMaxWordLength(
      value,
      maxWorkLen.specialRequest,
      ApplicationString.specialRequestErrorMessage
    );
    if (specialRequestMessage) {
      setSpecialRequestError(specialRequestMessage);
    } else {
      setSpecialRequestError(specialRequestMessage);
    }
    setSpecialRequest(value);
  };

  return (
    <div className="mt-4">
      <div className="font-semibold dark:text-white">
        {ApplicationString.bookingDetailsData.specialRequest}
      </div>
      <textarea
        className="w-full dark:border-dark_borderStroke dark:bg-dark_formFieldBg my-2 border rounded-2xl focus:outline-none max-h-80 min-h-30 mb-0 border-gray-300 p-4"
        placeholder={ApplicationString.bookingDetailsData.addListingDescription}
        value={specialRequest}
        onChange={(e) => handleInputChange(e.target.value)}
        data-testid="specialRequest-bookingDetails-input"
      />
      <div className="flex gap-2 items-center">
        <img
          src={infoIcon}
          className="w-4 h-4"
          alt="info text"
          data-testid="infoImage-bookingDetails-image"
        />
        {specialRequestError && (
          <div className="text-red-500 text-sm">{specialRequestError}</div>
        )}
        {!specialRequestError && (
          <div className="text-slate-500 text-sm">
            {ApplicationString.bookingDetailsData.wordsMax}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputArea;

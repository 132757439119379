import TextHeading from '../TextHeading';
import PreviewContentComponent from '../PreviewContent';
import { IStepData } from '../../../../Interfaces/Stepper';
import { defaultStringNAValue } from '../../../../Constants/commonConst';
import ApplicationString from '../../../../Constants/applicationString';
import { localStorageEnums, stepsNumberEnum } from '../../../../Utils/enums';
import { getDetailsFromLocalStorage } from '../../../../Utils/utils';

const PreviewAdditionalDetails = ({
  isEdit,
}: {
  isEdit: boolean;
}): JSX.Element => {
  const stepsData = getDetailsFromLocalStorage<IStepData[]>(
    localStorageEnums.stepsData
  );

  const vehicleData = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step4
  );

  return (
    <div className="flex flex-col gap-3">
      <div>
        <TextHeading
          label={ApplicationString.vehicleStepper.previewStep.previewStep4}
          buttonText={ApplicationString.vehicleStepper.previewStep.edit}
          navigatePathStep={stepsNumberEnum.step4}
          isEdit={isEdit}
        />
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step4Details.amenities}
            description={
              vehicleData?.data?.amenities?.join(', ') || defaultStringNAValue
            }
            isRequired
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step4Details.restricted}
            description={
              vehicleData?.data?.restrictions?.join(', ') ||
              defaultStringNAValue
            }
            isRequired
            isRightAligned
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewAdditionalDetails;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { OnArgs, Value, View } from 'react-calendar/dist/cjs/shared/types';
import { useSearchParams } from 'react-router-dom';
import { dateFormat } from '../../../Constants/commonConst';
import cssUtils from '../../../Utils/cssUtils';
import ApplicationString from '../../../Constants/applicationString';
import ColorModeContext from '../../../Utils/ColorModeContext';

dayjs.extend(utc);

const parseDate = (dateString: string) => dayjs.utc(dateString).toDate();

interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

interface DateRangePickerProps {
  isDateSingle: boolean;
  onDateChange: (value: DateRange) => void;
}

const CommonDatePicker: React.FC<DateRangePickerProps> = ({
  isDateSingle = false,
  onDateChange,
}) => {
  const [searchParams] = useSearchParams();
  const selectedStartDate = searchParams.get('startDate') || '';
  const selectedEndDate = searchParams.get('endDate') || '';
  const [value, setValue] = useState<Value | null>(
    isDateSingle
      ? (selectedStartDate && parseDate(selectedStartDate)) || null
      : selectedStartDate && selectedEndDate
        ? [parseDate(selectedStartDate), parseDate(selectedEndDate)]
        : null
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [error, setError] = useState('');
  const { currentTheme } = useContext(ColorModeContext);
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date(minDate);
  maxDate.setFullYear(minDate.getFullYear() + 1);

  const convertToString = (date: Date | null): string | null => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return null;
  };

  const handleDateChange = (date: Value) => {
    let convertedValue: DateRange;

    if (Array.isArray(date)) {
      const [start, end] = date;
      convertedValue = {
        startDate: convertToString(start),
        endDate: convertToString(end),
      };
      if (convertedValue.startDate && convertedValue.endDate)
        setValue([
          parseDate(convertedValue.startDate),
          parseDate(convertedValue.endDate),
        ]);
    } else {
      convertedValue = {
        startDate: convertToString(date),
        endDate: convertToString(date),
      };
      if (convertedValue.startDate && convertedValue.endDate)
        setValue(parseDate(convertedValue.startDate));
    }

    onDateChange(convertedValue);
    setError('');
    setIsCalendarOpen(false);
  };

  const handleDateChangeActiveStartDate = ({
    activeStartDate,
    view,
  }: OnArgs) => {
    // Get current date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Check if activeStartDate matches current month and year
    const activeMonth = activeStartDate?.getMonth();
    const activeYear = activeStartDate?.getFullYear();

    if (view === 'month') {
      if (activeMonth === currentMonth && activeYear === currentYear) {
        const convertedValue: DateRange = {
          startDate: convertToString(currentDate),
          endDate: convertToString(currentDate),
        };
        onDateChange(convertedValue);
      } else {
        const convertedValue: DateRange = {
          startDate: convertToString(activeStartDate),
          endDate: convertToString(activeStartDate),
        };
        onDateChange(convertedValue);
      }
    }
  };

  const tileDisabled = ({ date, view }: { date: Date; view: View }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (view === 'month' && date < today) return true;
    return false;
  };

  const handleClear = () => {
    setValue(null);
    onDateChange({ startDate: null, endDate: null });
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        !document
          .querySelector('.calendar-popover')
          ?.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
        setError('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    setValue(
      isDateSingle
        ? (selectedStartDate && parseDate(selectedStartDate)) || null
        : selectedStartDate && selectedEndDate
          ? [parseDate(selectedStartDate), parseDate(selectedEndDate)]
          : null
    );
  }, [isDateSingle]);

  const formatSingleDate = (date: Date | null): string => {
    return dayjs.utc(date).format(dateFormat);
  };

  const formatDateForInput = (date: Value, isSingle: boolean): string => {
    if (!date) return isSingle ? 'Select Date' : 'Select Dates';
    if (Array.isArray(date)) {
      const [startDate, endDate] = date;
      const formattedStartDate = formatSingleDate(startDate);
      const formattedEndDate = formatSingleDate(endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    return formatSingleDate(date);
  };

  return (
    <div className="date-picker-wrapper" ref={inputRef}>
      <input
        type="text"
        value={formatDateForInput(value, isDateSingle)}
        onFocus={() => setIsCalendarOpen(true)}
        readOnly
        className="mt-2 dark:bg-dark_bg_secondary z-10 dark:text-dart_web_accent py-1.5 pr-10 w-full focus:ring-0 border-0 focus:outline-none border-gray-0 rounded-md shadow-0 text-lg md:text-2xl text-title_text font-semibold"
      />
      {error && <div className="text-red-500 text-sm">{error}</div>}
      {isCalendarOpen && (
        <div
          className={`calendar-popover ${currentTheme === 'dark' ? 'calendar-dark-mode' : ''} dark:bg-dark_bg_secondary multi_date_selection dark:border-bg_Stroke dark:border dark:shadow-1`}
        >
          <p className="text-lg md:text-2xl text-title_text dark:text-dark_web_accent font-semibold">
            {isDateSingle
              ? ApplicationString.bookingDateRangeData.singleDate
              : ApplicationString.bookingDateRangeData.MultiDate}
          </p>
          <Calendar
            selectRange={!isDateSingle}
            showDoubleView={!isDateSingle}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            tileDisabled={tileDisabled}
            onChange={handleDateChange}
            onActiveStartDateChange={handleDateChangeActiveStartDate}
            showNavigation
            formatShortWeekday={(locale, date) =>
              date.toLocaleDateString('en-US', { weekday: 'short' })
            }
            className="dark:bg-dark_bg_secondary dark:text-dart_web_accent py-1.5 w-full focus:ring-0 border-0 focus:outline-none border-gray-0 rounded-md shadow-0 sm:text-sm text-lg md:text-xl text-title_text font-semibold"
          />
          <div className="flex w-full justify-end">
            <div className="flex gap-4">
              <button
                data-testid="commonDatePicker-clear-button"
                type="button"
                className="clear-button text-sm xl:text-lg font-semibold underline dark:text-dart_web_accent text-[#1A56DB]"
                onClick={handleClear}
              >
                {isDateSingle
                  ? ApplicationString.buttonType.singleClearDate
                  : ApplicationString.buttonType.multiClearDate}
              </button>
              <button
                data-testid="commonDatePicker-close-button"
                type="button"
                className={`close-button  ${cssUtils.button.primary}`}
                onClick={() => setIsCalendarOpen(false)}
              >
                {ApplicationString.buttonType.close}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonDatePicker;

/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import StepperActions from '../StepperActions';
import StepperCard from '../StepperCard';
import {
  Data,
  IBookingDetailsFormData,
  ICreateVehicleData,
  IStepData,
  IStepperErrorData,
  // IStepData,
  IStepperFormData,
  IStepperLabel,
  ISteps,
  TimeFrameFor12Hourly,
} from '../../../Interfaces/Stepper';
import {
  localStorageEnums,
  stepperLocalStepDefaultValue,
  stepperStepDefaultValue,
  stepsNumberEnum,
} from '../../../Utils/enums';
import ApplicationString from '../../../Constants/applicationString';
import {
  getDetailsFromLocalStorage,
  setLocalStorageInfo,
  showApiError,
} from '../../../Utils/utils';
import SuccessCard from '../SuccessScreen';
import LocationDetailsStepperComponent from '../LocationDetailsStep/StepperForm2';
import PoliciesStepperComponent from '../PoliciesStep/StepperForm6';
import {
  initialStep1ErrorData,
  initialStep1Values,
  initialStep2ErrorData,
  initialStep2Values,
  initialStep3ErrorData,
  initialStep3Values,
  initialStep4ErrorData,
  initialStep4Values,
  initialStep5ErrorData,
  initialStep5Values,
  initialStep6ErrorData,
  initialStep6Values,
} from '../../../Constants/commonConst';
import VehicleDetailsStep from '../VehicleDetailsStep';
import AdditionalDetailsStepperComponent from '../Step4';
import UploadImageStepperComponent from '../Step3';
import BookingDetailsStep from '../BookingDetailsStep';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import OwnerHeader from '../../common/OwnerHeader';
import {
  isStepperError,
  step1HandleValidation,
  step2HandleValidation,
  step3HandleValidation,
  step4HandleValidation,
  step5HandleValidation,
  step6HandleValidation,
} from '../Validation';
import { ApiRequest } from '../../../Api/api';
import URLS from '../../../Constants/urls';
import mapAPIDataToFormData from '../StepperActions/mapper';
import PreviewStep from '../PreviewStep';
import InternalRoute from '../../../Utils/internalRoutes';
import { LoggedInUserInfoDataType } from '../../../Interfaces/Login/LoginInterfaces';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import { useToast } from '../../../Services/ToastService';

interface ITimeFrame {
  from: number;
  to: number;
}
interface IStep5BookingData {
  bookingSlotType: string;
  bookingGap: number | null;
  shortNoticePeriod: number | null;
  isMultipleBookingSameDay?: boolean;
  bookingRateHourly: number | null;
  bookingRate12Hourly: number | null;
  timeFrameFor12Hourly?: ITimeFrame[] | null;
  bookingRate24Hourly: number | null;
  maxDaysFor24Hourly: string | number | null;
  monthLimit: number | null;
}

interface IStepperProps {
  createVehicle?: (
    data: ICreateVehicleData,
    setShowVerified: (arg: boolean) => void
  ) => Promise<void>;
  isEdit: boolean;
  updateVehicle?: (
    setActiveStep?: (arg0: (prevActiveStep: number) => number) => void
  ) => Promise<void>;
  isLoading: boolean;
}
const stepperLabelEnum = {
  Finished: ApplicationString.vehicleStepper.stepperLabel.finished,
  Pending: ApplicationString.vehicleStepper.stepperLabel.pending,
};

const settings = getDetailsFromLocalStorage<IUserSettingsData>(
  localStorageEnums.settings
);
const timeFrames = settings?.setting['12hourlyTimeFrame'] || [];
const mapTimeframe = (selectedTimeFrames: TimeFrameFor12Hourly[]): string[] => {
  if (selectedTimeFrames) {
    return timeFrames
      .filter(
        (ref) =>
          selectedTimeFrames &&
          selectedTimeFrames.length > 0 &&
          selectedTimeFrames.some(
            (item) => item.from === ref.value.from && item.to === ref.value.to
          )
      )
      .map((ref) => ref.label);
  }
  return [];
};
const mapStep5LocaDataToFormData = (data: Data): IBookingDetailsFormData => {
  const step5data: IBookingDetailsFormData = {
    isHourly: data.bookingRateHourly !== null,
    is12Hourly: data.bookingRate12Hourly !== null,
    is24Hourly: data.bookingRate24Hourly !== null,
    maxDaysFor24Hourly: data.maxDaysFor24Hourly,
    rate24Hourly: data.bookingRate24Hourly
      ? Number(data.bookingRate24Hourly)
      : null,
    isMultipleBookingOnSameDay: data.isMultipleBookingSameDay,
    gapHour: data.bookingGap ? Number(data.bookingGap) : null,
    shortNoticePeriod: Number(data.shortNoticePeriod),
    timeFrame: data.timeFrameFor12Hourly
      ? mapTimeframe(data.timeFrameFor12Hourly)
      : null,
    rate12Hourly: data.bookingRate12Hourly
      ? Number(data.bookingRate12Hourly)
      : null,
    rateHourly: data.bookingRateHourly ? Number(data.bookingRateHourly) : null,
    monthLimit: data.monthLimit,
  };
  return step5data;
};
const StepperComponent: React.FC<IStepperProps> = ({
  createVehicle,
  updateVehicle,
  isEdit,
  isLoading,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const localStoredStepsData =
    getDetailsFromLocalStorage<IStepData[]>(localStorageEnums.stepsData) || [];
  const step5LocalData =
    localStoredStepsData &&
    localStoredStepsData?.find((obj) => obj.step === stepsNumberEnum.step5)
      ?.data;
  const formDataInitialValues = {
    step1:
      localStoredStepsData?.find((obj) => obj.step === stepsNumberEnum.step1)
        ?.data || initialStep1Values,
    step2:
      localStoredStepsData?.find((obj) => obj.step === stepsNumberEnum.step2)
        ?.data || initialStep2Values,
    step3:
      localStoredStepsData?.find((obj) => obj.step === stepsNumberEnum.step3)
        ?.data || initialStep3Values,
    step4:
      localStoredStepsData?.find((obj) => obj.step === stepsNumberEnum.step4)
        ?.data || initialStep4Values,
    step5: step5LocalData
      ? mapStep5LocaDataToFormData(step5LocalData)
      : initialStep5Values,
    step6:
      localStoredStepsData?.find((obj) => obj.step === stepsNumberEnum.step6)
        ?.data || initialStep6Values,
  };
  // const [editData, setEditData] = useState<IStepperFormData>(
  //   formDataInitialValues
  // );
  const [formData, setFormData] = useState<IStepperFormData>({
    ...formDataInitialValues,
  } as IStepperFormData);
  const [errorsData, setErrorsData] = useState<IStepperErrorData>({
    step1: initialStep1ErrorData,
    step2: initialStep2ErrorData,
    step3: initialStep3ErrorData,
    step4: initialStep4ErrorData,
    step5: initialStep5ErrorData,
    step6: initialStep6ErrorData,
  });

  const steps: ISteps[] = [
    {
      label: ApplicationString.vehicleStepper.stepperSteps.step1.label,
      label2: ApplicationString.vehicleStepper.stepperSteps.step1.label2,
      key: 0,
      children: (
        <VehicleDetailsStep
          formData={formData}
          setFormData={setFormData}
          errorsData={errorsData}
          setErrorsData={setErrorsData}
        />
      ),
    },
    {
      label: ApplicationString.vehicleStepper.stepperSteps.step2.label,
      label2: ApplicationString.vehicleStepper.stepperSteps.step2.label2,
      key: 1,
      children: (
        <LocationDetailsStepperComponent
          formData={formData}
          setFormData={setFormData}
          errorsData={errorsData}
          setErrorsData={setErrorsData}
        />
      ),
    },
    {
      label: ApplicationString.vehicleStepper.stepperSteps.step3.label,
      label2: ApplicationString.vehicleStepper.stepperSteps.step3.label2,
      key: 2,
      children: (
        <UploadImageStepperComponent
          responseData={formData}
          setResponseData={setFormData}
          errorsData={errorsData}
          setErrorsData={setErrorsData}
        />
      ),
    },
    {
      label: ApplicationString.vehicleStepper.stepperSteps.step4.label,
      label2: ApplicationString.vehicleStepper.stepperSteps.step4.label2,
      key: 3,
      children: (
        <AdditionalDetailsStepperComponent
          formData={formData}
          setFormData={setFormData}
          errorsData={errorsData}
          setErrorsData={setErrorsData}
        />
      ),
    },
    {
      label: ApplicationString.vehicleStepper.stepperSteps.step5.label,
      label2: ApplicationString.vehicleStepper.stepperSteps.step5.label2,
      key: 4,
      children: (
        <BookingDetailsStep
          formData={formData}
          setFormData={setFormData}
          errorsData={errorsData}
          setErrorsData={setErrorsData}
        />
      ),
    },
    {
      label2: ApplicationString.vehicleStepper.stepperSteps.step6.label,
      label: ApplicationString.vehicleStepper.stepperSteps.step6.label2,
      key: 5,
      children: (
        <PoliciesStepperComponent
          formData={formData}
          setFormData={setFormData}
          errorsData={errorsData}
          setErrorsData={setErrorsData}
        />
      ),
    },
    {
      label: ApplicationString.vehicleStepper.stepperSteps.step7.label,
      label2: ApplicationString.vehicleStepper.stepperSteps.step7.label2,
      key: 6,
      children: (
        <PreviewStep slotError={errorsData.step5.slot.error} isEdit={isEdit} />
      ),
    },
  ];
  const { vehicleId } = useParams();

  const localStoredSteps =
    getDetailsFromLocalStorage<string>(localStorageEnums.step) ||
    stepperLocalStepDefaultValue;

  const localStoredSkippedSteps =
    getDetailsFromLocalStorage<number[]>(localStorageEnums.skippedSteps) || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  // Set the active step based on the local storage step value if it is within the valid range of steps, otherwise set it to 0.
  const initialActiveStepValue =
    localStoredSteps && parseInt(localStoredSteps, 10) < steps.length
      ? parseInt(localStoredSteps, 10)
      : stepperStepDefaultValue;
  const [activeStep, setActiveStep] = useState(initialActiveStepValue);

  const [skippedSteps, setSkippedSteps] = useState<number[]>(
    localStoredSkippedSteps
  );

  const initialStepLabels = isEdit
    ? steps.map(() => ({
        label: stepperLabelEnum.Finished,
        currentStep: activeStep,
      }))
    : steps.map((_, index) => ({
        label:
          index < activeStep && !skippedSteps.includes(index)
            ? stepperLabelEnum.Finished
            : stepperLabelEnum.Pending,
        currentStep: activeStep,
      }));
  const [stepLabels, setStepLabels] =
    useState<IStepperLabel[]>(initialStepLabels);
  const [showVerified, setShowVerified] = React.useState<boolean>(false);
  const [stepComplete, setStepComplete] = React.useState<boolean>(false);
  const stepValueFromSearchParam = Number(searchParams.get('step'));
  const searchStepValue =
    stepValueFromSearchParam <= steps.length
      ? stepValueFromSearchParam
      : stepsNumberEnum.step1;

  const storeStep5Data = (
    data: IBookingDetailsFormData | IStepData['data']
  ): IStep5BookingData => {
    const bookingData: IStep5BookingData = {
      bookingSlotType: '',
      bookingGap: null,
      shortNoticePeriod: null,
      isMultipleBookingSameDay: false,
      bookingRateHourly: null,
      bookingRate12Hourly: null,
      bookingRate24Hourly: null,
      maxDaysFor24Hourly: null,
      timeFrameFor12Hourly: null,
      monthLimit: null,
    };
    if (data.isHourly) {
      bookingData.bookingSlotType =
        ApplicationString.vehicleStepper.step5Details.slotType.hourly;
      bookingData.bookingRateHourly = Number(data.rateHourly);
    }
    if (data.is12Hourly) {
      bookingData.bookingSlotType =
        ApplicationString.vehicleStepper.step5Details.slotType.twelveHourly;
      bookingData.bookingRate12Hourly = Number(data.rate12Hourly);
      if (data.timeFrame && data.timeFrame.length > 0) {
        bookingData.timeFrameFor12Hourly = [];
        data.timeFrame.forEach((selectedTimeFrame: string) => {
          timeFrames?.forEach((time) => {
            if (time.label === selectedTimeFrame) {
              const newTimeFrame: ITimeFrame = {
                from: time.value.from,
                to: time.value.to,
              };
              if (bookingData.timeFrameFor12Hourly)
                bookingData.timeFrameFor12Hourly.push(newTimeFrame);
            }
          });
        });
      } else {
        bookingData.timeFrameFor12Hourly = null;
      }
    }
    if (data.is24Hourly) {
      bookingData.bookingSlotType =
        ApplicationString.vehicleStepper.step5Details.slotType.twentyFourHourly;
      bookingData.bookingRate24Hourly = Number(data.rate24Hourly);
      bookingData.maxDaysFor24Hourly = data.maxDaysFor24Hourly;
    }
    if (data.isMultipleBookingOnSameDay) {
      bookingData.isMultipleBookingSameDay = true;
    }
    bookingData.bookingGap = data.gapHour ? Number(data.gapHour) : null;
    if (data.shortNoticePeriod) {
      bookingData.shortNoticePeriod = Number(data.shortNoticePeriod);
    }
    bookingData.monthLimit = data.monthLimit;
    return bookingData;
  };
  useEffect(() => {
    if (activeStep < steps.length) {
      setLocalStorageInfo<number>(activeStep, localStorageEnums.step);
    }
  }, [activeStep]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const setActiveStepFromSearchParam = () => {
      if (searchStepValue && searchStepValue - 1 < steps.length) {
        setActiveStep(searchStepValue - 1);
      }
    };

    scrollToTop();
    setActiveStepFromSearchParam();
  }, [searchStepValue]);

  useEffect(() => {
    if (showVerified) {
      const finalStepLabels = steps.map(() => ({
        label: stepperLabelEnum.Finished,
        currentStep: activeStep,
      }));
      setStepLabels(finalStepLabels);
      setStepComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, showVerified]);

  useEffect(() => {
    if (skippedSteps) {
      setLocalStorageInfo<number[]>(
        skippedSteps,
        localStorageEnums.skippedSteps
      );
    }
  }, [skippedSteps]);

  useEffect(() => {
    if (activeStep === 0) {
      const params = new URLSearchParams(window.location.search);
      params.set('step', String(activeStep + 1));
      navigate({ search: params.toString() }, { replace: true });
    } else {
      setSearchParams({
        step: String(activeStep + 1),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (window.location.search === '?step=1') {
      setActiveStep(0);
    }
  }, [window.location.search]);

  const getFormDataForStep = () => {
    switch (activeStep) {
      case 0:
        return formData.step1;
      case 1:
        return formData.step2;
      case 2:
        return formData.step3;
      case 3:
        return formData.step4;
      case 4:
        return storeStep5Data(formData.step5);
      case 5:
        return formData.step6;
      default:
        return {};
    }
  };
  const setFormDataToLocalStorage = () => {
    const currentStepData = getFormDataForStep();
    const existingStepsData = getDetailsFromLocalStorage(
      localStorageEnums.stepsData
    );

    const updatedStepsData =
      existingStepsData instanceof Array
        ? existingStepsData.map((item) => {
            if (item.step === activeStep + 1) {
              return { ...item, data: currentStepData };
            }
            return item;
          })
        : [{ step: activeStep + 1, data: currentStepData }];

    if (!updatedStepsData.some((item) => item.step === activeStep + 1)) {
      updatedStepsData.push({ step: activeStep + 1, data: currentStepData });
    }

    setLocalStorageInfo(updatedStepsData, localStorageEnums.stepsData);
  };
  useEffect(() => {
    setLocalStorageInfo(
      isStepperError(errorsData),
      localStorageEnums.isStepperHasError
    );
  }, [errorsData]);

  const handleNext = () => {
    setFormDataToLocalStorage();
    if (activeStep === 0 && step1HandleValidation(formData, setErrorsData))
      return;
    if (activeStep === 1 && step2HandleValidation(formData, setErrorsData))
      return;
    if (activeStep === 2 && step3HandleValidation(formData, setErrorsData))
      return;
    if (activeStep === 3 && step4HandleValidation(formData, setErrorsData))
      return;
    if (activeStep === 4 && step5HandleValidation(formData, setErrorsData))
      return;
    if (activeStep === 5 && step6HandleValidation(formData, setErrorsData))
      return;
    if (isEdit && vehicleId) {
      const step1HasError = step1HandleValidation(formData, setErrorsData);
      const step2HasError = step2HandleValidation(formData, setErrorsData);
      const step3HasError = step3HandleValidation(formData, setErrorsData);
      const step4HasError = step4HandleValidation(formData, setErrorsData);
      const step5HasError = step5HandleValidation(formData, setErrorsData);
      const step6HasError = step6HandleValidation(formData, setErrorsData);
      if (step1HasError) {
        navigate(
          `${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId)}?step=1`
        );
        return;
      }
      if (step2HasError) {
        navigate(
          `${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId)}?step=2`
        );
        return;
      }
      if (step3HasError) {
        navigate(
          `${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId)}?step=3`
        );
        return;
      }
      if (step4HasError) {
        navigate(
          `${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId)}?step=4`
        );
        return;
      }
      if (step5HasError) {
        navigate(
          `${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId)}?step=5`
        );
        return;
      }
      if (step6HasError) {
        navigate(
          `${InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId)}?step=6`
        );
        return;
      }
    }
    setLocalStorageInfo(
      isStepperError(errorsData),
      localStorageEnums.isStepperHasError
    );
    const updatedSkippedSteps = skippedSteps?.filter(
      (step) => step !== activeStep
    );
    const updatedStepLabels = stepLabels.map((label, index) => {
      if (index === activeStep) {
        return {
          ...label,
          label: stepperLabelEnum.Finished,
        };
      }
      return label;
    });

    setStepLabels(updatedStepLabels);
    if (activeStep < steps.length) {
      if (!isEdit) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      setSkippedSteps(updatedSkippedSteps);
      if (activeStep < stepsNumberEnum.step7 && isEdit && updateVehicle) {
        updateVehicle(setActiveStep);
      }
    }
  };
  const getFormDataForEditStep = (
    stepIndex: number,
    mappedData: IStepperFormData
  ) => {
    switch (stepIndex) {
      case 0:
        return mappedData.step1;
      case 1:
        return mappedData.step2;
      case 2:
        return mappedData.step3;
      case 3:
        return mappedData.step4;
      case 4:
        return storeStep5Data(mappedData.step5);
      case 5:
        return mappedData.step6;
      default:
        return {};
    }
  };
  const setFormDataToLocalStorage2 = (mappedData: IStepperFormData) => {
    for (let stepIndex = 0; stepIndex < steps.length; stepIndex += 1) {
      const currentStepData = getFormDataForEditStep(stepIndex, mappedData);
      const existingStepsData = getDetailsFromLocalStorage(
        localStorageEnums.stepsData
      );
      const updatedStepsData =
        existingStepsData instanceof Array
          ? existingStepsData.map((item) => {
              return item;
            })
          : [{ step: stepIndex + 1, data: currentStepData }];
      if (!updatedStepsData.some((item) => item.step === stepIndex + 1)) {
        updatedStepsData.push({ step: stepIndex + 1, data: currentStepData });
      }
      setLocalStorageInfo(updatedStepsData, localStorageEnums.stepsData);
    }
  };

  const getVehicleData = async () => {
    try {
      const data = await ApiRequest.get(
        URLS.GET_VEHICLE_DETAILS_BY_ID.replace('#{id}', String(vehicleId))
      );
      if (data?.status === HttpStatusCode.Ok) {
        const accountId = getDetailsFromLocalStorage<LoggedInUserInfoDataType>(
          localStorageEnums.userInfo
        )?.accountId;
        const ownerAccountId = data?.data?.ownerDetails?.accountId;
        if (ownerAccountId && accountId !== ownerAccountId) {
          navigate(InternalRoute.Home);
        } else {
          // since the edit vehicle is accessed by the user which is forbidden so navigating to the Home page.
          const mappedData = mapAPIDataToFormData(data.data, timeFrames);
          setFormData(mappedData);
          setFormDataToLocalStorage2(mappedData);
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getVehicleData();
    }
  }, [vehicleId]);

  const handleBack = () => {
    const newStepLabels = stepLabels.map((item, index) => {
      if (index === activeStep) {
        return {
          ...(item as IStepperLabel),
          label: stepperLabelEnum.Pending,
        };
      }
      return item;
    });
    setStepLabels(newStepLabels);
    if (activeStep < steps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkipStep = () => {
    if (activeStep === 0) step1HandleValidation(formData, setErrorsData);
    if (activeStep === 1) step2HandleValidation(formData, setErrorsData);
    if (activeStep === 2) step3HandleValidation(formData, setErrorsData);
    if (activeStep === 3) step4HandleValidation(formData, setErrorsData);
    if (activeStep === 4) step5HandleValidation(formData, setErrorsData);
    if (activeStep === 5) step6HandleValidation(formData, setErrorsData);
    setLocalStorageInfo(
      isStepperError(errorsData),
      localStorageEnums.isStepperHasError
    );

    const newActiveStep = activeStep + 1;
    const newSkippedSteps = [...skippedSteps, activeStep];
    const removeDuplicates = newSkippedSteps.filter(
      (item, index) => newSkippedSteps.indexOf(item) === index
    );
    if (activeStep < steps.length) {
      setActiveStep(newActiveStep);
      setSkippedSteps(removeDuplicates);
      setFormDataToLocalStorage();
      if (isEdit) {
        const updatedStepLabels = stepLabels.map((label, index) => {
          if (index === activeStep) {
            return {
              ...label,
              label: stepperLabelEnum.Finished,
            };
          }
          return label;
        });
        setStepLabels(updatedStepLabels);
      }
    }
  };

  const renderStepContent = (stepIndex: number) => {
    return steps?.[stepIndex]?.children || null;
  };

  const renderActiveStepContent = () => {
    return renderStepContent(activeStep);
  };

  return (
    <div className="flex flex-col w-full gap-6 dark:text-bodydark min-h-screen">
      <OwnerHeader />
      <main className="mt-20 lg:mt-24 z-20 relative flex-1 min-h-0 bg-page_bg flex flex-col w-full gap-6 dark:bg-Light_bg_surface">
        <div className="mx-0 lg:mx-auto max-w-7xl pt-8 lg:px-8 xl:px-0 lg:pt-12 w-full">
          <StepperCard
            activeStep={activeStep}
            steps={steps}
            stepLabels={stepLabels}
            stepComplete={stepComplete}
            isEdit={isEdit}
            showVerified={showVerified}
          />
        </div>
        <div className="w-full mx-auto max-w-7xl px-6 3xsl:min-h-screen lg:px-8 2xl:px-0 flex flex-col items-center">
          {showVerified ? (
            <div className="h-full w-full min-h-[64vh] 3xsl:min-h-screen flex justify-center items-center">
              <SuccessCard
                title={
                  isEdit
                    ? ApplicationString.vehicleStepper.stepperSteps.editSuccess
                        .title
                    : ApplicationString.vehicleStepper.stepperSteps.success
                        .title
                }
                subTitle={
                  isEdit
                    ? ApplicationString.vehicleStepper.stepperSteps.editSuccess
                        .subTitle
                    : ApplicationString.vehicleStepper.stepperSteps.success
                        .subTitle
                }
              />
            </div>
          ) : (
            <div className="flex flex-col w-full gap-3">
              <div
                className={`md:text-lg font-bold text-black dark:text-white ${activeStep < stepsNumberEnum.step2 && window.innerWidth < 786 ? 'flex' : 'hidden'}`}
              >
                {steps[activeStep]?.label?.toUpperCase()}
              </div>
              <form className="w-full">{renderActiveStepContent()}</form>
            </div>
          )}
        </div>
      </main>
      {!showVerified && (
        <StepperActions
          handleNext={handleNext}
          handleBack={handleBack}
          handleSkip={handleSkipStep}
          activeStep={activeStep}
          steps={steps}
          isEdit={isEdit}
          setShowVerified={setShowVerified}
          createVehicle={createVehicle}
          errorsData={errorsData}
          updateVehicle={updateVehicle}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default StepperComponent;

// src/components/StackCards.tsx
import React, { useEffect, useRef } from 'react';
import ApplicationString from '../../Constants/applicationString';
import cssUtils from '../../Utils/cssUtils';
import Card from './AboutUsCard';

const StackCards: React.FC = () => {
  const stackCardsRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const element = stackCardsRef.current;

    if (!element) return;

    const items = element.getElementsByClassName(
      'stack-cards__item'
    ) as HTMLCollectionOf<HTMLElement>;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        window.addEventListener('scroll', handleScroll);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        window.removeEventListener('scroll', handleScroll);
      }
    });

    observer.observe(element);

    const handleScroll = () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      window.requestAnimationFrame(() => animateStackCards(element, items));
    };

    const animateStackCards = (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      element: HTMLElement,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      items: HTMLCollectionOf<HTMLElement>
    ) => {
      const { top } = element.getBoundingClientRect();

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        const cardHeight = items[i].offsetHeight;
        const marginY = 80; // Adjust margin as needed
        const scrolling = cardHeight - top - i * (cardHeight + marginY);
        if (scrolling > 0) {
          // eslint-disable-next-line no-param-reassign
          items[i].style.transform =
            `translateY(${marginY * i}px) scale(${(cardHeight - scrolling * 0.05) / cardHeight})`;
        } else {
          // eslint-disable-next-line no-param-reassign
          items[i].style.transform = `translateY(${marginY * i}px) scale(1)`;
        }
      }
    };

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ul
      ref={stackCardsRef}
      className={`min-h-max ${cssUtils.layout.sectionLayout} `}
    >
      {ApplicationString.aboutUs.cardsDetails.map((item, i, arr) => (
        <Card
          key={item.title}
          imgSrc={item.imgSrc}
          mobileImgSrc={item.mobileImgSrc}
          title={item.title}
          description={item.description}
          description2={item.description2}
          lastElement={arr.length - 1 === i}
          cardExtraClasses={`z-[${i + 1}]`}
        />
      ))}
    </ul>
  );
};

export default StackCards;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import chevronSvg from '../../../Assets/Svgs/chevron.svg';
import instantSvg from '../../../Assets/Svgs/instant.svg';
import { IMyBookings } from '../../../Interfaces/common/MyBookings/MyBookings';
import { formatSingleOrDateRange } from '../../../Utils/utils';
import { myBookingsStatusEnums } from '../../../Utils/enums';
import LoadingButton from '../../common/LoadingButton/LoadingButton';
import { timeFormat } from '../../../Constants/commonConst';
import ApplicationString from '../../../Constants/applicationString';
import InternalRoute from '../../../Utils/internalRoutes';

interface IInstantBookingCardProps {
  booking: IMyBookings;
  instantBookingActionsHandler: (
    bookingId: number,
    payload: string
  ) => Promise<void>;
}

dayjs.extend(utc);

const InstantBookingCard: React.FC<IInstantBookingCardProps> = (
  props
): JSX.Element => {
  const { booking, instantBookingActionsHandler } = props;
  const navigate = useNavigate();
  const [isBookingStatusChanging, setIsBookingStatusChanging] = useState<{
    forAccept: boolean;
    forReject: boolean;
  }>({ forAccept: false, forReject: false });

  const acceptOrRejectInstantBookingHandler = async (
    type: string
  ): Promise<void> => {
    try {
      setIsBookingStatusChanging((pre) =>
        type === myBookingsStatusEnums.active
          ? { ...pre, forAccept: true }
          : { ...pre, forReject: true }
      );

      await instantBookingActionsHandler(booking.id, type);
    } catch (error) {
      // catch error
    } finally {
      setIsBookingStatusChanging((pre) =>
        type === myBookingsStatusEnums.active
          ? { ...pre, forAccept: false }
          : { ...pre, forReject: false }
      );
    }
  };

  const navigateToBookingDetailsHandler = (target: HTMLElement): void => {
    const actionButtons = Array.from(
      document.getElementsByClassName('action-button')
    );

    // skip navigation when any action button clicked
    const skipNavigate = actionButtons.some((element) => target === element);

    if (!skipNavigate) {
      const bookingDetailsURL = InternalRoute.OwnerBookingDetails.replace(
        ':id',
        String(booking.id)
      );
      navigate(bookingDetailsURL);
    }
  };

  const vehicleImagePath = (): string => {
    // find the cover image and set as a vehicle image
    return booking.vehicleDetail.images.find((img) => img.isCover)?.path || '';
  };

  return (
    <div
      className="w-full bg-white p-4 rounded-md midXL:last:rounded-br-3xl midXL:last:rounded-bl-3xl midXL:rounded-[0px] dark:bg-[#292929] relative midXL:shadow-none shadow-[0px_4px_16px_0px_#1212120F] midXL:hover:bg-slate-200 midXL:hover:dark:bg-slate-700"
      tabIndex={0}
      role="button"
      onClick={(event) =>
        navigateToBookingDetailsHandler(event.target as HTMLElement)
      }
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          navigateToBookingDetailsHandler(event.target as HTMLElement);
        }
      }}
    >
      <span className="hidden items-center font-bold gap-1 text-sm midXL:hidden justify-evenly absolute right-4 top-4 z-10 2xsm:flex dark:text-white">
        <img
          src={instantSvg}
          alt={ApplicationString.OwnerDashboard.instantBooking.instantTxt}
        />
        {ApplicationString.OwnerDashboard.instantBooking.instantTxt}
      </span>
      <div className="flex max-[1200px]:flex-col flex-row gap-2">
        <div className="flex gap-2 w-[75%] max-[1200px]:w-full">
          <div className="flex items-center gap-5 w-[39%] max-[1200px]:w-full cursor-pointer">
            <img
              src={vehicleImagePath()}
              alt={booking.vehicleDetail.title}
              className="aspect-square w-[75px] rounded-xl"
            />
            <div className="w-full flex gap-4 midXL:flex-row flex-col flex-wrap">
              <h2 className="font-bold break-all">
                {booking.vehicleDetail.title}
              </h2>
              <span className="items-center font-bold gap-1 text-sm flex midXL:flex 2xsm:hidden dark:text-white">
                <img
                  src={instantSvg}
                  alt={
                    ApplicationString.OwnerDashboard.instantBooking.instantTxt
                  }
                />
                {ApplicationString.OwnerDashboard.instantBooking.instantTxt}
              </span>
              <div className="hidden flex-col w-[30%] justify-center max-[1200px]:flex max-[1200px]:w-full sm:text-base text-xs gap-1 sm:gap-0">
                <span className="font-medium break-all">
                  {formatSingleOrDateRange([
                    booking?.startDate,
                    booking?.endDate,
                  ])}{' '}
                </span>
                <span className="text-secondary_text dark:text-[#B8B8B8] break-all">
                  {`${dayjs.utc(booking?.startDate).format(timeFormat)} - ${dayjs.utc(booking?.endDate).format(timeFormat)}`}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[33%] justify-center max-[1200px]:hidden">
            <span className="text-base font-medium break-all">
              {formatSingleOrDateRange([booking?.startDate, booking?.endDate])}{' '}
            </span>
            <span className="text-secondary_text dark:text-[#B8B8B8] break-all">
              {`${dayjs.utc(booking?.startDate).format(timeFormat)} - ${dayjs.utc(booking?.endDate).format(timeFormat)}`}
            </span>
          </div>
          <div className="flex items-center gap-4 w-[13%] max-[1200px]:hidden">
            <span className="font-medium block">${booking.total || 0}</span>
          </div>
          <div className="flex items-center gap-4 w-[13%] max-[1200px]:hidden">
            <span className="font-medium block break-all">
              {booking.renterDetails.name}
            </span>
          </div>
        </div>
        <hr className="hidden max-[1200px]:block dark:opacity-20" />
        <div className="flex items-center gap-1 w-[22.5%] max-[1200px]:w-[100%] max-[1200px]:justify-between">
          <div className="hidden max-[1200px]:block text-secondary_text dark:text-[#B8B8B8] sm:text-sm text-xs break-all">
            {ApplicationString.OwnerDashboard.instantBooking.bookingPrice}:
            <span className="text-black dark:text-white font-bold">
              {' '}
              &nbsp; ${booking.total || 0}
            </span>
          </div>
          <div
            data-testid="action-button-container"
            className="flex items-center justify-end gap-4 xsm:w-auto"
          >
            <div className="flex gap-2 sm:gap-4">
              {isBookingStatusChanging.forReject ? (
                <LoadingButton
                  buttonText={
                    ApplicationString.OwnerDashboard.instantBooking
                      .rejectButtonTxt
                  }
                  extraTxtClasses="sm:text-sm text-xs text-slate-500"
                  extraBtnClasses="flex justify-center border border-solid dark:bg-white shadow-none border-[#D1D5DB] hover:bg-slate-300 hover:shadow-none rounded-4xl normal-case sm:text-sm text-xs font-bold sm:py-3 py-2 bg-white text-black"
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="dashboard-instant-booking-reject-button"
                  onClick={() =>
                    acceptOrRejectInstantBookingHandler(
                      myBookingsStatusEnums.rejected
                    )
                  }
                  className="action-button flex justify-center border border-solid shadow-none border-[#D1D5DB] hover:bg-slate-300 hover:shadow-none rounded-4xl normal-case sm:text-sm text-xs font-bold sm:py-3 py-2 px-6 bg-white text-black hover:border-black"
                >
                  {
                    ApplicationString.OwnerDashboard.instantBooking
                      .rejectButtonTxt
                  }
                </Button>
              )}
              {isBookingStatusChanging.forAccept ? (
                <LoadingButton
                  buttonText={
                    ApplicationString.OwnerDashboard.instantBooking
                      .acceptingButtonTxt
                  }
                  extraTxtClasses="sm:text-sm text-xs"
                  extraBtnClasses="flex justify-center rounded-4xl hover:bg-primary/80 normal-case sm:text-sm text-xs font-bold sm:py-3 text-white bg-primary"
                />
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    acceptOrRejectInstantBookingHandler(
                      myBookingsStatusEnums.active
                    )
                  }
                  data-testid="dashboard-instant-booking-accept-button"
                  className="action-button flex justify-center rounded-4xl hover:bg-primary/80 normal-case sm:text-sm text-xs font-bold sm:py-3 py-2 px-6  text-white bg-primary"
                >
                  {
                    ApplicationString.OwnerDashboard.instantBooking
                      .acceptButtonTxt
                  }
                </Button>
              )}
            </div>
            <div
              tabIndex={0}
              role="button"
              data-testid="dashboard-instant-booking-more-details-btn"
              className="cursor-pointer max-[1200px]:absolute right-4 top-[39%]"
              onClick={(event) =>
                navigateToBookingDetailsHandler(event.target as HTMLElement)
              }
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter') {
                  navigateToBookingDetailsHandler(event.target as HTMLElement);
                }
              }}
            >
              <img
                src={chevronSvg}
                aria-label="button"
                alt={
                  ApplicationString.OwnerDashboard.instantBooking
                    .moreDetailsChevronAltTxt
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantBookingCard;

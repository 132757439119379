import React from 'react';
import FaqsComponent from '../../Components/OwnerComponent/FaqsComponent';
import { getDetailsFromLocalStorage } from '../../Utils/utils';
import { IUserSettingsData } from '../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums } from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';

const FaqsContainer: React.FC = () => {
  const userSettings = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  );
  const settingFaqs = userSettings?.setting.faq;
  const defaultFaqs = ApplicationString.accordion.accordionData;

  const filterSettingFaqs = settingFaqs?.map((item, index) => ({
    id: index + 1,
    question: item.question,
    answer: item.answer,
  }));

  return (
    <div>
      <FaqsComponent accordionData={filterSettingFaqs || defaultFaqs} />
    </div>
  );
};

export default FaqsContainer;

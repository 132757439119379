import React, { useState, ReactNode, useMemo } from 'react';
import { UserCheck, X } from 'react-feather';
import ToastContext from '../../../Services/ToastService';

interface Toast {
  id: number;
  component: ReactNode;
}

interface ToastProviderProps {
  children: ReactNode;
}

type ToastType = 'success' | 'info' | 'error' | 'warning';

const toastIcons = {
  success: <UserCheck size={40} />,
  info: <UserCheck size={40} />,
  error: <UserCheck size={40} />,
  warning: <UserCheck size={40} />,
};

const toastColors = {
  success: 'bg-green-300 text-green-800',
  info: 'bg-blue-300 text-blue-800',
  error: 'bg-red-300 text-red-800',
  warning: 'bg-yellow-300 text-yellow-800',
};

export default function ToastProvider({
  children,
}: ToastProviderProps): JSX.Element {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const renderToast = (
    type: ToastType,
    message: string,
    description?: string
  ) => (
    <div className={`flex gap-2 ${toastColors[type]} p-4 rounded-lg shadow-lg`}>
      {toastIcons[type]}
      <div>
        <h3 className="font-bold mt-2">{message}</h3>
        {description && <p className="text-sm">{description}</p>}
      </div>
    </div>
  );

  const close = (closeId: number) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== closeId)
    );
  };
  const open = (
    type: ToastType,
    message: string,
    description?: string,
    timeout = 5000
  ) => {
    const id = Date.now();

    setToasts((prevToasts) => [
      ...prevToasts,
      { id, component: renderToast(type, message, description) },
    ]);

    setTimeout(() => close(id), timeout);
  };

  const success = (message: string, description?: string, timeout?: number) => {
    open('success', message, description, timeout);
  };

  const info = (message: string, description?: string, timeout?: number) => {
    open('info', message, description, timeout);
  };

  const error = (message: string, description?: string, timeout?: number) => {
    open('error', message, description, timeout);
  };

  const warning = (message: string, description?: string, timeout?: number) => {
    open('warning', message, description, timeout);
  };

  const providerValue = useMemo(
    () => ({
      close,
      success,
      error,
      warning,
      info,
    }),
    [close, success, error, warning, info]
  );

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
      <div className="space-y-2 fixed top-4 right-4 z-40">
        {toasts.map(({ id, component }) => (
          <div key={id} className="relative">
            <button
              aria-label="toast"
              type="button"
              onClick={() => close(id)}
              className="absolute top-1 right-2 p-1 rounded-lg bg-gray-200/20 text-gray-800/60 z-40"
            >
              <X size={16} />
            </button>
            {component}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SelectChangeEvent } from '@mui/material/Select';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import ColorModeContext from '../../Utils/ColorModeContext';
import ApplicationString from '../../Constants/applicationString';
import ProfileSettingsComponent from './Settings';
import { IProfile } from '../../Interfaces/common/Profile';
import ProfileFormComponent from './ProfileForm';
import ChangePasswordContainer from '../../Containers/MyProfile/ChangePassword';
import { localStorageEnums, myAccountSectionEnums } from '../../Utils/enums';
import {
  getDetailsFromLocalStorageForString,
  setLocalStorageInfo,
} from '../../Utils/utils';
import './style.css';
import PaymentDetailsComponent from './PaymentDetails';
import InternalRoute from '../../Utils/internalRoutes';
import { IDisableFields, IerrorsData } from '../../Interfaces/interfaces';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IProfileFormData {
  name: string;
  mobile: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  countryCode: string;
}
interface IErrorsData {
  countryCode: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  address: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  name: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  city: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  state: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  country: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  zip: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  email: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  mobile: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}
interface MyProfileComponentProps {
  profileData: IProfile;
  countryCodeOptions: string[];
  formData: IProfileFormData;
  isLoading: boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isDataLoading: boolean;
  errorsData: IErrorsData;
  fileUploadError: string;
  handleReset: () => void;
  resetError: () => void;
  setIsUploadingProfilePicture: Dispatch<SetStateAction<boolean>>;
  handleNameChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handlePhoneNumberChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleEmailChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAddressChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAddress2Change: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCityChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleStateChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCountryChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleZipCodeChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCountryCodeChange: (e: SelectChangeEvent<string>) => void;
  onAccountDetailsSubmit: (e: React.FormEvent) => void;
  uploadProfilePicture: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  imagePath: string;
  isUploadingProfilePicture: boolean;
  handleVerification: () => void;
  handleClose: () => void;
  verifyTheOtp: () => Promise<void>;
  setOtp: Dispatch<SetStateAction<string>>;
  otp: string;
  isModalOpen: boolean;
  isVerify: boolean;
  isVerifyingOtp: boolean;
  verificationerror: IerrorsData;
  setIsVerify: Dispatch<SetStateAction<boolean>>;
  isFieldDisabled: IDisableFields;
  setIsFieldDisabled: Dispatch<SetStateAction<IDisableFields>>;
}
function TabPanel(props: TabPanelProps) {
  const { children = null, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      data-testid={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const MyProfileComponent: React.FC<MyProfileComponentProps> = ({
  profileData,
  countryCodeOptions,
  formData,
  isLoading,
  isEditing,
  setIsEditing,
  errorsData,
  imagePath,
  handleReset,
  resetError,
  isUploadingProfilePicture,
  setIsUploadingProfilePicture,
  handleNameChange,
  handlePhoneNumberChange,
  handleEmailChange,
  handleAddressChange,
  handleCityChange,
  handleStateChange,
  handleCountryChange,
  handleZipCodeChange,
  handleCountryCodeChange,
  isDataLoading,
  fileUploadError,
  handleAddress2Change,
  uploadProfilePicture,
  onAccountDetailsSubmit,
  handleVerification,
  handleClose,
  verifyTheOtp,
  setOtp,
  otp,
  isModalOpen,
  isVerify,
  isVerifyingOtp,
  verificationerror,
  setIsVerify,
  isFieldDisabled,
  setIsFieldDisabled,
}) => {
  const { colorMode } = React.useContext(ColorModeContext);
  const [checked, setChecked] = useState(() => {
    const savedTheme = getDetailsFromLocalStorageForString('theme');
    return savedTheme === 'dark';
  });
  const [currentSectionClicked, setCurrentSectionClicked] = useState<
    string | null
  >(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = () => {
    setChecked(!checked);
    const currentTheme = !checked ? 'dark' : 'light';
    setLocalStorageInfo(currentTheme, localStorageEnums.theme);
    colorMode.toggleColorMode(currentTheme);
  };
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate(InternalRoute.myAccount);
    } else if (newValue === 1) {
      navigate(InternalRoute.settings);
    } else if (newValue === 2) {
      navigate(InternalRoute.payment);
    } else if (newValue === 3) {
      navigate(InternalRoute.changePassword);
    }
  };
  const handleSectionClick = (section: string | null) => {
    setCurrentSectionClicked(section);
    if (section === myAccountSectionEnums.accountInfo) {
      navigate(InternalRoute.myAccount);
    } else if (section === myAccountSectionEnums.settings) {
      navigate(InternalRoute.settings);
    } else if (section === myAccountSectionEnums.payment) {
      navigate(InternalRoute.payment);
    } else if (section === myAccountSectionEnums.changePassword) {
      navigate(InternalRoute.changePassword);
    }
  };

  const goBack = () => {
    setCurrentSectionClicked(null);
  };

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName.includes(myAccountSectionEnums.accountInfo)) {
      setValue(0);
      setCurrentSectionClicked(myAccountSectionEnums.accountInfo);
    }
    if (pathName.includes(myAccountSectionEnums.settings)) {
      setValue(1);
      setCurrentSectionClicked(myAccountSectionEnums.settings);
    }
    if (pathName.includes(myAccountSectionEnums.payment)) {
      setValue(2);
      setCurrentSectionClicked(myAccountSectionEnums.payment);
    }
    if (pathName.includes(myAccountSectionEnums.changePassword)) {
      setValue(3);
      setCurrentSectionClicked(myAccountSectionEnums.changePassword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (
    <div className="w-full bg-light_bg dark:bg-dark_bg_surface">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 2xl:px-0 bg-light_bg dark:bg-dark_bg_surface">
        <Box className="flex justify-between flex-col md:flex-row min-h-[100vh]">
          <div className="w-full md:hidden mt-10">
            {currentSectionClicked === null ? (
              <Stack
                spacing={4}
                className="w-full px-4 py-2 justify-center"
                divider={<Divider orientation="horizontal" flexItem />}
              >
                <Item
                  className="px-8 py-8 gap-2 flex flex-row items-center justify-between"
                  onClick={() => handleSectionClick('accountInfo')}
                  data-testid="my-account-switch-tabs-button"
                >
                  <div className="flex flex-row items-center gap-2">
                    <PersonOutlinedIcon />
                    <Typography className="">
                      {
                        ApplicationString.ProfileScreenLables.tabLabels
                          .accountInfo
                      }
                    </Typography>
                  </div>
                  <ChevronRightIcon />
                </Item>
                <Item
                  className="px-8 py-8 gap-2 flex flex-row items-center justify-between"
                  onClick={() => handleSectionClick('settings')}
                  data-testid="my-account-switch-tabs-button"
                >
                  <div className="flex flex-row items-center gap-2">
                    <SettingsOutlinedIcon />
                    <Typography>
                      {ApplicationString.ProfileScreenLables.tabLabels.settings}
                    </Typography>
                  </div>

                  <ChevronRightIcon />
                </Item>
                <Item
                  className="px-8 py-8 gap-2 flex flex-row items-center justify-between"
                  onClick={() => handleSectionClick('payment')}
                  data-testid="my-account-switch-tabs-button"
                >
                  <div className="flex flex-row items-center gap-2">
                    <PaymentOutlinedIcon />
                    <Typography>
                      {ApplicationString.ProfileScreenLables.tabLabels.Payment}
                    </Typography>
                  </div>
                  <ChevronRightIcon />
                </Item>
                <Item
                  className="px-8 py-8 gap-2 flex flex-row items-center justify-between"
                  onClick={() => handleSectionClick('changePassword')}
                  data-testid="my-account-switch-tabs-button"
                >
                  <div className="flex flex-row items-center gap-2">
                    <HttpsOutlinedIcon />
                    <Typography>
                      {
                        ApplicationString.ProfileScreenLables.tabLabels
                          .changePassword
                      }
                    </Typography>
                  </div>
                  <ChevronRightIcon />
                </Item>
              </Stack>
            ) : null}
            {currentSectionClicked === 'accountInfo' ? (
              <ProfileFormComponent
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                handleReset={handleReset}
                resetError={resetError}
                profileData={profileData}
                isDataLoading={isDataLoading}
                goBack={goBack}
                countryCodeOptions={countryCodeOptions}
                formData={formData}
                fileUploadError={fileUploadError}
                isLoading={isLoading}
                imagePath={imagePath}
                isUploadingProfilePicture={isUploadingProfilePicture}
                errorsData={errorsData}
                uploadProfilePicture={uploadProfilePicture}
                handleNameChange={handleNameChange}
                handlePhoneNumberChange={handlePhoneNumberChange}
                handleEmailChange={handleEmailChange}
                handleAddressChange={handleAddressChange}
                handleAddress2Change={handleAddress2Change}
                handleCityChange={handleCityChange}
                handleZipCodeChange={handleZipCodeChange}
                onAccountDetailsSubmit={onAccountDetailsSubmit}
                handleCountryChange={handleCountryChange}
                handleStateChange={handleStateChange}
                handleCountryCodeChange={handleCountryCodeChange}
                setIsUploadingProfilePicture={setIsUploadingProfilePicture}
                handleVerification={handleVerification}
                handleClose={handleClose}
                verifyTheOtp={verifyTheOtp}
                isVerifyingOtp={isVerifyingOtp}
                setOtp={setOtp}
                otp={otp}
                isVerify={isVerify}
                verificationerror={verificationerror}
                isModalOpen={isModalOpen}
                setIsVerify={setIsVerify}
                isFieldDisabled={isFieldDisabled}
                setIsFieldDisabled={setIsFieldDisabled}
              />
            ) : null}
            {currentSectionClicked === 'settings' ? (
              <ProfileSettingsComponent
                checked={checked}
                handleChange={handleChange}
                goBack={goBack}
              />
            ) : null}
            {currentSectionClicked === 'changePassword' ? (
              <ChangePasswordContainer goBack={goBack} />
            ) : null}
            {currentSectionClicked === 'payment' ? (
              <PaymentDetailsComponent goBack={goBack} />
            ) : null}
          </div>
          <div className="hidden md:block md:border-r-2 py-8 lg:py-12 border-slate-200 shadow-right-only dark:border-r-1 dark:border-black">
            <Tabs
              orientation="vertical"
              className="my-account-tab-class"
              value={value}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                width: '100%',
                overflow: 'visible',
              }}
            >
              <Tab
                className={`flex flex-row items-center pr-20 gap-4 justify-start capitalize text-nowrap text-lg 2xl:text-xl dark:text-dark_secondary_text ${
                  value === 0
                    ? 'bg-blue-white border-blue-500 border-r-0 text-black dark:text-white dark:bg-dark_formFieldBg'
                    : ''
                }`}
                icon={<PersonOutlinedIcon />}
                label={
                  ApplicationString.ProfileScreenLables.tabLabels.accountInfo
                }
                {...a11yProps(0)}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  border: value === 0 ? '1px solid blue' : '',
                  borderRadius: value === 0 ? '20px 0px 0px 20px' : '',
                }}
              />
              <Tab
                className={`flex flex-row items-center gap-4 mt-4 pr-20 justify-start capitalize text-nowrap text-lg 2xl:text-xl dark:text-dark_secondary_text ${
                  value === 1
                    ? 'bg-blue-white border-blue-500 border-r-0 text-black dark:text-white dark:bg-dark_formFieldBg'
                    : ''
                }`}
                icon={<SettingsOutlinedIcon />}
                label={ApplicationString.ProfileScreenLables.tabLabels.settings}
                {...a11yProps(1)}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  border: value === 1 ? '1px solid blue' : '',
                  borderRadius: value === 1 ? '20px 0px 0px 20px' : '',
                }}
              />
              <Tab
                className={`flex flex-row items-center gap-4 mt-4 justify-start pr-20 text-nowrap capitalize text-lg 2xl:text-xl dark:text-dark_secondary_text ${
                  value === 2
                    ? 'bg-blue-white border-blue-500 border-r-0 text-black dark:text-white dark:bg-dark_formFieldBg'
                    : ''
                }`}
                icon={<PaymentOutlinedIcon />}
                label={ApplicationString.ProfileScreenLables.tabLabels.Payment}
                {...a11yProps(2)}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  border: value === 2 ? '1px solid blue' : '',
                  borderRadius: value === 2 ? '20px 0px 0px 20px' : '',
                }}
              />
              <Tab
                className={`flex flex-row items-center gap-4 mt-4 justify-start pr-20 capitalize text-nowrap text-lg 2xl:text-xl dark:text-dark_secondary_text ${
                  value === 3
                    ? 'bg-blue-white border-blue-500 border-r-0 text-black dark:text-white dark:bg-dark_formFieldBg'
                    : ''
                }`}
                icon={<HttpsOutlinedIcon />}
                label={
                  ApplicationString.ProfileScreenLables.tabLabels.changePassword
                }
                {...a11yProps(3)}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  border: value === 3 ? '1px solid blue' : '',
                  borderRadius: value === 3 ? '20px 0px 0px 20px' : '',
                }}
              />
            </Tabs>
          </div>
          <div className=" hidden md:block md:w-4/6 px-8 lg:px-0 py-8 lg:py-12">
            <TabPanel value={value} index={0}>
              <ProfileFormComponent
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                handleReset={handleReset}
                resetError={resetError}
                profileData={profileData}
                isDataLoading={isDataLoading}
                goBack={() => {}}
                countryCodeOptions={countryCodeOptions}
                formData={formData}
                fileUploadError={fileUploadError}
                isLoading={isLoading}
                imagePath={imagePath}
                isUploadingProfilePicture={isUploadingProfilePicture}
                errorsData={errorsData}
                uploadProfilePicture={uploadProfilePicture}
                handleNameChange={handleNameChange}
                handlePhoneNumberChange={handlePhoneNumberChange}
                handleEmailChange={handleEmailChange}
                handleAddressChange={handleAddressChange}
                handleAddress2Change={handleAddress2Change}
                handleCityChange={handleCityChange}
                handleZipCodeChange={handleZipCodeChange}
                onAccountDetailsSubmit={onAccountDetailsSubmit}
                handleCountryChange={handleCountryChange}
                handleStateChange={handleStateChange}
                handleCountryCodeChange={handleCountryCodeChange}
                setIsUploadingProfilePicture={setIsUploadingProfilePicture}
                handleVerification={handleVerification}
                handleClose={handleClose}
                verifyTheOtp={verifyTheOtp}
                isVerifyingOtp={isVerifyingOtp}
                setOtp={setOtp}
                otp={otp}
                isVerify={isVerify}
                verificationerror={verificationerror}
                isModalOpen={isModalOpen}
                setIsVerify={setIsVerify}
                isFieldDisabled={isFieldDisabled}
                setIsFieldDisabled={setIsFieldDisabled}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProfileSettingsComponent
                checked={checked}
                handleChange={handleChange}
                goBack={() => {}}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              {}
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ChangePasswordContainer goBack={() => {}} />
            </TabPanel>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default MyProfileComponent;

import React from 'react';
import ApplicationString from '../../../Constants/applicationString';

const StatusBar: React.FC = () => {
  return (
    <div className="border-0 dark:bg-dark_bg_secondary bg-white rounded-xl p-4 flex xsm:justify-center xsm:items-center">
      <div className="xsm:items-center  items-start xsm:flex-row flex flex-col gap-4 text-xl sm:text-2xl">
        <div className="flex items-center gap-2">
          <span className="h-2 w-2 bg-gray-400  rounded-full inline-block" />
          <span className="text-gray-700 dark:text-white">
            {ApplicationString.MyCalendarStatusBar.notAvailable}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <span className="h-2 w-2 bg-primary rounded-full inline-block" />
          <span className="text-gray-700 dark:text-white">
            {ApplicationString.MyCalendarStatusBar.approved}
          </span>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;

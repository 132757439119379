/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import cssUtils from '../../Utils/cssUtils';
import MyListingsCard from './MyListingsCard';
import ApplicationString from '../../Constants/applicationString';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import InternalRoute from '../../Utils/internalRoutes';
import CreateVehicleFirstScreen from '../OwnerComponent/FirstCreateVehicleComponent';

interface MyListingsProps {
  listingDetails: IVehiclesData[];
  isLoading: boolean;
  handleActivateVehicle: (id: number) => void;
}
const MyListingsOwnerComponent: React.FC<MyListingsProps> = ({
  listingDetails,
  isLoading,
  handleActivateVehicle,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-page_bg dark:bg-dark_bg_primary">
      <div className={cssUtils.layout.sectionLayout}>
        <div className="flex flex-row justify-between items-center">
          <div className="text-2xl 2xl:text-3xl font-bold text-primary_text dark:text-dark_primary_text">
            {ApplicationString.MyListingsComponent.title}
          </div>

          {listingDetails.length > 0 && (
            <div>
              <Button
                variant="contained"
                color="primary"
                data-testid="my-listings-add-vehicle-button"
                className="text-white rounded-full shadow-1 shadow-slate-50 px-6 py-2 capitalize hover:bg-primary"
                onClick={() => {
                  navigate(InternalRoute.vehicleCreate);
                }}
              >
                {ApplicationString.MyListingsComponent.ButtonTexts.addVehicle}
              </Button>
            </div>
          )}
        </div>

        {!isLoading && listingDetails.length === 0 && (
          <CreateVehicleFirstScreen />
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {isLoading
            ? [...Array(12)].map(() => (
                <div
                  key={`skeleton-${Math.random()}`}
                  className="flex flex-col gap-2 bg-card_bg dark:bg-dark_bg_secondary p-2.5 rounded-xl"
                >
                  <Skeleton
                    variant="rounded"
                    className="w-full h-45 rounded-xl"
                  />
                  <div className="flex justify-between">
                    <Skeleton
                      variant="rounded"
                      className=" w-1/3 h-10 rounded-xl"
                    />
                    <Skeleton
                      variant="rounded"
                      className="w-1/4 h-10 rounded-xl"
                    />
                  </div>
                  <Skeleton
                    variant="rounded"
                    className=" w-4/6 h-7 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/2 h-7 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-full h-10 rounded-full"
                  />
                </div>
              ))
            : listingDetails.map((vehicleData) => {
                return (
                  <MyListingsCard
                    key={vehicleData.id}
                    vehicleData={vehicleData}
                    handleActivateVehicle={handleActivateVehicle}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default MyListingsOwnerComponent;

import React, { useContext } from 'react';
import logo from '../../Assets/onelifeLogo.png';
import logoDark from '../../Assets/logoDark.png';
import cssUtils from '../../Utils/cssUtils';
import gridImg from '../../Assets/bgLayoutImg.png';
import ApplicationString from '../../Constants/applicationString';
import './style.css';
import ColorModeContext from '../../Utils/ColorModeContext';

interface SearchLayoutProps {
  children: React.ReactNode;
}

const BackgroundLayout: React.FC<SearchLayoutProps> = ({ children }) => {
  const { currentTheme } = useContext(ColorModeContext);
  return (
    <div
      className={`w-full ${currentTheme === 'dark' ? 'darkBg-img' : 'bg-img'} 32xl:h-screen h-full`}
    >
      <div
        className={`${cssUtils.layout.sectionLayout} h-full 2xl:flex 2xl:items-end 32xl:items-start 2xl:pb-0`}
      >
        <div className="gap-10 2xl:pb-0 2xl:gap-0 mt-0 flex-col flex 2xl:flex-row w-full md:py-4 2xl:mt-10 justify-between items-center 2xl:items-start">
          <div className="flex flex-col gap-2 2xsm:gap-5">
            <div className="flex flex-col 2xsm:gap-2 2xl:mt-30">
              <p className="text-xl 2xsm:text-3xl md:text-6xl flex items-center text-white dark:text-dark_bg_primary">
                {ApplicationString.bgLayoutInfo.createMem}
                <img
                  className="h-4 w-4 2xsm:h-6 2xsm:w-6 md:h-9 md:w-9 mx-1 2xsm:mt-1 md:mt-2"
                  src={currentTheme === 'dark' ? logoDark : logo}
                  alt="logo"
                />{' '}
                {ApplicationString.bgLayoutInfo.riesOn}
              </p>
              <p className="text-xl 2xsm:text-3xl md:text-6xl flex items-center dark:text-dark_bg_primary text-white">
                {ApplicationString.bgLayoutInfo.landAndWater}
              </p>
            </div>
            <p className="text-sm 2xsm:text-2sm md:text-xl dark:text-dark_bg_primary text-white">
              {ApplicationString.bgLayoutInfo.description}
            </p>
            <img
              className="mt-20 h-auto hidden 2xl:block 3xsl:hidden"
              src={gridImg}
              alt="logo"
            />{' '}
          </div>
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundLayout;

import {
  Button,
  IconButton,
  Modal,
  Typography,
  // useMediaQuery,
  // useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ColorModeContext from '../../../Utils/ColorModeContext';
import ApplicationString from '../../../Constants/applicationString';

interface IDeleteVehicle {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteVehicle: (id: number) => void;
  vehicleId: number;
}
const DeleteVehiclePopUpComponent: React.FC<IDeleteVehicle> = ({
  open,
  setOpen,
  handleDeleteVehicle,
  vehicleId,
}) => {
  const { currentTheme } = useContext(ColorModeContext);

  const handleDeleteClose = () => {
    handleDeleteVehicle(vehicleId);
    setOpen(false);
  };
  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <div className="">
      <Modal
        open={open}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        data-testid="modal-modal-title"
        className="flex items-center justify-center min-h-screen p-4  dark:top-0 dark:w-full dark:backdrop-invert-0 dark:bg-white/60 dark:z-97"
      >
        <div className="bg-white px-6 py-4 rounded-xl w-full max-w-xs md:max-w-md mx-auto relative dark:bg-dark_bg_secondary">
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col">
              <Typography className="font-bold text-2xl text-black text-nowrap dark:text-white">
                {ApplicationString.DeleteVehiclePopUpComponent.deleteVehicle}
              </Typography>
              <Typography
                data-testid="modal-modal-description"
                sx={{ mt: 2 }}
                className="md:text-nowrap dark:text-dark_text_sub_primary"
              >
                {
                  ApplicationString.DeleteVehiclePopUpComponent
                    .deleteVehicleContent
                }
              </Typography>
            </div>
            <IconButton
              onClick={handleCancelClose}
              data-testid="close-delete-popUp"
              className="absolute top-2 right-2"
            >
              <CloseOutlinedIcon color="primary" fontSize="large" />
            </IconButton>
          </div>

          <Button
            autoFocus
            onClick={handleDeleteClose}
            data-testid="vehicle-confirm-delete-btn"
            className="normal-case mt-4 py-2 px-8 text-sm md:text-base font-bold rounded-full bg-primary text-white max-w-full hover:bg-primary"
            style={{
              color: `${currentTheme === 'dark' ? 'black' : 'white'}`,
            }}
          >
            {ApplicationString.DeleteVehiclePopUpComponent.confirmDelete}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteVehiclePopUpComponent;

// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { IMyBookings } from '../Interfaces/common/MyBookings/MyBookings';
import {
  Image,
  IVehiclesData,
  MetaData,
  OwnerDetails,
  TimeFrameFor12Hourly,
} from '../Interfaces/common/SearchVehicle/SearchVehicle';

export const ROLES = {
  admin: 'ADMIN',
  superAdmin: 'SUPER-ADMIN',
};
export const HOURS_IN_DAY = 24;
export const HOURS_IN_TWELVE = 12;
export const StaticDates = {
  startDate: dayjs().toISOString(),
  endDate: dayjs().toISOString(),
};

export const minPopularListingCount = 4;

export const maxWorkLen = {
  specialRequest: 255,
};

export const staticDatesForSearch = {
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
};
export const dateFormat = 'MM/DD/YYYY';
export const timeFormat = 'hh:mm A';

export const defaultStringValue = '';
export const defaultNumberValue = 0;
export const defaultNullValue = null;
export const defaultBooleanValue = false;

const defaultOwnerDetails: OwnerDetails = {
  name: defaultStringValue,
  avatarPath: defaultStringValue,
  joinedMonth: defaultStringValue,
  joinedYear: defaultStringValue,
};
export const defaultImagePath = 'https://picsum.photos/200/300';
export const defaultStringNAValue = 'N/A';

const defaultImage: Image = {
  id: defaultNumberValue,
  fileType: defaultStringValue,
  isCover: false,
  path: defaultImagePath,
};

const defaultMetaData: MetaData = {
  hitchSize: defaultStringValue,
  isTrailerIncluded: defaultBooleanValue,
  ownershipType: defaultStringValue,
  amenitiesDescription: defaultStringValue,
  amenities: [],
  restricted: [],
  engineSize: defaultStringValue,
};

const defaultTimeFrameFor12Hourly: TimeFrameFor12Hourly = {
  to: defaultNumberValue,
  from: defaultNumberValue,
};

export const defaultVehiclesData: IVehiclesData = {
  id: defaultNumberValue,
  category: defaultNumberValue,
  minimumDeposit: 0,
  subCategory: defaultNumberValue,
  title: defaultStringValue,
  rate: defaultNumberValue,
  description: defaultStringValue,
  manufacturingYear: defaultNumberValue,
  maker: defaultStringValue,
  model: defaultStringValue,
  isEnabled: false,
  capacity: defaultNumberValue,
  size: defaultNumberValue,
  country: defaultStringValue,
  dropCountry: defaultStringValue,
  state: defaultStringValue,
  dropState: defaultStringValue,
  city: defaultStringValue,
  dropCity: defaultStringValue,
  zip: defaultStringValue,
  dropZip: defaultStringValue,
  address: defaultStringValue,
  address2: defaultStringValue,
  dropAddress: defaultStringValue,
  dropAddress2: defaultStringValue,
  latLong: defaultStringValue,
  dropLatLong: defaultStringValue,
  bookingSlotType: defaultStringValue,
  bookingGap: defaultNumberValue,
  shortNoticePeriod: defaultNumberValue,
  rating: defaultNumberValue,
  isMultipleBookingSameDay: false,
  bookingRateHourly: defaultNumberValue,
  bookingRate12Hourly: defaultNumberValue,
  timeFrameFor12Hourly: [defaultTimeFrameFor12Hourly],
  bookingRate24Hourly: defaultNumberValue,
  maxDaysFor24Hourly: defaultNumberValue,
  metaData: defaultMetaData,
  ownerDetails: defaultOwnerDetails,
  images: [defaultImage],
};

export const myBookingsDefaultValues: IMyBookings = {
  confirmationCode: defaultStringValue,
  deposit: defaultNumberValue,
  endDate: StaticDates.endDate,
  id: defaultNumberValue,
  ownerDetails: {
    id: defaultStringValue,
    name: defaultStringValue,
    avatarPath: defaultStringValue,
  },
  rate: defaultNumberValue,
  renterDetails: {
    id: defaultStringValue,
    name: defaultStringValue,
    avatarPath: defaultStringValue,
  },
  specialRequest: null,
  startDate: StaticDates.startDate,
  tax: defaultNumberValue,
  total: defaultNumberValue,
  type: defaultStringValue,
  vehicleDetail: defaultVehiclesData,
  vehicleId: defaultNumberValue,
};

export const initialStep1Values = {
  title: defaultStringValue,
  description: defaultStringValue,
  category: defaultNullValue,
  subCategory: defaultNullValue,
  maker: defaultStringValue,
  manufacturingYear: defaultStringValue,
  model: defaultStringValue,
  capacity: defaultNullValue,
  size: defaultNullValue,
  engineSize: defaultStringValue,
  trailer: defaultNullValue,
  hitchSize: defaultStringValue,
  ownerShipType: defaultNullValue,
};
export const initialStep2Values = {
  country: defaultStringValue,
  pickUp: {
    latLong: defaultStringValue,
    address1: defaultStringValue,
    address2: defaultStringValue,
    city: defaultStringValue,
    state: defaultStringValue,
    zipCode: defaultStringValue,
  },
  dropOff: {
    dropLatLong: defaultStringValue,
    address1: defaultStringValue,
    address2: defaultStringValue,
    city: defaultStringValue,
    state: defaultStringValue,
    zipCode: defaultStringValue,
  },
  isSameAddress: defaultBooleanValue,
};

export const initialStep3Values = {
  images: [],
};

export const initialStep4Values = {
  amenities: [],
  restrictions: [],
};
export const initialStep5Values = {
  isHourly: defaultBooleanValue,
  rateHourly: defaultNullValue,
  is12Hourly: defaultBooleanValue,
  rate12Hourly: defaultNullValue,
  timeFrame: [],
  is24Hourly: defaultBooleanValue,
  rate24Hourly: defaultNullValue,
  maxDaysFor24Hourly: defaultNullValue,
  isMultipleBookingOnSameDay: defaultNullValue,
  gapHour: defaultStringValue,
  shortNoticePeriod: defaultNullValue,
  monthLimit: defaultNullValue,
};
export const initialStep6Values = {
  termsAndCondition: defaultNullValue,
};

export const defaultLocalStepsData = [
  { step: 1, data: initialStep1Values },
  { step: 2, data: initialStep2Values },
  { step: 3, data: initialStep3Values },
  { step: 4, data: initialStep4Values },
  { step: 5, data: initialStep5Values },
];

const initialError = {
  error: false,
  ValidationMessage: '',
};
export const initialStep1ErrorData = {
  isError: false,
  title: initialError,
  description: initialError,
  category: initialError,
  subCategory: initialError,
  maker: initialError,
  manufacturingYear: initialError,
  model: initialError,
  capacity: initialError,
  size: initialError,
  engineSize: initialError,
  trailer: initialError,
  hitchSize: initialError,
  ownerShipType: initialError,
};

export const initialStep2ErrorData = {
  isError: false,
  country: initialError,
  pickUp: {
    address1: initialError,
    address2: initialError,
    city: initialError,
    state: initialError,
    zipCode: initialError,
  },
  dropOff: {
    address1: initialError,
    address2: initialError,
    city: initialError,
    state: initialError,
    zipCode: initialError,
  },
};

export const initialStep3ErrorData = {
  isError: false,
  images: initialError,
};

export const initialStep4ErrorData = {
  isError: false,
  amenities: initialError,
  restrictions: initialError,
};
export const initialStep5ErrorData = {
  isError: false,
  slot: initialError,
  rateHourly: initialError,
  rate12Hourly: initialError,
  timeFrame: initialError,
  rate24Hourly: initialError,
  maxDaysFor24Hourly: initialError,
  isMultipleBookingOnSameDay: initialError,
  gapHour: initialError,
  monthLimit: initialError,
};
export const initialStep6ErrorData = {
  isError: false,
  termsAndCondition: initialError,
};

export const dayRange = {
  startHour: 0,
  endHour: 23,
};

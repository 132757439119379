/* eslint-disable no-param-reassign */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef, useContext } from 'react';
import clockIcon from '../../../Assets/clock.png';
import {
  DateRange,
  VehicleDetails,
} from '../../BookingDetails/bookingInterface';
import { getTimeSlot } from '../../../Utils/utils';
import ApplicationString from '../../../Constants/applicationString';
import './style.css';
import ColorModeContext from '../../../Utils/ColorModeContext';

interface TimeSlot {
  time: string;
  disabled: boolean;
}

interface TimeSlotDropdownProps {
  slots12hr: TimeSlot[];
  slots1hr: TimeSlot[];
  disabledSlots: string[]; // Array of disabled time slots
  onSelectTime: (startTime: string, endTime: string) => void; // Updated callback for start and end time
  // eslint-disable-next-line react/require-default-props
  borderRadious?: string;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  initialSlotTime?: string;
  vehicleDetails: VehicleDetails | null;
  dateRange: DateRange;
}

const TimeSlotDropdown: React.FC<TimeSlotDropdownProps> = ({
  slots12hr,
  disabledSlots,
  slots1hr,
  onSelectTime,
  dateRange,
  initialSlotTime,
  vehicleDetails,
  borderRadious = 'rounded-3xl',
}) => {
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(
    null
  );
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropUpRef = useRef<HTMLDivElement>(null);
  const [show12HrSlot, setShow12HrSlot] = useState<boolean>(false);
  const [showHourlySlot, setShowHourlySlot] = useState<boolean>(false);
  const { currentTheme } = useContext(ColorModeContext);

  const handleSelectOutside = () => {
    if (
      dropUpRef.current &&
      // eslint-disable-next-line no-restricted-globals
      !dropUpRef.current.contains(event?.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const isTimeDisabled = (time: string) => {
    return slots1hr.some((slot) => slot.time === time && slot.disabled);
  };

  const handleSelectTime = (time: string) => {
    if (!isTimeDisabled(time)) {
      const [startTime, endTime] = time.split(' to ').map((t) => t.trim());
      setSelectedStartTime(startTime);
      setSelectedEndTime(endTime);
      onSelectTime(startTime, endTime);
      setIsOpen(false);
    }
  };

  const getTimeLabel = (initialSlot: string) => {
    if (selectedStartTime && selectedEndTime) {
      return `${selectedStartTime} to ${selectedEndTime}`;
    }
    return initialSlot;
  };

  useEffect(() => {
    if (initialSlotTime) {
      const [startTime, endTime] = initialSlotTime
        .split(' to ')
        .map((t) => t.trim());
      setSelectedStartTime(startTime);
      setSelectedEndTime(endTime);
      onSelectTime(startTime, endTime);
    }
  }, []);

  useEffect(() => {
    if (vehicleDetails?.bookingRate12Hourly) {
      setShow12HrSlot(true);
    }
    if (vehicleDetails?.bookingRateHourly) {
      setShowHourlySlot(true);
    }
  }, [vehicleDetails]);

  useEffect(() => {
    const currentTime = new Date();
    const currentSlot = getTimeSlot(currentTime);
    const isToday = (date: Date) =>
      date.getDate() === currentTime.getDate() &&
      date.getMonth() === currentTime.getMonth() &&
      date.getFullYear() === currentTime.getFullYear();

    const disableSlots = (slots: TimeSlot[]) => {
      slots.forEach((slot) => {
        if (disabledSlots.includes(slot.time)) {
          slot.disabled = true;
        } else if (isToday(new Date(dateRange?.startDate || ''))) {
          if (slot.time.includes(' to ')) {
            const [start, end] = slot.time.split(' to ');
            const startTimeSlot = start.trim();
            const endTimeSlot = end.trim();
            slot.disabled =
              currentSlot >= startTimeSlot && currentSlot <= endTimeSlot;
          } else {
            slot.disabled = slot.time <= currentSlot;
          }
        } else {
          slot.disabled = false;
        }
      });
    };

    disableSlots(slots1hr);
    disableSlots(slots12hr);
  }, [disabledSlots, slots1hr, slots12hr, dateRange]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleSelectOutside);
    } else {
      document.removeEventListener('mousedown', handleSelectOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleSelectOutside);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`relative w-full ${currentTheme === 'dark' ? 'dark-time-dropdown' : 'time-dropdown'}`}
      ref={dropUpRef}
      data-testid="dropoff-container"
    >
      <button
        type="button"
        onClick={toggleDropdown}
        className={`w-full ${borderRadious} dark:border-dark_borderStroke flex-col gap-1 p-4 dark:bg-dark_bg_secondary bg-white border border-gray-300 text-gray-700 py-3 px-4 focus:outline-none flex justify-between`}
        data-testid="toggle-dropdown-button"
      >
        <div className="flex w-full justify-between">
          <div className="flex gap-2 items-center">
            <img src={clockIcon} className="w-4 h-4" alt="clock icon" />
            <span className="text-secondary_text dark:text-dark_text_sub_primary">
              {ApplicationString.timeSlotData.label}
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-gray-800 dark:text-dark_text_sub_primary text-sm"
            />
          </div>
        </div>
        <span className="mt-2 text-lg md:text-2xl dark:text-dart_web_accent text-title_text font-semibold">
          {getTimeLabel(initialSlotTime || 'Select Time')}
        </span>
      </button>
      {isOpen && (
        <div className="absolute z-40 max-h-90 overflow-y-scroll w-full mt-1 bg-white border border-gray-300 rounded-xl shadow-xl dark:shadow-2xl dark:shadow-slate-700 dark:text-darkPrimary dark:border-dark_borderStroke dark:bg-dark_bg_secondary">
          {showHourlySlot && (
            <div className="p-4">
              <h3 className="text-md dark:text-white mb-1 font-semibold">
                {' '}
                {ApplicationString.timeSlotData.slots.hr1}
              </h3>
              <div className="flex flex-col gap-2">
                {slots1hr.map((slot) => (
                  <label
                    key={slot.time}
                    htmlFor={`1hrSlot-${slot.time.replace(/[:\s]/g, '-')}`}
                    className={`p-3 border rounded-full cursor-pointer ${
                      // eslint-disable-next-line no-nested-ternary
                      slot.disabled
                        ? 'bg-gray-100 dark:bg-black dark:text-slate-400 dark:border-dark_borderStroke text-slate-600 opacity-70 cursor-not-allowed'
                        : selectedStartTime &&
                            selectedEndTime &&
                            `${selectedStartTime} to ${selectedEndTime}` ===
                              slot.time
                          ? 'bg-slate-100 text-black font-semibold  dark:border-dark_borderStroke dark:text-white selected dark:bg-dark_bg_surface'
                          : 'hover:bg-gray-100 dark:text-white dark:border-dark_borderStroke  hover:dark:bg-dark_bg_surface'
                    }`}
                  >
                    <input
                      id={`1hrSlot-${slot.time.replace(/[:\s]/g, '-')}`}
                      type="radio"
                      name="timeSlot"
                      value={slot.time}
                      disabled={slot.disabled}
                      checked={
                        selectedStartTime && selectedEndTime
                          ? `${selectedStartTime} to ${selectedEndTime}` ===
                            slot.time
                          : false
                      }
                      onChange={() => handleSelectTime(slot.time)}
                      className="focus:ring-2 focus:ring-blue-500"
                      data-testid={`time-slot-${slot.time.replace(/[:\s]/g, '-')}`}
                    />
                    <span>{slot.time}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {show12HrSlot && (
            <div className="p-4">
              <h3 className="text-md dark:text-white mb-1 font-semibold">
                {' '}
                {ApplicationString.timeSlotData.slots.hr12}
              </h3>
              <div className="flex flex-col gap-2">
                {slots12hr.map((slot) => (
                  <label
                    key={slot.time}
                    htmlFor={`12hrSlot-${slot.time.replace(/[:\s]/g, '-')}`}
                    className={`p-3 border rounded-full cursor-pointer ${
                      // eslint-disable-next-line no-nested-ternary
                      slot.disabled
                        ? 'bg-gray-100 text-slate-600 dark:text-slate-400 dark:bg-black dark:border-dark_borderStroke opacity-70 cursor-not-allowed'
                        : selectedStartTime &&
                            selectedEndTime &&
                            `${selectedStartTime} to ${selectedEndTime}` ===
                              slot.time
                          ? 'bg-slate-100 text-black font-semibold  dark:border-dark_borderStroke dark:text-white selected dark:bg-dark_bg_surface'
                          : 'hover:bg-gray-100 dark:text-white dark:border-dark_borderStroke  hover:dark:bg-dark_bg_surface'
                    }`}
                  >
                    <input
                      type="radio"
                      id={`12hrSlot-${slot.time.replace(/[:\s]/g, '-')}`}
                      name="timeSlot"
                      value={slot.time}
                      disabled={slot.disabled}
                      checked={
                        selectedStartTime && selectedEndTime
                          ? `${selectedStartTime} to ${selectedEndTime}` ===
                            slot.time
                          : false
                      }
                      onChange={() => handleSelectTime(slot.time)}
                      className="focus:ring-2 focus:ring-blue-500"
                      data-testid={`time-slot-${slot.time.replace(/[:\s]/g, '-')}`}
                    />
                    {slot.time}
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeSlotDropdown;

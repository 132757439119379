import React from 'react';
import { Checkbox, FormHelperText, Typography } from '@mui/material';
import {
  CheckCircleOutlineRounded,
  PanoramaFishEyeRounded,
} from '@mui/icons-material';
import { FormControl } from '@mui/base';
import { getLocalStorageAccountInfo } from '../../../Utils/utils';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums } from '../../../Utils/enums';
import ApplicationString from '../../../Constants/applicationString';
import { ICommonStepsProps } from '../../../Interfaces/Stepper';

const PoliciesStepperComponent: React.FC<ICommonStepsProps> = ({
  formData,
  setFormData,
  errorsData,
  setErrorsData,
}) => {
  const cancellationPolicy = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting.termsAndCondition;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      step6: {
        ...prevValues.step6,
        termsAndCondition: event.target.checked,
      },
    }));

    if (event.target.checked) {
      setErrorsData((prevState) => ({
        ...prevState,
        step6: {
          ...prevState.step6,
          termsAndCondition: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
  };
  const handleTypoClick = () => {
    setFormData((prevValues) => ({
      ...prevValues,
      step6: {
        ...prevValues.step6,
        termsAndCondition: !formData.step6.termsAndCondition,
      },
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      step6: {
        ...prevState.step6,
        termsAndCondition: {
          ValidationMessage: '',
          error: false,
        },
      },
    }));
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col md:flex-row justify-between gap-8 rounded-xl ">
        <div className="md:w-1/2 ">
          <Typography className="text-black text-sm font-bold dark:text-white">
            {
              ApplicationString.PoliciesStepperComponent.titleTexts
                .securityDeposit
            }
          </Typography>
          <div className=" w-full border border-borderStroke px-6 py-4 rounded-xl mt-4">
            <Typography className="text-black text-base font-bold dark:text-white">
              {
                ApplicationString.PoliciesStepperComponent
                  .securityDepositDetails.firstParagraph
              }
            </Typography>
            <Typography className="text-black text-base font-bold mt-4 dark:text-white">
              {
                ApplicationString.PoliciesStepperComponent
                  .securityDepositDetails.secondParagraph
              }
            </Typography>
          </div>
        </div>

        <div className="md:w-1/2">
          <Typography className="text-black text-sm font-bold dark:text-white">
            {
              ApplicationString.PoliciesStepperComponent.titleTexts
                .cancellationPolicy
            }
          </Typography>
          <div className=" w-full border border-borderStroke px-6 py-4 rounded-xl mt-4">
            {cancellationPolicy &&
              cancellationPolicy.map((policy) => {
                return (
                  <div
                    key={policy.title}
                    className="flex flex-col gap-x-2 gap-y-4"
                  >
                    <Typography className="text-black text-base font-bold mt-4 dark:text-white">
                      {policy.title}
                    </Typography>
                    {policy.details &&
                      policy.details.length > 0 &&
                      policy.details.map((detail, index) => {
                        return (
                          <Typography
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${policy.title}-${index}`}
                            className="text-secondary_text dark:text-dark_secondary_text text-base font-normal"
                          >
                            {detail}
                          </Typography>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <FormControl error={errorsData.step6.termsAndCondition.error}>
        <div className="w-full pt-3 pb-10 flex flex-col">
          <div
            className="w-full flex flex-row items-center cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={handleTypoClick}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleTypoClick();
              }
            }}
          >
            <Checkbox
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...label}
              icon={<PanoramaFishEyeRounded color="primary" />}
              data-testid="step6-checkbox-terms-and-conditions"
              checkedIcon={<CheckCircleOutlineRounded color="primary" />}
              checked={formData.step6.termsAndCondition || false}
              onChange={handleCheckboxChange}
            />
            <Typography className="text-black text-lg font-normal dark:text-white">
              {
                ApplicationString.PoliciesStepperComponent.titleTexts
                  .termsAndConditions
              }
            </Typography>
          </div>
          {errorsData.step6.termsAndCondition && (
            <FormHelperText className="text-error" sx={{ margin: '0px 14px' }}>
              {errorsData.step6.termsAndCondition.ValidationMessage}
            </FormHelperText>
          )}
        </div>
      </FormControl>
    </div>
  );
};

export default PoliciesStepperComponent;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ActivatedSvg, DeactivatedSvg, LocationIcon } from '../../Assets/Svgs';
import InternalRoute from '../../Utils/internalRoutes';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import { getCoverPhoto, removeLocalStorageItems } from '../../Utils/utils';
import ApplicationString from '../../Constants/applicationString';
import { localStorageEnums } from '../../Utils/enums';

interface IMyListingsCardProps {
  vehicleData: IVehiclesData;
  handleActivateVehicle: (id: number) => void;
}
const MyListingsCard: React.FC<IMyListingsCardProps> = ({
  vehicleData,
  handleActivateVehicle,
}) => {
  const { id, images, title, city, country, isEnabled } = vehicleData;
  const navigate = useNavigate();
  const coverPhoto = getCoverPhoto(images);
  const showStatusBadgeBasedOnType = (): JSX.Element => {
    if (isEnabled) {
      return (
        <div className="flex gap-1 absolute left-2 top-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-success items-center px-2 py-0.5">
          <ActivatedSvg />
          <h2 className="font-bold text-sm 4xl:text-base">
            {ApplicationString.MyListingsComponent.statusTexts.active}
          </h2>
        </div>
      );
    }

    return (
      <div className="flex gap-1 absolute left-2 top-2 bg-white dark:bg-dark_bg_secondary dark:text-dark_primary_text rounded-full border border-black items-center px-2 py-0.5">
        <DeactivatedSvg />
        <h2 className="font-bold text-sm 4xl:text-base">
          {ApplicationString.MyListingsComponent.statusTexts.deactivated}
        </h2>
      </div>
    );
  };
  const showButtonBasedOnType = (): JSX.Element => {
    if (isEnabled) {
      return (
        <Link to={InternalRoute.vehicleEdit.replace(':vehicleId', `${id}`)}>
          <Button
            variant="contained"
            color="primary"
            data-testid="my-listings-edit-vehicle-button"
            className="w-full flex justify-center shadow-1 shadow-slate-50 rounded-4xl normal-case sm:text-base font-bold sm:py-3 dark:text-dark_bg_secondary"
            onClick={(e) => {
              e.stopPropagation();
              removeLocalStorageItems([
                localStorageEnums.skippedSteps,
                localStorageEnums.step,
                localStorageEnums.stepsData,
                localStorageEnums.isSlotError,
                localStorageEnums.isStepperHasError,
              ]);
            }}
          >
            {
              ApplicationString.MyListingsComponent.MyListingsCardDetails
                .ButtonTexts.edit
            }
          </Button>
        </Link>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        data-testid="my-listings-activate-vehicle-button"
        onClick={(e) => {
          e.stopPropagation();
          handleActivateVehicle(id);
        }}
        className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3 bg-primary_text hover:bg-gray-700 dark:text-dark_outlineButton dark:bg-dark_formFieldBg"
      >
        {
          ApplicationString.MyListingsComponent.MyListingsCardDetails
            .ButtonTexts.activate
        }
      </Button>
    );
  };

  const handleCardClick = () => {
    navigate(
      InternalRoute.Owner_Vehicle_details.replace(':vehicleId', `${id}`)
    );
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleCardClick();
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      onClick={handleCardClick}
      data-testid="my-listings-vehicle-card"
      role="button"
      tabIndex={0}
      className="h-85 rounded-xl border p-2.5 bg-white dark:bg-dark_bg_secondary dark:border-dark_border_color"
    >
      <div className="img-container mb-2.5 h-45 relative">
        {coverPhoto ? (
          <img
            src={coverPhoto}
            alt={title}
            className="h-full w-full rounded-xl"
          />
        ) : (
          <div className="h-full w-full bg-gray-300 rounded-xl flex justify-center items-center text-2xl">
            {ApplicationString.commonStrings.noImageFound}
          </div>
        )}
        {showStatusBadgeBasedOnType()}
        {!isEnabled && (
          <div className="absolute top-0 h-full w-full backdrop-invert-0 bg-white/30 z-97" />
        )}
      </div>
      <div className="text-content-container flex flex-col gap-2 sm:gap-0 px-2 sm:px-4">
        <div className="flex flex-col justify-between max-w-full">
          <h1
            className={`text-xl sm:text-2xl 2xl:text-[28px] 4xl:text-3xl font-bold overflow-hidden whitespace-nowrap text-ellipsis ${isEnabled ? 'dark:text-white' : 'text-secondary_text dark:text-dark_text_sub_primary'}`}
            title={title}
            style={{ maxWidth: 'calc(100% - 5rem)' }}
          >
            {title}
          </h1>
          <div className="flex items-center gap-1">
            <LocationIcon />
            <span className="text-secondary_text  overflow-hidden whitespace-nowrap text-ellipsis text-lg font-medium dark:text-dark_text_sub_primary">
              {`${city}, ${country}`}
            </span>
          </div>
        </div>
        <div className="mt-5">{showButtonBasedOnType()}</div>
      </div>
    </div>
  );
};

export default MyListingsCard;

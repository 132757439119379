/* eslint-disable react/require-default-props */
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import {
  StepperNextSvg,
  StepperBackSvg,
  StepperBackDarkModeSvg,
  StepperNextDisableSvg,
  InformationSvgIcon,
} from '../../../Assets/Svgs';
import {
  ICreateVehicleData,
  IStepData,
  IStepperErrorData,
  ISteps,
} from '../../../Interfaces/Stepper';
import ColorModeContext from '../../../Utils/ColorModeContext';
import ApplicationString from '../../../Constants/applicationString';
import {
  localStorageEnums,
  stepsNumberEnum,
  systemColorModeEnum,
} from '../../../Utils/enums';
import {
  getDetailsFromLocalStorage,
  removeLocalStorageItems,
  setLocalStorageInfo,
} from '../../../Utils/utils';
import { isStepperError } from '../Validation';
import LoadingButton from '../../common/LoadingButton/LoadingButton';

interface StepperActionProps {
  handleNext: () => void;
  handleBack: () => void;
  handleSkip: () => void;
  activeStep: number;
  steps: ISteps[];
  setShowVerified: (value: boolean) => void;
  createVehicle?: (
    data: ICreateVehicleData,
    setShowVerified: (arg: boolean) => void
  ) => Promise<void>;
  updateVehicle?: (
    setActiveStep?: (arg0: (prevActiveStep: number) => number) => void
  ) => Promise<void>;
  errorsData: IStepperErrorData;
  isEdit: boolean;
  isLoading: boolean;
}

const StepperActions: React.FC<StepperActionProps> = ({
  handleNext,
  handleBack,
  handleSkip,
  activeStep,
  steps,
  setShowVerified,
  createVehicle,
  errorsData,
  isEdit,
  updateVehicle,
  isLoading,
}) => {
  const { currentTheme } = React.useContext(ColorModeContext);
  const stepsData =
    getDetailsFromLocalStorage<IStepData[]>(localStorageEnums.stepsData) || [];
  const isStepperHasError = getDetailsFromLocalStorage<boolean>(
    localStorageEnums.isStepperHasError
  );
  const step1Data = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step1
  )?.data;
  const step2Data = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step2
  )?.data;
  const step3Data = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step3
  )?.data;
  const step4Data = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step4
  )?.data;
  const step5Data = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step5
  )?.data;
  const step6Data = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step6
  )?.data;

  const vehicleData = {
    title: step1Data?.title,
    description: step1Data?.description,
    category: step1Data?.category,
    subCategory: step1Data?.subCategory,
    maker: step1Data?.maker,
    manufacturingYear: step1Data?.manufacturingYear,
    model: step1Data?.model,
    capacity: step1Data?.capacity,
    size: step1Data?.size,
    isAddressSame: step2Data?.isSameAddress,
    country: step2Data?.country,
    state: step2Data?.pickUp?.state,
    city: step2Data?.pickUp?.city,
    zip: step2Data?.pickUp?.zipCode,
    address: step2Data?.pickUp?.address1,
    address2: step2Data?.pickUp?.address2,
    latLong: step2Data?.pickUp?.latLong,
    dropCountry: step2Data?.country,
    dropState: step2Data?.dropOff?.state,
    dropCity: step2Data?.dropOff?.city,
    dropZip: step2Data?.dropOff?.zipCode,
    dropAddress: step2Data?.dropOff?.address1,
    dropAddress2: step2Data?.dropOff?.address2,
    dropLatLong: step2Data?.dropOff?.dropLatLong,
    metaData: {
      engineSize: step1Data?.engineSize,
      isTrailerIncluded: step1Data?.trailer,
      hitchSize: step1Data?.hitchSize,
      ownershipType: step1Data?.ownerShipType,
      amenities: step4Data?.amenities,
      restricted: step4Data?.restrictions,
      termsAndCondition: step6Data?.termsAndCondition,
    },
    images: step3Data?.images,
    maxDaysFor24Hourly:
      step5Data && step5Data.maxDaysFor24Hourly
        ? Number(step5Data.maxDaysFor24Hourly)
        : null,
    bookingSlotType: step5Data?.bookingSlotType,
    bookingGap: step5Data?.bookingGap,
    shortNoticePeriod: step5Data?.shortNoticePeriod,
    isMultipleBookingSameDay: step5Data?.isMultipleBookingSameDay,
    bookingRateHourly: step5Data?.bookingRateHourly,
    bookingRate12Hourly: step5Data?.bookingRate12Hourly,
    timeFrameFor12Hourly: step5Data?.timeFrameFor12Hourly,
    bookingRate24Hourly: step5Data?.bookingRate24Hourly,
    monthLimit: step5Data?.monthLimit,
  };

  const handleSubmit = () => {
    if (!isEdit && createVehicle) {
      setLocalStorageInfo(
        isStepperError(errorsData),
        localStorageEnums.isStepperHasError
      );
      if (isStepperError(errorsData) || isStepperHasError) {
        return;
      }
      createVehicle(vehicleData, setShowVerified);
    } else {
      setShowVerified(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      updateVehicle && updateVehicle();
      removeLocalStorageItems([
        localStorageEnums.skippedSteps,
        localStorageEnums.step,
        localStorageEnums.stepsData,
        localStorageEnums.isSlotError,
        localStorageEnums.isStepperHasError,
      ]);
    }
  };

  const handleBackAction = () => {
    handleBack();
  };

  const renderNextAction = () => {
    if (activeStep === steps.length - 1) {
      if (isLoading) {
        return (
          <LoadingButton
            buttonText={
              isEdit
                ? ApplicationString.vehicleStepper.stepperButtons.finalUpdating
                : ApplicationString.vehicleStepper.stepperButtons.finishing
            }
            extraBtnClasses="flex flex-row !py-2 gap-2 px-5 w-full"
          />
        );
      }
      return (
        <Button
          variant="contained"
          data-testid="stepper-data-submit-btn"
          onClick={handleSubmit}
          disabled={
            isStepperError(errorsData) ||
            (isStepperHasError !== null && isStepperHasError)
          }
          color="primary"
          className="rounded-4xl normal-case text-base"
        >
          {isEdit
            ? ApplicationString.vehicleStepper.stepperButtons.finalUpdate
            : ApplicationString.vehicleStepper.stepperButtons.finish}
          <span className="ml-2">
            {isStepperError(errorsData) ||
            (isStepperHasError !== null && isStepperHasError) ? (
              <StepperNextDisableSvg />
            ) : (
              <StepperNextSvg />
            )}
          </span>
        </Button>
      );
    }
    if (isLoading && isEdit) {
      return (
        <LoadingButton
          buttonText={ApplicationString.vehicleStepper.stepperButtons.update}
          extraBtnClasses="flex flex-row !py-2 gap-2 px-5 w-full"
        />
      );
    }
    return (
      <Button
        variant="contained"
        onClick={handleNext}
        color="primary"
        data-testid="stepper-data-next-btn"
        className="rounded-4xl normal-case text-base"
      >
        {isEdit
          ? ApplicationString.vehicleStepper.stepperButtons.update
          : ApplicationString.vehicleStepper.stepperButtons.next}
        <span className="ml-2">
          <StepperNextSvg />
        </span>
      </Button>
    );
  };

  return (
    <div className="bg-white sticky z-30 bottom-0 dark:bg-dark_bg_secondary w-full py-3 shadow-stepperAction">
      <Box className="flex flex-col md:flex md:flex-row gap-[5px] md:gap-0 items-center mx-auto max-w-7xl px-6 lg:px-8 2xl:px-0">
        {activeStep < steps.length - 1 && (
          <>
            <Button
              color="primary"
              onClick={handleSkip}
              data-testid="stepper-form-skip-btn"
              sx={{ mr: 1 }}
              className="normal-case text-base hover:bg-none"
            >
              {ApplicationString.vehicleStepper.stepperButtons.skip}
            </Button>
            {isEdit && (
              <div className="flex gap-2">
                <InformationSvgIcon />
                <p className="text-secondary_text text-sm leading-4 font-normal xl:max-w-full lg:max-w-[500px] max-w-[300px]	">
                  {ApplicationString.vehicleStepper.stepperButtons.skipInfo.replace(
                    '{buttonNameData}',
                    'update and next button'
                  )}
                </p>
              </div>
            )}
          </>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        <div className="flex flex-col gap-3 xsm:flex-row xsm:gap-0">
          {activeStep !== 0 && activeStep < steps.length && (
            <Button
              onClick={handleBackAction}
              data-testid="stepper-form-back-btn"
              className="rounded-4xl normal-case text-base border border-solid px-5 border-black text-black dark:border-card_bg dark:text-white dark:bg-dark_bg_secondary bg-card_bg hover:bg-card_bg"
              sx={{ mr: 1, boxShadow: 'none' }}
            >
              <span className="mr-2">
                {currentTheme === systemColorModeEnum.light ? (
                  <StepperBackSvg />
                ) : (
                  <StepperBackDarkModeSvg />
                )}
              </span>
              {ApplicationString.vehicleStepper.stepperButtons.back}
            </Button>
          )}
          {renderNextAction()}
        </div>
      </Box>
    </div>
  );
};

export default StepperActions;

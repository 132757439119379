import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ApplicationString from '../../../Constants/applicationString';
import InternalRoute from '../../../Utils/internalRoutes';
import { getLocalStorageAccountInfo } from '../../../Utils/utils';
import { localStorageEnums } from '../../../Utils/enums';
import { LoggedInUserInfoDataType } from '../../../Interfaces/Login/LoginInterfaces';
import LogoutPopupComponent from '../LogoutPopUp';

type LinkType = {
  key: string;
  label: string;
  route: string;
};

const appString = ApplicationString.userProfileInfo;

const Links: LinkType[] = [
  {
    key: appString.myBooking.key,
    label: appString.myBooking.label,
    route: InternalRoute.myBookings,
  },
  {
    key: appString.switchToOwner.key,
    label: appString.switchToOwner.label,
    route: InternalRoute.dashboard,
  },
  {
    key: appString.myAccount.key,
    label: appString.myAccount.label,
    route: InternalRoute.myAccount,
  },
  {
    key: appString.logOut.key,
    label: appString.logOut.label,
    route: '',
  },
];

interface OwnerProfileMenuButtonProps {
  handleMobileMenu: (route: string) => void;
}

const ProfileMenuButton: React.FC<OwnerProfileMenuButtonProps> = ({
  handleMobileMenu,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const userInfo = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleLinkClick = (link: LinkType) => {
    if (link.key === appString.logOut.key) {
      setOpen(true);
    } else {
      handleMobileMenu(link.route);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative w-full lg:w-auto" ref={menuRef}>
      <button
        type="button"
        className="relative flex items-center justify-between w-full px-6 lg:px-2 py-2 bg-white dark:border-dark_borderStroke dark:bg-dark_bg_secondary rounded-full border border-gray-200 hover:bg-gray-100 lg:w-auto "
        aria-expanded={isOpen}
        aria-label="Main navigation menu"
        data-testid="cypress-headernav-profile"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon
          icon={faBars}
          className="hidden lg:block px-2 w-4 h-4"
        />
        <p className="ml-2 lg:hidden font-semibold dark:text-white text-black">
          {ApplicationString.myProfileText}
        </p>

        <div className=" w-8 bg-darkPrimary rounded-full flex justify-center items-center font-semibold text-white h-8 ml-2">
          {userInfo?.userName
            ? userInfo.userName.slice(0, 1)
            : ApplicationString.dummyUserName}
        </div>
      </button>
      {open && <LogoutPopupComponent open={open} setOpen={setOpen} />}

      {isOpen && (
        <div className="absolute right-0 mt-2 w-full bg-white dark:text-darkPrimary dark:border-dark_borderStroke dark:bg-dark_bg_secondary border border-slate-300 font-medium rounded-md shadow-lg z-50 lg:w-48 lg:mt-6">
          <ul className="py-1">
            {Links.map((link) => (
              <li
                key={link.key}
                aria-hidden="true"
                onClick={() => handleLinkClick(link)}
                className="px-4 py-2 hover:bg-slate-100 dark:hover:bg-dark_bg_surface cursor-pointer"
              >
                {link.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfileMenuButton;

import React from 'react';
import {
  ErrorOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { FormControl } from '@mui/base';
import '../../../index.css';
import ApplicationString from '../../../Constants/applicationString';

interface IErrorsData {
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  confirmPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  currentPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface IPasswordQuality {
  passwordQuality: string;
  passwordMsgColor: string;
  passwordQualityIcon: JSX.Element | null;
}

interface IFormData {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

interface IChangePasswordFormComponentProps {
  onPasswordSubmit: (e: React.FormEvent) => void;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleForgotPasswordClick: () => void;
  handleConfirmPasswordChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCurrentPasswordChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  formData: IFormData;
  goBack: () => void;
  errorsData: IErrorsData;
  isChangingPassword: boolean;
  passwordQuality: IPasswordQuality;
}
const ChangePasswordComponent: React.FC<IChangePasswordFormComponentProps> = ({
  onPasswordSubmit,
  handlePasswordChange,
  handleForgotPasswordClick,
  handleConfirmPasswordChange,
  handleCurrentPasswordChange,
  formData,
  goBack,
  errorsData,
  isChangingPassword,
  passwordQuality,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const allFieldsFilled = Object.values(formData).every(
    (value) => value.trim() !== ''
  );
  return (
    <div className="w-full">
      <ChevronLeftIcon onClick={goBack} className="md:hidden text-2xl mb-8" />
      <Typography
        fontWeight={400}
        className="dark:text-white uppercase text-lg lg:text-xl 2xl:text-2xl"
      >
        {ApplicationString.changePasswordlabels.changePassword}
      </Typography>
      <Divider className="mt-2" />
      <div className="w-full mt-2">
        <Typography className="text-secondary_text text-base font-normal dark:text-dark_secondary_text">
          {ApplicationString.changePasswordlabels.descMsg}
        </Typography>
        <Typography className="text-black text-base font-bold dark:text-white">
          {ApplicationString.changePasswordlabels.descMsgBold}
        </Typography>
      </div>
      <form onSubmit={onPasswordSubmit}>
        <div data-testid="form-fields-container " className="md:w-115 mt-8">
          <FormControl className="w-full">
            <div className="w-full flex flex-col">
              <FormControl className="w-full">
                <TextField
                  label={ApplicationString.changePasswordlabels.currentPassword}
                  type={showCurrentPassword ? 'text' : 'password'}
                  className="customInputBase w-full rounded-4xl border-none text-base font-medium dark:bg-dark_formFieldBg dark:text-dark_secondary_text "
                  onChange={handleCurrentPasswordChange}
                  data-testid="my-account-current-password"
                  error={errorsData.currentPassword.error}
                  value={formData.currentPassword}
                  InputProps={{
                    className: `rounded-4xl px-2 border-none `,
                    endAdornment: (
                      <InputAdornment position="end" className="pr-4">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-testid="change-password-visibility-icon"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <VisibilityOutlined className=" text-primary " />
                          ) : (
                            <VisibilityOffOutlined className=" text-primary  " />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {(errorsData.currentPassword.error ||
                  errorsData.currentPassword.onlyErrorMsg) && (
                  <FormHelperText className="text-error flex items-center gap-1">
                    <ErrorOutlined className="size-3.5" />
                    {errorsData.currentPassword.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="mt-2 ml-auto text-right">
              <Button
                onClick={handleForgotPasswordClick}
                data-testid="myAccount-forgot-password-link"
                className=" text-primary text-base capitalize font-bold"
              >
                {ApplicationString.changePasswordlabels.forgotPass}
              </Button>
            </div>
            <div className=" flex flex-col mt-2">
              <FormControl className="w-full">
                <TextField
                  label={ApplicationString.changePasswordlabels.newPassword}
                  type={showPassword ? 'text' : 'password'}
                  className=" customInputBase w-full rounded-4xl border-none text-base font-medium dark:bg-dark_formFieldBg dark:text-dark_secondary_text"
                  onChange={handlePasswordChange}
                  data-testid="my-account-new-password"
                  error={errorsData.password.error}
                  value={formData.password}
                  InputProps={{
                    className: `rounded-4xl px-2 border-none`,
                    endAdornment: (
                      <InputAdornment position="end" className="pr-4">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlined className=" text-primary " />
                          ) : (
                            <VisibilityOffOutlined className=" text-primary  " />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorsData.password.error && (
                  <FormHelperText className="text-error flex items-center gap-1">
                    <ErrorOutlined className="size-3.5" />
                    {errorsData.password.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="w-full flex flex-col mt-4">
              <FormControl className="w-full">
                <TextField
                  label={ApplicationString.changePasswordlabels.confirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="customInputBase w-full rounded-4xl border-none text-base font-medium dark:bg-dark_formFieldBg  dark:text-dark_secondary_text "
                  onChange={handleConfirmPasswordChange}
                  data-testid="my-account-confirm-password"
                  error={errorsData.confirmPassword.error}
                  value={formData.confirmPassword}
                  InputProps={{
                    className: `rounded-4xl px-2 border-none  `,
                    endAdornment: (
                      <InputAdornment position="end" className="pr-4">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOutlined className=" text-primary " />
                          ) : (
                            <VisibilityOffOutlined className=" text-primary  " />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorsData.confirmPassword.error && (
                  <FormHelperText className="text-error flex items-center gap-1">
                    <ErrorOutlined className="size-3.5" />
                    {errorsData.confirmPassword.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            {passwordQuality.passwordQuality !== '' && (
              <div className="flex gap-2 mt-2">
                <span>{passwordQuality.passwordQualityIcon}</span>
                <span className={passwordQuality.passwordMsgColor}>
                  {`${ApplicationString.NameAndPasswordSignUp.passwordStrength.title} ${passwordQuality.passwordQuality}`}
                </span>
              </div>
            )}
            <Divider className="mt-4" />
            <div className="mt-6">
              <Button
                variant="contained"
                className="capitalize rounded-full px-12 py-2.5 gap-4 text-white bg-primary"
                color="primary"
                data-testid="change-password-submit-button"
                type="submit"
              >
                {isChangingPassword && (
                  <CircularProgress size={20} className="text-white mr-2" />
                )}
                {allFieldsFilled
                  ? `${ApplicationString.changePasswordlabels.save}`
                  : `${ApplicationString.changePasswordlabels.changePassword}`}
              </Button>
            </div>
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordComponent;

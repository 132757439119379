import { ErrorOutlined } from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { ChangeEvent } from 'react';
import ApplicationString from '../../Constants/applicationString';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import BackgroundLayout from '../LoginComponents/BackgroundLayout';

interface IsignUpPhoneFormData {
  countryCode: string;
  phoneNumber: string;
}

interface IErrorsData {
  countryCode: {
    error: boolean;
    ValidationMessage: string;
  };
  phoneNumber: {
    error: boolean;
    ValidationMessage: string;
  };
}

interface IsignupWithPhoneComponentProps {
  countryCodeOptions: string[];
  onSignupPhoneSubmit: (e: React.FormEvent) => Promise<void>;
  errorsData: IErrorsData;
  formData: IsignUpPhoneFormData;
  isSigningUp: boolean;
  countryCodeChangeHandler: (e: SelectChangeEvent) => void;
  phoneNumberChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ForgotPasswordPhoneComponent: React.FC<
  IsignupWithPhoneComponentProps
> = ({
  countryCodeOptions,
  onSignupPhoneSubmit,
  errorsData,
  formData,
  isSigningUp,
  countryCodeChangeHandler,
  phoneNumberChangeHandler,
}) => {
  return (
    <BackgroundLayout>
      <div className="xsm:w-100 max-h-[100vh] bg-white border border-borderStroke dark:border-dark_borderStroke rounded-2xl dark:bg-dark_bg_primary shadow-1 shadow-[#8F9AB02E]">
        <div className="pt-5 px-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-primary_text dark:text-dark_primary_text">
              {ApplicationString.ForgotPasword.title}
            </h1>
            <p className="text-secondary_text font-normal text-base dark:text-dark_secondary_text">
              {ApplicationString.signUpWithPhone.title.subTitle}
            </p>
          </div>
          <div data-testid="form-fields-container " className=" w-full mt-8">
            <form onSubmit={onSignupPhoneSubmit}>
              <FormControl
                sx={{ width: '100%' }}
                size="small"
                className="flex flex-col gap-3"
                data-testid="loginPhoneForm"
              >
                <div>
                  <div className="flex gap-1">
                    <FormControl
                      className="w-20 sm:w-30"
                      error={errorsData.countryCode.error}
                    >
                      <InputLabel
                        data-testid="countryCodeLoginSelect"
                        className="text-base font-medium"
                      >
                        {ApplicationString.signUpWithPhone.label.code}
                      </InputLabel>
                      <Select
                        data-testid="countryCodeLoginSelect"
                        className="w-20 sm:w-30 rounded-4xl text-base font-medium"
                        label={ApplicationString.signUpWithPhone.label.code}
                        defaultValue=""
                        aria-describedby="countryCodeLoginPhoneSelect"
                        onChange={countryCodeChangeHandler}
                      >
                        {countryCodeOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {errorsData.countryCode.error && (
                    <FormHelperText className="text-error flex items-center gap-1">
                      <ErrorOutlined className="size-3.5" />
                      {errorsData.countryCode.ValidationMessage}
                    </FormHelperText>
                  )} */}
                    </FormControl>
                    <FormControl className="flex-1 border">
                      <TextField
                        label={ApplicationString.signUpWithPhone.label.phone}
                        className="rounded-4xl  text-base font-medium "
                        InputProps={{ className: 'rounded-4xl px-2 ' }}
                        value={formData.phoneNumber}
                        error={errorsData.phoneNumber.error}
                        onChange={phoneNumberChangeHandler}
                      />
                    </FormControl>
                  </div>
                  <div>
                    {errorsData.phoneNumber.error && (
                      <FormHelperText className="text-error flex items-center gap-1">
                        <ErrorOutlined className="size-3.5" />
                        <span>{errorsData.phoneNumber.ValidationMessage}</span>
                      </FormHelperText>
                    )}
                  </div>
                </div>

                <div className="my-6 flex flex-col gap-3">
                  {isSigningUp ? (
                    <LoadingButton
                      buttonText={
                        ApplicationString.ForgotPasword.button.verifying
                      }
                    />
                  ) : (
                    <Button
                      variant="contained"
                      className="w-full flex justify-center rounded-4xl normal-case text-base font-bold py-3"
                      color="primary"
                      type="submit"
                    >
                      {ApplicationString.ForgotPasword.button.continue}
                    </Button>
                  )}
                </div>
              </FormControl>
            </form>
          </div>
        </div>
      </div>
    </BackgroundLayout>
  );
};

export default ForgotPasswordPhoneComponent;

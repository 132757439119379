import React, { useContext, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../../Assets/Images/logo-min.png';
import LogoDarkTheme from '../../../Assets/logoWhite.png';
import cssUtils from '../../../Utils/cssUtils';
import {
  isLoggedIn,
  handleApiError,
  setLocalStorageInfo,
  removeLocalStorageItems,
} from '../../../Utils/utils';
import InternalRoute from '../../../Utils/internalRoutes';
import ApplicationString from '../../../Constants/applicationString';
import { ApiRequest } from '../../../Api/api';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import {
  localStorageEnums,
  settingslatestVersion,
  settingsVersion,
} from '../../../Utils/enums';
import URLS from '../../../Constants/urls';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import ColorModeContext from '../../../Utils/ColorModeContext';
import OwnerProfileMenuButton from '../OwnerProfileMenuButton';
import Navbar from './links';

type LinkType = {
  key: string;
  label: string;
  route?: string;
  children?: LinkType[];
};

const headerLink = ApplicationString.ownerHeaderLinks;

// Global user setting data

export const getSettingsData = async (
  setCountryCodeOptions: React.Dispatch<
    React.SetStateAction<string[]>
  > = () => []
): Promise<void> => {
  try {
    const res = await ApiRequest.get(`${URLS.GET_USER_SETTINGS}`);
    const data: IUserSettingsData =
      settingsVersion === settingslatestVersion
        ? res.data[0]
        : res.data.filter(
            (item: IUserSettingsData) =>
              Number(item.version) === Number(settingsVersion)
          )[0];
    if (setCountryCodeOptions) {
      setCountryCodeOptions(
        data.setting.country.map((item) => item.countryCode)
      );
    }
    if (
      res?.status === HttpStatusCode.Ok &&
      data &&
      JSON.stringify(data) !== '{}'
    ) {
      setLocalStorageInfo(data, localStorageEnums.settings);
    }
  } catch (error) {
    const axiosError = error as AxiosError<IApiErrorResponse>;

    handleApiError(axiosError);
  }
};
const links: LinkType[] = [
  {
    key: headerLink.Dashboard.key,
    label: headerLink.Dashboard.label,
    route: InternalRoute.dashboard,
  },
  {
    key: headerLink.Calendar.key,
    label: headerLink.Calendar.label,
    route: InternalRoute.OwnerMyCalendar,
  },
  {
    key: headerLink.MyListings.key,
    label: headerLink.MyListings.label,
    route: InternalRoute.my_listings,
  },
  {
    key: headerLink.ContactUs.key,
    label: headerLink.ContactUs.label,
    route: InternalRoute.OwnerContact,
  },
  {
    key: headerLink.CreateListing.key,
    label: headerLink.CreateListing.label,
    route: InternalRoute.vehicleCreate,
  },
  {
    key: headerLink.FAQs.key,
    label: headerLink.FAQs.label,
    route: InternalRoute.ownerFAQ,
  },
];

const OwnerHeader: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentTheme } = useContext(ColorModeContext);

  const Navigate = useNavigate();
  const location = useLocation();
  const isUserLoggedIn = isLoggedIn();

  const handleMobileMenu = (route: string) => {
    removeLocalStorageItems([
      localStorageEnums.skippedSteps,
      localStorageEnums.step,
      localStorageEnums.stepsData,
    ]);
    Navigate(route);
    setMobileMenuOpen(false);
  };
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const handleAccordionToggle = (key: string) => {
    setOpenKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };

  const filteredLinks = isUserLoggedIn
    ? links.filter((link) => link.key !== headerLink.CreateListing.key)
    : links.filter(
        (link) =>
          link.key === headerLink.FAQs.key ||
          link.key === headerLink.CreateListing.key
      );

  const isActive = (route: string): boolean => {
    return location.pathname === route;
  };

  const renderLinks = (menulinks: LinkType[]) => {
    return menulinks.map((link) => {
      const isOpen = openKeys.includes(link.key);
      return (
        <li
          className={`${link.children ? 'flex gap-2' : 'mt-0'}`}
          key={link.key}
          data-testid={`link-${link.key}`}
        >
          {link.children ? (
            <div className="border-gray-200 w-full">
              <button
                type="button"
                className={`${isOpen ? 'bg-slate-200 dark:bg-dark_bg_secondary' : ''} hover:bg-slate-200 dark:hover:bg-dark_bg_secondary cursor-pointer rounded-sm w-full py-2 px-4 flex justify-between`}
                onClick={() => handleAccordionToggle(link.key)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleAccordionToggle(link.key);
                  }
                }}
                tabIndex={0}
                aria-expanded={openKeys.includes(link.key)}
                data-testid={`button-link-${link.key}`}
              >
                <span>{link.label}</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className={`w-4 h-4 transition-transform duration-200 transform ${
                    openKeys.includes(link.key) ? 'rotate-180' : 'rotate-0'
                  }`}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {openKeys.includes(link.key) && (
                <ul className="mt-2 flex gap-2 flex-col mx-4">
                  {renderLinks(link.children)}
                </ul>
              )}
            </div>
          ) : (
            <Link
              to={link.route || '#'}
              onClick={() => setMobileMenuOpen(false)}
              className={`block py-2 px-4 hover:bg-slate-200 ${isActive(link.route || '') ? 'font-bold' : ''}`}
              data-testid={`link-mobileMenu-${link.key}`}
            >
              {link.label}
            </Link>
          )}
        </li>
      );
    });
  };
  const renderButtons = () => {
    if (isUserLoggedIn) {
      return (
        <>
          {!location.pathname.includes(InternalRoute.vehicleCreate) && (
            <button
              type="button"
              onClick={() => handleMobileMenu(InternalRoute.vehicleCreate)}
              data-testid="button-action-signup"
              className={`flex gap-2 justify-between items-center lg:px-3 2xl:px-5 ${cssUtils.button.secondary}`}
            >
              {ApplicationString.header.button.createListing}
            </button>
          )}
          <OwnerProfileMenuButton handleMobileMenu={handleMobileMenu} />
        </>
      );
    }

    if (
      location.pathname === InternalRoute.EmailLogin ||
      location.pathname === InternalRoute.PhoneLogin
    ) {
      return (
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Signup)}
          data-testid="button-action-signup"
          className={cssUtils.button.primary}
        >
          {ApplicationString.header.button.signup}
        </button>
      );
    }

    const signUpRoutes = [
      InternalRoute.EmailSignup,
      InternalRoute.PhoneSignup,
      InternalRoute.EmailVerificationCode,
      InternalRoute.PhoneVerificationCode,
      InternalRoute.SignupEmailVerified,
      InternalRoute.SignupPhoneVerified,
      InternalRoute.SignUpNameAndPassword,
      InternalRoute.AccountCreated,
    ];
    if (signUpRoutes.includes(location.pathname)) {
      return (
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Login)}
          data-testid="button-action-login"
          className={cssUtils.button.secondary}
        >
          {ApplicationString.header.button.login}
        </button>
      );
    }

    return (
      <>
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Signup)}
          data-testid="button-action-signup"
          className={cssUtils.button.primary}
        >
          {ApplicationString.header.button.signup}
        </button>
        <button
          type="button"
          onClick={() => handleMobileMenu(InternalRoute.Login)}
          data-testid="button-action-login"
          className={cssUtils.button.secondary}
        >
          {ApplicationString.header.button.login}
        </button>
      </>
    );
  };

  // Get user settings data on header mount
  React.useEffect(() => {
    getSettingsData();
  }, []);

  return (
    <header className="bg-white dark:bg-dark_bg_secondary fixed top-0 w-full left-0 z-30">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 2xl:px-0 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1/2">
          <Link
            to={InternalRoute.dashboard}
            className="-m-1.5 p-1.5"
            data-testid="header-link-common"
          >
            <img
              className="h-8 w-auto"
              src={currentTheme === 'dark' ? LogoDarkTheme : Logo}
              alt="Logo"
              data-testid="header-image-common"
            />
          </Link>
        </div>
        <div className="hidden lg:block">
          <Navbar links={filteredLinks} />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
            data-testid="mobile-menu-open"
            aria-label="mobile-menu-open"
          >
            <Bars3Icon className="h-6 w-6 dark:text-white" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1/2 lg:justify-end gap-5">
          {renderButtons()}
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        data-testid="header-dialog-common"
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto dark:bg-dark_bg_primary bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex mb-10 items-center justify-between">
            <Link
              to={InternalRoute.Home}
              className="-m-1.5 p-1.5"
              data-testid="header-linkToHome-common"
            >
              <img
                className="h-8 w-auto"
                src={currentTheme === 'dark' ? LogoDarkTheme : Logo}
                alt="Logo"
                data-testid="header-imageForHeader-common"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5  text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
              data-testid="mobile-menu-close"
              aria-label="mobile-menu-close"
            >
              <XMarkIcon
                className="h-6 w-6 dark:text-white"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className=" flow-root">
            <div className="divide-gray-500/10 flex flex-col gap-4">
              <ul className=" flex-col flex gap-2 dark:text-white">
                {renderLinks(filteredLinks)}
              </ul>
              <div className="flex-col flex gap-5">{renderButtons()}</div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default OwnerHeader;

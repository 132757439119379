/* eslint-disable react/jsx-props-no-spreading */
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { IStepperLabel, ISteps } from '../../../Interfaces/Stepper';
import './styles.css';
import { StepperComponentSuccessSvg } from '../../../Assets/Svgs';
import { BooleanEnums, localStorageEnums } from '../../../Utils/enums';
import ApplicationString from '../../../Constants/applicationString';
import { getDetailsFromLocalStorage } from '../../../Utils/utils';

export const stepperLabelEnum = {
  Finished: ApplicationString.vehicleStepper.stepperLabel.finished,
  Inprogress: ApplicationString.vehicleStepper.stepperLabel.inprogress,
  Pending: ApplicationString.vehicleStepper.stepperLabel.pending,
};
interface StepperCardProps {
  activeStep: number;
  steps: ISteps[];
  stepLabels: IStepperLabel[];
  stepComplete: boolean;
  isEdit: boolean;
  showVerified: boolean;
}

const StepperCard: React.FC<StepperCardProps> = ({
  steps,
  activeStep,
  stepLabels,
  stepComplete,
  isEdit,
  showVerified,
}) => {
  const stepProps = useMemo(() => {
    return {
      completed: BooleanEnums.false,
    };
  }, []);

  const labelProps: {
    optional?: React.ReactNode;
  } = {};
  const localStoredSkippedSteps = getDetailsFromLocalStorage<number[]>(
    localStorageEnums.skippedSteps
  );

  const skippedSteps = useMemo(
    () => localStoredSkippedSteps || [],
    [localStoredSkippedSteps]
  );
  const isStepComplete =
    stepLabels?.find((obj) => obj.currentStep === activeStep)?.label ===
    stepperLabelEnum.Finished;
  const stepValue = activeStep + 1;

  return (
    <div className="w-full py-9 lg:py-8 bg-white lg:rounded-2xl dark:bg-dark_bg_secondary px-0 xsm:px-3 shadow-lg">
      <h1 className="text-base sm:text-2xl 2xl:text-3xl font-bold text-black pb-4 hidden p-0 md:pl-4 lg:flex dark:text-white">
        {activeStep === 0 &&
          !showVerified &&
          (isEdit
            ? ApplicationString.vehicleStepper.stepperInfo.editListing
            : ApplicationString.vehicleStepper.stepperInfo.startListing)}
        {!showVerified &&
          ApplicationString.vehicleStepper.stepperInfo.stepLabel + stepValue}
      </h1>
      <Stepper
        activeStep={activeStep}
        className="w-full flex justify-center items-center"
        sx={{
          '& .MuiStepIcon-root': {
            width: '2rem !important',
            height: '2rem !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media screen and (min-width: 786px)': {
              width: '2.2rem !important',
              height: '2.2rem !important',
            },
          },
          '& .MuiStepLabel-iconContainer': {
            paddingLeft: '2px !important',
            paddingRight: '2px !important',
            '@media screen and (min-width: 786px)': {
              paddingLeft: '4px !important',
              paddingRight: '4px !important',
            },
            '@media screen and (min-width: 1560px)': {
              paddingLeft: '3px !important',
              paddingRight: '3px !important',
            },
          },
          '& .MuiStep-root': {
            paddingLeft: '2px !important',
            paddingRight: '2px !important',
            '@media screen and (min-width: 786px)': {
              paddingLeft: '4px !important',
              paddingRight: '4px !important',
            },
            '@media screen and (min-width: 1440px)': {
              paddingLeft: '8px !important',
              paddingRight: '8px !important',
            },
          },
          '& .MuiStepIcon-text': {
            fontSize: '1rem !important',
            '@media screen and (min-width: 786px)': {
              fontSize: '0.8rem !important',
            },
          },
        }}
      >
        {steps?.map((item, index) => {
          if (isEdit) {
            stepProps.completed = BooleanEnums.true;
          } else if (skippedSteps.includes(index)) {
            stepProps.completed = BooleanEnums.false;
          } else if (index < activeStep) {
            if (stepLabels[index]?.label === stepperLabelEnum.Finished) {
              stepProps.completed = BooleanEnums.true;
            } else if (isStepComplete) {
              stepProps.completed = BooleanEnums.true;
            } else {
              stepProps.completed = BooleanEnums.false;
            }
          } else if (stepComplete) {
            stepProps.completed = BooleanEnums.true;
          } else {
            stepProps.completed = BooleanEnums.false;
          }
          let label;
          if (stepLabels[index]?.label === stepperLabelEnum.Finished) {
            label = stepperLabelEnum.Finished;
          } else if (index === activeStep) {
            label = stepperLabelEnum.Inprogress;
          } else {
            label = stepLabels[index]?.label;
          }
          if (stepLabels) {
            labelProps.optional = (
              <Typography variant="caption">
                <span className="hidden xl:flex text-xs">{label}</span>
              </Typography>
            );
          }
          return (
            <Step key={item.key} {...stepProps}>
              <StepLabel
                {...labelProps}
                icon={stepProps.completed && <StepperComponentSuccessSvg />}
              >
                <span className="hidden lg:hidden xl:flex text-sm lg:text-base">
                  {item?.label}
                </span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperCard;

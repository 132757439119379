import React, { useEffect } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import HomeComponent from '../../Components/Home';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import { showApiError } from '../../Utils/utils';
import { useToast } from '../../Services/ToastService';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';

const HomeContainer: React.FC = () => {
  const [popularListing, setPopularListing] = React.useState<IVehiclesData[]>(
    []
  );
  const [locationData, setLocationData] = React.useState([]);
  const toast = useToast();
  const DefaultPageSize = 100;
  const DefaultPopularListingValue = true;

  const fetchAllPopularListing = async () => {
    try {
      const response = await ApiRequest.get(
        `${URLS.SEARCH_QUERY}?pageSize=${DefaultPageSize}&isPopular
=${DefaultPopularListingValue}`
      );
      setPopularListing(response.data.data);
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showApiError(axiosError, toast);
    }
  };

  const getVehiclesByLocation = async (coords: string) => {
    try {
      const response = await ApiRequest.get(
        `${URLS.SEARCH_QUERY}?pageSize=${DefaultPageSize}&latlong=${coords}`
      );

      if (response.status === HttpStatusCode.Ok) {
        setLocationData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = `${position.coords.latitude},${position.coords.longitude}`;
          if (position) {
            getVehiclesByLocation(location);
          }
        },
        (err) => {
          console.error(err.message);
        }
      );
    }
  };
  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAllPopularListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeComponent
      popularListings={popularListing}
      locationData={locationData}
    />
  );
};

export default HomeContainer;

import React, { useState } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import boatIcon from '../../../Assets/ph_boat.png';
import ApplicationString from '../../../Constants/applicationString';

type Category = {
  label: string;
  value: string;
};

type SelectionComponentProps = {
  options: Category[];
  label: string;
  categorySelected: string;
  onSelect: (value: Category) => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const SelectionComponent: React.FC<SelectionComponentProps> = ({
  options,
  label,
  categorySelected,
  onSelect,
}) => {
  const getLabelFromValue = (value: string): string => {
    const category = ApplicationString.categoryOptions.find(
      (option) => option.value === value
    );
    return category ? category.label : '';
  };
  const OptionSelected = {
    label: getLabelFromValue(categorySelected),
    value: categorySelected,
  };
  const [select, setSelected] = useState<Category>(OptionSelected);
  const handleChange = (category: Category) => {
    setSelected(category);
    onSelect(category);
  };

  return (
    <Listbox value={select} onChange={handleChange}>
      {({ open }) => (
        <div className="flex w-full flex-col">
          <div className="flex  text-slate-500 items-center space-x-2 mb-0.5">
            <img className="" src={boatIcon} alt="" />
            <Label className="text-sm font-medium dark:text-dark_text_sub_primary text-gray-700">
              {label}
            </Label>
          </div>
          <div className="3xsm:relative 2xl:static mt-2 ">
            <ListboxButton
              data-testid="search-select"
              className=" 2xsm:relative 2xl:static cursor-pointer w-full dark:bg-dark_bg_secondary rounded-md bg-white py-1.5 pr-10 text-left text-title_text dark:ring-0 ring-1 ring-inset ring-white focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span className="flex items-center">
                <span className="text-lg md:text-2xl font-semibold flex items-center block truncate dark:text-dart_web_accent">
                  {select.label}
                  <FontAwesomeIcon
                    icon={!open ? faChevronDown : faChevronUp}
                    className="text-gray-500 text-sm ml-2 dark:text-dark_text_sub_primary"
                    data-testid="search-select-icon"
                  />
                </span>
              </span>
            </ListboxButton>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              data-testid="search-select-dropdown"
            >
              <ListboxOptions className="absolute 2xl:rounded-tl-none 2xl:rounded-tr-none z-10 xl:-left-7  2xl:left-0 3xsm:w-full 2xl:mt-2 2xl:w-85 max-h-56 rounded-2xl dark:bg-dark_bg_secondary bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((category) => (
                  <ListboxOption
                    key={category.value}
                    data-testid={category.value}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-slate-100 dark:bg-dark_bg_surface text-black dark:text-dart_web_accent'
                          : 'text-slate-900 dark:text-dart_web_accent',
                        'relative cursor-pointer rounded-2xl  select-none py-2 mx-1 pl-3 pr-9'
                      )
                    }
                    value={category}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {category.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active
                                ? 'text-slate-600 '
                                : 'text-slate-600 dark:text-dart_web_accent',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SelectionComponent;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { commonConfig } from '../Constants/commonConfig';
import { addressComponentsEnums } from '../Utils/enums';

interface AddressComponent {
  types: string[];
  long_name: string;
}
function getAddressComponents(components: AddressComponent[], type: string) {
  const component = components.find((comp) => comp.types.includes(type));
  return component ? component.long_name : '';
}

export const getLatLongFromAddress = async (
  address: string,
  section: string
) => {
  const url = `${commonConfig.GooglePlaceApi}/geocode/json`;
  try {
    const response = await fetch(
      `${url}?address=${address}&key=${commonConfig.GooglePlaceApiKey}`
    );
    const data = await response.json();
    if (data.status === 'OK' && data.results.length > 0) {
      if (section === 'stepperInput') {
        const details = {
          latLong: `${data.results[0].geometry.location.lat},${data.results[0].geometry.location.lng}`,
          dropLatLong: `${data.results[0].geometry.location.lat},${data.results[0].geometry.location.lng}`,
        };
        return details;
      }
      if (section === 'searchInput') {
        const latLong = `${data.results[0].geometry.location.lat},${data.results[0].geometry.location.lng}`;

        return latLong;
      }
    }
    return null;
  } catch (error) {
    console.error('Error during geocoding:', error);
    return null;
  }
};

export async function getLatLonByPlaceId(placeId: string, fields: string) {
  const url = `${commonConfig.GooglePlaceApi}/place/details/json?placeid=${placeId}&fields=${fields}&key=${commonConfig.GooglePlaceApiKey}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    const addressComponents = data.result.address_components;

    if (fields === 'geometry') {
      const latLong = `${data.result.geometry.location.lat},${data.result.geometry.location.lng}`;
      return latLong;
    }
    if (fields === 'address_component,geometry') {
      const details = {
        latLon: `${data.result.geometry.location.lat},${data.result.geometry.location.lng}`,
        city: getAddressComponents(
          addressComponents,
          addressComponentsEnums.locality
        ),
        country: getAddressComponents(
          addressComponents,
          addressComponentsEnums.country
        ),
        zipCode: getAddressComponents(
          addressComponents,
          addressComponentsEnums.zipCode
        ),
        state: getAddressComponents(
          addressComponents,
          addressComponentsEnums.state
        ),
      };
      return details;
    }
    return null;
  } catch (error) {
    console.error('Error fetching latitude and longitude:', error);
    return null;
  }
}

export async function getPlaceSuggestions(input: string) {
  const url = `${commonConfig.GooglePlaceApi}/place/autocomplete/json?input=${encodeURIComponent(input)}&key=${commonConfig.GooglePlaceApiKey}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.predictions;
  } catch (error) {
    console.error('Error fetching place suggestions:', error);
    return [];
  }
}

import React from 'react';
import ApplicationString from '../../Constants/applicationString';
import cssUtils from '../../Utils/cssUtils';
import { UsdCurrency } from '../../Utils/utils';
import LoadingButton from '../common/LoadingButton/LoadingButton';

interface PaymentCardProps {
  imageUrl: string;
  title: string;
  rating: number;
  // eslint-disable-next-line react/require-default-props
  // reviewsCount?: number;
  dailyRate: number;
  days: number;
  securityDeposit: number;
  taxes: number;
  total: number;
  hr: number;
  calculatedRate: number;
  handleConfirmAndPay: () => void;
  isSubmitting: boolean;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  imageUrl,
  title,
  rating,
  hr,
  // reviewsCount,
  dailyRate,
  days,
  securityDeposit,
  taxes,
  total,
  calculatedRate,
  handleConfirmAndPay,
  isSubmitting,
}) => {
  const renderRateInfo = (day: number, hrs: number, dailyRates: number) => {
    switch (true) {
      case day > 0:
        return (
          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-daily-rate"
          >
            {UsdCurrency(dailyRates)} ({day}{' '}
            {day > 1
              ? `${ApplicationString.bookingDetailsData.days}s`
              : ApplicationString.bookingDetailsData.days}
            )
          </span>
        );
      case hrs > 0:
        return (
          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-daily-rate"
          >
            {UsdCurrency(dailyRates)} ({hrs}{' '}
            {hrs > 1
              ? `${ApplicationString.bookingDetailsData.hr}s`
              : ApplicationString.bookingDetailsData.hr}
            )
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="2xl:max-w-xl mx-auto w-full dark:border dark:border-dark_borderStroke bg-white dark:bg-dark_bg_secondary shadow-lg rounded-2xl overflow-hidden"
      data-testid="payment-card"
    >
      <div className="flex w-full items-center p-4">
        <img
          className="w-16 h-16 rounded-xl object-cover"
          src={imageUrl}
          alt={title}
          data-testid="payment-card-image"
        />
        <div className="ml-4">
          <h2
            className="text-lg md:text-2xl dark:text-dart_web_accent text-title_text font-semibold"
            data-testid="payment-card-title"
          >
            {title}
          </h2>
          {rating > 0 && (
            <div className="flex items-center">
              <span
                className="ml-1 dark:text-white"
                data-testid="payment-card-rating"
              >
                {rating}
              </span>
              <span className="text-yellow-500">★</span>
            </div>
          )}
        </div>
      </div>
      <div className="p-4 dark:text-white">
        <div className="px-4 dark:border-dark_borderStroke pb-4 border-t border-gray-200" />
        <h3
          className="text-gray-700 dark:text-white font-semibold mb-2"
          data-testid="payment-details-header"
        >
          {ApplicationString.bookingDetailsData.paymentDetails}
        </h3>
        <div className="flex dark:text-white justify-between mb-2">
          {renderRateInfo(days, hr, dailyRate)}

          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-total-daily"
          >
            {UsdCurrency(calculatedRate)}
          </span>
        </div>
        <div className="flex dark:text-white justify-between mb-2">
          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-security-deposit"
          >
            {ApplicationString.bookingDetailsData.securityDeposit}
          </span>
          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-security-deposit-value"
          >
            {UsdCurrency(securityDeposit)}
          </span>
        </div>
        <div className="flex justify-between mb-4">
          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-taxes"
          >
            {ApplicationString.bookingDetailsData.taxes}
          </span>
          <span
            className="text-gray-700 dark:text-white"
            data-testid="payment-card-taxes-value"
          >
            {UsdCurrency(taxes)}
          </span>
        </div>
        <div className="border-t dark:border-dark_borderStroke border-gray-200 mb-4" />
        <div className="flex justify-between font-bold text-gray-900 dark:text-white">
          <span data-testid="payment-card-total-label">
            {ApplicationString.bookingDetailsData.total}
          </span>
          <span data-testid="payment-card-total-value">
            {UsdCurrency(total)}
          </span>
        </div>
      </div>
      <div className="px-4 pb-4">
        {isSubmitting ? (
          <LoadingButton
            buttonText={ApplicationString.contactUs.buttons.submitting}
          />
        ) : (
          <button
            type="button"
            className={`w-full ${cssUtils.button.secondary}`}
            onClick={handleConfirmAndPay}
            data-testid="confirmPay-bookingDetails-button"
          >
            {ApplicationString.bookingDetailsData.confirmAndPay}
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentCard;

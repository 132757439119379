/* eslint-disable react/require-default-props */
import React from 'react';
import arrwLeft from '../../../Assets/Svgs/ArrowLeft.png';
import arrwRight from '../../../Assets/Svgs/ArrowRight.png';

interface NextPreviousComponentProps {
  // eslint-disable-next-line react/no-unused-prop-types
  currentPage?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  totalPages?: number;
  onNext: () => void;
  onPrevious: () => void;
}

const NextPreviousComponent: React.FC<NextPreviousComponentProps> = ({
  // currentPage,
  // totalPages,
  onNext,
  onPrevious,
}) => {
  // const disabledNext = currentPage === 1;
  // const disabledPrevious = currentPage === totalPages;
  return (
    <div className="flex gap-2 mt-2 sm:mt-0 justify-between">
      <button
        type="button"
        data-testid="infinite-listing-previous-button"
        className="w-fit   p-2 bg-white border border-slate-300 text-black rounded-full"
        onClick={onPrevious}
        // disabled={disabledNext}
      >
        <img src={arrwLeft} alt="arrow-left" />
      </button>
      <button
        type="button"
        data-testid="infinite-listing-next-button"
        className="w-fit  p-2 bg-white border  border-slate-300 text-black rounded-full"
        onClick={onNext}
        // disabled={disabledPrevious}
      >
        <img src={arrwRight} alt="arrow-right" />
      </button>
    </div>
  );
};

export default NextPreviousComponent;

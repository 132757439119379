import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionHeading from '../../common/SectionHeading';
import pinIcon from '../../../Assets/Location.png';
import aminites from '../../../Assets/autobrightness.png';
import starIcon from '../../../Assets/star.png';
import boat from '../../../Assets/ph_boat.png';
import ApplicationString from '../../../Constants/applicationString';
import cssUtils from '../../../Utils/cssUtils';
import NextPreviousComponent from '../../common/NextPreviousComopnent';
import usePageSize from '../../../Hooks/usePageSize';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';
import {
  checkIfArrayIsTruthyOrNot,
  getCoverPhoto,
  getLocalStorageAccountInfo,
  getSubcategoryNameById,
} from '../../../Utils/utils';
import {
  IUserSettingsData,
  IVehicleCategory,
} from '../../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums } from '../../../Utils/enums';
import InternalRoute from '../../../Utils/internalRoutes';
import { minPopularListingCount } from '../../../Constants/commonConst';

interface ListingNearMeProps {
  listingData: IVehiclesData[];
}

const ListingNearMeComponent: React.FC<ListingNearMeProps> = ({
  listingData,
}) => {
  const { heading } = ApplicationString.listingNearMe;
  const pageSize = usePageSize();
  const [sortedListingData, setSortedListingData] = useState<IVehiclesData[]>(
    []
  );
  const navigate = useNavigate();
  useEffect(() => {
    setSortedListingData(listingData);
  }, [listingData]);

  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;

  const categoriesData: IVehicleCategory[] = checkIfArrayIsTruthyOrNot<
    IVehicleCategory[]
  >(userSettingsData?.vehicleCategory);

  const allSubCategories = categoriesData.flatMap(
    (category) => category.subCategory
  );
  const hideNextAndPrevious = sortedListingData.length > minPopularListingCount;
  const getCategoryName = (categoryId: number): string | undefined => {
    const categoryValue = categoriesData.find(
      (category) => category.id === categoryId
    );
    return categoryValue?.name;
  };
  const handleNext = () => {
    setSortedListingData((prevData) => {
      const newData = [...prevData];
      const firstItem = newData.shift();
      if (firstItem) {
        newData.push(firstItem);
      }
      return newData;
    });
  };

  const handlePrevious = () => {
    setSortedListingData((prevData) => {
      const newData = [...prevData];
      const lastItem = newData.pop();
      if (lastItem) {
        newData.unshift(lastItem);
      }
      return newData;
    });
  };

  const getCurrentCards = () => {
    const cardsPerPage = pageSize;
    return sortedListingData.slice(0, cardsPerPage);
  };

  return (
    <div className="w-full bg-blue-50 dark:bg-dark_bg_gray_primary">
      <div className={cssUtils.layout.sectionLayout}>
        <div className="flex items-center flex-col sm:flex-row justify-between">
          <SectionHeading
            title={heading.title}
            description={' '}
            darkTheme={false}
            descTextColor={cssUtils.colors.slate}
          />
          {hideNextAndPrevious && (
            <NextPreviousComponent
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4 justify-center mt-5">
          {getCurrentCards().map((listing) => {
            let bookingRate;
            if (
              listing.bookingRateHourly &&
              listing.bookingSlotType === '24hourly'
            ) {
              bookingRate = `${listing.bookingRate24Hourly} ${ApplicationString.exploreScreen.bookingValues.twentyFour_hourly}`;
            } else if (
              listing.bookingRate12Hourly &&
              listing.bookingSlotType === '12hourly'
            ) {
              bookingRate = `${listing.bookingRate12Hourly} ${ApplicationString.exploreScreen.bookingValues.twelve_hourly}`;
            } else {
              bookingRate = `${listing.bookingRateHourly} ${ApplicationString.exploreScreen.bookingValues.hourly}`;
            }
            const [amount, ...rest] = bookingRate.split(' ');
            const text = rest.join(' ');

            const formattedRating = listing.rating
              ? listing.rating.toFixed(1)
              : '';

            const subCategoryName = getSubcategoryNameById(
              allSubCategories,
              Number(listing.subCategory)
            );
            const categoryName = getCategoryName(listing.category);
            const coverPhoto = getCoverPhoto(listing.images);
            const amenitiesCount = listing.metaData.amenities
              ? listing.metaData.amenities.length
              : 0;
            return (
              <div
                role="button"
                tabIndex={0}
                key={listing.id}
                onClick={() => {
                  const path = InternalRoute.Vehicle_details.replace(
                    ':VehicleId',
                    listing.id.toString()
                  );
                  navigate(path);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    const path = InternalRoute.Vehicle_details.replace(
                      ':VehicleId',
                      listing.id.toString()
                    );
                    navigate(path);
                  }
                }}
                className="border dark:bg-dark_bg_surface bg-white border-slate-200 shadow-lg rounded-2xl transition-transform duration-300 transform cursor-pointer hover:scale-105"
              >
                <div className="relative">
                  {coverPhoto ? (
                    <img
                      src={coverPhoto}
                      alt={listing.title}
                      className="h-45 w-full min-w-30 2xl:min-30 rounded-2xl rounded-b-none object-cover"
                    />
                  ) : (
                    <div className="h-45 w-full min-w-30 bg-gray-300 rounded-2xl flex justify-center items-center text-2xl">
                      {ApplicationString.commonStrings.noImageFound}
                    </div>
                  )}
                  <div className="flex gap-2 items-center absolute bottom-2 text-sm font-medium text-slate-600 right-2 p-1 px-3 shadow-xl border  dark:bg-dark_bg_surface dark:border-dark_borderStroke dark:text-dark_text_sub_primary border-slate-50 bg-white rounded-3xl">
                    <div>
                      <img className="" src={boat} alt="Boat icon" />
                    </div>
                    {categoryName}
                  </div>
                </div>

                <div className="flex flex-col gap-2 sm:gap-4 p-4 sm:py-4">
                  <p className="font-semibold text-xl leading-tight text-blue-950 dark:text-dart_web_accent">
                    {subCategoryName}
                  </p>
                  <div className="flex gap-1 items-center">
                    <img className="" src={pinIcon} alt="Location icon" />
                    <p className="font-sans font-normal text-slate-500 dark:text-dark_text_sub_primary text-base leading-tight">
                      {listing.city}
                    </p>
                  </div>
                  <div className="flex gap-1 items-center">
                    <img className="" src={aminites} alt="Amenities icon" />
                    <p className="font-sans font-normal text-slate-500 dark:text-dark_text_sub_primary text-base leading-tight">
                      {amenitiesCount > 10
                        ? `10+ ${ApplicationString.exploreScreen.exploreLabels.amenitiesCount}`
                        : `${amenitiesCount} ${ApplicationString.exploreScreen.exploreLabels.amenitiesCount}`}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    {listing.rating && (
                      <div className="flex gap-1 items-center">
                        <img className="" src={starIcon} alt="" />
                        <p className="font-sans font-normal text-secondary_text dark:text-dark_text_sub_primary text-base leading-tight">
                          {`${formattedRating}/5.0`}
                        </p>
                      </div>
                    )}
                    <div className="flex gap-1">
                      <span className="font-semibold dark:text-dart_web_accent text">
                        ${amount}
                      </span>
                      <span className="text-slate-500 dark:text-dark_text_sub_primary ">
                        {text}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListingNearMeComponent;

import { Dispatch } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import ApplicationString from '../Constants/applicationString';
import { IApiErrorResponse, IErrorObject } from '../Interfaces/interfaces';
import { ToastContextType } from '../Services/ToastService';
import { localStorageEnums, navigationKeys, timeFrameData } from './enums';
import InternalRoute from './internalRoutes';
import {
  The12HourlyTimeFrame,
  UserSettingsData,
} from '../Interfaces/LocalStorageSettings';
// eslint-disable-next-line import/no-cycle
import {
  BookingDetails,
  BookingParams,
  DateRange,
  VehicleDetails,
} from '../Components/BookingDetails/bookingInterface';
import {
  HOURS_IN_DAY,
  HOURS_IN_TWELVE,
  dateFormat,
} from '../Constants/commonConst';
import { ISubCategory } from '../Interfaces/Settings/SettingsInterfaces';

// this function will return true if value is empty,undefine,length zero, object null other wise false
export const isNullOrEmpty = (value: unknown): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

export const isJson = (jsonstring: string): boolean => {
  try {
    JSON.parse(jsonstring);
    return true;
  } catch (error) {
    return false;
  }
};

export const getMaxCharacterRegex = (maxLength: number): RegExp => {
  return new RegExp(`^.{1,${maxLength}}$`);
};
export const getDetailsFromLocalStorage = <T>(key: string): T | null => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

export const getDetailsFromLocalStorageForString = (
  key: string
): string | null => {
  const storedValue = localStorage.getItem(key);
  return storedValue;
};
export const setLocalStorageInfo = <T>(
  data: T,
  localStorageKey: string
): void => {
  localStorage.setItem(localStorageKey, JSON.stringify(data));
};
export const setLocalStorageInfoForString = (
  data: string,
  localStorageKey: string
): void => {
  localStorage.setItem(localStorageKey, data);
};

export const removeLocalStorageItems = (localStorageKeys: string[]): void => {
  localStorageKeys.forEach((key) => localStorage.removeItem(key));
};

export const removeLocalStorageAccountInfoOnDeleteOrLogout = (
  navigate: NavigateFunction,
  key: string
): void => {
  const localStorageKeys = [
    localStorageEnums.userInfo,
    localStorageEnums.skippedSteps,
    localStorageEnums.step,
    localStorageEnums.stepsData,
  ];

  if (navigate && key === navigationKeys.delete) {
    removeLocalStorageItems(localStorageKeys);
    navigate(InternalRoute.EmailSignup);
  }

  if (navigate && key === navigationKeys.logout) {
    removeLocalStorageItems(localStorageKeys);
    navigate(InternalRoute.EmailLogin);
  }
};

// export const getLocalStorageUserId = ():string | null => {
//   return localStorage.getItem("userID");
// };

// export const getLocalStorageUserRole = ():string | null => {
//   return localStorage.getItem("Role");
// };
export const checkIfArrayIsTruthyOrNot = <T>(
  data: T | undefined | []
): T | [] => {
  if (data && Array.isArray(data) && data.length > 0) {
    return data;
  }
  return [];
};

export const createSelectOptions = <
  T extends object,
  I extends Array<T>,
  V extends keyof T,
  L extends keyof T,
>(
  itemList: I,
  value: V,
  label: L
): { value: T[V]; label: string }[] => {
  const options = itemList?.map((item) => ({
    value: item[value],
    label: `${item[label]}`,
  }));
  return options;
};
export const PAGE_SIZE_LARGE = 3;
export const PAGE_SIZE = 4;
export const PAGE_SIZE_SMALL = 1;
export const PAGE_SIZE_MID = 2;

export const userRoleEnums = {
  OWNER_WITHOUT_LOGIN: 'owner_without_login',
  MASTER: 'master',
  ADMIN: 'admin',
  OWNER: 'owner',
  USER: 'user',
  SUPER_ADMIN: 'superAdmin',
};
export const buildQueryString = (searchValues: object): string => {
  // Convert the modified search values object into an array of key-value pairs
  const searchQuery = Object.entries(searchValues || '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return searchQuery;
};
/**
 * Converts a sorting order string to a common sorting order string.
 *
 * @param {string} sortOrder - The sorting order string to be converted.
 * @return {string} The converted sorting order string. If the input is 'ascend', it returns 'asc'. If the input is 'descend', it returns 'desc'. Otherwise, it returns an empty string.
 */
export const commonSortingOrder = (sortOrder: string): string => {
  let resultSortOrder = '';
  if (sortOrder === 'ascend') {
    resultSortOrder = 'asc';
  } else if (sortOrder === 'descend') {
    resultSortOrder = 'desc';
  }
  return resultSortOrder;
};
/**
 * An array of page size options.
 */

export const PageSizeArray = [10, 50, 100];

/**
 * An object containing default pagination values.
 */
export const DefaultPaginationValues = {
  PAGE_SIZE: 10,
  PAGE: 1,
  TOTAL_PAGES: 1,
};

export const DefaultPaginationValueMyBookings = {
  PAGE_SIZE: 12,
  PAGE: 1,
  TOTAL_PAGES: 1,
};

/**
 * Formats a given price as a USD currency string.
 *
 * @param {number} price - The price to be formatted.
 * @return {string} The formatted price as a USD currency string.
 */
export const UsdCurrency = (price: number): string => {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
  return formattedPrice;
};
/**
 * Formats a given date into a string representation of the date in the format "Month Day, Year".
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted date string.
 */
export const DateFormat = (date: Date): string => {
  const dateString = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return dateString;
};
/**
 * Formats a given list of items into an array of objects with 'value' and 'label' properties.
 *
 * @param {ListItems[]} list - The list of items to be formatted.
 * @return {Array<{ value: string; label: string }>} The formatted array of objects.
 */
// export const OptionValuesFormat = (
//   list: ListItems[]
// ): { value: string; label: string } => {
//   const options = list?.map((item) => ({
//     ...item,
//     value: item.key,
//     label: item.key,
//   }));
//   return options;
// };+
/**
+ * Returns a function that checks if a given date is before the current date.
+ *
+ * @param {Date} currentDate - The current date.
+ * @return {Function} A function that takes a date and returns true if the date is before the current date, false otherwise.
+ */
export const disablePrevDates = (
  currentDate: Date
): ((date: Date) => boolean) => {
  return (date: Date) => {
    return date && date < currentDate;
  };
};
/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} str - The input string to capitalize.
 * @return {string} The string with the first letter of each word capitalized.
 */
export const CapitalizeWords = (str: string): string => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
/**
 * Debounces the given value by delaying the update of the debounced value.
 *
 * @param {string} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds before updating the debounced value.
 * @return {string} The debounced value.
 */
export const Debounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handle = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handle);
    };
  }, [value, delay]);
  return debouncedValue;
};
export const getLocalStorageAccountInfo = <T>(key: string): T | null => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

/**
 * Handles errors from the API and dispatches an action to display the error message.
 *
 * @param error - The error object from the API response.
 * @param defaultErrorMessage - The default error message to display if the API response does not contain one.
 * @param dispatch - The Redux dispatch function.
 */
export const handleApiErrorFunction = (
  error: IErrorObject,
  dispatch: Dispatch,
  defaultErrorMessage: string
): string => {
  // Extract error message from the API response, depending on the HTTP status code.
  let errorMessage = '';
  switch (error?.response?.status) {
    // Errors that may contain a custom error message from the API.
    case HttpStatusCode.InternalServerError:
    case HttpStatusCode.BadRequest:
    case HttpStatusCode.NotFound:
    case HttpStatusCode.Unauthorized:
    case HttpStatusCode.Forbidden:
      errorMessage = defaultErrorMessage || error?.response?.data?.errorMessage;
      break;
    // For other errors, use the default error message.
    default:
      errorMessage = defaultErrorMessage;
      break;
  }
  return errorMessage;
  // Dispatch an action to display the error message.
  // dispatch({ type: FETCH_ERROR, payload: errorMessage });
};
export const buildURL = (baseURL: string, params: object): string => {
  const url = new URL(baseURL);

  Object.keys(params).forEach((key) => {
    const value = params[key as keyof object];
    if (value !== undefined && value !== '') {
      url.searchParams.append(key, value);
    }
  });

  return url.toString();
};
export const isLoggedIn = (): boolean => {
  // Replace with actual login check logic
  return !!localStorage.getItem(localStorageEnums.userInfo);
};

export const spacePattern = '^.*\\S.*$';
export const emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$';

export const handleApiError = (
  error: AxiosError<IApiErrorResponse>,
  defaultMessage = ''
): void => {
  const errorMessage = defaultMessage || error?.response?.data?.message || '';

  const message = errorMessage?.trim();
  console.error(message);
  // error?.response?.status !== 401 && toast.error(message);
};

interface SubCategory {
  key: string;
  label: string;
  route: string;
}

interface Category {
  key: string;
  label: string;
  // route?: string; // Uncomment if you have predefined routes
  children: SubCategory[];
}

interface VehicleListing {
  children: Category[];
}

export const getVehicleListingFromLocalStorage = (): VehicleListing => {
  const vehicleListing = JSON.parse(
    localStorage.getItem(localStorageEnums.settings) as string
  );
  if (!vehicleListing) {
    return { children: [] }; // Handle the case where the data is not available
  }
  const transformSubCategories = (
    subCategories: { name: string }[]
  ): { key: string; label: string }[] => {
    return subCategories?.map((subCategory) => {
      return {
        key: subCategory?.name?.toLowerCase()?.replace(/ /g, ''),
        label: subCategory?.name,
      };
    });
  };
  const transformedListing = {
    children: vehicleListing?.setting?.vehicleCategory?.map(
      (category: { name: string; subCategory: { name: string }[] }) => {
        return {
          key: category?.name?.toLowerCase()?.replace(/ /g, ''),
          label: category?.name,
          children: transformSubCategories(category?.subCategory),
        };
      }
    ),
  };

  return transformedListing;
};

export const bookingCalculation = (params: BookingParams): BookingDetails => {
  const {
    dateRangeCal,
    hrRate,
    hr12Rate,
    hr24Rate,
    depositRate,
    taxRatePercentage,
    depositPercentage,
  } = params;

  // Format start and end dates
  const startDate = new Date(`${dateRangeCal.startDate}`).getTime();
  const endDate = new Date(`${dateRangeCal.endDate}`).getTime();

  // Calculate total hours
  const millisecondsDifference = endDate - startDate;
  const totalHours = millisecondsDifference / (1000 * 60 * 60);

  // Calculate vehicleRate based on total hours
  let vehicleRateAfterCalculation = 0;
  const includedTimes = { days: 0, hours: 0 };

  if (totalHours > HOURS_IN_TWELVE) {
    includedTimes.hours = totalHours;
    includedTimes.days = Math.ceil(totalHours / HOURS_IN_DAY);
    vehicleRateAfterCalculation = includedTimes.days * hr24Rate;
  } else if (totalHours === HOURS_IN_TWELVE) {
    includedTimes.hours = totalHours;
    vehicleRateAfterCalculation = (totalHours / HOURS_IN_TWELVE) * hr12Rate;
  } else {
    includedTimes.hours = totalHours;
    vehicleRateAfterCalculation = totalHours * hrRate;
  }

  // Calculate final deposit rate
  let finalDepositRate =
    vehicleRateAfterCalculation * (depositPercentage / 100);
  if (finalDepositRate < depositRate) {
    finalDepositRate = depositRate;
  }

  // Calculate final tax amount
  const finalTaxAmount =
    vehicleRateAfterCalculation * (taxRatePercentage / 100);

  // Calculate final total cost
  const finalTotalCost =
    vehicleRateAfterCalculation + finalDepositRate + finalTaxAmount;

  let hrRateByOwner;
  switch (true) {
    case totalHours >= HOURS_IN_DAY:
      hrRateByOwner = hr24Rate;
      break;
    case totalHours > HOURS_IN_TWELVE:
      hrRateByOwner = hr24Rate;
      break;
    case totalHours === HOURS_IN_TWELVE:
      hrRateByOwner = hr12Rate;
      break;
    default:
      hrRateByOwner = hrRate;
      break;
  }

  // Return BookingDetails including hrRateUsed and includedTime
  return {
    vehicleRate: vehicleRateAfterCalculation,
    depositRate: finalDepositRate,
    taxAmount: finalTaxAmount,
    totalCost: finalTotalCost,
    includedTime: includedTimes,
    hrRateUsed: hrRateByOwner,
  };
};

export const getLocalStorageSettingData = (): UserSettingsData => {
  const storedValue = localStorage.getItem('settings');
  return storedValue ? JSON.parse(storedValue) : null;
};
export const getMonthDateRange = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startDate: string | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endDate: string | null
): {
  startDate: string | null;
  endDate: string | null;
} => {
  const today = startDate ? new Date(startDate) : new Date();
  let startDateFormatted = today;
  // Calculate the end date (same day next month)
  const nextMonth = today.getMonth() + 2;
  const previousMonth = today.getMonth() - 1;
  startDateFormatted = new Date(
    today.getFullYear(),
    previousMonth,
    today.getDate()
  );
  const endDateFormatted = new Date(
    today.getFullYear(),
    nextMonth,
    today.getDate()
  );

  return {
    startDate: startDateFormatted.toISOString(),
    endDate: endDateFormatted.toISOString(),
  };
};

export const isSingleDay = (vehicle: VehicleDetails): boolean => {
  if (vehicle?.bookingRateHourly || vehicle?.bookingRate12Hourly) {
    return true;
  }
  return false;
};

export const isMultipleDay = (vehicle: VehicleDetails): boolean => {
  if (vehicle?.bookingRate24Hourly) {
    return true;
  }
  return false;
};
export const getBookingRateTypeDisplay = (
  vehicle: VehicleDetails,
  selectionType: 'single' | 'multi'
): string => {
  if (selectionType === 'multi') {
    return ApplicationString.vehicleBookingStaticData.rates.per24Hour;
  }

  if (vehicle?.bookingRateHourly) {
    return ApplicationString.vehicleBookingStaticData.rates.perHour;
  }
  if (vehicle?.bookingRate12Hourly) {
    return ApplicationString.vehicleBookingStaticData.rates.per12Hour;
  }
  if (vehicle?.bookingRate24Hourly) {
    return ApplicationString.vehicleBookingStaticData.rates.per24Hour;
  }
  return '';
};

export const getBookingRate = (
  vehicle: VehicleDetails,
  selectionType: 'single' | 'multi'
): number => {
  if (selectionType === 'multi') {
    return vehicle?.bookingRate24Hourly ?? 0;
  }

  if (vehicle?.bookingRateHourly) {
    return vehicle?.bookingRateHourly;
  }
  if (vehicle?.bookingRate12Hourly) {
    return vehicle?.bookingRate12Hourly;
  }
  if (vehicle?.bookingRate24Hourly) {
    return vehicle?.bookingRate24Hourly;
  }
  return 0;
};

export const extractHour = (dateTime: string): number => {
  const date = new Date(dateTime);
  return date.getUTCHours();
};
export const addOneDay = (startDateStr: string, day: number): string => {
  const startDate = new Date(startDateStr); // Convert string to Date object
  startDate.setDate(startDate.getDate() + day); // Add one day

  // Format the resulting date back to ISO string format
  const formattedDate = startDate.toISOString();

  return formattedDate;
};
export const subOneDay = (startDateStr: string, day: number): string => {
  const startDate = new Date(startDateStr); // Convert string to Date object
  startDate.setDate(startDate.getDate() - day); // Add one day

  // Format the resulting date back to ISO string format
  const formattedDate = startDate.toISOString();

  return formattedDate;
};
export const compareISODay = (isoDate1: string, isoDate2: string): boolean => {
  const date1 = new Date(isoDate1);
  const date2 = new Date(isoDate2);

  // Extract year, month, and day from each date
  const day1 = date1.getUTCDate();
  const month1 = date1.getUTCMonth();
  const year1 = date1.getUTCFullYear();

  const day2 = date2.getUTCDate();
  const month2 = date2.getUTCMonth();
  const year2 = date2.getUTCFullYear();

  // Compare year, month, and day
  return year1 === year2 && month1 === month2 && day1 === day2;
};

export const getDifferenceInDays = (
  isoDate1: string,
  isoDate2: string
): number => {
  // dates to UTC
  const date1 = new Date(
    Date.UTC(
      new Date(isoDate1).getUTCFullYear(),
      new Date(isoDate1).getUTCMonth(),
      new Date(isoDate1).getUTCDate()
    )
  );
  const date2 = new Date(
    Date.UTC(
      new Date(isoDate2).getUTCFullYear(),
      new Date(isoDate2).getUTCMonth(),
      new Date(isoDate2).getUTCDate()
    )
  );

  // Calculate the difference in milliseconds
  const diffInMs = date2.getTime() - date1.getTime();
  // Convert milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  return diffInDays;
};
export const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  const formattedDate = `${String(year)}-${month}-${day}`;

  return formattedDate;
};
export const subtractHoursFromDate = (
  isoDate: string,
  hoursToSubtract: number
): string => {
  const date = new Date(isoDate);

  // Subtract hours from the date
  date.setHours(date.getHours() - hoursToSubtract);

  // Convert date back to ISO string format
  const formattedDate = date.toISOString();

  return formattedDate;
};
export const addHoursFromDate = (
  isoDate: string,
  hoursToAdd: number
): string => {
  const date = new Date(isoDate);
  // Subtract hours from the date
  date.setHours(date.getHours() + hoursToAdd);
  if (date.getUTCMinutes() === 59) {
    date.setHours(date.getHours() + 1);
  }
  // Convert date back to ISO string format
  const formattedDate = date.toISOString();

  return formattedDate;
};
const formatHour = (hour: number) => {
  const period = hour < 12 ? 'AM' : 'PM';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  const paddedHour = formattedHour < 10 ? `0${formattedHour}` : formattedHour;
  return `${paddedHour}:00 ${period}`;
};

const defaultTimeFrame = [
  {
    label: '12 am to 12 pm',
    value: {
      to: 13,
      from: 1,
    },
  },
];

const generateTimeSlots = (startHour: number, endHour: number) => {
  return Array.from({ length: endHour - startHour }, (_, i) => {
    const start = startHour + i;
    const end = start + 1;
    const startTime = formatHour(start);
    const endTime = formatHour(end);
    return { time: `${startTime} to ${endTime}`, disabled: false };
  });
};
const localStorageSettingData = getLocalStorageSettingData();
const bookingTimeConstraints =
  localStorageSettingData?.setting?.bookingTimeConstraints || {};

const startHour = bookingTimeConstraints.hourlyBookingStartHour ?? 8; // Default to 0 if null or undefined
const endHour = bookingTimeConstraints.hourlyBookingEndHour ?? 22; // Default to 24 if null or undefined

export const availabilitySlots1hr = generateTimeSlots(startHour, endHour);

const timeFrame12hr =
  localStorageSettingData?.setting?.['12hourlyTimeFrame'] ?? defaultTimeFrame;

export const slots12hr = timeFrame12hr;

interface TimeSlot {
  time: string;
  disabled: boolean;
}

export const createTimeSlots = (ranges: The12HourlyTimeFrame[]): TimeSlot[] => {
  return ranges?.map((range: { value: { to: number; from: number } }) => {
    const startTime = formatHour(range.value.from);
    const endTime = formatHour(range.value.to);
    return {
      time: `${startTime} to ${endTime}`,
      disabled: false,
    };
  });
};

export const availabilitySlots12hr = createTimeSlots(slots12hr);

export const getTimeSlot = (date: Date): string => {
  const hours24 = date.getHours();
  // Determine AM or PM suffix
  const suffix = hours24 >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const hours12 = hours24 % 12 || 12;

  // Format minutes with leading zero if necessary
  const formattedMinutes = '00';

  // Construct the time slot string
  const timeSlot = `${hours12}:${formattedMinutes} ${suffix}`;

  return timeSlot;
};

export const convertTo24HourFormat = (timeString: string): string => {
  const [time, period] = timeString.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (period === 'PM' && hours !== '12') {
    hours = String(Number(hours) + 12);
  } else if (period === 'AM' && hours === '12') {
    hours = '00';
  }

  return `${hours}:${minutes}`;
};

export interface BookingLocalstorage {
  endTime?: string;
  startTime?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
  bookingType?: 'single' | 'multi';
  deposit: number;
  endDate: string;
  numberOfPerson: number;
  rate: number;
  startDate: string;
  tax: number;
  total: number;
  vehicleId: number;
  timeSlot?: string;
  startFormattedDate?: string;
  endFormattedDate?: string;
}

// Function to convert 24-hour time to 12-hour format
export const convertTo12HourFormat = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const hours12 = (hours % 12 || 12).toString().padStart(2, '0');
  return `${hours12}:${minutes.toString().padStart(2, '0')}`;
};

export const convertTo12HourFormatForLabel = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = (hours % 12 || 12).toString().padStart(2, '0');
  return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const formatDateToYMD = (dateString: string): string => {
  const date = new Date(dateString);
  const yyyy = date.getUTCFullYear();
  const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getUTCDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

// Common function to get booking data from localStorage and transform it
export const getBookingFromLocalStorage = (): BookingLocalstorage | null => {
  const bookingData = localStorage.getItem('booking');
  if (bookingData) {
    try {
      const booking: BookingLocalstorage = JSON.parse(bookingData);

      // Convert StartTime and EndTime to 12-hour format
      if (booking.startTime && booking.endTime) {
        const startTime12Hour = convertTo12HourFormat(booking.startTime);
        const endTime12Hour = convertTo12HourFormat(booking.endTime);
        const startTime12HourLabel = convertTo12HourFormatForLabel(
          booking.startTime
        );
        const endTime12HourLael = convertTo12HourFormatForLabel(
          booking.endTime
        );

        if (booking.bookingType === 'single') {
          booking.timeSlot = `${startTime12HourLabel} to ${endTime12HourLael}`;
        } else if (booking.bookingType === 'multi') {
          booking.startTime = startTime12Hour;
          booking.endTime = endTime12Hour;
          booking.startTimeLabel = startTime12HourLabel;
          booking.endTimeLabel = endTime12HourLael;
        }
      }

      // Add startFormattedDate and endFormattedDate
      booking.startFormattedDate = formatDateToYMD(booking.startDate);
      booking.endFormattedDate = formatDateToYMD(booking.endDate);

      return booking;
    } catch (error) {
      console.error('Error parsing booking data from localStorage:', error);
      return null;
    }
  }
  return null;
};

const getErrorMessageFromValidationError = (
  validationError: string | { message: string }
): string => {
  if (typeof validationError === 'string') {
    return validationError;
  }
  return validationError.message;
};

const getErrorMessageFromApiError = (
  error: AxiosError<IApiErrorResponse>,
  defaultErrorMessage?: string
): string | undefined => {
  const validationErrors = error?.response?.data?.errors;
  const validationErrorMessage = validationErrors
    ? validationErrors?.map(getErrorMessageFromValidationError).join(',')
    : '';
  return (
    defaultErrorMessage ||
    validationErrorMessage ||
    error?.response?.data?.message
  );
};

export const showApiError = (
  error: AxiosError<IApiErrorResponse>,
  toastContext: ToastContextType,
  defaultErrorMessage?: string
): void => {
  const errorMessage = getErrorMessageFromApiError(error, defaultErrorMessage);

  if (errorMessage) {
    toastContext.error(errorMessage);
  }
};

export const getTimeValue = (timeLabel: string): number | null => {
  const time = timeFrameData.find((timeFrame) => timeFrame.label === timeLabel);
  return time ? time.value : null;
};

export const isValidDropOffTime = (
  dropOffTime: string,
  pickUpTime: string | null
): boolean => {
  if (pickUpTime === null) {
    return true;
  }
  const dropOffValue = getTimeValue(dropOffTime);
  const pickUpValue = getTimeValue(pickUpTime);

  if (dropOffValue === null || pickUpValue === null) {
    return true;
  }

  return dropOffValue <= pickUpValue;
};

export const isValidPickUpTime = (
  pickUpTime: string,
  dropOffTime: string | null
): boolean => {
  if (dropOffTime === null) {
    return true;
  }
  const pickUpValue = getTimeValue(pickUpTime);
  const dropOffValue = getTimeValue(dropOffTime);

  if (pickUpValue === null || dropOffValue === null) {
    return true;
  }

  return pickUpValue >= dropOffValue;
};

export enum DateTimeTypeForFormat {
  DATE,
  TIME,
}

export const formatDateRange = (
  utcStart: string,
  utcEnd: string
  // type: DateTimeTypeForFormat
): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function formatDate(utcDate: string) {
    const date = new Date(utcDate);
    const month = months[date.getUTCMonth()];
    const day = date.getDate();
    const year = date.getUTCFullYear();
    return `${month} ${day}, ${year}`;
  }

  const formattedStartDate = formatDate(utcStart);
  const formattedEndDate = formatDate(utcEnd);

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatDayMonth = (date: Date): string => {
  const newDate = new Date(date);

  // Get the day and month
  const day = newDate.getUTCDate();
  const month = newDate.toLocaleString('default', { month: 'long' });

  const formatedDate = `${day} ${month}`;
  return formatedDate;
};

export const populateYears = (
  startYear = 2000
): { label: number; value: number; key: number }[] => {
  const years: { label: number; value: number; key: number }[] = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= startYear; year -= 1) {
    years.push({ label: year, value: year, key: year });
  }
  return years;
};

export const formatSingleOrDateRange = (date: string[]): string => {
  if (!date || date.length === 0) return '';
  const formattedStartDate = dayjs.utc(date[0]).format(dateFormat);
  const formattedEndDate = dayjs.utc(date[1]).format(dateFormat);

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate; // Return only one date if they are the same
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

interface Image {
  path: string;
  isCover: boolean;
}

export const getCoverPhoto = (images: Image[]): string | undefined => {
  // Check if there's an image marked as cover
  const coverImage = images?.find((image) => image.isCover);

  // If a cover image is found, return its path
  if (coverImage) {
    return coverImage.path;
  }
  // If no cover image is found, return the path of the first image with a path
  const firstImageWithPath = images.find((image) => image.path);
  return firstImageWithPath ? firstImageWithPath.path : undefined;
};

export const getDateRangeFromDayjsDate = (
  selectedDates: Dayjs[]
): DateRange[] => {
  const dateRanges: DateRange[] = [];
  selectedDates.forEach((selectedDate) => {
    const dateRange: DateRange = {
      startDate: selectedDate.toISOString(),
      endDate: selectedDate.toISOString(),
    };
    dateRanges.push(dateRange);
  });
  return dateRanges;
};

export const onRefreshOverlapHandler =
  (
    stateSetter: (value: boolean) => void
  ): ((e: React.AnimationEvent<HTMLElement>) => void) =>
  (e: React.AnimationEvent<HTMLElement>) => {
    const targetElement = e.target as Element | null;
    const autoFilled = !!targetElement?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill') {
      stateSetter(autoFilled);
    }
    if (e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(autoFilled);
    }
  };

export const isNonformatTime = (timeString: string): string => {
  const [hoursStr, minutesStr] = timeString.split(':');
  // Convert to numbers
  const hours = parseInt(hoursStr, 10);
  let minutes = parseInt(minutesStr, 10);

  // Check for NaN in minutes and default to 00
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(minutes)) {
    minutes = 0;
  }

  // Format hours and minutes to be two digits
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Return formatted time string
  return `${formattedHours}:${formattedMinutes} AM`; // Adjust for AM/PM as needed
};

export const getBrowserName = (): string => {
  const browserInfo = navigator.userAgent;
  let currentBrowser;
  if (browserInfo.includes('Chrome')) {
    currentBrowser = 'Chrome';
  } else if (browserInfo.includes('Safari')) {
    currentBrowser = 'Safari';
  } else {
    currentBrowser = 'Chrome';
  }
  return currentBrowser;
};
export const calculateHours = (
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string
): number => {
  // Parse the start and end dates to ISO format
  const isoStartDate = new Date(startDate).toISOString().split('T')[0];
  const isoEndDate = new Date(endDate).toISOString().split('T')[0];

  // Create Date objects with the given times
  const startDateTime = new Date(
    `${isoStartDate}T${convertTo24HourFormat(startTime)}:00Z`
  );
  const endDateTime = new Date(
    `${isoEndDate}T${convertTo24HourFormat(endTime)}:00Z`
  );

  // Calculate the difference in hours
  const totalHours =
    (endDateTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60);

  return totalHours;
};

interface TimeSlots {
  to: number;
  from: number;
}

interface LabeledTimeSlot {
  label: string;
  value: {
    to: number;
    from: number;
  };
}

export const convertToLabeledTimeSlots = (
  slots: TimeSlots[]
): LabeledTimeSlot[] => {
  const formatTime = (hour: number): string => {
    const ampm = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12} ${ampm}`;
  };

  return slots?.map((slot) => ({
    label: `${formatTime(slot.from)} to ${formatTime(slot.to)}`,
    value: {
      to: slot.to,
      from: slot.from,
    },
  }));
};
export const convertToTitleCase = (str: string): string => {
  // Add spaces before uppercase letters and trim any leading/trailing spaces
  const spacedStr = str.replace(/([A-Z])/g, ' $1');
  // Convert the first letter of each word to uppercase
  const titleCaseStr = spacedStr.replace(/\b\w/g, (txt) => {
    return txt.toUpperCase();
  });
  return titleCaseStr;
};

export const checkMaxWordLength = (
  value: string,
  maxLen: number,
  errorMessage: string
): boolean | string => {
  const maxLength = maxLen;
  if (value && value.length > maxLength) {
    return errorMessage;
  }
  return false;
};

export const getSubcategoryNameById = (
  subcategories: ISubCategory[],
  id: number
): string => {
  const subcategoryId = subcategories.find(
    (subCat: ISubCategory) => subCat.id === id
  );
  return subcategoryId ? subcategoryId.name : '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  delay = 300
): ((...args: Parameters<F>) => void) => {
  let timerId: number | undefined;
  return (...args: Parameters<F>) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = window.setTimeout(() => func(...args), delay);
  };
};

import TextHeading from '../TextHeading';
import PreviewContentComponent from '../PreviewContent';
import { Data, IStepData } from '../../../../Interfaces/Stepper';
import { defaultStringNAValue } from '../../../../Constants/commonConst';
import ApplicationString from '../../../../Constants/applicationString';
import { localStorageEnums, stepsNumberEnum } from '../../../../Utils/enums';
import { getDetailsFromLocalStorage } from '../../../../Utils/utils';

const PreviewLocationDetails = ({
  isEdit,
}: {
  isEdit: boolean;
}): JSX.Element => {
  const stepsData = getDetailsFromLocalStorage<IStepData[]>(
    localStorageEnums.stepsData
  );

  const vehicleData = stepsData?.find(
    (obj: IStepData) => obj.step === stepsNumberEnum.step2
  );

  const formatAddress = ({
    address1,
    address2,
    city,
    state,
    zipCode,
  }: {
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zipCode: string | undefined;
  }): string => {
    const formattedAddress2 = address2 ? `, ${address2}` : '';

    if (!address1 && !city && !state && !zipCode) {
      return defaultStringNAValue;
    }
    return `${address1}${formattedAddress2} ${city}, ${state} ${zipCode}`;
  };

  const getFormattedAddress = (data: IStepData['data']['pickUp']): string => {
    const { address1, address2, city, state, zipCode } = data;

    if (!data) {
      return defaultStringNAValue;
    }

    return formatAddress({
      address1,
      address2,
      city,
      state,
      zipCode,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <TextHeading
          label={ApplicationString.vehicleStepper.previewStep.previewStep2}
          buttonText={ApplicationString.vehicleStepper.previewStep.edit}
          navigatePathStep={stepsNumberEnum.step2}
          isEdit={isEdit}
        />
      </div>
      <div className="flex flex-col justify-between items-start gap-4 md:flex-row">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step2Details.pickUp}
            description={getFormattedAddress(
              (vehicleData?.data?.pickUp as Data['pickUp']) || {}
            )}
            isRequired
          />
        </div>
        <div className="w-full text-left md:text-right">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step2Details.country}
            description={vehicleData?.data?.country || defaultStringNAValue}
            isRequired
          />
        </div>
      </div>

      <div className="flex justify-between items-start gap-4">
        <div className="w-full text-left">
          <PreviewContentComponent
            title={ApplicationString.vehicleStepper.step2Details.dropOff}
            description={getFormattedAddress(
              (vehicleData?.data?.dropOff as Data['dropOff']) || {}
            )}
            isRequired={false}
          />
        </div>
        <div className="w-full text-right">
          <PreviewContentComponent title="" description="" isRequired={false} />
        </div>
      </div>
    </div>
  );
};

export default PreviewLocationDetails;

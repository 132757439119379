import React from 'react';
import { useNavigate } from 'react-router-dom';
// import NewsLetterComponent from '../common/NewsLetter';
import OwnerHeroSection from '../OwnerComponent/OwnerHeroSection';
import PopularListing from '../OwnerComponent/PopularListing';
import TopRecommend from '../OwnerComponent/TopRecommendedDestinations';
import TestimonialComponent from '../OwnerComponent/Testimonial';
import ExploreSection from '../HomeComponent/ExploreSection';
import ListingExploreSection from '../HomeComponent/CreateListing';
import ExploreCardComponent from '../HomeComponent/Cards';
import ListingNearMeComponent from '../HomeComponent/ListingNearMe';
import SearchComponent from '../common/SearchComponent';
import SearchLayout from '../HomeComponent/SearchLayout';
import InternalRoute from '../../Utils/internalRoutes';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';

interface IHomeComponentProps {
  popularListings: IVehiclesData[];
  locationData: IVehiclesData[];
}
interface SearchProps {
  category: string;
  latlong: string;
  locationName: string;
  startDate: string;
  endDate: string;
}
const HomeComponent: React.FC<IHomeComponentProps> = ({
  popularListings,
  locationData,
}) => {
  const Navigate = useNavigate();
  const handleSearch = (data: SearchProps) => {
    const trimmedData = {
      ...data,
    };

    const queryParams = new URLSearchParams(trimmedData).toString();
    Navigate(InternalRoute.searchParamUrl.replace('#queryParams', queryParams));
  };
  return (
    <>
      <OwnerHeroSection />
      <SearchLayout>
        <SearchComponent
          onSearch={handleSearch}
          initialCategory="allVehicles"
          initialLocation=""
          initialCoordinates=""
        />
      </SearchLayout>
      <ExploreSection />
      {popularListings?.length > 0 && (
        <PopularListing popularListings={popularListings} />
      )}
      <TopRecommend />
      <ExploreCardComponent />
      {locationData?.length > 0 && (
        <ListingNearMeComponent listingData={locationData} />
      )}

      <ListingExploreSection />
      <TestimonialComponent />
      {/* <NewsLetterComponent /> */}
    </>
  );
};

export default HomeComponent;

import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApplicationString from '../../Constants/applicationString';
import { formatSingleOrDateRange } from '../../Utils/utils';
import chevronSvg from '../../Assets/Svgs/chevron.svg';
import InternalRoute from '../../Utils/internalRoutes';
import { BookingsDetails } from '../BookingDetails/bookingInterface';

interface IProps {
  isLoading: boolean;
  bookingData: BookingsDetails[];
}
const BookingPersonList: React.FC<IProps> = ({ isLoading, bookingData }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams({});
  const vehicleId = params.get('vehicleId');
  const [filteredBookingData, setFilteredBookingData] = useState<
    BookingsDetails[]
  >([]);

  useEffect(() => {
    const filterData = bookingData?.filter(
      (item) =>
        item?.ownerDetails?.id !== item?.renterDetails?.id ||
        (item?.ownerDetails?.id === item?.renterDetails?.id &&
          item?.total !== 0)
    );
    setFilteredBookingData(filterData);
  }, [bookingData]);

  const renderLoading = () => (
    <div className="flex items-center h-full justify-center">
      <CircularProgress disableShrink />
    </div>
  );

  const renderNoBookings = () => (
    <div>
      <h1 className="font-semibold text-gray-500">
        {ApplicationString.OwnerBookingDetails.noBookings}
      </h1>
    </div>
  );

  const navigateToDetails = (id: number) => {
    const bookingDetailsURL = InternalRoute.OwnerBookingDetails.replace(
      ':id',
      String(id)
    );
    navigate(bookingDetailsURL, {
      state: {
        from: `${InternalRoute.OwnerMyCalendar}?vehicleId=${vehicleId}`,
      },
    });
  };

  const renderBookings = () => (
    <List
      data-testid="owner-vehicle-booking-list"
      className="gap-3 flex flex-col"
    >
      {filteredBookingData?.map((item) => (
        <ListItem
          onClick={() => navigateToDetails(item.id)}
          data-testid={`owner-vehicle-booking-${item.vehicleId}`}
          className="p-2 bg-gray-100 cursor-pointer rounded-lg  dark:bg-dark_bg_surface  dark:bg-dark_bg_gray_primary"
          key={item.id}
        >
          <ListItemAvatar>
            <Avatar src={item.renterDetails?.avatarPath} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div>
                <h1 className="font-semibold break-all">
                  {item.renterDetails?.name}
                </h1>
              </div>
            }
            secondary={formatSingleOrDateRange([item.startDate, item.endDate])}
          />
          <Button>
            <img
              src={chevronSvg}
              aria-label="button"
              data-testid={`owner-booking-details-${item.vehicleId}`}
              alt={
                ApplicationString.OwnerDashboard.instantBooking
                  .moreDetailsChevronAltTxt
              }
            />
          </Button>
        </ListItem>
      ))}
    </List>
  );

  const renderContent = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (vehicleId && filteredBookingData.length > 0) {
      return renderBookings();
    }

    return renderNoBookings();
  };

  return (
    <div>
      <div className="">
        <div className="bg-white p-5 rounded-3xl  dark:bg-dark_bg_surface  dark:bg-dark_bg_gray_primary dark:border">
          <div className="flex flex-col gap-2">
            <p className="text-xl font-semibold ">
              {ApplicationString.OwnerCalendar.PersonListTitle}
            </p>
            <div className="h-[200px] lg:h-[400px] mb-3 overflow-y-auto">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPersonList;

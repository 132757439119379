import { AxiosError, HttpStatusCode } from 'axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import commmonRegexs from '../../../Utils/commonRegex';
import ApplicationString from '../../../Constants/applicationString';
import { ApiRequest } from '../../../Api/api';
import URLS from '../../../Constants/urls';
import { IApiErrorResponse } from '../../../Interfaces/interfaces';
import {
  EditProfileFormEnums,
  localStorageEnums,
  signupPasswordStrengthEnums,
} from '../../../Utils/enums';
import ChangePasswordComponent from '../../../Components/MyProfile/ChangePassword';
import {
  getLocalStorageAccountInfo,
  // handleApiError,
} from '../../../Utils/utils';
import { LoggedInUserInfoDataType } from '../../../Interfaces/Login/LoginInterfaces';
import ToastContext from '../../../Services/ToastService';
import InternalRoute from '../../../Utils/internalRoutes';

interface IErrorsData {
  password: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  confirmPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  currentPassword: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}

interface IPasswordQuality {
  passwordQuality: string;
  passwordMsgColor: string;
  passwordQualityIcon: JSX.Element | null;
}

interface IFormData {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

interface IChangePasswordPayload {
  existingPassword: string;
  password: string;
}

const ChangePasswordContainer: React.FC<{ goBack: () => void }> = ({
  goBack,
}) => {
  const accountId = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.accountId;
  const toast = useContext(ToastContext);
  const [isChangingPassword, setIsChangingPassword] =
    React.useState<boolean>(false);
  const [passwordQuality, setPasswordQuality] =
    React.useState<IPasswordQuality>({
      passwordQuality: '',
      passwordMsgColor: '',
      passwordQualityIcon: null,
    });
  const [formData, setFormData] = React.useState<IFormData>({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    currentPassword: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    password: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    confirmPassword: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
  });

  const navigate = useNavigate();
  const email = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.email;
  const mobileNumber =
    getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
      localStorageEnums.userInfo
    )?.mobile ?? null;
  const handleForgotPasswordClick = () => {
    switch (true) {
      case Boolean(email) && Boolean(mobileNumber):
        navigate(InternalRoute.EmailForgotPassword);
        break;
      case Boolean(email):
        navigate(InternalRoute.EmailForgotPassword);
        break;
      case Boolean(mobileNumber):
        navigate(InternalRoute.PhoneForgotPassword);
        break;
      default:
        console.error(
          ApplicationString.changePasswordlabels.validationMessages
            .defaultDetailsNotFoundMsg
        );
    }
  };

  const showValidationErrors = (err: AxiosError<IApiErrorResponse>) => {
    if (
      (err?.response?.status === HttpStatusCode.BadRequest ||
        err?.response?.status === HttpStatusCode.NotFound) &&
      formData.password.trim() &&
      formData.currentPassword.trim() &&
      formData.confirmPassword.trim()
    ) {
      setErrorsData((prevState) => ({
        ...prevState,
        password: {
          ...prevState.password,
          error: false,
          onlyErrorMsg: true,
          ValidationMessage:
            ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
              .password,
        },
        currentPassword: {
          ...prevState.currentPassword,
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: err.response?.data?.message || '',
        },
        confirmPassword: {
          ...prevState.confirmPassword,
          error: false,
          onlyErrorMsg: true,
          ValidationMessage:
            ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
              .confirmPassword,
        },
      }));
    }
  };
  const onPasswordSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (
      !commmonRegexs.signupPassword.test(formData.password) &&
      formData.password.trim() !== '' &&
      formData.confirmPassword.trim() !== '' &&
      formData.currentPassword.trim() !== ''
    )
      return;

    if (formData.password.trim() !== formData.confirmPassword.trim()) return;

    setErrorsData((prevState) => ({
      ...prevState,
      password: {
        error: !formData.password.trim(),
        ValidationMessage: !formData.password.trim()
          ? ApplicationString.changePasswordlabels.validationMessages
              .requiredMsgs.password
          : '',
      },
      currentPassword: {
        error: !formData.currentPassword.trim(),
        ValidationMessage: !formData.currentPassword.trim()
          ? ApplicationString.changePasswordlabels.validationMessages
              .requiredMsgs.currentPassword
          : '',
      },
      confirmPassword: {
        error: !formData.confirmPassword.trim(),
        ValidationMessage: !formData.confirmPassword.trim()
          ? ApplicationString.changePasswordlabels.validationMessages
              .requiredMsgs.confirmPassword
          : '',
      },
    }));

    if (
      !formData.currentPassword.trim() ||
      !formData.password.trim() ||
      !formData.confirmPassword.trim()
    )
      return;

    const payload: IChangePasswordPayload = {
      password: formData.confirmPassword,
      existingPassword: formData.currentPassword,
    };

    try {
      setIsChangingPassword(true);
      const response = await ApiRequest.put(
        accountId
          ? `${URLS.UPDATE_PASSWORD.replace('#{accountId}', accountId)}`
          : '',
        payload
      );
      if (response?.status === HttpStatusCode.NoContent) {
        toast?.success(ApplicationString.changePasswordlabels.passwordUpdated);
        setFormData({ currentPassword: '', password: '', confirmPassword: '' });
        setPasswordQuality({
          passwordQuality: '',
          passwordMsgColor: '',
          passwordQualityIcon: null,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showValidationErrors(axiosError);
      setIsChangingPassword(false);
    } finally {
      setIsChangingPassword(false);
    }
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let errorBoolean = false;
    let message = '';
    let checkConfirmPassword = false;
    let checkConfirmPasswordMsg = '';

    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');

    // Check password strength
    const hasSpecialChar = commmonRegexs.hasSpecialChar.test(trimmedPassword);
    const hasCapitalLetter =
      commmonRegexs.hasCapitalLetter.test(trimmedPassword);
    const hasSmallLetter = commmonRegexs.hasSmallLetter.test(trimmedPassword);
    const hasDigit = commmonRegexs.hasDigit.test(trimmedPassword);

    let quality = '';
    let qualityColor = '';
    let qualityIcon: JSX.Element | null = null;

    if (
      trimmedPassword.length >= 6 &&
      hasSpecialChar &&
      hasCapitalLetter &&
      hasSmallLetter &&
      hasDigit
    ) {
      quality = signupPasswordStrengthEnums.strong;
      qualityColor = 'text-green-500';
      qualityIcon = <EmojiEmotionsOutlinedIcon className="text-green-500" />;
    } else if (
      trimmedPassword.length >= 6 &&
      (hasSpecialChar || hasCapitalLetter || hasDigit || hasSmallLetter)
    ) {
      quality = signupPasswordStrengthEnums.medium;
      qualityColor = 'text-yellow-500';
      qualityIcon = (
        <SentimentSatisfiedOutlinedIcon className="text-yellow-500" />
      );
    } else if (trimmedPassword.length > 0) {
      quality = signupPasswordStrengthEnums.weak;
      qualityColor = 'text-red-500';
      qualityIcon = (
        <SentimentDissatisfiedOutlinedIcon className="text-red-500" />
      );
    }

    if (
      trimmedPassword !== '' &&
      formData.confirmPassword.trim() !== '' &&
      trimmedPassword !== formData.confirmPassword.trim()
    ) {
      checkConfirmPassword = true;
      checkConfirmPasswordMsg =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .confirmPassword;
    }

    if (
      !commmonRegexs.signupPassword.test(trimmedPassword) &&
      trimmedPassword !== ''
    ) {
      errorBoolean = true;
      message =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .password;
    }

    if (e.target.value.trim().length > EditProfileFormEnums.maxPasswordLength) {
      errorBoolean = true;
      message =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .passwordLength;
    }

    setFormData((prevState) => ({
      ...prevState,
      password: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      password: {
        error: errorBoolean,
        ValidationMessage: message,
      },
      confirmPassword: {
        error: checkConfirmPassword,
        ValidationMessage: checkConfirmPasswordMsg,
      },
    }));

    setPasswordQuality((prevState) => ({
      ...prevState,
      passwordQuality: quality,
      passwordMsgColor: qualityColor,
      passwordQualityIcon: qualityIcon,
    }));
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let errorBoolean = false;
    let message = '';
    if (formData.password !== e.target.value.trim()) {
      errorBoolean = true;
      message =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .confirmPassword;
    }
    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');

    setFormData((prevState) => ({
      ...prevState,
      confirmPassword: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      confirmPassword: {
        error: errorBoolean,
        ValidationMessage: message,
      },
    }));
  };

  const handleCurrentPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let errorBoolean = false;
    let message = '';
    const trimmedPassword = e.target.value
      .trim()
      .replaceAll(commmonRegexs.hasSpace, '');
    if (
      !commmonRegexs.signupPassword.test(e.target.value.trim()) &&
      trimmedPassword !== ''
    ) {
      errorBoolean = true;
      message =
        ApplicationString.NameAndPasswordSignUp.validationMsgs.invalidMsgs
          .password;
    }

    if (trimmedPassword.length > EditProfileFormEnums.maxPasswordLength) {
      errorBoolean = true;
      message =
        ApplicationString.changePasswordlabels.validationMessages
          .maxPasswordLength;
    }

    setFormData((prevState) => ({
      ...prevState,
      currentPassword: trimmedPassword,
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      currentPassword: {
        error: errorBoolean,
        ValidationMessage: message,
      },
    }));
  };
  return (
    <ChangePasswordComponent
      onPasswordSubmit={onPasswordSubmit}
      handleForgotPasswordClick={handleForgotPasswordClick}
      handlePasswordChange={handlePasswordChange}
      handleConfirmPasswordChange={handleConfirmPasswordChange}
      handleCurrentPasswordChange={handleCurrentPasswordChange}
      formData={formData}
      goBack={goBack}
      errorsData={errorsData}
      isChangingPassword={isChangingPassword}
      passwordQuality={passwordQuality}
    />
  );
};

export default ChangePasswordContainer;

import { Button } from '@mui/material';
import React from 'react';
import ApplicationString from '../../Constants/applicationString';
import { CancelBookingCalenderIcon } from '../../Assets/Svgs';
import LoadingButton from '../common/LoadingButton/LoadingButton';

type Props = {
  onCancelHandler: () => void;
  handleCloseCancelBookingModal: () => void;
  loading: boolean;
};

const MyBookingCancelComponent: React.FC<Props> = ({
  onCancelHandler,
  handleCloseCancelBookingModal,
  loading,
}) => {
  return (
    <div className="w-[90vw] max-w-125 min-h-max bg-white dark:bg-dark_bg_secondary p-6 rounded-2xl flex flex-col gap-1">
      <div>
        <CancelBookingCalenderIcon />
      </div>
      <div className="flex flex-col gap-1">
        <h1 className="text-xl 2xl:text-2xl font-bold text-primary_text dark:text-dark_primary_text ">
          {ApplicationString.MyBookingsCancelComponent.title}
        </h1>
        <h3 className="text-base font-normal text-secondary_text dark:text-dark_secondary_text">
          {ApplicationString.MyBookingsCancelComponent.subTitle}
        </h3>
      </div>
      <div className="flex-col flex xsm:flex-row gap-2 mt-6">
        {loading ? (
          <LoadingButton
            buttonText={
              ApplicationString.MyBookingsCancelComponent.cancelingBooking
            }
            extraBtnClasses="bg-transparent border border-black"
            extraTxtClasses="text-primary_text dark:text-dark_primary_text"
          />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            data-testid="my-bookings-cancel-booking-button"
            onClick={onCancelHandler}
            className="w-full flex justify-center rounded-4xl normal-case sm:text-base font-bold sm:py-3 border-dark_bg_surface text-dark_bg_surface dark:border-dark_primary_text dark:text-dark_primary_text"
          >
            {ApplicationString.MyBookingsCancelComponent.cancelBookingBtn}
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          data-testid="my-bookings-back-button"
          onClick={handleCloseCancelBookingModal}
          className="w-full flex justify-center rounded-4xl normal-case sm:text-base font-bold sm:py-3 dark:text-dark_bg_secondary "
        >
          {ApplicationString.MyBookingsCancelComponent.goBackBtn}
        </Button>
      </div>
    </div>
  );
};

export default MyBookingCancelComponent;

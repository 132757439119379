import { AxiosError, HttpStatusCode } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRequest } from '../../Api/api';
import SignUpWithEmailComponent from '../../Components/SignUpComponents/SignUpWithEmailComponent';
import ApplicationString from '../../Constants/applicationString';
import URLS from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import commmonRegexs from '../../Utils/commonRegex';
import { verificationTypeEnums } from '../../Utils/enums';
import InternalRoute from '../../Utils/internalRoutes';
import { handleApiError } from '../../Utils/utils';

interface IApiPayload {
  verificationOf: string;
  verificationType: string;
}

const SignUpWithEmailContainer: React.FC = () => {
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<{ email: string }>({
    email: '',
  });
  const [errorsData, setErrorsData] = useState<{
    email: {
      error: boolean;
      ValidationMessage: string;
    };
  }>({
    email: {
      error: false,
      ValidationMessage: '',
    },
  });

  const getVerificationId = async (payload: IApiPayload) => {
    try {
      const res = await ApiRequest.post(
        `${URLS.USER_GET_VERIFICATION_ID}`,
        payload
      );
      const verificationId = window.btoa(res.data.verificationId);
      const encodedEmail = window.btoa(formData.email);
      navigate(
        `${InternalRoute.EmailVerificationCode}?verificationId=${verificationId}&email=${encodedEmail}`
      );
    } catch (error) {
      setErrorsData((prevState) => ({
        ...prevState,
        email: {
          error: true,
          ValidationMessage:
            ApplicationString.commonInvalidMsg.somethingWentWrong,
        },
      }));
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
    }
  };

  // Varification of email that if it exist or not
  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!commmonRegexs.email.test(formData.email) && formData.email !== '')
      return;
    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: !formData.email.trim(),
        ValidationMessage: !formData.email.trim()
          ? ApplicationString.signUpWithEmail.validationMessages.requiredMsgs
              .email
          : '',
      },
    }));
    if (!formData.email.trim()) return;

    const payload = {
      verificationOf: formData.email,
      verificationType: verificationTypeEnums.email,
    };
    try {
      setIsVerifying(true);
      const res = await ApiRequest.post(`${URLS.USER_EXISTS}`, payload);
      if (res?.status === HttpStatusCode.Ok) {
        if (res?.data && JSON.stringify(res?.data) !== '{}') {
          if (!res.data.isExists) {
            getVerificationId(payload);
          } else {
            setErrorsData((prevState) => ({
              ...prevState,
              email: {
                error: true,
                ValidationMessage:
                  ApplicationString.signUpWithEmail.validationMessages
                    .invalidMsgs.alreadyExistEmail,
              },
            }));
          }
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
    } finally {
      setIsVerifying(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const email = e.target.value;
    const emailRegex = commmonRegexs.email;

    let emailError = false;
    let validationMessage = '';

    if (!emailRegex.test(email) && email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .invalidEmail;
    }

    if (email.length > 0 && email.length > 255) {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .maxEmailLength;
    }

    setFormData((prevState) => ({
      ...prevState,
      email: email.trim(),
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: emailError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };

  return (
    <SignUpWithEmailComponent
      formData={formData}
      onFormSubmit={onFormSubmit}
      errorsData={errorsData}
      handleEmailChange={handleEmailChange}
      isVerifying={isVerifying}
    />
  );
};

export default SignUpWithEmailContainer;

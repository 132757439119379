import { IThe12HourlyTimeFrame } from '../../../Interfaces/Settings/SettingsInterfaces';
import {
  IStepperFormData,
  TimeFrameFor12Hourly,
} from '../../../Interfaces/Stepper';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';

const findMatchingLabels = (
  firstList: IThe12HourlyTimeFrame[],
  secondList: TimeFrameFor12Hourly[]
) => {
  const matchedLabels: string[] = [];

  secondList.forEach((secondItem) => {
    const matchingItem = firstList.find(
      (firstItem) =>
        firstItem.value.to === secondItem.to &&
        firstItem.value.from === secondItem.from
    );

    if (matchingItem) {
      matchedLabels.push(matchingItem.label);
    }
  });

  return matchedLabels;
};

const mapAPIDataToFormData = (
  data: IVehiclesData,
  timeFrames: IThe12HourlyTimeFrame[]
): IStepperFormData => {
  let timeFrameLabels: string[] = [];
  if (data.timeFrameFor12Hourly && data.timeFrameFor12Hourly.length > 0)
    timeFrameLabels = findMatchingLabels(timeFrames, data.timeFrameFor12Hourly);
  const dataToSet = {
    step1: {
      title: data.title ? data.title : '',
      description: data.description ? data.description : '',
      capacity: data.capacity ? data.capacity : null,
      category: data.category ? data.category : null,
      subCategory: data.subCategory ? data.subCategory : null,
      engineSize: data.metaData?.engineSize ? data?.metaData?.engineSize : '',
      hitchSize: data?.metaData.hitchSize ? data?.metaData?.hitchSize : '',
      maker: data.maker ? data.maker : '',
      manufacturingYear: data.manufacturingYear
        ? String(data.manufacturingYear)
        : '',
      model: data.model ? data.model : '',
      ownerShipType: data?.metaData?.ownershipType
        ? data?.metaData.ownershipType
        : null,
      size: data.size ? data.size : null,
      trailer: data?.metaData?.isTrailerIncluded
        ? data.metaData?.isTrailerIncluded
        : null,
    },
    step2: {
      country: data.country ? data.country : '',
      isSameAddress: data.isAddressSame as boolean | undefined,
      pickUp: {
        latLong: data.latLong ? data.latLong : '',
        address1: data.address ? data.address : '',
        address2: data?.address2 ? data?.address2 : '',
        city: data?.city ? data?.city : '',
        state: data?.state ? data?.state : '',
        zipCode: data?.zip ? data?.zip : '',
      },
      dropOff: {
        dropLatLong: data.dropLatLong ? data.dropLatLong : '',
        address1: data.dropAddress ? data.dropAddress : '',
        address2: data?.dropAddress2 ? data?.dropAddress2 : '',
        city: data?.dropCity ? data?.dropCity : '',
        state: data?.dropState ? data?.dropState : '',
        zipCode: data?.dropZip ? data?.dropZip : '',
      },
    },
    step3: {
      images: data.images ? data.images : [],
    },
    step4: {
      amenities: data.metaData?.amenities ? data.metaData?.amenities : [],
      restrictions: data?.metaData?.restricted
        ? data?.metaData?.restricted
        : [],
    },
    step5: {
      isHourly: !!data.bookingRateHourly,
      is12Hourly: !!data.bookingRate12Hourly,
      is24Hourly: !!data.bookingRate24Hourly,

      rateHourly: data.bookingRateHourly
        ? Number(data.bookingRateHourly)
        : null,
      rate12Hourly: data.bookingRate12Hourly
        ? Number(data.bookingRate12Hourly)
        : null,
      rate24Hourly: data.bookingRate24Hourly
        ? Number(data.bookingRate24Hourly)
        : null,
      isMultipleBookingOnSameDay: data.isMultipleBookingSameDay,
      maxDaysFor24Hourly: data.maxDaysFor24Hourly
        ? data?.maxDaysFor24Hourly
        : null,
      gapHour: data.bookingGap ? Number(data.bookingGap) : null,
      shortNoticePeriod: data.shortNoticePeriod
        ? Number(data.shortNoticePeriod)
        : null,
      timeFrame: timeFrameLabels,
      monthLimit: data?.monthLimit ? (data?.monthLimit as number) : null,
    },
    step6: {
      termsAndCondition: data.metaData?.termsAndCondition
        ? data.metaData?.termsAndCondition
        : null,
    },
  };
  return dataToSet;
};

export default mapAPIDataToFormData;
